import React from "react";

const SettingsButton = ({ unit, selectedUnit, onClick, width }) => {
  return (
    <button
      className={`h-9 rounded-[17px] text-center align-middle text-gray-900 hover:bg-[rgba(255,255,255,0.73)] hover:text-black ${selectedUnit === unit ? "bg-white text-black" : ""}`}
      style={{ width }}
      onClick={() => onClick(unit)}
    >
      {unit}
    </button>
  );
};

export default SettingsButton;
