import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import "../../../Assets/styles/GISStyle.css";
import { useDispatch, useSelector } from "react-redux";
import X2JS from "x2js";
import { gisLeafletActions } from "../../../redux/slices/GIS/gis-leaflet-slice";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { addIco } from "../../../Assets/icons/gisIcons/icons";
import "../../../Assets/styles/GISStyle.css";
import Cookies from "js-cookie";
import { fetchData } from "../../../Utils/indexedDB";
import toast from "react-hot-toast";
import L from "leaflet";
const segregateAssets = (objectsArray) => {
  const Assets = [];
  objectsArray.forEach((obj) => {
    const dateMatch = obj.Name.match(/\d{2}_\d{2}_\d{4}/);
    if (dateMatch) {
      const date = dateMatch[0];
      const agpObject = { date, ...obj };
      Assets.push(agpObject);
    } else {
      Assets.push(obj);
    }
  });
  return Assets;
};

function transformLayers(layers) {
  // Helper function to format the date
  //   function formatDate(dateStr) {
  //     const dateParts = dateStr.split("-");
  //     const day = dateParts[0];
  //     const month = dateParts[1];
  //     const year = dateParts[2];

  //     const monthNames = [
  //       "January",
  //       "February",
  //       "March",
  //       "April",
  //       "May",
  //       "June",
  //       "July",
  //       "August",
  //       "September",
  //       "October",
  //       "November",
  //       "December",
  //     ];
  //     const monthName = monthNames[parseInt(month, 10) - 1];

  //     return `${day}, ${monthName} ${year}`;
  //   }

  //   // Create an object to group layers by date
  //   const groupedLayers = {};

  //   // Iterate through each layer
  //   layers.forEach((layer) => {
  //     // Extract the date from the layerName
  //     const dateMatch = layer?.layerName?.match(/\d{2}_\d{2}_\d{4}/);
  //     if (dateMatch) {
  //       const date = dateMatch[0].replace(/_/g, "-");

  //       // If the date is not in the groupedLayers object, add it
  //       if (!groupedLayers[date]) {
  //         groupedLayers[date] = [];
  //       }

  //       // Add the layer to the corresponding date group
  //       groupedLayers[date].push({
  //         layerName: layer.layerName,
  //         type: layer.layerType,
  //       });
  //     }
  //   });

  //   // Convert the groupedLayers object into the desired format
  //   const result = Object.keys(groupedLayers).map((date) => ({
  //     section: {
  //       title: formatDate(date),
  //     },
  //     assets: groupedLayers[date],
  //   }));
  //   console.log(layers, "layers");

  return layers;
}

const GeneralIndexing = ({ openAssetIndexing, mapRef }) => {
  const [assetData, setAssetData] = useState([]);
  const clientName = Cookies.get("clientName");
  const [projectData, setProjectData] = useState(null);
  const dispatch = useDispatch();
  const selectedAsset = useSelector((state) => state.gisLeaflet.asset);
  const showComparer = useSelector((state) => state.gisTools.showComparer);
  useEffect(() => {
    const initializeProjectData = async () => {
      try {
        const projectDataFromDB = await fetchData("project");
        setProjectData(projectDataFromDB);
      } catch (error) {
        console.error("Failed to fetch project data:", error);
      }
    };

    initializeProjectData();
  }, []);

  const projectLayers = useMemo(() => projectData?.photogrammetryLayers);
  // console.log(projectLayers);
  const map = mapRef.current;
  //   console.log(projectLayers, "projectLayers");

  // console.log(projectLayers);

  useEffect(() => {
    const geoServerClientReferenace = clientName?.toLowerCase();

    // const wmsUrl = `https://gis.indrones.com/geoserver/${clientName}/wms`;
    // Step 1: GetLayers
    const getCapabilities = async () => {
      const response = await axios.get(
        `https://gis.indrones.com/geoserver/${geoServerClientReferenace}/wms?request=GetCapabilities`,
      );
      const x2js = new X2JS();
      const jsonObj = x2js.xml2js(response.data);
      // console.log(jsonObj, "jsonobj");
      const layers = jsonObj.WMS_Capabilities.Capability.Layer.Layer;
      const groupedData = segregateAssets(layers);
      setAssetData([]);
      setAssetData(groupedData);
      if(geoServerClientReferenace==="mrvc"){
        const setDefaultAsset = groupedData.find(
          (asset) => asset.Name === "ORTHO_MRVC",
        );
        dispatch(gisLeafletActions.setAsset(setDefaultAsset));
      }else{
        const setDefaultAsset = groupedData.find(
          (asset) => asset.Name === "HMEL_ORTHO_14-04-2024_355.8_TO_364.8_COG",
        );
        dispatch(gisLeafletActions.setAsset(setDefaultAsset));
      }
    };
    getCapabilities();
  }, []);

  /**
   * function to index the retrived data form the geoserver
   * @param {Array[]} originalData
   */
  function convertMixedData(originalData) {
    const formattedData = [];

    // Group data by date
    const groupedData = originalData.reduce((acc, entry) => {
      const date = entry.split("_")[2];
      acc[date] = acc[date] || [];
      acc[date].push(entry);
      return acc;
    }, {});

    // Process each date group
    Object.keys(groupedData).forEach((date) => {
      const entries = groupedData[date];
      const sections = {};

      // Organize entries into sections by start chainage
      entries.forEach((entry) => {
        const parts = entry.split("_");
        const startChainage = parseFloat(parts[3]);
        sections[startChainage] = sections[startChainage] || [];
        sections[startChainage].push(entry);
      });

      // Convert sections to formatted data and sort them by start chainage
      Object.keys(sections)
        .sort((a, b) => parseFloat(a) - parseFloat(b)) // Sort sections by start chainage
        .forEach((startChainage) => {
          const entriesInSection = sections[startChainage];
          const endChainage = parseFloat(entriesInSection[0].split("_")[5]);
          const title = `${startChainage}km to ${endChainage}km`;
          const section = {
            startChainage,
            endChainage,
            title,
          };
          const assets = entriesInSection.map((entry) => ({
            id: entry,
            type: entry.startsWith("HMEL_DSM") ? "DSM" : "ORI",
          }));
          formattedData.push({ section, assets });
        });
    });

    return formattedData;
  }

  const logNames = (assetData) => {
    // console.log(assetData);
    let assetNameArry = assetData.map((asset) => asset.Name);
    // console.log(assetNameArry);
    return assetNameArry;
  };

  const dataToPass = logNames(assetData);
  // const formattedData = convertMixedData(dataToPass);

  const selectedAssetEquals = (assetTitle) => {
    if (assetTitle !== selectedAsset?.Title) {
      return false;
    } else if (selectedAsset?.Title === undefined) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    dataToPass?.map((asset) => {
      asset !== null &&
        dispatch(gisLeafletActions.setAvaliableRasterAssets(asset));
    });
  }, [assetData]);

  return (
    <div
      className={`${
        openAssetIndexing ? "block" : "hidden"
      } relative mb-2 ml-2 mr-2 mt-2 min-w-[18rem] !grow overflow-x-hidden rounded-tl-md rounded-tr-md border-[1px] border-solid border-[#d7d8d8] bg-[#fff]`}
    >
      <div className="flex w-full items-center rounded-tl-[1px] rounded-tr-[1px] bg-[#ffca0094] pb-[10px] pt-[10px] font-[500]">
        <div className="flex grow items-center justify-center">Assets</div>
      </div>
      <div className="overflow-x-hidden">
        {projectLayers?.layers ? (
          <Accordion className={`!my-0 !p-0 text-sm shadow-none`}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="!m-0 flex min-h-max !items-center !justify-center"
            >
              <p className="text-sm">{projectLayers?.nameToShow}</p>
            </AccordionSummary>
            {projectLayers.layers.map((layer, index) => (
              <AccordionDetails className="!m-0 w-full grow !p-2">
                {/* {asset.assets.map((asset, index) => ( */}
                <Tooltip placement="right" key={index} title={"Click to add "}>
                  <div
                    className={`my-2 flex grow items-center rounded-md py-1 hover:bg-[#ffea00b4] ${
                      selectedAsset?.Name === layer?.layerName
                        ? "bg-[#ecfc61]"
                        : showComparer === true
                          ? "bg-white"
                          : ""
                    }`}
                    key={index}
                  >
                    <div
                      className={`flex w-full cursor-pointer items-center justify-between px-2 pr-[10px]`}
                      onClick={() => {
                        const assetToSet = assetData.find(
                          (item) => item?.Name === layer.layerName,
                        );

                        if (assetToSet) {
                          dispatch(gisLeafletActions.setAsset(assetToSet));
                          const {
                            westBoundLongitude,
                            eastBoundLongitude,
                            northBoundLatitude,
                            southBoundLatitude,
                          } = assetToSet?.EX_GeographicBoundingBox;

                          // Create Leaflet LatLngBounds object
                          const southWest = [
                            parseFloat(southBoundLatitude),
                            parseFloat(westBoundLongitude),
                          ];
                          const northEast = [
                            parseFloat(northBoundLatitude),
                            parseFloat(eastBoundLongitude),
                          ];
                          const bounds = [southWest, northEast];

                          // Fit the map to the bounds
                          map.fitBounds(bounds);
                        } else {
                          toast.error("Opps! Data not found");
                        }
                      }}
                    >
                      <p
                        className={`pl-4 text-sm ${
                          selectedAsset?.Name === layer.layerName
                            ? null
                            : "border-l-2"
                        }`}
                      >
                        {layer?.layerType}
                      </p>
                      <span className="text-xs"></span>

                      <img
                        src={addIco}
                        className="my-auto h-5 rounded-full border-2 border-[#808080]"
                        alt=""
                        srcSet=""
                      />
                    </div>
                  </div>
                </Tooltip>
                {/* ))} */}
              </AccordionDetails>
            ))}
          </Accordion>
        ) : (
          transformLayers(projectLayers)?.map((asset, index) => {
            // console.log(asset);
            return (
              <Accordion
                key={asset?.section?.title + 1 + index}
                className={`!my-0 !p-0 text-sm shadow-none`}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="!m-0 flex min-h-max !items-center !justify-center"
                >
                  <p className="text-sm">{asset?.nameToShow}</p>
                </AccordionSummary>
                <AccordionDetails className="!m-0 w-full grow !p-2">
                  {/* {asset.assets.map((asset, index) => ( */}
                  <Tooltip
                    placement="right"
                    key={asset?.id + index}
                    title={"Click to add "}
                  >
                    <div
                      className={`my-2 flex grow items-center rounded-md py-1 hover:bg-[#ffea00b4] ${
                        selectedAsset?.Name === asset?.layerName
                          ? "bg-[#ecfc61]"
                          : showComparer === true
                            ? "bg-white"
                            : ""
                      }`}
                      key={index}
                    >
                      <div
                        className={`flex w-full cursor-pointer items-center justify-between px-2 pr-[10px]`}
                        onClick={() => {
                          const assetToSet = assetData.find(
                            (item) => item?.Name === asset.layerName,
                          );
                          dispatch(gisLeafletActions.setAsset(assetToSet));
                          const {
                            westBoundLongitude,
                            eastBoundLongitude,
                            northBoundLatitude,
                            southBoundLatitude,
                          } = assetToSet?.EX_GeographicBoundingBox;

                          const bounds = L.latLngBounds(
                            L.latLng(
                              parseFloat(southBoundLatitude),
                              parseFloat(westBoundLongitude),
                            ),
                            L.latLng(
                              parseFloat(northBoundLatitude),
                              parseFloat(eastBoundLongitude),
                            ),
                          );
                          map.fitBounds(bounds);
                        }}
                      >
                        <p
                          className={`pl-4 text-sm ${
                            selectedAsset?.Name === asset.name
                              ? null
                              : "border-l-2"
                          }`}
                        >
                          {asset?.layerType}
                        </p>
                        <span className="text-xs"></span>

                        <img
                          src={addIco}
                          className="my-auto h-5 rounded-full border-2 border-[#808080]"
                          alt=""
                          srcSet=""
                        />
                      </div>
                    </div>
                  </Tooltip>
                  {/* ))} */}
                </AccordionDetails>
              </Accordion>
            );
          })
        )}
      </div>
    </div>
  );
};

export default GeneralIndexing;
