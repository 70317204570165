import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Checkbox, IconButton, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { filterToggleActions } from "../../../redux/slices/Videography/filter-toggle-slice";

const filterList = [
  { label: "Nallah Crossing", filterName: "NALLA" },
  { label: "Road Crossing", filterName: "ROAD" },
  { label: "River Crossing", filterName: "RIVER" },
  { label: "Bridges", filterName: "BR" },
  { label: "Station", filterName: "STATION" },
  { label: "FOB", filterName: "FOB" },
  { label: "ROB", filterName: "ROB" },
];

const FilterAnnotations = (props) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const dispatch = useDispatch();
  const filteredList = useSelector((state) => state.filterToggle.filterList);
  const [searchValue, setSearchValue] = React.useState("");
  const [checkedCheckboxes, setCheckedCheckboxes] =
    React.useState(filteredList);

  React.useEffect(() => {
    const item = filteredList.find((obj) => obj.index === -1);
    if (item) {
      setSearchValue(item.filter);
    }
  }, [filteredList]);

  const handleCheckboxChange = (index, filter) => {
    const updatedCheckboxes = [...checkedCheckboxes];
    const existingIndex = updatedCheckboxes.findIndex(
      (item) => item.index === index,
    );

    if (existingIndex !== -1) {
      updatedCheckboxes.splice(existingIndex, 1);
    } else {
      updatedCheckboxes.push({ index, filter });
    }
    setCheckedCheckboxes(updatedCheckboxes);

    const filters = [...updatedCheckboxes];
    const item = filteredList.find((obj) => obj.index === -1);
    if (item) {
      filters.push(item);
    }

    dispatch(filterToggleActions.setFilter(filters));
  };

  const handleSearch = (event) => {
    event.preventDefault();

    const value = event.target.value;

    let filters = [...filteredList];
    if (value !== "") {
      const item = filteredList.find((obj) => obj.index === -1);
      if (item) {
        filters = filters.filter((item) => item.index !== -1);
      }
      filters.push({ index: -1, filter: value });
    } else {
      filters = filters.filter((item) => item.index !== -1);
    }
    setSearchValue(value);
    dispatch(filterToggleActions.setFilter(filters));
  };

  return (
    <div className="bg-box-model ml-[10px] flex w-[300px] flex-col gap-2 rounded-[12px] bg-white">
      <div className="flex w-full items-center rounded-tl-[12px] rounded-tr-[12px] bg-[#ffca0094] pb-[10px] pt-[10px] font-[500]">
        <div className="flex grow items-center justify-center">Filter</div>
        <div className="mr-[10px]" onClick={props.openFilterPopper}>
          <IconButton aria-label="delete" size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
      <div className="mx-auto w-[80%]">
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          size="small"
          value={searchValue}
          fullWidth
          onChange={handleSearch}
        />
      </div>
      <div className="flex flex-col p-2">
        {filterList.map((filter, index) => {
          var isChecked = false;
          if (filteredList.length) {
            isChecked = filteredList.some(
              (item) => item.filter === filter.filterName,
            );
          }
          return (
            <>
              <div
                key={index}
                className="flex w-[100%] items-center justify-between"
              >
                <Checkbox
                  color="default"
                  size="small"
                  id={`checkbox${index + 1}`}
                  {...label}
                  onChange={() =>
                    handleCheckboxChange(index, filter.filterName)
                  }
                  checked={isChecked}
                />
                <div className={`"text-black" grow`}>{filter.label}</div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default FilterAnnotations;
