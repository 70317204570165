import React, { useState } from "react";
import LogoImg from "../../Assets/images/ID_LOGO_S_BLACK-07.png";
import InspectLogo from "../../Assets/images/inspect-single-logo.png";
import BackgroundImg from "../../Assets/images/ID_D_LOGO_YELLOW-16.png";
import BackgroundImgsecond from "../../Assets/images/ID_LOGO_S_WHITE-09.png";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
// import { LoginApi } from "../../apis/LoginApis";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { InchargeApis } from "../../Services/SecondPartyApi/Incharge";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import Cookies from "js-cookie";
import { userActions } from "../../redux/slices/user-slice";

const DemoLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const isEmailValid = (email) => {
    // Regular expression for a basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const params = {};
  const handleSignin = async () => {
    // Check for null or empty email
    if (!email) {
      setEmailError("Please enter your email.");
      setPasswordError("");
      return;
    }

    // Check for valid email format
    if (!isEmailValid(email)) {
      setEmailError("Please enter a valid email address.");
      setPasswordError("");
      return;
    }

    // Check for null or empty password
    if (!password) {
      setPasswordError("Please enter your password.");
      setEmailError("");
      return;
    }

    params.username = email.toLocaleLowerCase();
    params.password = password;
    params.user_app = "Inspect";
    const { token, userDetails, status, message } =
      await InchargeApis.authenticateUser(params);
    //   await LoginApi.authenticateUser(params);
    if (message === "success") {
      toast.success("Sign in Successfully");
    } else if (message === "password is incorrect") {
      toast.error("password is incorrect");
      setPasswordError("password is incorrect");
    }
    if (status === 200) {
      const { access } = token;

      const getPerms = await axios.get(
        `https://delivery.inspect.indrones.com/api/v1/role_permission/asigned_permission/${userDetails.role_name}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      //   dispatch(projectActions.setAccessId(access));
      Cookies.set("user_permissions", getPerms.data.permissions);
      Cookies.set("access_token", access);
      dispatch(userActions.setUser(userDetails));
      Cookies.set("userId", userDetails.user_id);
      Cookies.set("userEmail", userDetails.email);
      Cookies.set("userFirstName", userDetails.first_name);
      Cookies.set("userLastName", userDetails.last_name);
      toast.success("Login Successful!");
      navigate("/catalog");
    } else if (status === 404) {
      toast.error("User not found!");
    } else {
      toast.error("Wrong password!");
    }

    // Clear any previous error messages
    setEmailError("");
    setPasswordError("");
  };

  return (
    <>
      <div className="loginpage relative">
        <div className="absolute right-[0] top-[9rem] z-[99] flex justify-start opacity-[.45]">
          <img src={BackgroundImgsecond} alt="icon" width="20%" />
        </div>

        <div className="loginbody rounded-xl">
          <div className="heading_name">
            <div className="flex flex-row gap-4">
              <img src={InspectLogo} alt="logo" className="h-10 w-auto" />
              <h3 className="relative right-[11px] text-[30px] font-[700] text-[#4a4844]">
                Inspect
              </h3>
            </div>
            <h3 className="text-[1.1rem] font-[600] text-[#868279]">
              Good to see you again
            </h3>
          </div>
          <div className="flex flex-col gap-2">
            <label className="font-[500] text-[#9f9999]">Your email</label>
            <TextField
              className={`w-full ${emailError && "border-red-500"}`}
              variant="outlined"
              placeholder="e.g. inspect@indrones.com"
              type="text"
              size="Normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={Boolean(emailError)}
              helperText={emailError}
              InputProps={{
                style: {
                  fontSize: "16px",
                  borderRadius: "8px",
                  fontWeight: "500",
                  background: "white",
                },
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="font-[500] text-[#9f9999]">Your password</label>
            <TextField
              className={`login-pass w-full ${passwordError && "border-red-500"}`}
              variant="outlined"
              placeholder="e.g. indrones1235"
              type={showPassword ? "text" : "password"}
              size="Normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={Boolean(passwordError)}
              helperText={passwordError}
              InputProps={{
                style: {
                  fontSize: "16px",
                  borderRadius: "8px",
                  fontWeight: "500",
                  background: "white",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={`flex w-[100%]`} onClick={handleSignin}>
            <div className="labelBtn relative mt-[10px] w-[100%] cursor-pointer rounded-lg border-[0.5px] border-solid border-[#E8E7E5] !bg-[#ffca42] px-[19px] py-[15px] text-center text-[17px] font-[600] tracking-[0.18px] !text-[#4a4844]">
              Sign in
            </div>
          </div>
        </div>
        <div className="absolute right-[-34%] top-[17rem] z-[99] opacity-5">
          <img src={BackgroundImg} alt="icon" width="50%" />
        </div>
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerStyle={{ marginTop: 44 }}
      />
    </>
  );
};

export default DemoLogin;
