import { Checkbox, IconButton, Modal } from "@mui/material";
import axios from "axios";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const style = {
  height: "auto",
  width: 400,
  boxShadow: 24,
};

function formatDate(inputDateString) {
  // Create a new Date object from the input string
  const date = new Date(inputDateString);
  const options = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return formattedDate;
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const TimelineModal = ({ project, open, handleClose, isCompare }) => {
  const navigate = useNavigate();
  const [compareMsg, setCompareMsg] = React.useState("");
  const [projectTimelines, setProjectTimelines] = useState([]);
  const [checkedCheckboxes, setCheckedCheckboxes] = React.useState([]);
  const { projectMeta } = useSelector((state) => state.projectMeta);
  const nameForRoute = projectMeta.routeId;
  const closeModal = () => {
    handleClose();
    setCheckedCheckboxes([]);
    setCompareMsg("");
  };

  React.useEffect(() => {
    const getTimelines = async () => {
      const response = await axios.get(
        `https://delivery.inspect.indrones.com/api/v1/timeline/get_by_project/${project}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      // console.log("timeline", response.data);
      setProjectTimelines(response.data);
    };
    getTimelines();
  }, [project]);

  const handleFly = (location) => {
    navigate(`/vdo/${nameForRoute}/surveillance/${location}`);
  };

  const handleCheckboxChange = (index, round) => {
    const updatedCheckboxes = [...checkedCheckboxes];
    const existingIndex = updatedCheckboxes.findIndex(
      (item) => item.index === index,
    );

    if (existingIndex !== -1) {
      updatedCheckboxes.splice(existingIndex, 1);
    } else {
      updatedCheckboxes.push({ index, round });
    }
    setCheckedCheckboxes(updatedCheckboxes);
  };

  const handleCompareScreen = () => {
    if (checkedCheckboxes.length === 2) {
      const [checkbox1, checkbox2] = checkedCheckboxes;
      var round1, round2;
      if (checkbox1.round > checkbox2.round) {
        round1 = checkbox2.round;
        round2 = checkbox1.round;
      } else {
        round1 = checkbox1.round;
        round2 = checkbox2.round;
      }
      navigate(`/vdo/${nameForRoute}/compare/${round1}_${round2}`);
    } else if (checkedCheckboxes.length < 2 || !checkedCheckboxes.length) {
      setCompareMsg("Please select atleast 2 choices!");
      document.getElementById("errorText").classList.add("shake");
    } else {
      setCompareMsg("Only two choices are allowed!");
      document.getElementById("errorText").classList.add("shake");
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        className="flex items-center justify-center"
      >
        <div
          style={style}
          className="flex h-min w-full flex-col items-center justify-between rounded-[12px] bg-white"
        >
          <div className="mb-[8px] flex w-full items-center justify-between rounded-tl-[12px] rounded-tr-[12px] border-b bg-[#ffca0094] pb-[10px] pt-[10px] font-[500]">
            <h3 className="flex grow items-center justify-center !text-[1.20rem] text-[#211f1c]">
              Timeline
            </h3>
            <div className="mr-[10px]">
              <IconButton
                onClick={closeModal}
                aria-label="Close"
                size="small"
                className="text-[#211f1c]"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="w-full grow p-3 pb-2 pt-0">
            {projectTimelines.map((timeline, index) => {
              return (
                <>
                  {timeline.active && (
                    <div
                      key={index}
                      className="mx-auto my-[10px] flex w-[100%] items-center justify-between rounded-lg border bg-white p-2"
                    >
                      {isCompare && (
                        <Checkbox
                          {...label}
                          color="default"
                          size="small"
                          id={`checkbox${index}`}
                          disabled={timeline.compare ? false : true}
                          onChange={() =>
                            handleCheckboxChange(index, timeline.id)
                          }
                        />
                      )}
                      <div className={`grow`}>{timeline.name}</div>
                      {!isCompare && (
                        <div>
                          <button
                            className={`rounded bg-[#F8F6F2] px-[18px] py-[5px] text-[#8E887A] hover:bg-[#F6F1E9]`}
                            onClick={() => {
                              handleFly(timeline.id);
                            }}
                          >
                            Go
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </>
              );
            })}
          </div>
          {isCompare && (
            <>
              <div
                id="errorText"
                className="mb-3 text-center text-sm font-medium text-[#ff0000]"
              >
                {compareMsg}
              </div>

              <div className="mb-[20px] flex items-center">
                <button
                  onClick={handleCompareScreen}
                  className="h-[32px] !rounded-[5px] rounded bg-[#4a4844] px-[20px] py-[5px] text-[14px] text-white hover:bg-[#716e69]"
                >
                  Go
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default TimelineModal;
