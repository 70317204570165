import db from './db';
import { encryptData, decryptData } from './encryption';

const ensureDbOpen = async () => {
  if (!db.isOpen()) {
    await db.open();
  }
};

const storeData = async (storeName, data, id = 1) => {
    try {
      await ensureDbOpen();
      const encryptedData = await encryptData(data);
      await db[storeName].add({ id, [`${storeName}Data`]: encryptedData });
    } catch (error) {
      console.error(`Failed to store ${storeName} data in IndexedDB:`, error);
    }
  };
  
  const fetchData = async (storeName, id = 1) => {
    try {
      await ensureDbOpen();
      const record = await db[storeName].get(id);
      if (!record) {
        return null;
      }
      const decryptedRecord= await decryptData(record[`${storeName}Data`]);
      return decryptedRecord
    } catch (error) {
      console.error(`Failed to fetch ${storeName} data from IndexedDB:`, error);
    }
  };
  
  const updateData = async (storeName, id, data) => {
    try {
      await ensureDbOpen();
      const encryptedData = await encryptData(data);
      await db[storeName].put({ id, [`${storeName}Data`]: encryptedData });
    } catch (error) {
      console.error(`Failed to update ${storeName} data in IndexedDB:`, error);
    }
  };
  
  // Helper function to generate a unique key based on parameters
const generateRequestKey = (bbox, layerName) => `${bbox}_${layerName}`;

const storeWFSData = async (bbox, layerName, data) => {
  try {
    await ensureDbOpen();
    const requestKey = generateRequestKey(bbox, layerName);
    const encryptedData = await encryptData(data);
    await db.wfsCache.put({ id: requestKey, requestKey, data: encryptedData });
  } catch (error) {
    console.error('Failed to store WFS data in IndexedDB:', error);
  }
};

const fetchWFSData = async (bbox, layerName) => {
  try {
    await ensureDbOpen();
    const requestKey = generateRequestKey(bbox, layerName);
    const record = await db.wfsCache.get(requestKey);
    if (!record) {
      return null;
    }
    const decryptedData = await decryptData(record.data);
    return decryptedData;
  } catch (error) {
    console.error('Failed to fetch WFS data from IndexedDB:', error);
  }
};

const updateWFSData = async (bbox, layerName, data) => {
  try {
    await ensureDbOpen();
    const requestKey = generateRequestKey(bbox, layerName);
    const encryptedData = await encryptData(data);
    await db.wfsCache.put({ id: requestKey, requestKey, data: encryptedData });
  } catch (error) {
    console.error('Failed to update WFS data in IndexedDB:', error);
  }
};
export { storeData, fetchData, updateData, storeWFSData, fetchWFSData, updateWFSData  };
