import { useEffect } from "react";
import { whiteLogo } from "../../../Assets/icons/gisIcons/icons";

const BrandingAndStatsBarLeaflet3D = ({ isSidebaropen }) => {
  // effect for size of bottom bar
  useEffect(() => {
    const otherItem = document.getElementById("GISSidebarBodyLeaflet");
    const showComparer = false;

    if (otherItem) {
      const width = otherItem.offsetWidth;
      const component = document.getElementById("BrandingAndStatsBar");

      if (component) {
        showComparer
          ? (component.style.width = `calc(100vw)`)
          : (component.style.width = `calc(100vw - ${width}px)`);
      }
    }
  }, [isSidebaropen]);

  return (
    <footer
      id="BrandingAndStatsBar"
      className="absolute bottom-0 left-[100%] z-50 flex w-full items-center justify-between px-2 py-1"
    >
      <div className="flex gap-4">
        <img
          src={whiteLogo}
          className="h-4"
          alt="Indrones white name logo"
          srcSet=""
        />

        <p className="flex cursor-default items-center text-[10px] text-white">
          Rights reserved
        </p>
      </div>
      <div></div>

      <div className="statsSection mr-1 flex items-center gap-4"></div>
    </footer>
  );
};

export default BrandingAndStatsBarLeaflet3D;
