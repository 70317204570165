import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gisToolActions } from "../../../redux/slices/GIS/gis-tools-slice";
import { gisLeafletActions } from "../../../redux/slices/GIS/gis-leaflet-slice";
import { closeIco } from "../../../Assets/icons/gisIcons/icons";

const ComparerUIL = () => {
  const dispatch = useDispatch();
  const {  avaliableRasterAssets } = useSelector(
    (state) => state.gisLeaflet
  );
  const [leftAsset, setLeftAsset] = useState("");
  const [rightAsset, setRightAsset] = useState("");

  const handleLeftAssetChange = (event) => {
    const selectedAsset = event.target.value;

    const newLeftAsset = selectedAsset;

    if (newLeftAsset !== rightAsset) {
      setLeftAsset(newLeftAsset);
      dispatch(
        gisLeafletActions.setCompareRasterLayers({
          leftLayer: newLeftAsset,
          rightLayer: rightAsset,
        })
      );
    }
  };

  const handleRightAssetChange = (event) => {
    const selectedAsset = event.target.value;
    if (selectedAsset !== leftAsset) {
      setRightAsset(selectedAsset);
      dispatch(
        gisLeafletActions.setCompareRasterLayers({
          leftLayer: leftAsset,
          rightLayer: selectedAsset,
        })
      );
    }
  };
  return (
    <>
      <div className="flex z-[1000] absolute top-0 w-full  justify-between p-4">
        <div className="z-[1000] relative ">
          <label
            htmlFor="left-select"
            id="left-select-label "
            className=" absolute -top-2 left-2 bg-[#EBF5FB] text-xs  tracking-wide px-1 rounded-md"
          >
            Left Asset
          </label>
          <select
            id="left-select"
            value={leftAsset}
            onChange={handleLeftAssetChange}
            className="z-[1000] rounded-md text-sm min-w-max bg-[#EBF5FB] border !border-[#3498DB] focus:ring-[#3498DB]"
          >
            <option value="selectLeft">Select Left Layer</option>
            {avaliableRasterAssets?.map(
              (asset) =>
                asset !== rightAsset && (
                  <option key={asset} value={asset} className="text-sm">
                    {asset}
                  </option>
                )
            )}
          </select>
        </div>
        <div className="z-[1000] relative">
          <label
            htmlFor="right-select"
            id="right-select-label"
            className="absolute -top-2 left-2 bg-[#EAFAF1] text-xs tracking-wide px-1 rounded-md "
          >
            Right Asset
          </label>
          <select
            id="right-select"
            value={rightAsset}
            onChange={handleRightAssetChange}
            className="z-[1000] rounded-md text-sm min-w-max border !border-[#28B463] focus:ring-[#28B463] bg-[#EAFAF1]"
          >
            <option value="selectRight">Select Right Layer</option>
            {avaliableRasterAssets?.map(
              (asset) =>
                asset !== leftAsset && (
                  <option key={asset} value={asset} className="text-sm">
                    {asset}
                  </option>
                )
            )}
          </select>
        </div>
      </div>
      <button
        className="h-10 z-[500] absolute flex justify-center items-center gap-2 bottom-8 right-16 bg-red-500  border text-white border-red-600 pr-4 pl-3 py-1 rounded-[20px] cursor-pointer text-center hover:bg-red-600 hover:border-red-800 hover:text-white font-bold  "
        onClick={() => {
          dispatch(
            gisLeafletActions.setCompareRasterLayers({
              leftLayer: "",
              rightLayer: "",
            })
          );
          dispatch(gisToolActions.toggleTools("showComparer"));
        }}
      >
        <img src={closeIco} alt="Close Icon" /> <span>Exit Comparer</span>
      </button>
    </>
  );
};

export default ComparerUIL;
