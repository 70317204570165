import {
  Ion,
  Viewer,
  Math as MathOfCesium,
  Cesium3DTileset,
  CesiumTerrainProvider,
  Cartesian3,
  HeadingPitchRange,
  IonImageryProvider,
} from "cesium";
import { useEffect, useRef, useState } from "react";
import { fetchData } from "../../Utils/indexedDB";
import GISSidebarLeaflet from "./Leaflet/GISSidebarLeaflet";

const GISHome3d = () => {
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmNzhkZjc0ZS02YzQzLTRjMzAtODg3ZC0zOTk4MzFlMWRmNzgiLCJpZCI6MTU5MTU1LCJpYXQiOjE2OTIyODIwODV9._OhEfJPn8nLJ6LwuOSd7-Z3MB8MpQT_FwyxIOnWL2cI";
  const viewerRef = useRef(null);
  const [projectData, setProjectData] = useState(null);

  useEffect(() => {
    const initializeProjectData = async () => {
      try {
        const projectDataFromDB = await fetchData('project');
        setProjectData(projectDataFromDB);
      } catch (error) {
        console.error('Failed to fetch project data:', error);
      }
    };

    initializeProjectData();
  }, []);

  const asset =
    projectData?.photogrammetryLayers?.length > 0
      ? projectData.photogrammetryLayers[0]
      : "";


  // const asset = 2589234;
  useEffect(() => {
    try {
      Ion.defaultAccessToken = token;

      // If the viewer doesn't exist, create one
      if (!viewerRef.current) {
        viewerRef.current = new Viewer("cesiumContainer", {
          // viewer defaulf parameters

          requestRenderMode: true,
          maximumRenderTimeChange: Infinity,
          baseLayerPicker: true,
          timeline: false,
          homeButton: false,
          sceneModePicker: false,
          navigationHelpButton: false,
          fullscreenButton: false,
          animation: false,
          creditContainer: null,
          targetFrameRate: 60,
          contextOptions: {
            webgl: {
              alpha: true,
              depth: true,
              stencil: false,
              antialias: true,
              premultipliedAlpha: true,
              preserveDrawingBuffer: false,
              failIfMajorPerformanceCaveat: true,
            },
          },
        });
      }
    } catch (error) {}
  });

  useEffect(() => {
    const initCesium = async () => {
      try {
        const viewer = viewerRef.current;

        const baseLayer =
          viewer.imageryLayers.length > 0 ? viewer.imageryLayers.get(0) : null;

        const findTilesetInPrimitives = (primitives) => {
          for (let i = 0; i < primitives.length; i++) {
            const primitive = primitives.get(i);
            if (primitive instanceof Cesium3DTileset) {
              return primitive;
            }
          }
          return null;
        };

        if (baseLayer) {
          viewer.imageryLayers.removeAll();
          viewer.imageryLayers.addImageryProvider(baseLayer.imageryProvider);

          const existingTileset = findTilesetInPrimitives(
            viewer?.scene.primitives,
          );
          if (existingTileset) {
            viewer.scene.primitives.remove(existingTileset);
          }

          const terrainProvider = await CesiumTerrainProvider.fromIonAssetId(1);

          const tileset = await Cesium3DTileset.fromIonAssetId(asset.layerName);
          viewer.terrainProvider = terrainProvider;
          viewer.scene?.primitives.add(tileset);

          Promise.all([
            tileset.readyPromise,
            terrainProvider.readyPromise,
          ]).then(() => {
            const boundingSphere = tileset.boundingSphere;

            let offset = new HeadingPitchRange(
              0.0,
              -MathOfCesium.PI_OVER_TWO,
              boundingSphere.radius,
            );

            viewer.camera.flyToBoundingSphere(boundingSphere, {
              offset: offset,
              complete: () => {
                const screenCenterX = viewer.canvas.clientWidth / 2;
                const screenCenterY = viewer.canvas.clientHeight / 2;

                const assetPosition = viewer.scene.cartesianToCanvasCoordinates(
                  boundingSphere.center,
                );

                const deltaX = screenCenterX - assetPosition.x;
                const deltaY = screenCenterY - assetPosition.y;

                const adjustedPosition = viewer.camera.position.clone();
                adjustedPosition.x += deltaX;
                adjustedPosition.y += deltaY;

                viewer.camera.position = adjustedPosition;
              },
            });
          });
        } else {
          // setLoader(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (asset) {
      setTimeout(() => {
        initCesium();
      }, 2000);
    }
  }, [asset]);
  return (
    <>
      <div className="flex min-h-screen items-center justify-center bg-gray-100">
        <div id="cesiumContainer" className="h-[100vh]"></div>
      </div>
      {<GISSidebarLeaflet mapRef={null} />}
    </>
  );
};

export default GISHome3d;
