/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  svStationMarker,
  assetMarkerIco,
  highMarker
} from "../../../Assets/icons/gisIcons/icons";
import {
  kmMarkerIco,
  amMarkerIco,
  ifpMarkerIco,
  ofcMarkerIco,
  otherMarkerIco,
  tlpMarkerIco,
  tpoMarkerIco,
  wbMarkerIco,
  wpMarkerIco,
} from "../../../Assets/icons/markers/markers";
import legendData from "../../../Data/Jsons/LegendsData.json";
import legendMrvcData from "../../../Data/Jsons/LegendMrvcData.json";
import { useDispatch, useSelector } from "react-redux";
const LegendsModalLeaflet = ({ isOpen, onClose, map }) => {
  const {
    asset
  } = useSelector((state) => state.gisLeaflet);
  const iconMap = {
    svStationMarker: svStationMarker,
    assetMarkerIco: assetMarkerIco,
    kmMarkerIco: kmMarkerIco,
    amMarkerIco: amMarkerIco,
    ifpMarkerIco: ifpMarkerIco,
    ofcMarkerIco: ofcMarkerIco,
    otherMarkerIco: otherMarkerIco,
    tlpMarkerIco: tlpMarkerIco,
    tpoMarkerIco: tpoMarkerIco,
    wbMarkerIco: wbMarkerIco,
    wpMarkerIco: wpMarkerIco,
    chainagePointIcon:highMarker,
  };
  // Choose the appropriate legend data based on asset.Name
  const legendItems = asset?.Name === "ORTHO_MRVC" ? legendMrvcData : legendData;
  return (
    <div
      className={`z-[1000] ${isOpen ? "block" : "hidden"} absolute bottom-[168px] right-14 flex max-h-[288px] w-[236px] flex-col overflow-y-auto rounded-[9px] bg-[rgba(20,26,34,.725)] py-4 text-xs text-white`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="w-full px-4 font-bold">Map Legends</div>
      <div className="mt-4 flex max-h-96 flex-col gap-2 pb-1">
        {legendItems.map((item, index) => (
          <div key={index} className="row flex items-center px-4">
            {item.type === "color" && (
              <div
                className={`mr-2 h-4 w-[30px] rounded-sm border border-white`}
                style={{ backgroundColor: item.color }}
              ></div>
            )}
            {item.type === "line" && (
              <div className="relative mr-2 h-4 w-[30px] rounded-sm border border-white">
                <div
                  className="absolute inset-x-0 top-1/2 h-[2px] -translate-y-1/2 transform"
                  style={{ backgroundColor: item.color }}
                ></div>
              </div>
            )}
            {item.type === "icon" && (
              <img
                className="mb-[-6px] ml-2 mr-[14px] mt-[-2px] h-[30px] w-4"
                src={iconMap[item.src]}
                alt="icon"
              />
            )}
            <span className={`${item.type === "icon" ? "" : ""}`}>
              {item.text}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LegendsModalLeaflet;
