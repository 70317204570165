import "@cyntler/react-doc-viewer/dist/index.css";
import { Modal } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import {
  bookIcon,
  closeIco
} from "../../Assets/icons/gisIcons/icons";
const style = {
  position: "absolute",
  top: "64px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: "1200px",
  height: "calc(100% - 84px)",
  boxShadow: "0px 4px 4px 3px rgba(0, 0, 0, 0.25)",
  boxSizing: "border-box",
  background: "#FFFFFF",
  border: "1px solid rgba(58, 58, 60, 0.5)",
  borderRadius: "24px",
};

const DocViwerModal = ({ isOpen, onClose, industry }) => {

  const documents = industry.documents;
  const [pdfUrl, setPdfUrl] = useState(null);
  const [scale, setScale] = useState(1);

  const handleOpenPdf = (url,title) => {
    setPdfUrl({ uri: url, fileType: 'pdf',title: title});
    setScale(1); // Reset scale when opening a new PDF
  };

  const pdfObjectRef = useRef(null);

  //requires same rigin of pdf 
  // useEffect(() => {
  //   const hideDownloadButton = () => {
  //     const pdfObject = pdfObjectRef.current;
  //     if (!pdfObject) return;

  //     const pdfDoc = pdfObject.contentDocument || pdfObject.contentWindow.document;
  //     if (!pdfDoc) return;

  //     const downloadButton = pdfDoc.getElementById('downloads');
  //     if (downloadButton) {
  //       downloadButton.style.display = 'none';                    
  //     }
  //   };

  //   const handleLoad = () => {
  //     setTimeout(hideDownloadButton, 1000);
  //   };

  //   const pdfObject = pdfObjectRef.current;
  //   if (pdfObject) {
  //     pdfObject.addEventListener('load', handleLoad);
  //   }

  //   return () => {
  //     if (pdfObject) {
  //       pdfObject.removeEventListener('load', handleLoad);
  //     }
  //   };
  // }, [pdfUrl]);
  
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.3)", // Adjust the opacity as needed
        },
      }}
    >
      <div style={style} className="flex w-full flex-col px-12 pt-8 pb-12">
        <div className="flex h-[10%] w-full flex-row justify-between">
          <p className="text-2xl font-semibold tracking-widest">
            Inspect Document Viewer
          </p>
          <button
            id="closeButton"          
            onClick={onClose}
            className="absolute right-4 top-4 flex h-8 w-8 items-center justify-center overflow-hidden rounded-full transition duration-300 ease-in-out hover:bg-gray-300"
          >
            <div className="flex h-6 w-6 justify-center rounded-full bg-white">
              <img
                src={closeIco}
                alt="Close"
                className="h-6 w-6 invert-[100%] filter transition duration-300 ease-in-out"
              />
            </div>
          </button>
        </div>

        <div className="flex h-[90%] w-full flex-row" >
          <div className="flex w-[30%] flex-col ">
            <div className="flex flex-row">
              <img src={bookIcon} alt="bookIcon" className="mr-4 h-6 w-6" />
              <span className="font-medium tracking-wider">Documents</span>
            </div>
            <p className="mt-4 tracking-widest">{industry.name}</p>
            <div className="mt-4 flex flex-col overflow-y-auto">
              {documents.map((doc, index) => (
                <button
                  key={index}
                  className={`border-l p-2 text-left transition duration-200 ease-in-out ${
                    pdfUrl && pdfUrl.uri === doc.url ? "border-black font-bold" : "hover:border-black hover:bg-[#D9D9D9]"
                  }`}
                  onClick={() => handleOpenPdf(doc.url,doc.title)}
                >
                  {index + 1}. {doc.title}
                </button>
              ))}
            </div>
          </div>

          <div className="relative flex w-[70%] flex-col h-full overflow-hidden flex-grow">
          {pdfUrl ? (
            <div className="relative h-full w-full overflow-x-auto overflow-y-auto">
              <iframe
                title={pdfUrl.title || 'PDF Viewer'}
                id="pdfIframe"
                ref={pdfObjectRef}
                src={`${pdfUrl.uri}`}
                type="application/pdf"
                style={{ height: '100%', width: '100%', zIndex: 40 }}
              />
              <div
                id="hideButtons"
                className="absolute right-0 top-0 flex h-14 w-32 bg-[rgb(50,54,57)] z-50 text-white"
                style={{ display: 'block' }}
              ></div>
            </div>
          ) : (
            <div className="flex h-full items-center justify-center rounded-3xl bg-gray-200">
              Please select a document to view
            </div>
          )}
        </div>
        </div>
      </div>
    </Modal>
  );
};

export default DocViwerModal;




