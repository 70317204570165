import { convertTimeToSeconds, filterMarks } from "./OtherUtils";

const filterlabel = (label) => {
  if (label < 0) {
    return;
  } else if ((label.toFixed(1) * 10) % 2 === 0) {
    return label.toFixed(1);
  }
};

export const getLatLon = (data) => {
  var latLon = [];
  var seenTimes = new Set();
  for (let i = 0; i < data.length; i++) {
    if (!seenTimes.has(data[i].time_in_sec)) {
      seenTimes.add(data[i].time_in_sec);
      var obj = {
        lat: data[i].lat,
        lon: data[i].lon,
        time: data[i].time_in_sec,
        chainage: data[i].chainage,
      };
      latLon.push(obj);
    }
  }
  latLon.sort((a, b) => a.time - b.time);
  return latLon;
};

export const getChainage = (data, id) => {
  var chainages = [];
  for (let i = 0; i < data.length - 1; i++) {
    var Chainobj = {
      value: data[i].time_in_sec,
      label: filterlabel(data[i].chainage),
      tobeFiltered: true,
    };
    chainages.push(Chainobj);
  }
  var Firstobj = {
    value: data[0].time_in_sec,
    label: data[0].chainage.toFixed(1),
    tobeFiltered: false,
  };
  chainages.push(Firstobj);
  var Lastobj = {
    value: data[data.length - 1].time_in_sec,
    label: data[data.length - 1].chainage.toFixed(1),
    tobeFiltered: false,
  };
  chainages.push(Lastobj);

  const toBeFiltered = chainages?.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.label === item.label),
  );

  return filterMarks(toBeFiltered, id);
};

export const getAnnotation = (data) => {
  var annotations = [];
  for (let i = 0; i < data.length; i++) {
    var annoObj = {};
    if (data[i].description) {
      annoObj.id = data[i].id;
      annoObj.timeInSec = data[i].time_in_sec;
      annoObj.time = data[i].start_time;
      annoObj.description = data[i].description;
      annoObj.chainage = data[i].chainage.toFixed(1);
      annoObj.endTime = convertTimeToSeconds(data[i].end_time);
      annoObj.severity = "low";
      annotations.push(annoObj);
    }
  }
  return annotations.sort((a, b) => a.timeInSec - b.timeInSec);
};
