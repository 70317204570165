export const observationRisks = ["Low", "Medium", "High"];

export const observationsAndCategories = [
  { category: "Asset", type: "KM board" },
  { category: "Asset", type: "Warning board" },
  { category: "Asset", type: "Vent pipe" },
  { category: "Asset", type: "TLP" },
  { category: "Discolouration", type: "Discolouration" },
  { category: "Encroachment", type: "Building" },
  { category: "Encroachment", type: "Excavation area/digging" },
  { category: "Encroachment", type: "HT tower" },
  { category: "Encroachment", type: "Structure" },
  { category: "Encroachment", type: "Tree" },
  { category: "Encroachment", type: "Debris" },
  { category: "Encroachment", type: "Water body" },
  { category: "Encroachment", type: "Electric pole" },
  { category: "Encroachment", type: "Burnt ground" },
  { category: "Encroachment", type: "Borewell" },
  { category: "Vulnerable area", type: "Vulnerable area" },
];

export const observationTypes = [
  "KM board",
  "Warning board",
  "Vent pipe",
  "TLP",
  "Discolouration",
  "Building",
  "Excavation area/digging",
  "HT tower",
  "Structure",
  "Tree",
  "Debris",
  "Water body",
  "Electric pole",
  "Burnt ground",
  "Borewell",
  "Vulnerable area",
];

export const observationCategories = [
  "Asset",
  "Discolouration",
  "Vulnerable area",
  "Encroachment",
];

export const observationTaskStatus = [
  "Yet to start",
  "Started",
  "On Hold",
  "Legal action Initiated",
  "Resolved with permanent fix",
  "Resolved with temporary fix",
];

export const CSStationsList = [
  {
    name: "CS01",
    stationName: "Kakinada",
    state: "AP",
    latitude: 16.7465,
    longitude: 82.2995,
  },
  {
    name: "CS02",
    stationName: "Eluru",
    state: "AP",
    latitude: 16.7531,
    longitude: 81.0127,
  },
  {
    name: "CS03",
    stationName: "Suryapet",
    state: "TS",
    latitude: 17.1122,
    longitude: 79.7643,
  },
  {
    name: "CS04",
    stationName: "Pudur",
    state: "TS",
    latitude: 17.6132,
    longitude: 78.5347,
  },
  {
    name: "CS05",
    stationName: "Humnabad",
    state: "KA",
    latitude: 17.8191,
    longitude: 77.1709,
  },
  {
    name: "CS06",
    stationName: "Barshi",
    state: "MH",
    latitude: 18.261,
    longitude: 75.7486,
  },
  {
    name: "CS07",
    stationName: "Shirur",
    state: "MH",
    latitude: 18.6367,
    longitude: 74.3815,
  },
  {
    name: "CS08",
    stationName: "Kalyan",
    state: "MH",
    latitude: 19.2467,
    longitude: 73.2493,
  },
  {
    name: "CS09",
    stationName: "Valsad",
    state: "GJ",
    latitude: 20.5837,
    longitude: 73.0242,
  },
  {
    name: "CS10",
    stationName: "Bharuch",
    state: "GJ",
    latitude: 21.679,
    longitude: 72.8293,
  },
];

export const HMELDates = [
  {
    name: "HMEL_ORTHO_13-04-2024_382_TO_363_COG",
    timeline: "2024/04/13",
    id: 1,
    nameToShow: "13 April 2024",
    type: "ORI",
    chainage: {
      start: 363,
      end: 382,
    },
  },
  {
    name: "HMEL_ORTHO_13-04-2024_70_TO_78",
    timeline: "2024/04/13",
    id: 2,
    nameToShow: "13 April 2024",
    type: "ORI",
    chainage: {
      start: 70,
      end: 78,
    },
  },
  {
    name: "HMEL_ORTHO_14-04-2024_362_TO_345_COG",
    timeline: "2024/04/14",
    id: 3,
    nameToShow: "14 April 2024",
    type: "ORI",
    chainage: {
      start: 345,
      end: 362,
    },
  },
  {
    name: "HMEL_ORTHO_14-04-2024_362_TO_352_COG",
    timeline: "2024/04/14",
    id: 4,
    nameToShow: "14 April 2024",
    type: "ORI",
    chainage: {
      start: 352,
      end: 362,
    },
  },
  {
    name: "HMEL_ORTHO_14-04-2024_78_TO_96",
    timeline: "2024/04/14",
    id: 5,
    nameToShow: "14 April 2024",
    type: "ORI",
    chainage: {
      start: 78,
      end: 96,
    },
  },
  {
    name: "HMEL_ORTHO_15-04-2024_344_TO_327_COG",
    timeline: "2024/04/15",
    id: 6,
    nameToShow: "15 April 2024",
    type: "ORI",
    chainage: {
      start: 327,
      end: 344,
    },
  },
  {
    name: "HMEL_ORTHO_15-04-2024_96.3_TO_115",
    timeline: "2024/04/15",
    id: 7,
    nameToShow: "15 April 2024",
    type: "ORI",
    chainage: {
      start: 96.3,
      end: 115,
    },
  },
  {
    name: "HMEL_ORTHO_16-04-2024_115",
    timeline: "2024/04/16",
    id: 8,
    nameToShow: "16 April 2024",
    type: "ORI",
    chainage: {
      start: 115,
      end: null, // Assuming end is null as it's not provided
    },
  },
  {
    name: "HMEL_ORTHO_17-04-2024_148",
    timeline: "2024/04/17",
    id: 9,
    nameToShow: "17 April 2024",
    type: "ORI",
    chainage: {
      start: 148,
      end: null, // Assuming end is null as it's not provided
    },
  },
  {
    name: "HMEL_ORTHO_18-04-2024_169_TO_174",
    timeline: "2024/04/18",
    id: 10,
    nameToShow: "18 April 2024",
    type: "ORI",
    chainage: {
      start: 169,
      end: 174,
    },
  },
  {
    name: "HMEL_ORTHO_18-04-2024_275_TO_261",
    timeline: "2024/04/18",
    id: 11,
    nameToShow: "18 April 2024",
    type: "ORI",
    chainage: {
      start: 261,
      end: 275,
    },
  },
  {
    name: "HMEL_ORTHO_19-04-2024_185_TO_200",
    timeline: "2024/04/19",
    id: 12,
    nameToShow: "19 April 2024",
    type: "ORI",
    chainage: {
      start: 185,
      end: 200,
    },
  },
  {
    name: "HMEL_ORTHO_19-04-2024_261_TO_246_COG",
    timeline: "2024/04/19",
    id: 13,
    nameToShow: "19 April 2024",
    type: "ORI",
    chainage: {
      start: 246,
      end: 261,
    },
  },
  {
    name: "HMEL_ORTHO_20-04-2024_205.1_TO215",
    timeline: "2024/04/20",
    id: 14,
    nameToShow: "20 April 2024",
    type: "ORI",
    chainage: {
      start: 205.1,
      end: 215,
    },
  },
  {
    name: "HMEL_ORTHO_20-04-2024_245_TO_224",
    timeline: "2024/04/20",
    id: 15,
    nameToShow: "20 April 2024",
    type: "ORI",
    chainage: {
      start: 224,
      end: 245,
    },
  },
];

// export const HMELDates = [
//   {
//     name: "HMEL_ORTHO_13-04-2024_382_TO_363_COG",
//     timeline: "2024/04/13",
//     id: 1,
//     nameToShow: "13 April 2024",
//     type: "ORI",
//     chainage :{
//       start : 363 ,
//       end : 382
//     }
//   },
//   {
//     name: "HMEL_ORTHO_13-04-2024_70_TO_78",
//     timeline: "2024/04/13",
//     id: 2,
//     nameToShow: "13 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_14-04-2024_362_TO_345_COG",
//     timeline: "2024/04/14",
//     id: 3,
//     nameToShow: "14 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_14-04-2024_362_TO_352_COG",
//     timeline: "2024/04/14",
//     id: 4,
//     nameToShow: "14 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_14-04-2024_78_TO_96",
//     timeline: "2024/04/14",
//     id: 5,
//     nameToShow: "14 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_15-04-2024_344_TO_327_COG",
//     timeline: "2024/04/15",
//     id: 6,
//     nameToShow: "15 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_15-04-2024_96.3_TO_115",
//     timeline: "2024/04/15",
//     id: 7,
//     nameToShow: "15 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_16-04-2024_115",
//     timeline: "2024/04/16",
//     id: 8,
//     nameToShow: "16 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_17-04-2024_148",
//     timeline: "2024/04/17",
//     id: 9,
//     nameToShow: "17 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_18-04-2024_169_TO_174",
//     timeline: "2024/04/18",
//     id: 10,
//     nameToShow: "18 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_18-04-2024_275_TO_261",
//     timeline: "2024/04/18",
//     id: 11,
//     nameToShow: "18 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_19-04-2024_185_TO_200",
//     timeline: "2024/04/19",
//     id: 12,
//     nameToShow: "19 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_19-04-2024_261_TO_246_COG",
//     timeline: "2024/04/19",
//     id: 13,
//     nameToShow: "19 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_20-04-2024_205.1_TO215",
//     timeline: "2024/04/20",
//     id: 14,
//     nameToShow: "20 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_20-04-2024_245_TO_224",
//     timeline: "2024/04/20",
//     id: 15,
//     nameToShow: "20 April 2024",
//     type: "ORI",
//   },
// ];

// [
//   "DSM_13-04-2024_382_TO_363_COG",
//   "DSM_13-04-2024_70_TO_78.2_COG",
//   "DSM_14-04-2024_362_TO_345_COG",
//   "DSM_14-04-2024_362_TO_352_COG",
//   "DSM_14-04-2024_78_TO_96.5_COG",
//   "DSM_15-04-2024_344_TO_327_COG",
//   "DSM_15-04-2024_96.3_TO_115.8_COG",
//   "DSM_16-04-2024_115.5_TO_140_COG",
//   "DSM_16-04-2024_326_TO_296_COG",
//   "DSM_17-04-2024_148.8_TO_158_COG",
//   "DSM_18-04-2024_169_TO_174.1_COG",
//   "DSM_19-04-2024_185_TO_200.1_COG",
//   "DSM_20-04-2024_200_TO_224.3_COG",
//   "HMEL_ORTHO_13-04-2024_382_TO_363_COG",
//   "HMEL_ORTHO_13-04-2024_70_TO_78.2_COG",
//   "HMEL_ORTHO_14-04-2024_362_TO_345_COG",
//   "HMEL_ORTHO_14-04-2024_362_TO_352_COG",
//   "HMEL_ORTHO_14-04-2024_78_TO_96.5_COG",
//   "HMEL_ORTHO_15-04-2024_344_TO_327_COG",
//   "HMEL_ORTHO_15-04-2024_96.3_TO_115.8_COG",
//   "HMEL_ORTHO_16-04-2024_115.5_TO_140_COG",
//   "HMEL_ORTHO_17-04-2024_148.8_TO_158_COG",
//   "HMEL_ORTHO_18-04-2024_169_TO_174.1_COG",
//   "HMEL_ORTHO_18-04-2024_275_TO_261.5_COG",
//   "HMEL_ORTHO_19-04-2024_185_TO_200.1_COG",
//   "HMEL_ORTHO_19-04-2024_261_TO_246_COG",
//   "HMEL_ORTHO_20-04-2024_205.1_TO215.5_COG",
//   "HMEL_ORTHO_20-04-2024_245_TO_224.3_COG",
// ];

// export const HMELIndeixngData = [
//   {
//     section: {
//       startChainage: 70,
//       endChainage: 78.2,
//       title: "70km to 78.2km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_13-04-2024_70_TO_78.2_COG", type: "ORI" },
//       { id: "DSM_13-04-2024_70_TO_78.2_COG", type: "DSM" },
//     ],
//   },
//   {
//     section: {
//       startChainage: 78,
//       endChainage: 96.5,
//       title: "78km to 96.5km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_14-04-2024_78_TO_96.5_COG", type: "ORI" },
//       { id: "DSM_14-04-2024_78_TO_96.5_COG", type: "DSM" },
//     ],
//   },
//   {
//     section: {
//       startChainage: 96.3,
//       endChainage: 115.8,
//       title: "96.3km to 115.8km",
//     },
//     assets: [
//       {
//         id: "DSM_15-04-2024_96.3_TO_115.8_COG",
//         type: "DSM",
//       },
//     ],
//   },

//   {
//     section: {
//       startChainage: 115.5,
//       endChainage: 140,
//       title: "115.5km to 140km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_16-04-2024_115.5_TO_140_COG", type: "ORI" },
//       { id: "DSM_16-04-2024_115.5_TO_140_COG", type: "DSM" },
//     ],
//   },
//   {
//     section: {
//       startChainage: 148.8,
//       endChainage: 158,
//       title: "148.8km to 158km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_17-04-2024_148.8_TO_158_COG", type: "ORI" },
//       { id: "DSM_17-04-2024_148.8_TO_158_COG", type: "DSM" },
//     ],
//   },
//   {
//     section: {
//       startChainage: 169,
//       endChainage: 174.1,
//       title: "169km to 174.1km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_18-04-2024_169_TO_174.1_COG", type: "ORI" },
//       { id: "DSM_18-04-2024_169_TO_174.1_COG", type: "DSM" },
//     ],
//   },

//   {
//     section: {
//       startChainage: 185,
//       endChainage: 200.1,
//       title: "185km to 200.1km",
//     },
//     assets: [{ id: "DSM_19-04-2024_185_TO_200.1_COG", type: "DSM" }],
//   },

//   {
//     section: {
//       startChainage: 200,
//       endChainage: 224.3,
//       title: "200km to 224.3km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_20-04-2024_245_TO_224.3_COG", type: "ORI" },
//       { id: "DSM_20-04-2024_200_TO_224.3_COG", type: "DSM" },
//     ],
//   },
//   {
//     section: {
//       startChainage: 224.3,
//       endChainage: 245,
//       title: "224.3km to 245km",
//     },
//     assets: [{ id: "HMEL_ORTHO_20-04-2024_245_TO_224.3_COG", type: "ORI" }],
//   },
//   {
//     section: {
//       startChainage: 205.1,
//       endChainage: 215.5,
//       title: "205.1km to 215.5km",
//     },
//     assets: [{ id: "HMEL_ORTHO_20-04-2024_205.1_TO215.5_COG", type: "ORI" }],
//   },
//   {
//     section: {
//       startChainage: 246,
//       endChainage: 261,
//       title: "246km to 261km",
//     },
//     assets: [{ id: "HMEL_ORTHO_19-04-2024_261_TO_246_COG", type: "ORI" }],
//   },
//   {
//     section: {
//       startChainage: 261.5,
//       endChainage: 275,
//       title: "261.5km to 275km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_18-04-2024_275_TO_261.5_COG", type: "ORI" },
//       {
//         id: "DSM_18-04-2024_275_TO_261.5_COG ",
//         type: "DSM",
//       },
//     ],
//   },
//   {
//     section: {
//       startChainage: 296,
//       endChainage: 326,
//       title: "296km to 326km",
//     },
//     assets: [{ id: "DSM_16-04-2024_326_TO_296_COG", type: "DSM" }],
//   },
//   {
//     section: {
//       startChainage: 327,
//       endChainage: 344,
//       title: "327km to 344km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_15-04-2024_344_TO_327_COG", type: "ORI" },
//       { id: "DSM_15-04-2024_344_TO_327_COG", type: "DSM" },
//     ],
//   },
//   {
//     section: {
//       startChainage: 345,
//       endChainage: 362,
//       title: "345km to 362km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_14-04-2024_362_TO_345_COG", type: "ORI" },
//       { id: "DSM_14-04-2024_362_TO_345_COG", type: "DSM" },
//     ],
//   },
//   {
//     section: {
//       startChainage: 352,
//       endChainage: 362,
//       title: "352km to 362km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_14-04-2024_362_TO_352_COG", type: "ORI" },
//       { id: "DSM_14-04-2024_362_TO_352_COG", type: "DSM" },
//     ],
//   },
//   {
//     section: {
//       startChainage: 363,
//       endChainage: 382,
//       title: "363km to 382km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_13-04-2024_382_TO_363_COG", type: "ORI" },
//       { id: "DSM_13-04-2024_382_TO_363_COG", type: "DSM" },
//     ],
//   },
// ];

export const HMELIndeixngData = [
  {
    section: {
      startChainage: 71.1,
      endChainage: 78.8,
      title: "71.1km to 78.8km",
    },
    assets: [
      { id: "HMEL_ORTHO_13-04-2024_71.1_TO_78.8_COG", type: "ORI" },
      { id: "HMEL_DSM_13-04-2024_71.1_TO_78.8_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 78.8,
      endChainage: 97.2,
      title: "78.8km to 97.2km",
    },
    assets: [
      { id: "HMEL_ORTHO_14-04-2024_78.8_TO_97.2_COG", type: "ORI" },
      { id: "HMEL_DSM_14-04-2024_78.8_TO_97.2_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 97.2,
      endChainage: 116.2,
      title: "97.2km to 116.2km",
    },
    assets: [
      { id: "HMEL_ORTHO_15-04-2024_97.2_TO_116.2_COG", type: "ORI" },
      { id: "HMEL_DSM_15-04-2024_97.2_TO_116.2_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 116.2,
      endChainage: 142.5,
      title: "116.2km to 142.5km",
    },
    assets: [
      { id: "HMEL_ORTHO_16-04-2024_116.2_TO_142.5_COG", type: "ORI" },
      { id: "HMEL_DSM_16-04-2024_116.2_TO_142.5_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 142.5,
      endChainage: 164.5,
      title: "142.5km to 164.5km",
    },
    assets: [
      { id: "HMEL_ORTHO_17-04-2024_142.5_TO_164.5_COG", type: "ORI" },
      { id: "HMEL_DSM_17-04-2024_142.5_TO_164.5_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 164.5,
      endChainage: 180,
      title: "164.5km to 180km",
    },
    assets: [
      { id: "HMEL_ORTHO_18-04-2024_164.5_TO_180_COG", type: "ORI" },
      { id: "HMEL_DSM_18-04-2024_164.5_TO_180_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 180,
      endChainage: 200.4,
      title: "180km to 200.4km",
    },
    assets: [
      { id: "HMEL_ORTHO_19-04-2024_180_TO_200.4_COG", type: "ORI" },
      { id: "HMEL_DSM_19-04-2024_180_TO_200.4_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 200.4,
      endChainage: 225.1,
      title: "200.4km to 225.1km",
    },
    assets: [
      { id: "HMEL_ORTHO_20-04-2024_200.4_TO_225.1_COG", type: "ORI" },
      { id: "HMEL_DSM_20-04-2024_200.4_TO_225.1_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 225,
      endChainage: 247.3,
      title: "225km to 247.3km",
    },
    assets: [
      { id: "HMEL_ORTHO_20-04-2024_225_TO_247.3_COG", type: "ORI" },
      { id: "HMEL_DSM_20-04-2024_225_TO_247.3_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 247.3,
      endChainage: 262.6,
      title: "247.3km to 262.6km",
    },
    assets: [{ id: "HMEL_ORTHO_19-04-2024_247.3_TO_262.6_COG", type: "ORI" }],
  },
  {
    section: {
      startChainage: 262.6,
      endChainage: 277.1,
      title: "262.6km to 277.1km",
    },
    assets: [{ id: "HMEL_ORTHO_18-04-2024_262.6_TO_277.1_COG", type: "ORI" }],
  },
  {
    section: {
      startChainage: 297.3,
      endChainage: 327.7,
      title: "297.3km to 327.7km",
    },
    assets: [{ id: "HMEL_DSM_16-04-2024_327.7_TO_297.3_COG", type: "DSM" }],
  },
  {
    section: {
      startChainage: 327.7,
      endChainage: 346.5,
      title: "327.7km to 346.5km",
    },
    assets: [
      { id: "HMEL_ORTHO_15-04-2024_327.7_TO_346.5_COG", type: "ORI" },
      { id: "HMEL_DSM_15-04-2024_327.7_TO_346.5_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 346.5,
      endChainage: 356,
      title: "346.5km to 356km",
    },
    assets: [
      { id: "HMEL_ORTHO_14-04-2024_346.5_TO_356_COG", type: "ORI" },
      { id: "HMEL_DSM_14-04-2024_346.5_TO_356_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 355.8,
      endChainage: 364.8,
      title: "355.8km to 364.8km",
    },
    assets: [
      { id: "HMEL_ORTHO_14-04-2024_355.8_TO_364.8_COG", type: "ORI" },
      { id: "HMEL_DSM_14-04-2024_355.8_TO_364.8_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 364.8,
      endChainage: 383.9,
      title: "364.8km to 383.9km",
    },
    assets: [
      { id: "HMEL_ORTHO_13-04-2024_364.8_TO_383.9_COG", type: "ORI" },
      { id: "HMEL_DSM_13-04-2024_364.8_TO_383.9_COG", type: "DSM" },
    ],
  },
];

export const svStationsJson = [
  {
    id: "0FC6B54F973078D15AEC",
    name: "DT",
    chainage: {
      startChainage: 0,
      endChainage: 15.095,
    },
    distanceFromPrevious: 0,
    village: "Mundra",
    taluka: "Mundra",
    district: "Kutch",
    state: "GUJARAT",
    coordinates: [69.74915479019967, 22.817227531460126, 0],
    old_assets: [],
  },
  {
    id: "0732FC4BB03078D15AED",
    name: "SV-1",
    chainage: {
      startChainage: 15.095,
      endChainage: 35.289,
    },
    distanceFromPrevious: 15.095,
    village: "Mundra",
    taluka: "Mundra",
    district: "Kutch",
    state: "GUJARAT",
    coordinates: [69.77733333839498, 22.89586913717253, 0],
    old_assets: [],
  },
  {
    id: "0961D2FC033078D15C23",
    name: "SV-2",
    chainage: {
      startChainage: 35.289,
      endChainage: 65.797,
    },
    distanceFromPrevious: 20.194,
    village: "Gundala",
    taluka: "Anjar",
    district: "Kutch",
    state: "GUJARAT",
    coordinates: [69.90099204683857, 23.03481672105442, 0],
    old_assets: [],
  },
  {
    id: "1EB87E7E253078D15C23",
    name: "SV-3",
    chainage: {
      startChainage: 65.797,
      endChainage: 93.737,
    },
    distanceFromPrevious: 30.508,
    village: "Bhimasar",
    taluka: "Anjar",
    district: "Kutch",
    state: "GUJARAT",
    coordinates: [70.12980616114973, 23.19090395006687, 0],
    old_assets: [
      "HMEL_ORTHO_13-04-2024_71.1_TO_78.8_COG",
      "HMEL_ORTHO_14-04-2024_78.8_TO_97.2_COG",
    ],
  },
  {
    id: "05FCE17E9D3078D15C24",
    name: "SV-4",
    chainage: {
      startChainage: 93.737,
      endChainage: 129.392,
    },
    distanceFromPrevious: 27.94,
    village: "Bhachau",
    taluka: "Bhachau",
    district: "Kutch",
    state: "GUJARAT",
    coordinates: [70.33659256179689, 23.31569677181801, 0],
    old_assets: [
      "HMEL_ORTHO_14-04-2024_78.8_TO_97.2_COG",
      "HMEL_ORTHO_15-04-2024_97.2_TO_116.2_COG",
      "HMEL_ORTHO_16-04-2024_116.2_TO_142.5_COG",
    ],
  },
  {
    id: "10EEEB95933078D15C24",
    name: "IPS-1",
    chainage: {
      startChainage: 129.392,
      endChainage: 161.308,
    },
    distanceFromPrevious: 35.655,
    village: "Dedarwa",
    taluka: "Rapar",
    district: "Kutch",
    state: "GUJARAT",
    coordinates: [70.65743058371126, 23.43744124787702, 0],
    old_assets: [
      "HMEL_ORTHO_16-04-2024_116.2_TO_142.5_COG",
      "HMEL_ORTHO_17-04-2024_142.5_TO_164.5_COG",
    ],
  },
  {
    id: "2EB43CF6063078D15C24",
    name: "SV-5",
    chainage: {
      startChainage: 161.308,
      endChainage: 197.97199999999998,
    },
    distanceFromPrevious: 31.916,
    village: "Bhangera-Jadupur",
    taluka: "Rapar",
    district: "Kutch",
    state: "GUJARAT",
    coordinates: [70.94463317772127, 23.54302958637537, 0],
    old_assets: [
      "HMEL_ORTHO_17-04-2024_142.5_TO_164.5_COG",
      "HMEL_ORTHO_18-04-2024_164.5_TO_180_COG",
      "HMEL_ORTHO_19-04-2024_180_TO_200.4_COG",
    ],
  },
  {
    id: "311348A28D3078D15C24",
    name: "SV-6",
    chainage: {
      startChainage: 197.972,
      endChainage: 249.953,
    },
    distanceFromPrevious: 36.664,
    village: "Santalpur",
    taluka: "Santalpur",
    district: "Patan",
    state: "GUJARAT",
    coordinates: [71.18417987987227, 23.76528438439814, 0],
    old_assets: [
      "HMEL_ORTHO_20-04-2024_200.4_TO_225.1_COG",
      "HMEL_ORTHO_20-04-2024_225_TO_247.3_COG",
      "HMEL_ORTHO_19-04-2024_247.3_TO_262.6_COG",
    ],
  },
  {
    id: "0BA2848F563078D15C25",
    name: "SV-7",
    chainage: {
      startChainage: 249.953,
      endChainage: 281.75600000000003,
    },
    distanceFromPrevious: 51.981,
    village: "Dev",
    taluka: "Radhanpur",
    district: "Patan",
    state: "GUJARAT",
    coordinates: [71.64422578829775, 23.94149843509362, 0],
    old_assets: [
      "HMEL_ORTHO_19-04-2024_247.3_TO_262.6_COG",
      "HMEL_ORTHO_18-04-2024_262.6_TO_277.1_COG",
      "HMEL_DSM_16-04-2024_327.7_TO_297.3_COG",
    ],
  },
  {
    id: "1E31CFB1B93078D15C25",
    name: "IPS-2",
    chainage: {
      startChainage: 281.756,
      endChainage: 312.77799999999996,
    },
    distanceFromPrevious: 31.803,
    village: "Juna Raviyana",
    taluka: "Kankrej",
    district: "Banas Kantha",
    state: "GUJARAT",
    coordinates: [71.89818336888325, 24.09931856359993, 0],
    old_assets: ["HMEL_ORTHO_15-04-2024_327.7_TO_346.5_COG"],
  },
  {
    id: "20186AC4F73078D15C25",
    name: "SV-8",
    chainage: {
      startChainage: 312.778,
      endChainage: 334.50600000000003,
    },
    distanceFromPrevious: 31.022,
    village: "Kupat",
    taluka: "Deesa",
    district: "Banas Kantha",
    state: "GUJARAT",
    coordinates: [72.11780450995002, 24.25805408191142, 0],
    old_assets: ["HMEL_ORTHO_15-04-2024_327.7_TO_346.5_COG"],
  },
  {
    id: "3C5C7FF9853078D15C25",
    name: "SV-9",
    chainage: {
      startChainage: 334.506,
      endChainage: 362.399,
    },
    distanceFromPrevious: 21.728,
    village: "Bural",
    taluka: "Deesa",
    district: "Banas Kantha",
    state: "GUJARAT",
    coordinates: [72.18742134710703, 24.46582392214406, 0],
    old_assets: [
      "HMEL_ORTHO_15-04-2024_327.7_TO_346.5_COG",
      "HMEL_ORTHO_14-04-2024_346.5_TO_356_COG",
      "HMEL_ORTHO_14-04-2024_355.8_TO_364.8_COG",
    ],
  },
  {
    id: "0C3F01C2373078D15C26",
    name: "SV-10",
    chainage: {
      startChainage: 362.399,
      endChainage: 376.811,
    },
    distanceFromPrevious: 27.893,
    village: "Bargaon",
    taluka: "Raniwara",
    district: "Jalore",
    state: "RAJASTHAN",
    coordinates: [72.26981669164833, 24.69261420569688, 0],
    old_assets: ["HMEL_ORTHO_13-04-2024_364.8_TO_383.9_COG"],
  },
  {
    id: "1C8806A1043078D15C26",
    name: "SV-11",
    chainage: {
      startChainage: 376.811,
      endChainage: 405.30899999999997,
    },
    distanceFromPrevious: 14.412,
    village: "Akhrar",
    taluka: "Raniwara",
    district: "Jalore",
    state: "RAJASTHAN",
    coordinates: [72.28288241553025, 24.81808790753194, 0],
    old_assets: [],
  },
  {
    id: "24E6C70E983078D15C26",
    name: "SV-12",
    chainage: {
      startChainage: 405.309,
      endChainage: 424.432,
    },
    distanceFromPrevious: 28.498,
    village: "Bhadarda",
    taluka: "Bhinmal",
    district: "Jalore",
    state: "RAJASTHAN",
    coordinates: [72.29504146620657, 25.0678125345258, 0],
    old_assets: [],
  },
  {
    id: "36B40573993078D15C26",
    name: "IPS-3",
    chainage: {
      startChainage: 424.432,
      endChainage: 444.47200000000004,
    },
    distanceFromPrevious: 19.123,
    village: "Dhansa",
    taluka: "Bhinmal",
    district: "Jalore",
    state: "RAJASTHAN",
    coordinates: [72.37904461802565, 25.21769773727652, 0],
    old_assets: [],
  },
  {
    id: "0E788984773078D15C27",
    name: "SV-13",
    chainage: {
      startChainage: 444.472,
      endChainage: 463.67699999999996,
    },
    distanceFromPrevious: 20.04,
    village: "Ummedabad-Gol",
    taluka: "Jalore",
    district: "Jalore",
    state: "RAJASTHAN",
    coordinates: [72.46802932308887, 25.37324492440896, 0],
    old_assets: [],
  },
  {
    id: "19AE1AAC243078D15C27",
    name: "SV-14",
    chainage: {
      startChainage: 463.677,
      endChainage: 497.103,
    },
    distanceFromPrevious: 19.205,
    village: "Kathari",
    taluka: "Siwana",
    district: "Barmer",
    state: "RAJASTHAN",
    coordinates: [72.54598198229353, 25.53006281608655, 0],
    old_assets: [],
  },
  {
    id: "2F4F80C2423078D15C27",
    name: "SV-15",
    chainage: {
      startChainage: 497.103,
      endChainage: 527.532,
    },
    distanceFromPrevious: 33.426,
    village: "Laliya",
    taluka: "Siwana",
    district: "Barmer",
    state: "RAJASTHAN",
    coordinates: [72.67221762615354, 25.8098140674664, 0],
    old_assets: [],
  },
  {
    id: "370D7F71393078D15C27",
    name: "SV-16",
    chainage: {
      startChainage: 527.532,
      endChainage: 544.157,
    },
    distanceFromPrevious: 30.429,
    village: "Gelawas",
    taluka: "Luni",
    district: "Jodhpur",
    state: "RAJASTHAN",
    coordinates: [72.77145221149112, 26.0632770167226, 0],
    old_assets: [],
  },
  {
    id: "0F85B242AC3078D15C28",
    name: "SV-17",
    chainage: {
      startChainage: 544.157,
      endChainage: 593.1080000000001,
    },
    distanceFromPrevious: 16.625,
    village: "Jhanwar",
    taluka: "Luni",
    district: "Jodhpur",
    state: "RAJASTHAN",
    coordinates: [72.84123024034946, 26.19775631362604, 0],
    old_assets: [],
  },
  {
    id: "178DD786313078D15C28",
    name: "IPS-4",
    chainage: {
      startChainage: 593.108,
      endChainage: 642.959,
    },
    distanceFromPrevious: 48.951,
    village: "Jud",
    taluka: "Osiyan",
    district: "Jodhpur",
    state: "RAJASTHAN",
    coordinates: [73.03038292469094, 26.58410880571596, 0],
    old_assets: [],
  },
  {
    id: "22A7E439263078D15C28",
    name: "SV-18",
    chainage: {
      startChainage: 642.959,
      endChainage: 698.372,
    },
    distanceFromPrevious: 49.851,
    village: "Kantiya",
    taluka: "Khinwasar",
    district: "Nagaur",
    state: "RAJASTHAN",
    coordinates: [73.29947698762535, 26.97077083042966, 0],
    old_assets: [],
  },
  {
    id: "3832BD22923078D15C28",
    name: "SV-19",
    chainage: {
      startChainage: 698.372,
      endChainage: 732.1049999999999,
    },
    distanceFromPrevious: 55.413,
    village: "Sateran",
    taluka: "Nagaur",
    district: "Nagaur",
    state: "RAJASTHAN",
    coordinates: [73.5495492214228, 27.40694468744245, 0],
    old_assets: [],
  },
  {
    id: "01C874AE9D3078D15C29",
    name: "SV-20",
    chainage: {
      startChainage: 732.105,
      endChainage: 762.972,
    },
    distanceFromPrevious: 33.733,
    village: "Kakra",
    taluka: "Nokha",
    district: "Bikaner",
    state: "RAJASTHAN",
    coordinates: [73.6921551430604, 27.6799004785773, 0],
    old_assets: [],
  },
  {
    id: "15126DE5EE3078D15C29",
    name: "SV-21",
    chainage: {
      startChainage: 762.972,
      endChainage: 795.5169999999999,
    },
    distanceFromPrevious: 30.867,
    village: "Sanwatsar",
    taluka: "Shri Dungargarh",
    district: "Bikaner",
    state: "RAJASTHAN",
    coordinates: [73.84104905415668, 27.923262932457, 0],
    old_assets: [],
  },
  {
    id: "2A12CFAE4C3078D15C29",
    name: "IPS-5",
    chainage: {
      startChainage: 795.517,
      endChainage: 823.3470000000001,
    },
    distanceFromPrevious: 32.545,
    village: "Gusainsar",
    taluka: "Shri Dungargarh",
    district: "Bikaner",
    state: "RAJASTHAN",
    coordinates: [73.97981846392955, 28.17957387553305, 0],
    old_assets: [],
  },
  {
    id: "3CE0264C403078D15C29",
    name: "SV-22",
    chainage: {
      startChainage: 823.347,
      endChainage: 870.081,
    },
    distanceFromPrevious: 27.83,
    village: "Arsisar",
    taluka: "Sardarshar",
    district: "Churu",
    state: "RAJASTHAN",
    coordinates: [74.10740777974583, 28.40446191140404, 0],
    old_assets: [],
  },
  {
    id: "03650F029A3078D15C2A",
    name: "SV-23",
    chainage: {
      startChainage: 870.081,
      endChainage: 902.553,
    },
    distanceFromPrevious: 46.734,
    village: "Sawar",
    taluka: "Sardarshar",
    district: "Churu",
    state: "RAJASTHAN",
    coordinates: [74.33231407771002, 28.77289908011577, 0],
    old_assets: [],
  },
  {
    id: "1ACCCDF6E13078D15C2A",
    name: "SV-24",
    chainage: {
      startChainage: 902.553,
      endChainage: 934.331,
    },
    distanceFromPrevious: 32.472,
    village: "Hamirdesar",
    taluka: "Rawatsar",
    district: "Hanumangarh",
    state: "RAJASTHAN",
    coordinates: [74.44983636108236, 29.03941219175593, 0],
    old_assets: [],
  },
  {
    id: "25E71194683078D15C2A",
    name: "SV-25",
    chainage: {
      startChainage: 934.331,
      endChainage: 959.789,
    },
    distanceFromPrevious: 31.778,
    village: "Topariyan",
    taluka: "Nohar",
    district: "Hanumangarh",
    state: "RAJASTHAN",
    coordinates: [74.60760477895379, 29.28261107387302, 0],
    old_assets: [],
  },
  {
    id: "3430FC40F33078D15C2A",
    name: "SV-26",
    chainage: {
      startChainage: 959.789,
      endChainage: 962.942,
    },
    distanceFromPrevious: 25.458,
    village: "Maujukhera",
    taluka: "Ellenabad",
    district: "Sirsa",
    state: "HARYANA",
    coordinates: [74.7302768162522, 29.48303520967411, 0],
    old_assets: [],
  },
  {
    id: "027B616C213078D15C2B",
    name: "SV-27",
    chainage: {
      startChainage: 962.942,
      endChainage: 1017,
    },
    distanceFromPrevious: 3.153,
    village: "Nakora",
    taluka: "Raniya",
    district: "Sirsa",
    state: "HARYANA",
    coordinates: [74.74449886092471, 29.50874914857454, 0],
    old_assets: [],
  },
  {
    id: "1D4F7DC2E13078D15C2B",
    name: "RT",
    chainage: {
      startChainage: 1016.93,
      endChainage: 1019,
    },
    distanceFromPrevious: 54.058,
    village: "Phulakhari",
    taluka: "Talwandi Saboo",
    district: "Bathinda",
    state: "PUNJAB",
    coordinates: [74.94385716584887, 29.92341059711275, 0],
    old_assets: [],
  },
];
