const SECRET_KEY =  generateSecretKey();
async function generateSecretKey() {
    const key = await window.crypto.subtle.generateKey(
      {
        name: "AES-GCM",
        length: 256,
      },
      true, // extractable
      ["encrypt", "decrypt"]
    );
    const rawKey = await window.crypto.subtle.exportKey("raw", key);
    return Array.from(new Uint8Array(rawKey));
  } 

const getKey = async (secret) => {
  const enc = new TextEncoder();
  return await window.crypto.subtle.importKey(
    'raw',
    enc.encode(secret),
    { name: 'AES-GCM' },
    false,
    ['encrypt', 'decrypt']
  );
};

export const encryptData = async (data) => {
  const key = await getKey(SECRET_KEY);
  const enc = new TextEncoder();
  const iv = window.crypto.getRandomValues(new Uint8Array(12)); // Initialization vector

  const encrypted = await window.crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv: iv
    },
    key,
    enc.encode(JSON.stringify(data))
  );

  return {
    iv: Array.from(iv),
    data: Array.from(new Uint8Array(encrypted))
  };
};

export const decryptData = async (encryptedData) => {
  const { iv, data } = encryptedData;
  const key = await getKey(SECRET_KEY);
  const dec = new TextDecoder();

  const decrypted = await window.crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv: new Uint8Array(iv)
    },
    key,
    new Uint8Array(data)
  );

  return JSON.parse(dec.decode(decrypted));
};
