import "./App.css";
import "leaflet/dist/leaflet.css";
import "../src/Assets/styles/pageStyle.scss";
import { Route, Routes } from "react-router-dom";
import {
  Signup,
  Projects,
  ProjectViewLayout,
  Assets,
  SingleScreen,
  CompareScreen,
} from "./imports";
import AdminLayout from "./Components/Admin/AdminLayout";
import Permissions from "./Components/Admin/Permissions";
import RolePermission from "./Components/Admin/RolePermission";
import Settings from "./Components/Admin/Settings";
import ErrorPage from "./Pages/Error/ErrorPage";
import DemoLogin from "./Pages/Login/DemoLogin";
import CatalogPage from "./Pages/Catalog/CatalogPage";
import DemoFlowLayout from "./Components/Layouts/DemoFlowLayout";
import Client from "./Components/Admin/Client";

function App() {
  return (
    <div className="App h-full overflow-hidden">
      <Routes>
        <Route path="/" element={<DemoLogin />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/catalog" element={<CatalogPage />}></Route>
        <Route element={<ProjectViewLayout />}>
          <Route path="/projects" element={<Projects />}></Route>
          <Route path="/assets" element={<Assets />}></Route>
        </Route>
        <Route path="/adminLayout" element={<Client />}></Route>
        <Route
          path="/map/:projectName/:mode"
          element={<DemoFlowLayout />}
        ></Route>
        <Route
          path="/vdo/:projectName/surveillance/:project"
          element={<SingleScreen />}
        ></Route>
        <Route
          path="/vdo/:projectName/compare/:ids"
          element={<CompareScreen />}
        ></Route>
        <Route element={<AdminLayout />}>
          <Route path="/setting" element={<Settings />} />
          <Route path="/perms" element={<Permissions />} />
          <Route path="/role_perms" element={<RolePermission />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;
