import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const gisToolsSlice = createSlice({
  name: "gis-tools",
  initialState: {
    selectedTool: null,
    showComparer: false,
    annotationToggle: false,
    marker: false,
    polygon: false,
    polyline: false,
    measureToolToggle: false,
    lineMeasure: false,
    polylineMeasure: false,
    polygonMeasure: false,
    unitsIsOpen: false,
  },
  reducers: {
    setselectedTool(state, action) {
      if (state.selectedTool === action.payload) {
        state.selectedTool = null;
      } else {
        state.selectedTool = action.payload;
      }
    },

    annotationToggle(state, action) {
      state.annotationToggle = action.payload;
    },
    measureToggle(state, action) {
      state.measureToolToggle = action.payload;
    },

    turnOffTools(state) {
      state.annotationToggle = false;
      state.measureToolToggle = false;
      state.marker = false;
      state.polygon = false;
      state.polyline = false;
      state.lineMeasure = false;
      state.polylineMeasure = false;
      state.polygonMeasure = false;
    },

    toggleTools(state, action) {
      const toolName = action.payload;

      if (toolName === "showComparer") {
        state.measureToolToggle = false;
        state.marker = false;
        state.polygon = false;
        state.polyline = false;
        state.showComparer = !state.showComparer;

        if (state.showComparer === false) {
          toast.error("Comparer has been turned off");
        } else {
          toast.success(
            "Comparer is turned on, select assets/timelines which you want ot compare."
          );
        }

        if (state.annotationToggle) {
          state.annotationToggle = false;
        }
      } else if (toolName === "marker") {
        state.showComparer = false;
        state.measureToolToggle = false;
        state.polygon = false;
        state.polyline = false;

        state.marker = !state.marker;

        if (state.marker === false) {
          toast.error("Marker tool has been turned off");
        } else {
          toast.success("Point marker tool activated.");
        }
      } else if (toolName === "polygon") {
        state.annotationToggle = false;
        state.measureToolToggle = false;
        state.showComparer = false;
        state.measureToolToggle = false;
        state.marker = false;
        state.polyline = false;
        state.lineMeasure = false;
        state.polylineMeasure = false;
        state.polygonMeasure = false;
        state.polygon = !state.polygon;

        if (state.polygon === false) {
          toast.error("Polygon marker tool has been turned off");
        } else {
          toast.success("Polygon marker tool activated.");
        }
      } else if (toolName === "polyline") {
        state.showComparer = false;
        state.annotationToggle = false;
        state.measureToolToggle = false;
        state.marker = false;
        state.polygon = false;
        state.lineMeasure = false;
        state.polylineMeasure = false;
        state.polygonMeasure = false;
        state.polyline = !state.polyline;

        if (state.polyline === false) {
          toast.error("Polyline marker tool has been turned off");
        } else {
          toast.success("Polyline marker tool activated.");
        }
      } else if (toolName === "polylineMeasure") {
        state.showComparer = false;
        state.annotationToggle = false;
        state.measureToolToggle = false;
        state.marker = false;
        state.polygon = false;
        state.polyline = false;
        state.lineMeasure = false;
        state.polygonMeasure = false;
        state.polylineMeasure = !state.polylineMeasure;

        if (state.polylineMeasure === false) {
          toast.error("Measure distance tool has been turned off");
        } else {
          toast.success("Measure distance tool activated.");
        }
      } else if (toolName === "polygonMeasure") {
        state.showComparer = false;
        state.annotationToggle = false;
        state.measureToolToggle = false;
        state.marker = false;
        state.polygon = false;
        state.polyline = false;
        state.lineMeasure = false;
        state.polylineMeasure = false;
        state.polygonMeasure = !state.polygonMeasure;

        if (state.polygonMeasure === false) {
          toast.error("Measure area tool has been turned off");
        } else {
          toast.success("Measure area tool activated.");
        }
      } else if (toolName === "lineMeasure") {
        state.showComparer = false;
        state.annotationToggle = false;
        state.measureToolToggle = false;
        state.marker = false;
        state.polygon = false;
        state.polyline = false;
        state.polylineMeasure = false;
        state.polygonMeasure = false;
        state.lineMeasure = !state.lineMeasure;

        if (state.lineMeasure === false) {
          toast.error("Line Measure has been turned off");
        } else {
          toast.success("Line measure activated.");
        }
      }
    },

    setUnitIsOpen(state) {
      state.unitsIsOpen = !state.unitsIsOpen;
    },
  },
});

export const gisToolActions = gisToolsSlice.actions;

export default gisToolsSlice.reducer;
