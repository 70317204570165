import { Tooltip } from "@mui/material";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import axios from "axios";
import Cookies from "js-cookie";
import L from "leaflet";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  ZoomInIcon,
  ZoomOutIcon,
  defaultMarker,
  homeIcon,
  lineIco,
  markers,
  measuringTapeIco,
  pinIcon,
  polygonIco,
  polylineIco,
  scaleIco,
  closeIco,
  settingsIcon,
  switchIcon,
} from "../../../Assets/icons/gisIcons/icons";
import * as LeafletToolBarUtils from "../../../Utils/Leaflet/LeafletToolBarUtils";
import { gisActions } from "../../../redux/slices/GIS/gis-slice";
import { formatDate } from "../../../Features/GIS/Utils/otherUtils";
import { AnnotationManager } from "../../../Services/ThirdPartyApi/AWS/DynamoDBL";
import { S3Apis } from "../../../Services/ThirdPartyApi/AWS/S3";
import { Measure ,LayerControlUtils} from "../../../Utils/Leaflet/LeafletUtils";
import { gisHomeActions } from "../../../redux/slices/GIS/gis-home-slice";
import { gisLeafletActions } from "../../../redux/slices/GIS/gis-leaflet-slice";
import { gisToolActions } from "../../../redux/slices/GIS/gis-tools-slice";
import ContextMenuModalLeaflet from "./ContextMenuModalLeaflet";
import MetaInputLeaflet from "./MetaInputLeaflet";
import LegendsModalLeaflet from "./LegendsModalLeaflet";
import PreviousCurrentUpcommingLayerToggles from "./PreviousCurrentUpcommingLayerToggles";
import SettingsModal from "./SettingsModal";
import { fetchData, updateData, storeData  } from '../../../Utils/indexedDB';
import db from '../../../Utils/indexedDB/db';
const GISToolbarLeaflet = ({
  handleHomeButtonClick,
  handleZoom,
  imageryLabel,
  mapRef,
}) => {
  const map = mapRef.current;
  const dispatch = useDispatch();
  const { asset } = useSelector((state) => state.gisLeaflet);
  const { mode } = useSelector((state) => state.gisHome);
  const { userPermissions } = useSelector((state) => state.gis);
  const { bottomReportToggle } = useSelector((state) => state.gisHome);

  // const [mapComparer, setMapCompapere] = useState(false);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [clickedLatLng, setClickedLatLng] = useState();
  const [recentUsedTool, setRecentUsedTool] = useState("marker");
  // const [recentToolToggle, setRecentToolToggle] = useState("marker");
  const [drawMarker, setDrawMarker] = useState(null);
  const [drawPolygon, setDrawPolygon] = useState(null);
  const [drawPolyline, setDrawPolyline] = useState(null);
  const [drawLineMeasure, setLineMeasure] = useState(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [legendsOpen, setlegendsOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [storedPreset, setStoredPreset] = useState();
  const { refCounterUnits } = useSelector((state) => state.gisLeaflet);
  useEffect(() => {
    const getPreset = async () => {
      try {
        const fetchedPreset = await fetchData('presets');
        setStoredPreset(fetchedPreset);
      } catch (error) {
        console.error('Failed to fetch preset:', error);
      }
    };
    getPreset();
  }, [refCounterUnits]);

  useEffect(() => {
    if (bottomReportToggle) {
      setSettingsOpen(false);
      setlegendsOpen(false);
    }
  }, [bottomReportToggle]);
  const measure = new Measure();
  const layerControlUtils= new LayerControlUtils();
  const { showComparer, unitsIsOpen, selectedTool } = useSelector(
    (state) => state.gisTools,
  );
  const { vectorMeasurentLayers, measurementArray } = useSelector(
    (state) => state.gisLeaflet,
  );

  const { projectMeta } = useSelector((state) => state.projectMeta);
  const { clientName, compareMode } = projectMeta;
  const clientNameForDB = clientName?.toLowerCase();

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.originalEvent.preventDefault();
      const lngLat = e.latlng; // Destructure lngLat from the event object
      if (
        drawMarker?._enabled ||
        drawPolygon?._enabled ||
        drawPolyline?._enabled ||
        drawLineMeasure?._enabled
      ) {
        setContextMenuOpen(false);
      } else {
        setClickedLatLng(lngLat); // Store lat lng in state
        setModalPosition({
          top: e.originalEvent.clientY,
          left: e.originalEvent.clientX,
        });
        setModalPosition({
          top: e.originalEvent.clientY,
          left: e.originalEvent.clientX,
        });
        setContextMenuOpen(true);
      }
    };
    const handleKeyPress = (e) => {
      if (e.key === "p" || e.key === "P") {
        if (
          !drawMarker?._enabled &&
          !drawPolygon?._enabled &&
          !drawPolyline?._enabled &&
          !drawLineMeasure?._enabled
        ) {
          dispatch(gisToolActions.setselectedTool("polygon"));
          dispatch(gisToolActions.toggleTools("polygon"));
          handleDrawStart("polygon");
        }
      }
    };
    map?.on("contextmenu", handleContextMenu);
    // document.addEventListener("keydown", handleKeyPress);
    // Cleanup function to remove event listener
    return () => {
      map?.off("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedTool]);

  const hoverColor = "rgba(20,26,34)";
  const [selectedMeasurmentUnit, setSelectedMeasurmentUnit] = useState("m");
  const [selectedAreaUnit, setSelectedAreaUnit] = useState("sq m");
  const [isModalOpenCustom, setIsModalOpenCustom] = useState(false);
  const [markerLayerCurrent, setMarkerLayer] = useState(null);
  const [markerPosition, setMarkerPosition] = useState([]);
  const handleUnitChange = (unit) => {
    setSelectedMeasurmentUnit(unit);
  };
  const handleAreaUnitChange = (unit) => {
    setSelectedAreaUnit(unit);
  };
  const measurementPermissions = [
    "measurement_line_view",
    "measurement_polyline_view",
    "measurement_polygon_view",
  ];
  const hasMeasurementPermission = userPermissions.some((permission) =>
    measurementPermissions.includes(permission),
  );
  const annotationPolylinePermissions = [
    "annotation_line_add",
    "annotation_polyline_add",
    "annotation_polygon_add",
  ];
  const hasAnnotationPermission = userPermissions.some((permission) =>
    annotationPolylinePermissions.includes(permission),
  );
  const siteName = Cookies.get("start_location");
  //code for leaflet-draw
  const customMarkerIcon = new L.Icon({
    className: "custom-marker-icon",
    iconUrl: defaultMarker,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  useEffect(() => {
    if (!mapRef.current) return; // Ensure map is initialized
    // Initialize drawing tools
    const marker = new L.Draw.Marker(mapRef.current, {
      icon: customMarkerIcon,
    });
    const polygon = new L.Draw.Polygon(mapRef.current, {
      shapeOptions: {
        stroke: true,
        color: "#000",
        weight: 4,
        opacity: 0.5,
        lineCap: "round",
        lineJoin: "round",
        fill: true,
        clickable: true,
        fillColor: "#FFF",
        fillOpacity: 0.3,
        dashArray: "10,10",
      },
      // repeatMode: true,
    });
    const polyline = new L.Draw.Polyline(mapRef.current, {
      shapeOptions: {
        stroke: true,
        color: "#000",
        weight: 4,
        opacity: 0.9,
        lineCap: "round",
        lineJoin: "round",
        fill: false,
        clickable: true,
        dashArray: "10,10",
      },
      // repeatMode: true,
    });
    const line = new L.Draw.Polyline(mapRef.current, {
      allowIntersection: false,
      shapeOptions: {
        stroke: true,
        color: "#000",
        weight: 4,
        opacity: 0.9,
        lineCap: "round",
        lineJoin: "round",
        fill: false,
        clickable: true,
        dashArray: "10,10",
      },
      maxPoints: 2, // Once this number of points are placed, finish shape
      // repeatMode: true,
    });
    // Set the drawing tools in state
    setDrawMarker(marker);
    setDrawPolygon(polygon);
    setDrawPolyline(polyline);
    setLineMeasure(line);

    return () => {
      // Clean up resources when component unmounts
      // map.off(L.Draw.Event.CREATED, handleDrawCreated);
    };
  }, [mapRef]);

  const disableAllTools = () => {
    if (drawPolygon._enabled) {
      drawPolygon.disable();
    }
    if (drawPolyline._enabled) {
      drawPolyline.disable();
    }
    if (drawMarker._enabled) {
      drawMarker.disable();
    }
    if (drawLineMeasure._enabled) {
      drawLineMeasure.disable();
    }
  };

  const handleDrawStart = (selectedTool) => {
    setRecentUsedTool(selectedTool);
    // setRecentToolToggle(selectedTool);
    LeafletToolBarUtils.handleToolClick(
      disableAllTools,
      drawMarker,
      drawPolygon,
      drawPolyline,
      drawLineMeasure,
      selectedTool,
    );
  };
  document.addEventListener("keydown", function (event) {
    // Check if the pressed key is "esc" and any Leaflet Draw mode is enabled
    if (
      event.key === "Escape" &&
      (drawMarker?._enabled ||
        drawPolygon?._enabled ||
        drawPolyline?._enabled ||
        drawLineMeasure?._enabled)
    ) {
      handleEscKeyPress();
    }
  });

  // Your function to be called when any Leaflet Draw mode is active and "esc" key is pressed
  function handleEscKeyPress() {
    dispatch(gisToolActions.setselectedTool(null));
    dispatch(gisToolActions.turnOffTools());
    disableAllTools();
  }

  useEffect(() => {
    const handleDrawCreated = (e) => {
      const layer = e.layer;
      const type = e.layerType;
      layer.type = type;
      map.addLayer(layer);

      if (type === "polygon") {
        setMarkerPosition(layer.getLatLngs());
        measure.handlePolygon(layer, map, null,storedPreset);
      } else if (type === "polyline") {
        setMarkerPosition(layer.getLatLngs());
        measure.handlePolyline(layer, map, null,storedPreset);
      } else {
        setMarkerPosition(layer.getLatLng());
      }

      if (
        selectedTool !== "lineMeasure" &&
        selectedTool !== "polylineMeasure" &&
        selectedTool !== "polygonMeasure"
      ) {
        setIsModalOpenCustom(true);
        setMarkerLayer(layer);
        dispatch(gisLeafletActions.addItemToVisibleLayers(true));
        dispatch(gisActions.refCounterUpdate());
      } else {
        const latlngArray=layerControlUtils.convertLatLngToPlainObject(layer.getLatLngs())
        const newMeasure = {
          id: uuidv4(),
          assetId: asset?.Name ?? "Measure",
          measurePosition: layer.getLatLngs(),
          length: null,
          area: null,
          perimeter: null,
          createdOn: formatDate(newTimestamp),
          groupName: clientNameForDB,
          filter_type: layer.type,
          visible: true,
        };
        storeMeasuremntData(newMeasure,layer);
      }
    };
    const storeMeasuremntData = async (newMeasure,layer) => {
      try {
        const mesurementsFromDB = await fetchData('measurements');
        if(newMeasure?.filter_type){
          const newData = [...mesurementsFromDB, newMeasure];
          await updateData('measurements',1, newData);
          // await db.measurements.add(newMeasure);
          dispatch(gisLeafletActions.refCounterMeasureUpdate());
          map.removeLayer(layer);        
          dispatch(gisToolActions.turnOffTools());
          dispatch(gisToolActions.setselectedTool(null));
        }
       } catch (error) {
        console.error('Failed to fetch measurement data:', error);
      }
    };

    storeMeasuremntData();
    map?.on(L.Draw.Event.CREATED, handleDrawCreated);
    return () => {
      map.off(L.Draw.Event.CREATED, handleDrawCreated);
    };
  }, [measurementArray, selectedTool, vectorMeasurentLayers]);

  //saving data
  const newTimestamp = Date.now();

  const handleMarkerSave = async (
    description,
    keyValuePairs,
    observationType,
    category,
    severity,
    markerName,
    assetId,
    file,
    date,
    AnnotationType,
    taskStatus,
    observerName,
    verifierName,
    reviewerName,
    length,
    area,
    perimeter,
  ) => {
    if (true) {
      var imagedata;
      if (file) {
        const imageData = await S3Apis.uploadData(file, "images");
        imagedata = [{ path: imageData.s3Url.key, date: imageData.Imagedate }];
      } else {
        imagedata = [];
      }
      if (AnnotationType === "Custom") {
        const newMarker = {
          id: uuidv4(),
          timestamp: newTimestamp,
          description: description,
          keyValuePairs: keyValuePairs,
          category: "Other",
          severity: severity,
          markerName: markerName,
          assetId: assetId,
          markerDate: date,
          markerPosition,
          createdOn: formatDate(newTimestamp),
          images: imagedata,
          groupName: clientNameForDB,
          filter_type: AnnotationType,
          visible: true,
        };
        markerLayerCurrent._leaflet_id = newMarker.id;
      } else if (AnnotationType === "marker") {
        const newMarker = {
          id: uuidv4(),
          timestamp: newTimestamp,
          markerName: markerName,
          assetId: assetId,
          observationType: observationType,
          category: category,
          severity: severity,
          description: description,
          filter_type: AnnotationType,
          markerPosition,
          keyValuePairs: keyValuePairs,
          markerDate: date,
          createdOn: formatDate(newTimestamp),
          images: imagedata,
          groupName: clientNameForDB,
          visible: true,
          taskStatus: taskStatus,
          addedBy: observerName,
          verifiedBy: verifierName,
          reviewedBy: reviewerName,
          is5kmSegment: true,
        };
        map.removeLayer(markerLayerCurrent);
        AnnotationManager.addAnnotations(newMarker);

        dispatch(gisActions.refCounterUpdate());

        const dataForPdfGeneration = {
          annotation_id: newMarker.id,
          annotation_timestamp: newMarker.timestamp,
          layer_name: assetId,
          table_name: process.env.REACT_APP_OBSERVATIONS_TABLE,
          polygon_cordinates: [markerPosition], //lat_lon walla
          timeline: assetId,
          shape_type: "marker",
        };

        const res = await axios.post(
          "https://inspecthb.indrones.com/create_ori_snapshot_image",
          dataForPdfGeneration,
        );
      } else if (AnnotationType === "polyline") {
        const newMarker = {
          id: uuidv4(),
          timestamp: newTimestamp,
          markerName: markerName,
          assetId: assetId,
          observationType: observationType,
          category: category,
          severity: severity,
          description: description,
          filter_type: AnnotationType,
          markerPosition,
          keyValuePairs: keyValuePairs,
          markerDate: date,
          createdOn: formatDate(newTimestamp),
          images: imagedata,
          groupName: clientNameForDB,
          visible: true,
          taskStatus: taskStatus,
          addedBy: observerName,
          verifiedBy: verifierName,
          reviewedBy: reviewerName,
          is5kmSegment: true,
        };
        markerLayerCurrent.remove();

        AnnotationManager.addAnnotations(newMarker);

        dispatch(gisActions.refCounterUpdate());

        const dataForPdfGeneration = {
          annotation_id: newMarker.id,
          annotation_timestamp: newMarker.timestamp,
          layer_name: assetId,
          table_name: process.env.REACT_APP_OBSERVATIONS_TABLE,
          polygon_cordinates: markerPosition, //lat_lon walla
          timeline: assetId,
          shape_type: "polyline",
        };

        const res = await axios.post(
          "https://inspecthb.indrones.com/create_ori_snapshot_image",
          dataForPdfGeneration,
        );
      } else if (AnnotationType === "polygon") {
        const newMarker = {
          id: uuidv4(),
          timestamp: newTimestamp,
          markerName: markerName,
          assetId: assetId,
          observationType: observationType,
          category: category,
          severity: severity,
          description: description,
          filter_type: AnnotationType,
          markerPosition,
          keyValuePairs: keyValuePairs,
          markerDate: date,
          createdOn: formatDate(newTimestamp),
          images: imagedata,
          groupName: clientNameForDB,
          visible: true,
          taskStatus: taskStatus,
          addedBy: observerName,
          verifiedBy: verifierName,
          reviewedBy: reviewerName,
          is5kmSegment: true,
        };
        map.removeLayer(markerLayerCurrent);
        AnnotationManager.addAnnotations(newMarker);
        dispatch(gisActions.refCounterUpdate());

        const dataForPdfGeneration = {
          annotation_id: newMarker.id,
          annotation_timestamp: newMarker.timestamp,
          layer_name: assetId,
          table_name: process.env.REACT_APP_OBSERVATIONS_TABLE,
          polygon_cordinates: markerPosition[0], //lat_lon walla
          timeline: assetId,
          shape_type: "polygon",
        };

        const res = await axios.post(
          "https://inspecthb.indrones.com/create_ori_snapshot_image",
          dataForPdfGeneration,
        );
      }
    }
  };
  return (
    <>
      <div className="toolbarContainer">
        {!showComparer && (
          <div
            id="toolbar"
            className="absolute right-2 top-12 z-[1000] mt-1 flex flex-col items-end justify-end"
          >
            {asset !== null && (
              <Tooltip title="Home" arrow placement="left">
                <button
                  className={`my-2 !ml-0 flex h-10 w-10 items-center justify-center rounded-[50%] bg-[rgba(20,26,34,.725)] hover:bg-[rgba(20,26,34)] active:bg-[#48b]`}
                  onClick={handleHomeButtonClick}
                  style={{
                    boxShadow: "0 0 1.5px rgba(255,255,255,.7)",
                    backdropFilter: "blur(20px)",
                  }}
                >
                  <img className="w-6" src={homeIcon} alt="" />
                </button>
              </Tooltip>
            )}
            {asset !== null &&
              clientNameForDB !== "ESSEL" &&
              hasAnnotationPermission &&
              mode !== "3D" && (
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  icon={
                    <img
                      className="w-6"
                      src={
                        selectedTool === "marker"
                          ? pinIcon
                          : selectedTool === "polyline"
                            ? polylineIco
                            : selectedTool === "polygon"
                              ? polygonIco
                              : selectedTool === "line"
                                ? lineIco
                                : markers
                      }
                      alt={`${selectedTool} icon`}
                    />
                  }
                  direction="left"
                  sx={{
                    marginTop: !settingsOpen && !legendsOpen ? "-6px" : "2px",
                    "& .MuiSpeedDial-fab": {
                      backgroundColor:
                        selectedTool === "marker" ||
                        selectedTool === "polyline" ||
                        selectedTool === "polygon"
                          ? "#48b"
                          : "rgba(20,26,34,.725)",
                      "&:hover": {
                        backgroundColor: hoverColor,
                      },
                      height: 40,
                      width: 40,
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => {
                    setlegendsOpen(false);
                    setSettingsOpen(false);
                    if (
                      selectedTool === "marker" ||
                      selectedTool === "polyline" ||
                      selectedTool === "polygon"
                    ) {
                      dispatch(gisToolActions.setselectedTool(null));
                      dispatch(gisToolActions.turnOffTools());
                      disableAllTools();
                    }
                  }}
                >
                  {userPermissions.includes("annotation_marker_add") &&
                    !settingsOpen &&
                    !legendsOpen && (
                      <SpeedDialAction
                        key="Point Observation"
                        icon={
                          <img
                            src={pinIcon}
                            className="w-6"
                            alt="Point Observation"
                          />
                        }
                        arrow
                        tooltipTitle="Point Observation"
                        sx={{
                          backgroundColor: "rgba(20,26,34,.725)",
                          "&:hover": {
                            backgroundColor: "rgba(20,26,34)",
                          },
                          boxShadow: "0 0 1.5px rgba(255,255,255,.7)",
                          backdropFilter: "blur(20px)",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(gisToolActions.setselectedTool("marker"));
                          dispatch(gisToolActions.toggleTools("marker"));
                          handleDrawStart("marker");
                        }}
                      />
                    )}
                  {userPermissions.includes("annotation_polyline_add") &&
                    !settingsOpen &&
                    !legendsOpen && (
                      <SpeedDialAction
                        key="Polyline Observation"
                        arrow
                        icon={
                          <img
                            src={polylineIco}
                            className="w-6"
                            alt="Polyline Observation"
                          />
                        }
                        tooltipTitle="Polyline Observation"
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(gisToolActions.setselectedTool("polyline"));
                          dispatch(gisToolActions.toggleTools("polyline"));
                          handleDrawStart("polyline");
                        }}
                        sx={{
                          backgroundColor: "rgba(20,26,34,.725)",
                          "&:hover": {
                            backgroundColor: hoverColor,
                          },
                          boxShadow: "none",
                        }}
                      />
                    )}
                  {userPermissions.includes("annotation_polygon_add") &&
                    !settingsOpen &&
                    !legendsOpen && (
                      <SpeedDialAction
                        key="Polygon Observation"
                        arrow
                        icon={
                          <img
                            src={polygonIco}
                            className="w-6"
                            alt="Polygon Observation"
                          />
                        }
                        tooltipTitle="Polygon Observation"
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(gisToolActions.setselectedTool("polygon"));
                          dispatch(gisToolActions.toggleTools("polygon"));
                          handleDrawStart("polygon");
                        }}
                        sx={{
                          backgroundColor: "rgba(20,26,34,.725)",
                          "&:hover": {
                            backgroundColor: hoverColor,
                          },
                          boxShadow: "none",
                        }}
                      />
                    )}
                </SpeedDial>
              )}

            {/* compair  */}
            {userPermissions.includes("compare_timelines") &&
              compareMode &&
              mode !== "3D" && (
                <Tooltip title="Click to Compare" arrow placement="left">
                  <button
                    style={{
                      boxShadow: "0 0 1.5px rgba(255,255,255,.7)",
                      backdropFilter: "blur(20px)",
                    }}
                    sx={{
                      backgroundColor: "rgba(20,26,34,.725)",
                      "&:hover": {
                        backgroundColor: "rgba(20,26,34)",
                      },
                      boxShadow: "0 0 1.5px rgba(255,255,255,.7)",
                      backdropFilter: "blur(20px)",
                    }}
                    className={`mb-2 flex h-10 w-10 items-center justify-center rounded-[50%] ${
                      showComparer ? "bg-[#48b]" : "bg-[rgba(20,26,34,.725)]"
                    } hover:bg-[rgba(20,26,34)]`}
                    onClick={() => {
                      if (mode === "3d") {
                        // setShowCompare(!showComparer);
                        // dispatch(gisToolActions.toggleTools("showComparer"));
                        // Toggle the showButtons
                        // showAssetsSelect();
                        // dispatch(gisHomeActions.toggleShowComparer(showComparer));
                      } else {
                        dispatch(gisToolActions.setselectedTool(null));
                        dispatch(gisToolActions.turnOffTools());
                        disableAllTools();
                        dispatch(gisToolActions.toggleTools("showComparer"));
                        // setMapCompapere(!mapComparer);
                      }
                    }}
                  >
                    <img className="w-6 text-red-200" src={switchIcon} alt="" />
                  </button>
                </Tooltip>
              )}

            {/* measure tools  */}
            {asset !== "" && hasMeasurementPermission && mode !== "3D" && (
              <SpeedDial
                ariaLabel="SpeedDial basic example"
                icon={
                  <img
                    className="w-6"
                    src={
                      selectedTool === "lineMeasure"
                        ? lineIco
                        : selectedTool === "polylineMeasure"
                          ? polylineIco
                          : selectedTool === "polygonMeasure"
                            ? polygonIco
                            : scaleIco
                    }
                    alt=""
                  />
                }
                direction="left"
                sx={{
                  marginTop: !settingsOpen && !legendsOpen ? "-6px" : "10px",
                  "& .MuiSpeedDial-root": {
                    pointerEvents: "none",
                  },
                  "& .MuiSpeedDial-fab": {
                    backgroundColor:
                      selectedTool === "lineMeasure" ||
                      selectedTool === "polylineMeasure" ||
                      selectedTool === "polygonMeasure"
                        ? "#48b"
                        : "rgba(20,26,34,.725)",
                    boxShadow: "0 0 1.5px rgba(255,255,255,.7)",
                    backdropFilter: "blur(20px)",
                    "&:hover": {
                      backgroundColor: hoverColor,
                    },
                    height: 40,
                    width: 40,
                  },
                }}
                onClick={() => {
                  setlegendsOpen(false);
                  setSettingsOpen(false);
                  if (
                    selectedTool === "lineMeasure" ||
                    selectedTool === "polylineMeasure" ||
                    selectedTool === "polygonMeasure"
                  ) {
                    dispatch(gisToolActions.setselectedTool(null));
                    dispatch(gisToolActions.turnOffTools());
                    disableAllTools();
                  }
                }}
              >
                {userPermissions.includes("measurement_line_view") &&
                  !settingsOpen &&
                  !legendsOpen && (
                    <SpeedDialAction
                      key="Measure Line"
                      icon={
                        <img src={lineIco} className="w-6" alt="Measure Line" />
                      }
                      arrow
                      tooltipTitle="Measure Line"
                      sx={{
                        backgroundColor: "rgba(20,26,34,.725)",
                        "&:hover": {
                          backgroundColor: hoverColor,
                        },
                        boxShadow: "none",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(gisToolActions.setselectedTool("lineMeasure"));
                        dispatch(gisToolActions.toggleTools("lineMeasure"));
                        handleDrawStart("lineMeasure");
                      }}
                    />
                  )}
                {userPermissions.includes("measurement_polyline_view") &&
                  !settingsOpen &&
                  !legendsOpen && (
                    <SpeedDialAction
                      key="Measure Distance"
                      icon={
                        <img
                          src={polylineIco}
                          className="w-6"
                          alt="Measure Distance"
                        />
                      }
                      arrow
                      tooltipTitle="Measure Distance"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(
                          gisToolActions.setselectedTool("polylineMeasure"),
                        );
                        dispatch(gisToolActions.toggleTools("polylineMeasure"));
                        handleDrawStart("polylineMeasure");
                      }}
                      sx={{
                        backgroundColor: "rgba(20,26,34,.725)",
                        "&:hover": {
                          backgroundColor: hoverColor,
                        },
                        boxShadow: "none",
                      }}
                    />
                  )}
                {userPermissions.includes("measurement_polygon_view") &&
                  !settingsOpen &&
                  !legendsOpen && (
                    <SpeedDialAction
                      key="Measure Area"
                      icon={
                        <img
                          src={polygonIco}
                          className="w-6"
                          alt="Measure Area"
                        />
                      }
                      arrow
                      tooltipTitle="Measure Area"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(
                          gisToolActions.setselectedTool("polygonMeasure"),
                        );
                        dispatch(gisToolActions.toggleTools("polygonMeasure"));
                        handleDrawStart("polygonMeasure");
                      }}
                      sx={{
                        backgroundColor: "rgba(20,26,34,.725)",
                        "&:hover": {
                          backgroundColor: hoverColor,
                        },
                        boxShadow: "none",
                      }}
                    />
                  )}
              </SpeedDial>
            )}
          </div>
        )}

        {asset !== "" && (
          <>
            <div className="bottomToolbar absolute bottom-8 right-2 z-[500] flex flex-col gap-2">
              <div className="unitsSelector relative hidden flex-row-reverse gap-2">
                {!unitsIsOpen ? (
                  <Tooltip title="Unit selector" arrow placement="left">
                    <button
                      className={`flex h-7 w-7 items-center justify-center rounded border bg-[rgba(20,26,34,.725)] text-white ${
                        unitsIsOpen
                          ? "rounded border bg-[#48b]"
                          : `hover:bg-[${hoverColor}]`
                      }`}
                      onClick={() => {
                        dispatch(gisToolActions.setUnitIsOpen());
                      }}
                    >
                      <img
                        className="w-6"
                        src={measuringTapeIco}
                        alt=""
                        srcSet=""
                      />
                    </button>
                  </Tooltip>
                ) : (
                  <button
                    className={`flex h-7 w-7 items-center justify-center rounded border bg-[rgba(20,26,34,.725)] text-white ${
                      unitsIsOpen
                        ? "rounded border bg-[#48b]"
                        : `hover:bg-[${hoverColor}]`
                    }`}
                    onClick={() => {
                      dispatch(gisToolActions.setUnitIsOpen());
                    }}
                  >
                    <img
                      className="w-6"
                      src={measuringTapeIco}
                      alt=""
                      srcSet=""
                    />
                  </button>
                )}
                {unitsIsOpen && (
                  <div className="absolute bottom-1 right-8 flex flex-col gap-2 rounded-sm bg-[#a0aab4bb] bg-white p-2">
                    <div className="flex items-center justify-between gap-2">
                      <p>Length</p>{" "}
                      <select
                        className={`h-7 w-max rounded border bg-[rgba(20,26,34,.725)] !py-0 text-xs text-white hover:bg-[${hoverColor}] relative z-10`}
                        value={selectedMeasurmentUnit}
                        onChange={(e) => handleUnitChange(e.target.value)}
                      >
                        <option className="text-xs" value="m">
                          m
                        </option>
                        <option className="text-xs" value="km">
                          km
                        </option>
                        <option className="text-xs" value="ft">
                          ft
                        </option>
                      </select>
                    </div>
                    <div className="flex items-center justify-between">
                      <p>Area</p>
                      <select
                        className={`h-7 w-max rounded border bg-[rgba(20,26,34,.725)] !py-0 text-xs text-white hover:bg-[${hoverColor}] relative z-10`}
                        value={selectedAreaUnit}
                        onChange={(e) => handleAreaUnitChange(e.target.value)}
                      >
                        <option className="text-xs" value="m">
                          sq m
                        </option>
                        <option className="text-xs" value="km">
                          sq km
                        </option>
                        <option className="text-xs" value="ft">
                          sq ft
                        </option>
                        <option className="text-xs" value="acer">
                          acre
                        </option>
                        <option className="text-xs" value="hector">
                          hector
                        </option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
              <Tooltip
                title={settingsOpen ? "" : "Settings"}
                arrow
                placement="left"
              >
                <button
                  onClick={() => {
                    handleEscKeyPress();
                    setlegendsOpen(false);
                    setSettingsOpen((prevState) => !prevState);
                  }}
                  className={`flex h-10 w-10 items-center justify-center rounded-[50%] bg-[rgba(20,26,34,.725)] text-lg font-bold text-white hover:bg-[${hoverColor}] `}
                >
                  {settingsOpen ? (
                    <img src={closeIco} alt="closeicon" />
                  ) : (
                    <img src={settingsIcon} alt="settingsIcon" />
                  )}
                </button>
              </Tooltip>
              <Tooltip
                title={legendsOpen ? "" : "Legends"}
                arrow
                placement="left"
              >
                <button
                  onClick={() => {
                    handleEscKeyPress();
                    setSettingsOpen(false);
                    setlegendsOpen((prevState) => !prevState);
                  }}
                  className={`flex h-10 w-10 items-center justify-center rounded-[50%] bg-[rgba(20,26,34,.725)] text-lg font-bold text-white hover:bg-[${hoverColor}] `}
                >
                  {legendsOpen ? <img src={closeIco} alt="closeicon" /> : "L"}
                </button>
              </Tooltip>
              <div className="zoomController flex h-20 w-10 flex-col rounded-[20px] bg-[rgba(20,26,34,.725)] text-white">
                <button
                  className="flex h-10 w-10 items-center justify-center rounded-t-[20px] hover:bg-[rgba(20,26,34)]"
                  onClick={() => handleZoom("in")}
                >
                  <img
                    className="mt-2 h-6 w-6"
                    src={ZoomInIcon}
                    alt="zoom in"
                  />
                </button>
                <button
                  className="flex h-10 w-10 items-center justify-center rounded-b-[20px] hover:bg-[rgba(20,26,34)]"
                  onClick={() => handleZoom("out")}
                >
                  <img
                    className="mt-[-4px] h-6 w-6"
                    src={ZoomOutIcon}
                    alt="zoom out"
                  />
                </button>
              </div>
              {imageryLabel !== "" &&
                (siteName === "Site B" ? (
                  <Tooltip
                    title={mode === "2D" ? "Switch to 3D" : "Switch to 2D"}
                    arrow
                    placement="left"
                  >
                    <button
                      onClick={() => {
                        dispatch(gisHomeActions.setMode());
                      }}
                      className={`h-10 w-10 rounded-[50%] bg-[rgba(20,26,34,.725)] text-white hover:bg-[${hoverColor}] font-semibold`}
                    >
                      {mode}
                    </button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Orthographic View" arrow placement="left">
                    <button
                      className={`h-10 w-10 rounded-[50%] bg-[rgba(20,26,34,.725)] text-white hover:bg-[${hoverColor}]`}
                    >
                      <h2 className="font-bold">2D</h2>
                    </button>
                  </Tooltip>
                ))}
              {asset?.Name && clientNameForDB === "hmel" && (
                <PreviousCurrentUpcommingLayerToggles />
              )}
            </div>
          </>
        )}
      </div>
      {isModalOpenCustom && (
        <MetaInputLeaflet
          isOpen={isModalOpenCustom}
          onClose={() => {
            setIsModalOpenCustom(false);
          }}
          onSave={handleMarkerSave}
          markerLayer={markerLayerCurrent}
          map={map}
        />
      )}
      {contextMenuOpen && (
        <ContextMenuModalLeaflet
          isOpen={contextMenuOpen}
          onClose={() => {
            setContextMenuOpen(false);
          }}
          modalPosition={modalPosition}
          latLng={clickedLatLng} // Pass lat lng to the component
          onRecentUsedTool={() => {
            dispatch(gisToolActions.setselectedTool(recentUsedTool));
            dispatch(gisToolActions.toggleTools(recentUsedTool));
            handleDrawStart(recentUsedTool);
          }}
          onAddMarker={() => {
            dispatch(gisToolActions.setselectedTool("marker"));
            dispatch(gisToolActions.toggleTools("marker"));
            handleDrawStart("marker");
          }}
          recentTool={recentUsedTool}
          map={map}
        />
      )}
      {legendsOpen && (
        <LegendsModalLeaflet
          isOpen={legendsOpen}
          onClose={() => {
            setlegendsOpen(false);
          }}
          map={map}
        />
      )}
      {settingsOpen && <SettingsModal isOpen={settingsOpen} />}
      <Toaster />
    </>
  );
};

export default GISToolbarLeaflet;
