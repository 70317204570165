import { createSlice } from "@reduxjs/toolkit";
import {
  getAnnotation,
  getChainage,
  getLatLon,
} from "../../../Utils/annotationUtils";

const projectSlice = createSlice({
  name: "project-data",
  initialState: {
    projectName: "",
    isCompare: false,
    projectId: "",
    videoSrc: "",
    annotations: [],
    annotations2: [],
    chainages: null,
    data: [],
    data2: [],
    latLng: null,
    latLng2: null,
    projectId2: "",
    clipSrc: "",
  },
  reducers: {
    setProjects(state, action) {
      const timeline = action.payload.data;
      const id = timeline.id;
      state.isCompare = false;
      state.projectId = timeline.id;
      state.data = timeline.metaData;
      state.videoSrc = timeline.video_src;
      state.clipSrc = timeline.clip_src;
      state.annotations = getAnnotation(timeline.metaData);
      state.latLng = getLatLon(timeline.metaData);
      state.chainages = getChainage(timeline.metaData, id);
      state.projectName = timeline.name;
    },

    setCompareProjects(state, action) {
      state.isCompare = true;
      state.projectId = action.payload.id[0];
      state.projectId2 = action.payload.id[1];
      const projectList = action.payload;
      state.data = projectList.data1;
      state.data2 = projectList.data2;
      state.latLng = getLatLon(projectList.data1);
      state.annotations = getAnnotation(projectList.data1);
      state.latLng2 = getLatLon(projectList.data2);
      state.annotations2 = getAnnotation(projectList.data2);
      state.chainages = getChainage(projectList.data1, action.payload.id[0]);
    },
  },
});

export const projectActions = projectSlice.actions;

export default projectSlice.reducer;
