import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import L from "leaflet";
import {
  locationIcon,
  a_zIcon,
  gridIcon,
  indronesGreyLogo,
  listIcon,
  searchIcon,
  closeIco,
} from "../../Assets/icons/gisIcons/icons";
import inspectLogo from "../../Assets/images/gisImg/inspect_logo.png";
import projectCatalog, {
  industryImages,
} from "../../Data/config/ProjectCatalogConfig";
// import { Pagination } from "@mui/material";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { gisLeafletActions } from "../../redux/slices/GIS/gis-leaflet-slice";
import TimelineModal from "../Projects/TimelineModal";
import CatalogCard from "./CatalogCard";
import FilterDiv from "./FilterDiv";
import ProfileModal from "./ProfileModal";
import DocViwerModal from "./DocViwerModal";
import { fetchData, storeData, updateData } from '../../Utils/indexedDB';
import { projectActions } from "../../redux/slices/project-slice";
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 0;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

const AutocompleteStyled = styled(Autocomplete)({
  width: "130px",
  // height: '56px',
  // '.MuiOutlinedInput-notchedOutline': { border: 'none' },
  // '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
  // '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
});

const extractUniqueTags = (catalog) => {
  const allTags = catalog.industries.map((industry) => industry.tags).flat();
  return [...new Set(allTags)];
};
const tags = extractUniqueTags(projectCatalog);
const tagsWithSelectAll = ["Select all", ...tags];
const selectAllText = "Select all";
const removeAllText = "Remove all";
const CatalogPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [page, setPage] = useState(1);
  const [profileOpen, setProfileOpen] = useState(false);
  // const [itemsPerPage, setItemsPerPage] = useState(3); // Initial default value
  const [initial, setInitial] = useState("");
  const [open, setOpen] = useState(false);
  const [docOpen, setDocOpen] = useState(false);
  const [currentIndustry, setCurrentIndustry] = useState(false);
  const [isCompare, setIsCompare] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const mapContainerRef = useRef(null);
  const [isAscending, setIsAscending] = useState(true);
  const [sortedIndustries, setSortedIndustries] = useState(
    projectCatalog.industries,
  );
  const [selectedTags, setSelectedTags] = useState([]);
  const [isGridView, setIsGridView] = useState(true);
  const [catalogSearch, setCatalogSearch] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
  const inputRef = useRef(null);
  const suggestionListRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const headerRef = useRef(null);

  // Calculate the items to be displayed on the current page
  // const startIndex = (page - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const paginatedIndustries = sortedIndustries.slice(startIndex, endIndex);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //scroll bg js
  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    // const scrollRatio = scrollTop / (scrollHeight - clientHeight);
    // console.log("scrollRatio", scrollRatio,"scrollTop",scrollTop,"scrollheight",scrollHeight,"clientHeight",clientHeight);
    if (scrollTop > 20) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    const firstName = Cookies.get("userFirstName");
    if (firstName) {
      setInitial(firstName?.charAt(0).toUpperCase());
    }

    dispatch(gisLeafletActions.resetStateGisLeafletSlice());
  }, []);

  // useEffect(() => {
  //   const calculateItemsPerPage = () => {
  //     const headerHeight = 64; // Adjust based on your header height
  //     const footerHeight = 68; // Adjust based on your footer height
  //     const availableHeight =window.innerHeight - headerHeight - footerHeight - 64;
  //     const itemHeight = 72; // Approximate height of each item
  //     const items = Math.floor(availableHeight / itemHeight);
  //     setItemsPerPage(items);
  //   };

  //   // Calculate on initial load
  //   calculateItemsPerPage();

  //   // Recalculate on window resize
  //   window.addEventListener("resize", calculateItemsPerPage);
  //   return () => {
  //     window.removeEventListener("resize", calculateItemsPerPage);
  //   };
  // }, []);

  useEffect(() => {
    const initialSortedIndustries = [...projectCatalog.industries].sort(
      (a, b) => a.name.localeCompare(b.name),
    );
    setSortedIndustries(initialSortedIndustries);
  }, []);

  //map
  useEffect(() => {
    const map = L.map(mapContainerRef.current, {
      center: [22.9868, 87.8550], // Set initial center
      zoom: 1, // Start at minimum zoom level
      zoomControl: false,
    });

    var CartoDB_DarkMatter = L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 20
    }).addTo(map);

    // var TopPlusOpen_Grey = L.tileLayer('http://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web_grau/default/WEBMERCATOR/{z}/{y}/{x}.png', {
    //   maxZoom: 18,
    //   attribution: 'Map data: &copy; <a href="http://www.govdata.de/dl-de/by-2-0">dl-de/by-2-0</a>'
    // }).addTo(map);
    

    map.flyTo([22.9868, 87.8550], 3.5, { duration: 3.5 }); // Fly to the specified lat lng with a zoom level of 13 in 3 seconds

    return () => {
      map.remove();
    };
  }, []);

  const handleViewClick = async (industry, compare, project_id) => {
    let modeParameter;
    // const nameForRoute = industry?.name.split(" ").join("_").toLowerCase();
    const nameForRoute =
      industry?.routeId || industry?.name.split(" ")[0].toLowerCase();
    //storing project meta
    try {
      // Initialize preset if not already stored
      const project = await fetchData('project');
      // console.log("project fetch", project);
      if (project===null) {
        await storeData('project',industry);
      }else{
        await updateData('project',1,industry)
      }      
    } catch (error) {
      console.error('Failed to initialize project:', error);
    }
    dispatch(projectActions.setProjectMeta(industry));
    Cookies.set("clientName", industry?.clientName || null);

    if (industry?.mode === "ortho") {
      modeParameter = encodeURIComponent("ortho");
      navigate(`/map/${nameForRoute}/${modeParameter}`);
      setProjectId(null);
    } else if (industry?.mode === "3d") {
      modeParameter = encodeURIComponent("3d");
      navigate(`/map/${nameForRoute}/${modeParameter}`);
      setProjectId(null);
    } else if (industry?.mode === "video") {
      setProjectId(project_id);
      if (compare) {
        setIsCompare(true);
      } else {
        setIsCompare(false);
      }
      handleOpen();
    } else {
      modeParameter = "error";
      navigate(`/error`);
      setProjectId(null);
    }
  };

  const handleSortToggle = () => {
    const sorted = [...sortedIndustries].reverse();
    setIsAscending(!isAscending);
    setSortedIndustries(sorted);
  };

  // const handleTagChange = (event, newValue) => {
  //   if (newValue.includes("Select all")) {
  //     if (selectedTags.includes("Select all")) {
  //       // If "Select all" is already selected, do nothing
  //       setSelectedTags(newValue.filter(tag => tag !== "Select all"));
  //     } else {
  //       // If "Select all" is clicked and not all options are selected, select all options
  //       setSelectedTags([]);
  //     }
  //   } else {
  //     // Handle individual tag selection
  //     const newTags = newValue.filter((tag) => tag !== "Select all");
  //     if (newTags.length === tags.length) {
  //       // If all individual tags are selected, include "Select all"
  //       setSelectedTags(tagsWithSelectAll);
  //     } else {
  //       // Handle the case where "Select all" is active, and the user clicks on another option to deactivate "Select all" and toggle that specific option
  //       const isSelectAllActive = selectedTags.includes("Select all");
  //       if (isSelectAllActive) {
  //         // Deactivate "Select all" and set the new tags
  //         setSelectedTags(newTags);
  //       } else {
  //         // Just set the new tags
  //         setSelectedTags(newTags);
  //       }
  //     }
  //   }
  // };

  const handleTagChange = (event, newValue) => {
    if (newValue.includes(selectAllText) || newValue.includes(removeAllText)) {
      if (
        selectedTags.includes(selectAllText) ||
        selectedTags.includes(removeAllText)
      ) {
        // If "Select All" or "Remove All" is already selected, deselect all
        setSelectedTags(newValue.filter((tag) => tag !== "Select all"));
      } else {
        // If "Select All" is clicked and not all options are selected, select all options
        // console.log("select all clicked when all emplty");
        setSelectedTags(tagsWithSelectAll);
      }
    } else {
      // Handle individual tag selection
      const newTags = newValue.filter(
        (tag) => tag !== selectAllText && tag !== removeAllText,
      );
      if (newTags.length === tags.length) {
        // If all individual tags are selected, include "Select All"
        setSelectedTags(tagsWithSelectAll);
      } else {
        setSelectedTags(newTags);
      }
    }
    // Handle the case where all individual tags are selected, but "Select All" and "Remove All" are not
    if (
      !newValue.includes(selectAllText) &&
      !newValue.includes(removeAllText) &&
      newValue.length === tags.length
    ) {
      setSelectedTags([]);
    }
  };

  const [dataToShowInCataolog, setDataToShowInCataolog] = useState([]);

  useEffect(() => {
    const filteredIndustries = sortedIndustries.filter((industry) =>
      selectedTags.length === 0
        ? true
        : industry.tags.some((tag) => selectedTags.includes(tag)),
    );

    const updatedSearchedData = filteredIndustries.filter((filterItem) =>
      filterItem.name.toLowerCase().includes(catalogSearch.toLowerCase()),
    );

    setDataToShowInCataolog(updatedSearchedData);
  }, [selectedTags, catalogSearch, sortedIndustries]);

  useEffect(() => {
    if (
      activeSuggestionIndex >= 0 &&
      activeSuggestionIndex < suggestions.length
    ) {
      inputRef.current.value = suggestions[activeSuggestionIndex];
    } else {
      inputRef.current.value = catalogSearch;
    }
  }, [activeSuggestionIndex]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(e.target) &&
        suggestionListRef.current &&
        !suggestionListRef.current.contains(e.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleView = () => {
    setIsGridView(!isGridView);
  };

  const handleDelete = (chipToDelete) => {
    const updatedTags = selectedTags.filter((chip) => chip !== chipToDelete);
    setSelectedTags(updatedTags.filter((chip) => chip !== "Select all"));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (
        activeSuggestionIndex >= 0 &&
        activeSuggestionIndex < suggestions.length
      ) {
        setCatalogSearch(suggestions[activeSuggestionIndex]);
        setShowSuggestions(false);
      }
      e.target.blur();
      setShowSuggestions(false);
    } else if (e.key === "ArrowDown") {
      setActiveSuggestionIndex((prevIndex) =>
        prevIndex === suggestions.length - 1 ? 0 : prevIndex + 1,
      );
    } else if (e.key === "ArrowUp") {
      setActiveSuggestionIndex((prevIndex) =>
        prevIndex <= 0 ? suggestions.length - 1 : prevIndex - 1,
      );
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setCatalogSearch(value);
    if (value.length > 0) {
      const filteredSuggestions = sortedIndustries
        .filter((industry) =>
          industry.name.toLowerCase().includes(value.toLowerCase()),
        )
        .map((industry) => industry.name);
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setCatalogSearch(suggestion);
    setSuggestions([]);
    setShowSuggestions(false);
    setActiveSuggestionIndex(-1);
  };

  const clearSearch = () => {
    setCatalogSearch("");
    setSuggestions([]);
    setShowSuggestions(false);
    setActiveSuggestionIndex(-1);
  };

// 0055CC

  return (
    <>
      <div   
        onScroll={handleScroll} 
        className="flex h-screen flex-col overflow-y-auto bg-[#FFFFFF] relative">
          {/* map div */}
          <div className="absolute top-0 z-0 flex h-[304px] w-full p-1"
            id="map"
            ref={mapContainerRef}
          ></div>
          {/* Nav Bar */}
          <div className={`text-white sticky top-0 z-20 flex w-full items-center justify-between p-2 transition-colors duration-600 
          ${isScrolled ? "bg-[#2C333A]" : ''}`}
          >
            <div className="flex flex-row gap-0 px-7">
              <div className="flex">
                <img
                  className="mr-2 h-[45px] w-[45px] "
                  src={inspectLogo}
                  alt="Logo"
                />
              </div>
              <div className="flex items-center">
                <p className="text-2xl font-bold">Inspect</p>
                <p className="mb-[-4px] ml-2 text-xs">powered by </p>
                <img
                  className={`mb-[-6px] h-6 invert-[100%] filter`}
                  src={indronesGreyLogo}
                  alt=""
                  srcSet=""
                />
              </div>
            </div>
            <div className="flex h-12 w-12 items-center justify-center rounded-full transition duration-300 ease-in hover:border-4 hover:border-blue-400/20">
              <button
                className="flex h-[2.5rem] w-[2.5rem] items-center justify-center rounded-[50%] bg-ic-yellow !text-[19px] text-base font-bold text-[#4a4844]"
                onClick={() => {
                  setProfileOpen((prevState) => !prevState);
                }}
              >
                <span>{initial}</span>
              </button>
            </div>
          </div>
          {/* Search bar Div */}    
          <div className="text-white flex w-full z-10 flex-col items-center justify-center pb-10 relative">
            <p className="pb-12 pt-14 text-5xl font-bold tracking-widest">
              Welcome to Inspect
            </p>
            {/* Updated search box with custom image icon */}
            <div className="text-black relative flex w-[657px] items-center justify-center">
              <div className="relative w-full">
                <input
                  ref={inputRef}
                  type="text"
                  value={catalogSearch}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Search by name"
                  className="box-border h-12 w-full rounded-full border border-[#CACACA] pl-12 pr-4 text-lg shadow-md shadow-[rgba(0,0,0,0.1)] focus:border-[#451a03] focus:outline-none"
                />
                <img
                  src={searchIcon}
                  alt="Search Icon"
                  className="absolute left-4 top-1/2 h-6 w-6 -translate-y-1/2 transform"
                />
                {catalogSearch && (
                  <img
                    src={closeIco}
                    alt="Clear Icon"
                    onClick={clearSearch}
                    className="absolute right-4 top-1/2 h-6 w-6 -translate-y-1/2 transform cursor-pointer invert-[100%] filter"
                  />
                )}
              </div>
            </div>
          </div>
          {showSuggestions && suggestions.length > 0 && (
            <ul
              className="text-black absolute z-50 top-[266px] w-[657px] self-center rounded-md border border-[#CACACA] bg-white shadow-[rgba(0,0,0,0.1)] h-60 overflow-y-auto"
              ref={suggestionListRef}
            >
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className={`cursor-pointer px-4 py-2 hover:bg-gray-100 ${
                    index === activeSuggestionIndex ? "bg-gray-200" : ""
                  }`}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}

          {/* Catalog Header*/}
          <div className="bg-[#F7F8F9] sticky top-16 z-20 flex w-full justify-center">
            <div className="flex w-full max-w-7xl items-center px-4">
              <p className="w-[15%] text-2xl font-bold">Project Catalog</p>
              <div className="w-[63%]">
                <FilterDiv selectedTags={selectedTags} onDelete={handleDelete} />
              </div>
              <div className="flex w-[12%] justify-end">
                <AutocompleteStyled
                  size="small"
                  className="mt-[-18px] h-8 !w-[100%]"
                  disableCloseOnSelect
                  multiple
                  limitTags={10}
                  id="size-small-standard-multi"
                  value={selectedTags}
                  options={tagsWithSelectAll}
                  getOptionLabel={(option) => {
                    if (
                      selectedTags.includes(selectAllText) ||
                      selectedTags.includes(removeAllText)
                    ) {
                      return option === selectAllText ? removeAllText : option;
                    }
                    return option;
                  }}
                  defaultValue={selectedTags} // Select all tags by default
                  onChange={handleTagChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filters"
                      placeholder={``}
                      variant="standard"
                    />
                  )}
                  // renderOption={(props, option, { selected }) => (
                  //   <li {...props} style={{ backgroundColor: selected ? 'rgba(0, 0, 0, 0.08)' : 'inherit' }}>
                  //     {option}
                  //   </li>
                  // )}
                  renderTags={() => null} // This hides the selected options in the label
                />
              </div>
              <div className="flex w-[10%] justify-end gap-2">
                <Tooltip
                  title={isGridView ? "List View" : "Grid View"}
                  arrow
                  placement="bottom"
                >
                  <button
                    className="flex h-8 w-8 items-center rounded-[50%] p-1 font-bold hover:bg-[rgba(204,204,204,0.2)]"
                    onClick={toggleView}
                  >
                    <img
                      src={isGridView ? listIcon : gridIcon}
                      alt="Toggle View Icon"
                      className="h-7 w-7"
                    />
                  </button>
                </Tooltip>
                <Tooltip
                  title={isAscending ? "Sort Descending" : "Sort Ascending"}
                  arrow
                  placement="bottom"
                >
                  <button
                    className="flex h-8 w-8 items-center rounded-[50%] p-1 font-bold hover:bg-[rgba(204,204,204,0.2)]"
                    onClick={handleSortToggle}
                  >
                    <img
                      src={a_zIcon}
                      alt="Sort Icon"
                      className={`h-7 w-7 transform transition-transform ${!isAscending ? "scale-x-[-1]" : ""}`}
                    />
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
          {/* Card div */}
          <div className="relative bg-[#FFFFFF] z-10 mb-4 flex h-auto w-full flex-col justify-center items-center">
            <div className="!max-w-7xl w-full px-10 pb-3 pt-10">
              {dataToShowInCataolog.length > 0 ? (
                isGridView ? (
                  <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                    {dataToShowInCataolog.map((industry, index) => (
                      <CatalogCard
                        key={index}
                        industry={industry}
                        handleViewClick={handleViewClick}
                      />
                    ))}
                  </div>
                ) : (
                  <div>
                    {dataToShowInCataolog.map((industry, index) => {
                      const oriCount = industry.photogrammetryLayers.filter(
                        (layer) => layer.layerType === "ORI",
                      ).length;
                      return (
                        <div
                          key={index}
                          className="catalog-card custom-grid flex items-center p-2"
                        >
                          <div className="card-icon-container !bg-yellow-200">
                            <img
                              src={industryImages[industry.name]}
                              alt="Industry Icon"
                              className="card-icon"
                            />
                          </div>
                          <div className="ml-4 flex flex-col">
                            <p className="title">{industry.name}</p>
                            <div className="mt-1 flex items-center">
                              <img
                                src={locationIcon}
                                alt="Location Icon"
                                className="location-icon"
                              />
                              <p className="description">
                                {industry.location}India
                              </p>
                            </div>
                            <div className="tag-container mt-2 flex">
                              {industry.tags.map((tag, tagIndex) => (
                                <div key={tagIndex} className="tag">
                                  <span className="tag-text">{tag}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="ml-auto flex gap-2">
                            {industry.projectType === "Videography" && (
                              <button
                                className="compare-button"
                                onClick={() =>
                                  handleViewClick(industry, true, industry.id)
                                }
                              >
                                Compare
                              </button>
                            )}
                            {industry.name === "Real Estate - I" && (
                              <Tooltip
                                title={"Documents"}
                                arrow
                                placement="bottom"
                              >
                                <button
                                  className="list-document-button"
                                  onClick={() => {
                                    setDocOpen(true);
                                    setCurrentIndustry(industry);
                                  }}
                                >
                                  Documents
                                </button>
                              </Tooltip>
                            )}
                            <button
                              className="view-button"
                              onClick={() =>
                                handleViewClick(industry, false, industry.id)
                              }
                            >
                              View
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )
              ) : (
                <p className="mt-4 text-center text-xl font-medium tracking-wide">
                  Project not found.
                </p>
              )}
            </div>
          </div>
      </div>

      {profileOpen && (
        <ProfileModal
          isOpen={profileOpen}
          onClose={() => {
            setProfileOpen(false);
          }}
        />
      )}

      {projectId && (
        <TimelineModal
          project={projectId}
          open={open}
          handleClose={handleClose}
          isCompare={isCompare}
        />
      )}

      {docOpen && (
        <DocViwerModal
          isOpen={docOpen}
          onClose={() => {
            setDocOpen(false);
          }}
          industry={currentIndustry}
        />
      )}
    </>
  );
};

export default CatalogPage;

// {/* <p className='sticky top-32 mt-20 font-semibold flex flex-row text-base  bg-white items-center justify-between'>
// Videography
//  <div className="catalog-card">
//     <p className="title">{industry.name}</p>
// </p>
// {projectCatalog.Videography.industries.map((industry, index) => (
//     <p className="description ml-2 text-xs font-light">Ldam molestiae, ex ab animi doloribus iusto numquam eius</p>
//     <div className="flex gap-2">
//         {industry.tags.map((tag, tagIndex) => (
//             <button key={tagIndex} className="tag">{tag}</button>
//         ))}
//     </div>
//     <button className="view-button">View</button>
// </div>
// ))}
// <p className='sticky top-32 mt-14 font-semibold flex flex-row text-base bg-white items-center justify-between'>
// Photogrammetry
// </p>
// {projectCatalog.Videography.industries.map((industry, index) => (
// <div className="catalog-card">
//     <p className="title">{industry.name}</p>
//     <p className="description ml-2 text-xs font-light">{industry.location}</p>
//     <div className="flex gap-2">
//         {industry.tags.map((tag, tagIndex) => (
//             <button key={tagIndex} className="tag">{tag}</button>
//         ))}
//     </div>
//     <button className="view-button">View</button>
// </div>
// ))} */}
