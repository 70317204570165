import { createSlice } from "@reduxjs/toolkit";

const gisLeafletSlice = createSlice({
  name: "gisLeaflet",
  initialState: {
    refCounterMeasure: 0,
    refCounterUnits: 0,
    avaliableRasterAssets: [],
    compareRasterLayers: { leftLayer: "", rightLayer: "" },
    vectorMeasurentLayers: [],
    measurementArray: [],
    KmlsetArray: [], // New array for KML layers
    geoJSONArray: [],
    visibleLayers: [], // New array for visible layers
    asset: null,
    opacityOfTimeline: null,
    selectAssetFromPreviousCurrentNextNav: "",
    // leaflet assets (annotations )
    observationsBucket: [],
    observationsVisibilityStatus: [],
    IsWMSAssetLoading: false,
    layersBetweenStation: [],
    currentStation: {},
    //shape geojson
    shapeFeturesetArray: [],
    shpaeGeoJson: {},
  },
  reducers: {
    refCounterMeasureUpdate: (state) => {
      state.refCounterMeasure += 1;
    },
    refCounterUnitsUpdate: (state) => {
      state.refCounterUnits += 1;
    },
    setAvaliableRasterAssets: (state, action) => {
      if (!state.avaliableRasterAssets.includes(action.payload)) {
        state.avaliableRasterAssets = [
          ...state.avaliableRasterAssets,
          action.payload,
        ];
      }
    },
    setCompareRasterLayers: (state, action) => {
      state.compareRasterLayers = action.payload;
    },

    setAsset: (state, action) => {
      state.asset = action.payload;
    },
    setVectorMeasurentLayers: (state, action) => {
      state.vectorMeasurentLayers = [
        ...state.vectorMeasurentLayers,
        action.payload,
      ];
    },
    removeLayer: (state, action) => {
      const indexToRemove = state.vectorMeasurentLayers.findIndex(
        (layer) => layer.id === action.payload.id,
      );
      if (indexToRemove !== -1) {
        state.vectorMeasurentLayers.splice(indexToRemove, 1);
      }
    },
    setmeasurementArray: (state, action) => {
      state.measurementArray = [...state.measurementArray, action.payload]; // Add new item to measurementArray
    },
    removeItemFromMeasurementArrayy: (state, action) => {
      const indexToRemove = state.measurementArray.findIndex(
        (layer) => layer.id === action.payload.id,
      );
      if (indexToRemove !== -1) {
        state.measurementArray.splice(indexToRemove, 1);
      }
    },
    // New reducers for KmlsetArray
    setKmlsetArray: (state, action) => {
      state.KmlsetArray = [...state.KmlsetArray, action.payload];
    },
    removeKmlLayer: (state, action) => {
      state.KmlsetArray = state.KmlsetArray.filter(
        (kml) => kml.id !== action.payload,
      );
    },

    clearKmlsetArray: (state) => {
      state.KmlsetArray = [];
    },

    toggleVisibility: (state, action) => {
      const id = action.payload;
      const measureIndex = state.KmlsetArray.findIndex(
        (measure) => measure.id === id,
      );
      // Check if measureIndex is valid
      if (measureIndex !== -1 && state.KmlsetArray[measureIndex]) {
        state.KmlsetArray[measureIndex].visible =
          !state.KmlsetArray[measureIndex].visible;
      }
    },
    // New reducers for shapeFeturesetArray
    setshapeFeturesetArray: (state, action) => {
      state.shapeFeturesetArray = [...state.shapeFeturesetArray, action.payload];
    },
    removeShapeObj: (state, action) => {
      state.shapeFeturesetArray = state.shapeFeturesetArray.filter(
        (kml) => kml.id !== action.payload,
      );
    },

    clearshapeFeturesetArray: (state) => {
      state.shapeFeturesetArray = [];
    },

    toggleShapeFeatureVisibility: (state, action) => {
      const id = action.payload;
      const measureIndex = state.shapeFeturesetArray.findIndex(
        (measure) => measure.id === id,
      );
      // Check if measureIndex is valid
      if (measureIndex !== -1 && state.shapeFeturesetArray[measureIndex]) {
        state.shapeFeturesetArray[measureIndex].visible =
          !state.shapeFeturesetArray[measureIndex].visible;
      }
    },
    // New reducers for GeoJSON data
    setGeoJSON: (state, action) => {
      state.geoJSONArray = [...state.geoJSONArray, action.payload];
    },
    removeGeoJSONLayer: (state, action) => {
      state.geoJSONArray = state.geoJSONArray.filter(
        (geoJSON) => geoJSON.id !== action.payload,
      );
    },
    clearGeoJSONArray: (state) => {
      state.geoJSONArray = [];
    },

    toggleGeoJSONVisibility: (state, action) => {
      const id = action.payload;
      const geoJSONIndex = state.geoJSONArray.findIndex(
        (geoJSON) => geoJSON.id === id,
      );
      // Check if geoJSONIndex is valid
      if (geoJSONIndex !== -1 && state.geoJSONArray[geoJSONIndex]) {
        state.geoJSONArray[geoJSONIndex].visible =
          !state.geoJSONArray[geoJSONIndex].visible;
      }
    },

    setOpacityOfTimeline: (state, action) => {
      state.opacityOfTimeline = action.payload;
    },
    // New reducer to add item to visibleLayers array
    addItemToVisibleLayers: (state, action) => {
      state.visibleLayers.push(action.payload);
    },
    // New reducer to toggle layer visibility
    toggleLayerVisibility: (state, action) => {
      const { index } = action.payload;
      state.visibleLayers[index] = !state.visibleLayers[index];
    },
    // New reducer to remove item from visibleLayers array
    removeItemFromVisibleLayers: (state, action) => {
      const { index } = action.payload;
      state.visibleLayers.splice(index, 1);
    },

    // leaflet assets (annotations) reducers
    setObservationsBucket: (state, action) => {
      state.observationsBucket = action.payload;
    },

    setObservationsVisibilityStatus: (state, action) => {
      state.observationsVisibilityStatus = action.payload;
    },

    setIsWMSAssetLoading: (state, action) => {
      state.IsWMSAssetLoading = action.payload;
    },

    setLayersBetweenStation: (state, action) => {
      state.layersBetweenStation = action.payload;
    },
    setCurrentStation: (state, action) => {
      state.currentStation = action.payload;
    },
    setShpaeGeoJson: (state, action) => {
      state.shpaeGeoJson = action.payload;
    },
    setSelectAssetFromPreviousCurrentNextNav: (state, action) => {
      state.selectAssetFromPreviousCurrentNextNav = action.payload;
    },
    resetStateGisLeafletSlice: (state) => {
      state.refCounterMeasure = 0;
      state.refCounterUnits = 0;
      state.avaliableRasterAssets = [];
      state.compareRasterLayers = { leftLayer: "", rightLayer: "" };
      state.vectorMeasurentLayers = [];
      state.measurementArray = [];
      state.KmlsetArray = [];
      state.geoJSONArray = [];
      state.visibleLayers = [];
      state.asset = null;
      state.opacityOfTimeline = null;
      state.selectAssetFromPreviousCurrentNextNav = "";
      state.observationsBucket = [];
      state.observationsVisibilityStatus = [];
      state.IsWMSAssetLoading = false;
      state.layersBetweenStation = [];
      state.currentStation = {};
      state.shapeFeturesetArray = [];
    },
  },
});

export const gisLeafletActions = gisLeafletSlice.actions;

export default gisLeafletSlice.reducer;
