/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { copyIcon } from "../../../Assets/icons/gisIcons/icons";
import {
  lineIco,
  markers,
  measuringTapeIco,
  pinIcon,
  polygonIco,
  polylineIco,
  scaleIco,
} from "../../../Assets/icons/gisIcons/icons";
const ContextMenuModalLeaflet = ({
  isOpen,
  onClose,
  modalPosition,
  latLng,
  onRecentUsedTool,
  onAddMarker,
  recentTool,
  map,
}) => {
  const [adjustedPosition, setAdjustedPosition] = useState(modalPosition);
  const [toolIcon,setToolIcon] = useState();
  const toolLabels = {
    lineMeasure: "Line Measure",
    polylineMeasure: "Measure Distance",
    polygonMeasure: "Measure Area",
    marker: "Point Marker",
    polyline: "Polyline Marker",
    polygon: "Polygon Marker"
  };
  const toolIcons = {
    lineMeasure: lineIco,
    polylineMeasure: polylineIco,
    polygonMeasure: polygonIco,
    marker: pinIcon,
    polyline: polylineIco,
    polygon: polygonIco
  };
  
  const [recentToolUsed,setRecentToolUsed]=useState("");
  
  useEffect(() => {
    setRecentToolUsed(toolLabels[recentTool] || "");
    setToolIcon(toolIcons[recentTool] || null);
  }, [recentTool]);
  useEffect(() => {
    const adjustPosition = () => {
      const { clientWidth, clientHeight } = document.documentElement;
      const modalWidth = 280;
      const modalHeight = 100;
      let { top, left } = modalPosition;
      if (top + modalHeight > clientHeight) {
        top = clientHeight - modalHeight - 20;
      }
      if (left + modalWidth > clientWidth) {
        left = clientWidth - modalWidth;
      }
      setAdjustedPosition({ top, left });
    };
    adjustPosition();
  }, [modalPosition]);

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextMenu);
    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  const style = {
    position: "absolute",
    top: `${adjustedPosition.top}px`,
    left: `${adjustedPosition.left}px`,
    maxHeight: "280px",
    maxWidth: "280px",
  };
  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast(
          () => (
            <div className="flex items-center">
              <span>Coordinates copied to the clipboard.</span>
            </div>
          ),
          {
            style: {
              background: "black",
              color: "white",
            },
          },
        );
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };
  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0)", // Adjust the opacity as needed
          },
        }}
      >
        <div
          style={style}
          className="flex flex-col justify-content-start  rounded bg-[rgba(20,26,34,.725)] py-4 text-xs text-white shadow-sm shadow-[rgba(82,82,82,0.7)]"
        >
          <div className="flex flex-row px-4 py-1 hover:bg-[rgba(20,26,34)]">
            <button
              className="flex "
              onClick={() => {
                handleCopyToClipboard(`${latLng.lat}, ${latLng.lng}`);
                onClose();
              }}
            >
              <img
                className="h-4 rounded"
                src={copyIcon}
                alt="copy id icon"
                srcSet=""
                style={{ filter: 'invert(100%)' }} // changing colour to white
              />
              <span className="ml-2 font-bold">Copy Coordinates</span>
            </button>
          </div>
          <button
            className="flex px-4 py-1 hover:bg-[rgba(20,26,34)]  items-center"
            onClick={() => {
              onClose();
              onRecentUsedTool();
            }}
          >
             <img
                className="p-[1px] w-4 h-[30px] rounded"
                src={toolIcon}
                alt="recent tool icon"
                srcSet=""
              />
            <span className="ml-2 font-bold"> Use Recent Tool: {recentToolUsed} </span>
          </button>
          {/* <button
            onClick={() => {
              onClose();
              onAddMarker();
            }}
            className="bg-white px-4 w-full  hover:bg-gray-200"
          >
           Add a Marker here 
          </button> */}
        </div>
      </Modal>
    </>
  );
};

export default ContextMenuModalLeaflet;
