import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import React from "react";
import {
  adminSettings,
  closeIco,
  logoutIco,
} from "../../Assets/icons/gisIcons/icons";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const ScrollableDiv = styled(Paper)(({ theme }) => ({
  background:"inherit",
  display: "flex",
  alignItems: "center",
  listStyle: "none",
  margin: 0,
  overflow: "hidden",
  whiteSpace: "nowrap",
  height: "50px", // Fixed height for the container
  // cursor: 'grab',
  position: "relative",
  width: "92%", // Subtract the total width of the scroll buttons (40px each)
  boxSizing: "border-box",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "-ms-overflow-style": "none", // IE and Edge
  "scrollbar-width": "none", // Firefox
}));

const ScrollButton = styled(IconButton)(({ theme, disabled }) => ({
  position: "relative",
  // top: '50%',
  // transform: 'translateY(-50%)',
  zIndex: 1,
  opacity: disabled ? 0 : 1,
  pointerEvents: disabled ? "none" : "auto",
}));

const FilterDiv = ({ selectedTags, onDelete }) => {
  const scrollRef = React.useRef(null);
  const [isLeftDisabled, setIsLeftDisabled] = React.useState(true);
  const [isRightDisabled, setIsRightDisabled] = React.useState(false);

  // const handleClick = (chipToToggle) => () => {
  //   setChipData((chips) =>
  //     chips.map((chip) =>
  //       chip.key === chipToToggle.key
  //         ? { ...chip, active: !chip.active }
  //         : chip
  //     )
  //   );
  // };

  const handleMouseDown = (e) => {
    scrollRef.current.isDown = true;
    scrollRef.current.startX = e.pageX - scrollRef.current.offsetLeft;
    scrollRef.current.scrollLeft = scrollRef.current.scrollLeft;
    scrollRef.current.style.cursor = "grabbing";
  };

  const handleMouseLeave = () => {
    scrollRef.current.isDown = false;
    scrollRef.current.style.cursor = "grab";
  };

  const handleMouseUp = () => {
    scrollRef.current.isDown = false;
    scrollRef.current.style.cursor = "grab";
  };

  const handleMouseMove = (e) => {
    if (!scrollRef.current.isDown) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - scrollRef.current.startX) * 0.1; // Slow down the scroll speed
    scrollRef.current.scrollLeft = scrollRef.current.scrollLeft - walk;
    updateButtonState();
  };

  const handleScrollLeft = () => {
    if (scrollRef.current) {
      const chipWidth = scrollRef.current.querySelector("li").offsetWidth;
      scrollRef.current.scrollBy({ left: -chipWidth, behavior: "smooth" });
      updateButtonState();
    }
  };

  const handleScrollRight = () => {
    if (scrollRef.current) {
      const chipWidth = scrollRef.current.querySelector("li").offsetWidth;
      scrollRef.current.scrollBy({ left: chipWidth, behavior: "smooth" });
      updateButtonState();
    }
  };

  const updateButtonState = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setIsLeftDisabled(scrollLeft === 0);
      setIsRightDisabled(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  React.useEffect(() => {
    updateButtonState();
    // Recalculate button states on window resize
    const handleResize = () => {
      updateButtonState();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="relative flex w-full flex-row items-center justify-between">
      <div className="w-[4%]">
        <ScrollButton
          className=""
          onClick={handleScrollLeft}
          disabled={isLeftDisabled}
        >
          <ArrowBackIosIcon />
        </ScrollButton>
      </div>
      <ScrollableDiv
        ref={scrollRef}
        component="ul"
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        elevation={0}
      >
        {selectedTags.map((data, index) => {
          if (data === "Select all") return null;
          return (
            <ListItem key={index}>
              <Chip
                label={data}
                onDelete={() => onDelete(data)}
                variant="outlined"
                deleteIcon={
                  <img
                    src={closeIco}
                    alt="delete"
                    style={{ width: "16px", height: "16px" }}
                    className="rounded-[50%]"
                  />
                }
                sx={{
                  width: 82, // Set a fixed width for each chip
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#e2edfd", 
                  // border: "2px solid #bfbfbf",
                  // transition: "border 0.3s ease-in-out", // Smooth transition for the border property
                  '&:hover': {
                    border: "2px solid #ffca00", // Border color on hover
                    // backgroundColor: '#ffca00', // Prevent hover background color change
                  },
                  "& .MuiChip-deleteIcon": {
                    filter: "invert(100%)", // Example filter applied to the delete icon
                    transition: "filter 0.3s ease", // Smooth transition for the filter property
                  },
                  "& .MuiChip-deleteIcon:hover": {
                    backgroundColor: "rgba(209, 213, 219,0.3)", // Background color on delete icon hover
                  },
                }}
              />
            </ListItem>
          );
        })}
      </ScrollableDiv>
      <div className="w-[4%]">
        <ScrollButton onClick={handleScrollRight} disabled={isRightDisabled}>
          <ArrowForwardIosIcon />
        </ScrollButton>
      </div>
    </div>
  );
};

export default FilterDiv;
