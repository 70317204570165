import Dexie from 'dexie';
import { encryptData } from './encryption';
const db = new Dexie('UserDatabase');
db.version(1).stores({
    presets: '++id,presetsData' ,
    measurements: '++id,measurementsData',
    project:'++id,projectData',
    wfsCache: '++id,requestKey,data' 
});
// Open the database
db.open().catch((error) => {
    console.error("Failed to open db:", error);
});

const initializeMeasurementsData = async () => {
    const record = await db.measurements.get(1);
    if (!record) {
      const initialData = await encryptData([]);
      await db.measurements.add({ id: 1, measurementsData: initialData });
      // console.log('Initialized measurementsData as an empty array');
    }
  };
  
  initializeMeasurementsData().catch((error) => {
    console.error('Failed to initialize measurements data:', error);
  });

export default db;
