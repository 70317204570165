import axios from "axios";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";

import { IconButton, Modal, TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const Settings = () => {
  const [type, setType] = React.useState([]);
  const [reloader, setReloader] = React.useState(0);
  const [category, setCategory] = React.useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const [modaletype, setmodaltype] = React.useState("");
  const [selected, setSelected] = React.useState(null);
  const [editType, setEditType] = React.useState("type");
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    const getCategories = async () => {
      const response = await axios.get(
        "https://delivery.inspect.indrones.com/api/v1/settings/category/get_all/hmel"
      );
      // console.log(response);
      setCategory(response.data);

      const types = response.data.flatMap((item) =>
        item.type.map((typeItem) => ({
          ...typeItem,
          category: item.name,
          category_id: item.id,
        }))
      );
      setType(types);
    };

    getCategories();
  }, [reloader]);

  const handleOpenModal = (type) => {
    setIsOpen(true);
    setmodaltype(type);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleAddCategory = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `https://delivery.inspect.indrones.com/api/v1/settings/category/create`,
        {
          name: event.target.categoryName.value,
          client_abbr: "hmel",
        }
      );
      // console.log("res", response);
      setReloader(reloader + 1);
      handleCloseModal();
    } catch (error) {
      console.error("err", error);
    }
  };

  const handleAddType = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `https://delivery.inspect.indrones.com/api/v1/settings/type/create`,
        {
          name: event.target.typeName.value,
          category_id: event.target.category.value,
        }
      );
      // console.log("res", response);
      setReloader(reloader + 1);
      handleCloseModal();
    } catch (error) {
      console.error("err", error);
    }
  };

  const handleEdit = (index, data, type) => {
    if (!editMode) {
      setSelectedIndex(index);
      setSelected(data);
      setEditType(type);
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  };
  const handleDelete = async (id, type) => {
    try {
      const response = await axios.post(
        `https://delivery.inspect.indrones.com/api/v1/settings/${type}/delete/${id}`,
        {}
      );
      setReloader(reloader + 1);
    } catch (error) {
      console.error("err", error);
    }
  };
  const handleSave = async (id, type) => {
    try {
      const response = await axios.post(
        `https://delivery.inspect.indrones.com/api/v1/settings/${type}/edit/${id}`,
        selected
      );
      // console.log(response);
      setReloader(reloader + 1);
      setEditMode(false);
    } catch (error) {
      console.error("err", error);
    }
  };

  return (
    <>
      <div className="h-[calc(100%-60px)] w-[90%] flex flex-col mx-auto">
        <div className="h-[50%] w-full cursor-default m-4 overflow-y-auto overflow-x-auto custom-scrollbar">
          <div
            onClick={() => handleOpenModal("Category")}
            className="cursor-pointer w-max ml-auto mb-3 mr-5 !rounded-[5px] bg-[#4a4844] text-white rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
          >
            Add Category
          </div>
          <table className="table w-full border-[1px] border-solid border-[#e5e7eb]">
            <thead className="bg-[#4a4844] text-[#fff] sticky top-0">
              <tr>
                <th className="px-2 py-3 font-[500]">Sr No</th>
                <th className="px-2 py-3 font-[500]">Name</th>
                <th className="px-2 py-3 font-[500]">Action</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {category.map((category, index) => {
                const isSelected = selectedIndex === index;
                return (
                  <tr key={index}>
                    <td className="px-2 py-3">{index + 1}</td>

                    {isSelected && editMode && editType === "category" ? (
                      <>
                        <td className="px-2 py-3">
                          <TextField
                            name="textNote"
                            size="small"
                            value={selected.name}
                            onChange={(e) =>
                              setSelected((prevState) => ({
                                ...prevState,
                                name: e.target.value,
                              }))
                            }
                            className="grow"
                          />
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="px-2 py-3">{category.name}</td>
                      </>
                    )}

                    <td className="px-2 py-3">
                      <div className="flex items-center justify-center gap-3">
                        <EditIcon
                          onClick={() =>
                            handleEdit(index, category, "category")
                          }
                        />
                        <DeleteIcon
                          onClick={() => handleDelete(category.id, "category")}
                        />
                        {editMode && editType === "category" && (
                          <SaveIcon
                            onClick={() => handleSave(category.id, "category")}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="h-[50%] w-full cursor-default m-4 overflow-y-auto overflow-x-auto custom-scrollbar">
          <div
            onClick={() => handleOpenModal("Type")}
            className="cursor-pointer w-max ml-auto mr-5 mb-3 !rounded-[5px] bg-[#4a4844] text-white rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
          >
            Add Type
          </div>
          <table className="table w-full border-[1px] border-solid border-[#e5e7eb]">
            <thead className="bg-[#4a4844] text-[#fff] sticky top-0">
              <tr>
                <th className="px-2 py-3 font-[500]">Sr No</th>
                <th className="px-2 py-3 font-[500]">Name</th>
                <th className="px-2 py-3 font-[500]">Category</th>
                <th className="px-2 py-3 font-[500]">Action</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {type.map((type, index) => {
                const isSelected = selectedIndex === index;
                return (
                  <tr key={index}>
                    <td className="px-2 py-3">{index + 1}</td>

                    {isSelected && editMode && editType === "type" ? (
                      <>
                        <td className="px-2 py-3">
                          <TextField
                            name="textNote"
                            size="small"
                            value={selected.name}
                            onChange={(e) =>
                              setSelected((prevState) => ({
                                ...prevState,
                                name: e.target.value,
                              }))
                            }
                            className="grow"
                          />
                        </td>
                        <td className="px-2 py-3">
                          <select
                            className="border border-gray-600 rounded"
                            name="client"
                            value={selected.client_id}
                            onChange={(e) =>
                              setSelected((prevState) => ({
                                ...prevState,
                                category_id: parseInt(e.target.value),
                              }))
                            }
                          >
                            {category?.map((client, index) => (
                              <option key={index} value={client.id}>
                                {client.name}
                              </option>
                            ))}
                          </select>
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="px-2 py-3">{type.name}</td>
                        <td className="px-2 py-3">{type.category}</td>
                      </>
                    )}

                    <td className="px-2 py-3">
                      <div className="flex items-center justify-center gap-3">
                        <EditIcon
                          onClick={() => handleEdit(index, type, "type")}
                        />
                        <DeleteIcon
                          onClick={() => handleDelete(type.id, "type")}
                        />
                        {editMode && editType === "type" && (
                          <SaveIcon
                            onClick={() => handleSave(type.id, "type")}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <Modal open={isOpen} onClose={handleCloseModal}>
        <div
          style={style}
          className="flex flex-col items-center justify-between bg-white w-full h-max rounded-[12px] "
        >
          <div className="flex items-center justify-between rounded-tl-[12px] rounded-tr-[12px] w-full p-5 border-b bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
            <h3 className="text-[1.20rem] text-[#211f1c] grow flex items-center justify-center">
              Add {modaletype}
            </h3>
            <div className="mr-[10px]">
              <IconButton
                onClick={handleCloseModal}
                aria-label="Close"
                size="small"
                className="text-[#211f1c]"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          {modaletype === "Category" ? (
            <>
              <form
                className="grow w-full p-3 flex flex-col gap-3"
                onSubmit={handleAddCategory}
              >
                <div className="flex items-center justify-center w-full">
                  <label className="w-[25%]">Name: </label>
                  <TextField
                    name="categoryName"
                    size="small"
                    className="grow"
                    required
                  />
                </div>

                <div className="m-auto my-2">
                  <button
                    aria-label="add"
                    className="!rounded-[5px] bg-[#4a4844] text-white rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
            </>
          ) : (
            <>
              <form
                className="grow w-full p-3 flex flex-col gap-3"
                onSubmit={handleAddType}
              >
                <div className="flex items-center justify-center w-full">
                  <label className="w-[25%]">Name: </label>
                  <TextField
                    name="typeName"
                    size="small"
                    className="grow"
                    required
                  />
                </div>

                <div className="flex items-center justify-center w-full">
                  <label className="w-[25%]">Category: </label>
                  <select
                    className="border border-gray-600 px-2 py-1 rounded grow"
                    name="category"
                    required
                  >
                    {category.map((category) => (
                      <>
                        <option value={category.id}>{category.name}</option>
                      </>
                    ))}
                  </select>
                </div>
                <div className="m-auto my-2">
                  <button
                    aria-label="add"
                    className="!rounded-[5px] bg-[#4a4844] text-white rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Settings;
