import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  settingsIcon,
  lineIco,
  polygonIco,
} from "../../../Assets/icons/gisIcons/icons";
import SettingsButton from "./SettingsButton";
import { gisLeafletActions } from "../../../redux/slices/GIS/gis-leaflet-slice";
import { fetchData, updateData } from '../../../Utils/indexedDB';
const SettingsModal = ({ isOpen, onClose }) => {
  const [selectedDistanceUnit, setSelectedDistanceUnit] = useState("km");
  const [selectedAreaUnit, setSelectedAreaUnit] = useState("m²");
  const dispatch = useDispatch();

  const distanceUnits = [
    { unit: "m", width: "25%" },
    { unit: "km", width: "25%" },
    { unit: "Miles", width: "25%" },
    { unit: "Nautical Miles", width: "25%" },
  ];

  const areaUnits = [
    { unit: "m²", width: "25%" },
    { unit: "km²", width: "25%" },
    { unit: "Acres", width: "25%" },
    { unit: "Hectares", width: "25%" },
  ];

  useEffect(() => {
    const initializePreset = async () => {
      try {
        // Fetch preset from IndexedDB
        const preset = await fetchData('presets');
        
        if (preset) {
          // If preset exists, set selected units
          setSelectedDistanceUnit(preset.units.length);
          setSelectedAreaUnit(preset.units.area);
        }
      } catch (error) {
        console.error('Failed to initialize preset:', error);
      }
    };

    initializePreset();
  }, []);

  const updatePresetUnit = async (unitType, unit) => {
    try {
      // Fetch current preset from IndexedDB
      const preset = await fetchData('presets');
      
      if (preset) {
        // Update the units in the fetched preset based on unitType
        preset.units[unitType] = unit;
        await updateData('presets', 1, preset); // update the updated preset in IndexedDB
        dispatch(gisLeafletActions.refCounterUnitsUpdate());
      } else {
        console.error('Preset not found in IndexedDB.'); // Handle error appropriately
      }
    } catch (error) {
      console.error(`Failed to update ${unitType} unit:`, error); // Handle error appropriately
    }
  };

  const handleDistanceUnitClick = (unit) => {
    setSelectedDistanceUnit(unit);
    updatePresetUnit('length', unit);
  };

  const handleAreaUnitClick = (unit) => {
    setSelectedAreaUnit(unit);
    updatePresetUnit('area', unit);
  };
  return (
    <div
      className={`z-[1000] ${
        isOpen ? "block" : "hidden"
      } absolute bottom-[216px] right-14 flex h-[300px] max-h-[1731px] w-[440px] flex-col rounded-[9px] bg-[rgba(20,26,34,.725)] py-4 text-xs text-white`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex w-full items-center gap-2 px-4 text-lg font-bold">
        <img src={settingsIcon} alt="settingsIcon" />
        <span>Settings </span>
      </div>
      <p className="text-[rgba(255, 255, .85)] ml-4 mt-4 text-lg font-bold tracking-wide">
        Units
      </p>
      <div className="mx-4 mb-2 mt-1 flex flex-col gap-2 rounded-[9px] border border-white p-4 shadow-sm shadow-[rgba(255,255,255,.7)]">
        <div className="flex w-full items-center gap-2">
          <img className="w-4" src={lineIco} alt="LineIcon" />
          <span className="text-[15px]">Distance </span>
        </div>
        <div className="relative flex flex-row gap-0 rounded-[17px] bg-[rgba(255,255,255,0.56)]">
          {distanceUnits.map(({ unit, width }) => (
            <SettingsButton
              key={unit}
              unit={unit}
              selectedUnit={selectedDistanceUnit}
              onClick={handleDistanceUnitClick}
              width={width}
            />
          ))}
        </div>
        <div className="mt-2 flex w-full items-center gap-2">
          <img className="w-4" src={polygonIco} alt="areaIcon" />
          <span className="text-[15px]">Area </span>
        </div>
        <div className="relative flex flex-row gap-0 rounded-[17px] bg-[rgba(255,255,255,0.56)]">
          {areaUnits.map(({ unit, width }) => (
            <SettingsButton
              key={unit}
              unit={unit}
              selectedUnit={selectedAreaUnit}
              onClick={handleAreaUnitClick}
              width={width}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
