import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import Tooltip from "@mui/material/Tooltip";
import Cookies from "js-cookie";
import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  layersFilledIco,
  layersOutlinedIco,
  logoutIco,
  previewFilledIco,
  previewOutlinedIco,
  reportsFilled,
  reportsOutlined,
} from "../../../Assets/icons/gisIcons/icons";
import { inspectLogo } from "../../../Assets/images/gisImg/images";
import { gisHomeActions } from "../../../redux/slices/GIS/gis-home-slice";
import SidebarNavItem from "../../UI/GIS/SidebarNavItem";
import AnnotationsLeaflet from "./AnnotationsLeaflet";
import BrandingAndStatsBarLeaflet from "./BrandingAndStatsBarLeaflet";
import ReportTableL from "./ReportTableL";
import SidebarLayersLeaflet from "./SidebarLayersLeaflet";
import { removeAllCookies } from "../../../Utils/Cookie";
import SVStationIndexing from "./SVStationIndexing";
import IndexingLeaflet from "./IndexingLeaflet";
import BrandingAndStatsBarLeaflet3D from "./BrandingAndStatsBarLeaflet3D";
import GeneralIndexing from "./GeneralIndexing";
import { fetchData } from '../../../Utils/indexedDB';
const GISSidebarLeaflet = ({ mapRef }) => {
  const navigate = useNavigate();
  const viewerRef = useRef(null);
  const [openAssetIndexing, setOpenAssetIndexing] = useState(false);
  const [openObservation, setOpenObservations] = useState(false);
  const [openLayers, setOpenLayers] = useState(false);
  const clientName = Cookies.get("clientName");
  const [storedPreset, setStoredPreset] = useState();
  // redux
  const dispatch = useDispatch();
  const { showComparer } = useSelector((state) => state.gisTools);
  const { bottomReportToggle } = useSelector((state) => state.gisHome);
  const { currentStation, asset } = useSelector((state) => state.gisLeaflet);
  const { projectMeta } = useSelector((state) => state.projectMeta);
  const { assetIndexing, client } = projectMeta;
  const { refCounterUnits } = useSelector((state) => state.gisLeaflet);
  useEffect(() => {
    const getPreset = async () => {
      try {
        const fetchedPreset = await fetchData('presets');
        setStoredPreset(fetchedPreset);
      } catch (error) {
        console.error('Failed to fetch preset:', error);
      }
    };
    getPreset();
  }, [refCounterUnits])
  /**
   * Handles toggling of sidebar navigation items.
   * @param {string} item - The name of the item to toggle. Can be one of: "sidebar", "observations", "layers".
   */
  const handleToggle = (item) => {
    switch (item) {
      case "indexing":
        setOpenAssetIndexing((preState) => !preState);
        setOpenObservations(false);
        setOpenLayers(false);
        dispatch(gisHomeActions.bottomReportToggle(false));

        break;
      case "observations":
        setOpenAssetIndexing(false);
        setOpenObservations((prevState) => !prevState);
        setOpenLayers(false);
        dispatch(gisHomeActions.bottomReportToggle(false));

        break;
      case "layers":
        setOpenAssetIndexing(false);
        setOpenObservations(false);
        dispatch(gisHomeActions.bottomReportToggle(false));
        setOpenLayers((prevState) => !prevState);
        break;
      case "reports":
        setOpenAssetIndexing(false);
        setOpenObservations(false);
        setOpenLayers(false);
        dispatch(gisHomeActions.bottomReportToggle());
        break;
      default:
        setOpenAssetIndexing(false);
        setOpenObservations(false);
        setOpenLayers(false);
        break;
    }
  };
  const handleLogout = () => {
    removeAllCookies();
    navigate("/");
    // cookieValue === "infra" ? navigate("/AGPLogin") : navigate("/");
  };
  const formatRangeInKmSegment = (str) => {
    const parts = str.split("_");
    const startValue = parts[2];
    const endValue = parts[4];
    return `${startValue}km to ${endValue}km`;
  };

  if (!!mapRef) {
    return (
      <div
        id="GISSidebarBodyLeaflet"
        className="absolute left-0 top-0 z-[500] flex h-[100vh] flex-row rounded-tr-lg bg-white"
      >
        {!showComparer && (
          <div className="relative left-0 top-0 flex h-[100vh] flex-row rounded-tr-lg bg-white">
            <div className="flex min-w-max flex-col gap-2 p-2">
              <div className="mb-2 flex cursor-default items-center justify-center rounded-[5px]">
                <img src={inspectLogo} className="w-[52px]" alt="logo" />
              </div>
              <SidebarNavItem
                title="Sections"
                onClick={() => handleToggle("indexing")}
                isActive={openAssetIndexing}
              >
                <FormatListBulletedRoundedIcon style={{ color: "#4a4844" }} />
              </SidebarNavItem>
              {clientName !== "ESSEL" && (
                <SidebarNavItem
                  title="Observations"
                  onClick={() => handleToggle("observations")}
                  isActive={openObservation}
                >
                  {openObservation ? (
                    <img src={previewFilledIco} alt="Close Observations" />
                  ) : (
                    <img src={previewOutlinedIco} alt="Open observations" />
                  )}
                </SidebarNavItem>
              )}
              <SidebarNavItem
                title="Layers"
                onClick={() => handleToggle("layers")}
                isActive={openLayers}
              >
                {openLayers ? (
                  <img src={layersFilledIco} alt="Close Layers" />
                ) : (
                  <img src={layersOutlinedIco} alt="Open Layers" />
                )}
              </SidebarNavItem>
              <SidebarNavItem
                title="Reports"
                onClick={() => handleToggle("reports")}
                isActive={bottomReportToggle}
              >
                {bottomReportToggle ? (
                  <img src={reportsFilled} alt="Close Reports" />
                ) : (
                  <img src={reportsOutlined} alt="Open reports" />
                )}
              </SidebarNavItem>

              <Tooltip title="Logout" placement="bottom" arrow>
                <div
                  className="hover:bg-grey-200 absolute bottom-5 left-4 p-1 hover:rounded-full"
                  onClick={() => handleLogout()}
                >
                  <img src={logoutIco} className="h-6" alt="" srcSet="" />
                </div>
              </Tooltip>
            </div>
            <div className="flex h-[100vh] flex-col rounded-lg focus:border focus:border-b-2">
              {assetIndexing?.indexingType === "sectional" && (
                <SVStationIndexing
                  clientName={clientName}
                  openAssetIndexing={openAssetIndexing}
                  mapRef={mapRef}
                />
              )}
              {assetIndexing?.indexingType === "timeline" && (
                <IndexingLeaflet
                  openAssetIndexing={openAssetIndexing}
                  mapRef={mapRef}
                />
              )}{" "}
              {assetIndexing?.indexingType === "general" && (
                <GeneralIndexing
                  openAssetIndexing={openAssetIndexing}
                  mapRef={mapRef}
                />
              )}
              <AnnotationsLeaflet
                viewer={viewerRef.current}
                openObservation={openObservation}
                mapRef={mapRef}
              />
              <SidebarLayersLeaflet openLayers={openLayers} mapRef={mapRef} />
            </div>
            {currentStation?.name && client === "hmel" && (
              <div
                className={`absolute ${
                  asset?.Name ? "-right-[14.5rem]" : "-right-16"
                } top-[0.5rem] flex w-max items-center space-x-2 rounded-full bg-[rgba(20,26,34,.725)] px-3 py-1 font-medium text-white shadow`}
                style={{ alignSelf: "flex-start" }}
              >
                <p className="flex max-w-max items-center space-x-2 text-sm">
                  {currentStation?.name}
                  {asset?.Name && <span className="mx-1 text-white">/</span>}
                  {asset?.Name && formatRangeInKmSegment(asset?.Name)}
                </p>
              </div>
            )}
          </div>
        )}
        <ReportTableL
          isSidebaropen={openAssetIndexing}
          openShapes={openObservation}
          openLayers={openLayers}
          mapRef={mapRef}
        />
        <BrandingAndStatsBarLeaflet
          isSidebaropen={openAssetIndexing}
          openShapes={openObservation}
          openLayers={openLayers}
          map={mapRef.current}
        />
      </div>
    );
  } else {
    return (
      <div
        id="GISSidebarBodyLeaflet"
        className="absolute left-0 top-0 z-[500] flex h-[100vh] flex-row rounded-tr-lg bg-white"
      >
        <div className="relative left-0 top-0 flex h-[100vh] flex-row rounded-tr-lg bg-white">
          <div className="flex min-w-max flex-col gap-2 p-2">
            <div className="mb-2 flex cursor-default items-center justify-center rounded-[5px]">
              <img src={inspectLogo} className="w-[52px]" alt="logo" />
            </div>

            <Tooltip title="Logout" placement="bottom" arrow>
              <div
                className="hover:bg-grey-200 absolute bottom-5 left-4 p-1 hover:rounded-full"
                onClick={() => handleLogout()}
              >
                <img src={logoutIco} className="h-6" alt="" srcSet="" />
              </div>
            </Tooltip>
          </div>
          <div className="flex h-[100vh] flex-col rounded-lg focus:border focus:border-b-2">
            {/* {assetIndexing?.indexingType === "timeline" && (
              <IndexingLeaflet openAssetIndexing={openAssetIndexing} />
            )} */}
          </div>
        </div>

        <BrandingAndStatsBarLeaflet3D isSidebaropen={openAssetIndexing} />
      </div>
    );
  }
};

export default GISSidebarLeaflet;
