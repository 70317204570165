import { useEffect, useState } from "react";
import L from "leaflet";
import { useSelector } from "react-redux";
import "leaflet-side-by-side";
import { useQuery } from "@tanstack/react-query";
import { DynamoDBApisL } from "../../../../Services/ThirdPartyApi/AWS/DynamoDBL";
import {
  blueLocationPin,
  greenLocationPin,
} from "../../../../Assets/icons/gisIcons/icons";
import Cookies from "js-cookie";

export function useCompareAssetsL(mapRef) {
  const map = mapRef.current;
  // const { grp: clientName } = useParams();
  const clientName = Cookies.get("clientName");

  const { showComparer } = useSelector((state) => state.gisTools);
  const { asset, compareRasterLayers, observationsBucket } = useSelector(
    (state) => state.gisLeaflet
  );
  let leftWMSLayer, rightWMSLayer;
  const [bounds, setBounds] = useState("");
  const { leftLayer, rightLayer } = useSelector(
    (state) => state.gisLeaflet.compareRasterLayers
  );
  const leftBlue = "#3498DB";
  const rightGreen = "#28B463";

  const components = document.getElementsByClassName("baselayerPicker");
  if (components.length > 0) {
    for (let i = 0; i < components.length; i++) {
      components[i].style.display = showComparer ? "none" : "block";
    }
  }

  const fetchObservationsForLayer = async (layer) => {
    try {
      const data = await DynamoDBApisL.getDataByID(layer);
      return data;
    } catch (error) {
      console.error("Error fetching data for layer:", error);
      return [];
    }
  };

  // Fetch observations for left layer
  const leftQuery = useQuery({
    queryKey: ["leftAssetRelatedObservations", leftLayer],
    queryFn: () => fetchObservationsForLayer(leftLayer),
    enabled: showComparer, // Only fetch if comparer is shown
  });
  const leftObservations = leftQuery.data || [];

  // console.log(leftObservations, "leftObservations");

  const rightQuery = useQuery({
    queryKey: ["rightAssetRelatedObservations", rightLayer],
    queryFn: () => fetchObservationsForLayer(rightLayer),
    enabled: showComparer,
  });
  const rightObservations = rightQuery.data || [];

  const leftLayerGroup = L.featureGroup();
  const rightLayerGroup = L.featureGroup();

  const geoServerClientReferenace = clientName?.toLowerCase();
  const wmsUrl = `https://gis.indrones.com/geoserver/${geoServerClientReferenace}/wms`;

  useEffect(() => {
    map.eachLayer(function (layer) {
      if (!layer.overlay) {
        return;
      }
      map.removeLayer(layer);
    });
    map.eachLayer(function (layer) {
      // Check if the layer is an overlay
      if (layer instanceof L.MarkerClusterGroup) {
        // Remove the overlay layer
        map.removeLayer(layer);
      }
    });
    if (leftQuery.isLoading || rightQuery.isLoading) {
      return;
    }

    leftObservations.forEach((observation) => {
      if (observation.filter_type === "marker") {
        const { lat, lng } = observation.markerPosition;
        const pinIcon = L.icon({
          iconUrl: blueLocationPin,
          iconSize: [40, 40],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32],
        });
        const marker = L.marker([lat, lng], {
          icon: pinIcon,
        });
        leftLayerGroup.addLayer(marker);
      } else if (
        observation.filter_type === "polygon" ||
        observation.filter_type === "polyline"
      ) {
        const coordinates = observation.markerPosition;
        const color = leftBlue;
        const layer =
          observation.filter_type === "polygon"
            ? L.polygon(coordinates, { color: color })
            : L.polyline(coordinates, { color: color });
        leftLayerGroup.addLayer(layer);
      }
    });

    rightObservations.forEach((observation) => {
      if (observation.filter_type === "marker") {
        const { lat, lng } = observation.markerPosition;
        const pinIcon = L.icon({
          iconUrl: greenLocationPin,
          iconSize: [40, 40],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32],
        });
        const marker = L.marker([lat, lng], {
          icon: pinIcon,
        });
        rightLayerGroup.addLayer(marker);
      } else if (
        observation.filter_type === "polygon" ||
        observation.filter_type === "polyline"
      ) {
        const coordinates = observation.markerPosition;
        const color = rightGreen;
        const layer =
          observation.filter_type === "polygon"
            ? L.polygon(coordinates, { color: color })
            : L.polyline(coordinates, { color: color });
        rightLayerGroup.addLayer(layer);
      }
    });

    const removeObservations = () => {
      map.eachLayer((layer) => {
        // Check if the layer is an observation added programmatically
        if (
          layer instanceof L.Marker ||
          layer instanceof L.Polyline ||
          layer instanceof L.Polygon
        ) {
          map.removeLayer(layer);
        }
      });
    };

    if (map && showComparer) {
      // Clear previous layers
      removeObservations();
      if (leftWMSLayer) map.removeLayer(leftWMSLayer);
      if (rightWMSLayer) map.removeLayer(rightWMSLayer);

      leftWMSLayer = L.tileLayer.wms(wmsUrl, {
        layers: leftLayer,
        format: "image/png",
        transparent: true,
        tiled: true,
        detectRetina: true,
        maxZoom: 24,
        zIndex: 10,
      });

      // Get the bounds of the WMS layer
      // var bounds = leftWMSLayer.getBounds();

      // // Fly to the bounds of the WMS layer with some padding
      // map.flyToBounds(bounds, {
      //   padding: [50, 50], // Adjust padding as needed
      // });
      // console.log(leftWMSLayer, "leftWMSLayer");

      rightWMSLayer = L.tileLayer.wms(wmsUrl, {
        layers: rightLayer,
        format: "image/png",
        transparent: true,
        tiled: true,
        detectRetina: true,
        maxZoom: 24,
        zIndex: 10,
      });

      leftWMSLayer.addTo(map);
      rightWMSLayer.addTo(map);
      leftLayerGroup.addTo(map);
      rightLayerGroup.addTo(map);

      const comparerControl = L.control.sideBySide(
        [leftWMSLayer],
        [rightWMSLayer]
      );

      comparerControl.addTo(map);

      return () => {
        // Remove layers and controls
        comparerControl.remove();
        removeObservations();
        if (leftWMSLayer) map.removeLayer(leftWMSLayer);
        if (rightWMSLayer) map.removeLayer(rightWMSLayer);
        if (showComparer) {
          leftLayerGroup.clearLayers();
          rightLayerGroup.clearLayers();
        }
      };
    }
  }, [
    showComparer,
    compareRasterLayers,
    leftQuery.isLoading,
    rightQuery.isLoading,
  ]);
}
