/* eslint-disable react-hooks/exhaustive-deps */
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base/";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Slider from "@mui/material/Slider";
import L from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  svStationMarker,
  visibilityIco,
  visibilityOffIco,
  highMarker,
} from "../../../Assets/icons/gisIcons/icons";
import {
  LayerControlUtils,
  Measure,
} from "../../../Utils/Leaflet/LeafletUtils";
import { gisLeafletActions } from "../../../redux/slices/GIS/gis-leaflet-slice";
import {
  fetchData,
  updateData,
  storeWFSData,
  fetchWFSData,
} from "../../../Utils/indexedDB";

const SidebarLayersLeaflet = ({ openLayers, mapRef }) => {
  const map = mapRef.current;
  const { refCounterUnits } = useSelector((state) => state.gisLeaflet);
  const {
    asset,
    KmlsetArray,
    refCounterMeasure,
    geoJSONArray,
    shapeFeturesetArray,
  } = useSelector((state) => state.gisLeaflet);
  const [measurementArray, setMeasurementArray] = useState(null);
  const measure = new Measure();
  const layerControlUtils = new LayerControlUtils();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [opacityValues, setOpacityValues] = useState({});
  const [shapeGroupopacityValues, setShapeGroupopacityValues] = useState({});
  const handleAccordionToggle = (panelIndex) => (event, isExpanded) => {
    setExpanded(isExpanded ? panelIndex : false);
  };
  const [firstTimeAdd, setFirstTimeAdd] = useState(0);
  const [storedPreset, setStoredPreset] = useState();
  //edit control
  const [showMeasureEditButtonsArray, setShowMeasureEditButtonsArray] =
    useState(measurementArray ? measurementArray?.map(() => false) : [false]);
  const [editMeasureMode, setEditMeasureMode] = useState(false);
  const [selectedMeasureLayer, setSelecteMeasuredLayer] = useState(null);
  const editCtrlMeasure = useRef(null); // Ref to hold the editCtrl object
  const kmlLayerGroup = L.featureGroup().addTo(map);
  const SVMarkersGroup = L.featureGroup().addTo(kmlLayerGroup);
  // const shapeGeoJsonGroup = L.featureGroup().addTo(map);
  function getKmlLayerById(layerId) {
    let foundLayer = null;
    kmlLayerGroup.eachLayer((layer) => {
      if (layer._leaflet_id === layerId) {
        foundLayer = layer;
      }
    });
    return foundLayer;
  }
  useEffect(() => {
    const getDataFromKml = () => {
      try {
        // Retrieve measure data from local storage

        if (!KmlsetArray) {
          return;
        }
        // Clear existing markers on the map
        // kmlLayerGroup.clearLayers();
        if (KmlsetArray.length > 0) {
          // Iterate over each measure in the filtered local data
          KmlsetArray.forEach((kmlObj) => {
            // Check if the measure exists on the map
            // console.log("object-", kmlObj);
            const existingMarker = getKmlLayerById(kmlObj.id);

            // console.log("existing", existingMarker);
            if (kmlObj.visible) {
              if (existingMarker === null) {
                layerControlUtils
                  .loadKML(kmlObj.kmlFilePath, kmlObj.id)
                  .then((kmlLayer) => {
                    // console.log(kmlLayer, "kmlLayer");
                    kmlLayer._leaflet_id = kmlObj.id;

                    const defaultStyle = {
                      fillColor: "transparent",
                      color: "#efb118",
                      className:
                        "kml-layer transition-colors duration-300 ease-in-out",
                    };

                    const hoverStyle = {
                      fillColor: "transparent",
                      color: "#97bbf5", // Change color on hover
                    };

                    // Set the initial style
                    kmlLayer.setStyle(defaultStyle);

                    // Add hover event listeners
                    kmlLayer.on("mouseover", function (e) {
                      kmlLayer.setStyle(hoverStyle);
                      kmlLayer.openTooltip(); // Show tooltip on hover
                    });

                    kmlLayer.on("mouseout", function (e) {
                      kmlLayer.setStyle(defaultStyle);
                      kmlLayer.closeTooltip(); // Hide tooltip when not hovering
                    });

                    // Bind the tooltip to the layer
                    kmlLayer.bindTooltip(kmlObj?.KmlName, {
                      permanent: false, // Tooltip shows only on hover
                      direction: "top",
                    });

                    kmlLayer.bringToFront();
                    kmlLayer.type = "polyline"; // Use the provided id
                    kmlLayerGroup.addLayer(kmlLayer);
                    const bounds = kmlLayer.getBounds();
                    // console.log(kmlLayer, "kmlLayer");

                    const defaultMarkerIcon = new L.Icon({
                      className: "custom-marker-icon",
                      iconUrl: svStationMarker,
                      iconSize: [32, 32],
                    });

                    // var customIcon = L.divIcon({
                    //   className: "custom-div-icon",
                    //   html: '<img src="path/to/custom-icon.png" class="custom-icon"/><div class="custom-label">Label Text</div>',
                    //   iconAnchor: [0, -10],
                    // });

                    // const chainageLabelCreator = (lat, lon) => {
                    //   const label = L.marker([lat, lon], {
                    //     icon: L.divIcon({
                    //       className: "leaflet-div-label",
                    //       html: `<div class="text-[10px] absolute bg-black bg-opacity-50 text-white py-0.5 px-1 rounded w-max">km</div>`,
                    //       iconAnchor: [0, -10],
                    //     }),
                    //     zIndexOffset: 20,
                    //     pane: "labels",
                    //   });
                    // };
                    if (kmlObj.KmlName === "Center line") {
                      kmlLayer.setStyle({
                        fillColor: "transparent",
                        color: "#ff0000",
                      });
                      kmlLayer.bringToFront();
                      kmlLayer.type = "polyline";
                    } else if (kmlObj.KmlName === "SV stations") {
                      // Array to store unique identifiers for markers and labels
                      const addedMarkers = [];

                      // Iterate through each layer in the KML layer
                      kmlLayer.eachLayer(function (layer) {
                        if (layer instanceof L.Marker) {
                          // Set the default marker icon
                          layer.setIcon(defaultMarkerIcon);

                          // Create a label for the marker
                          const label = L.marker(layer.getLatLng(), {
                            icon: L.divIcon({
                              className: "leaflet-div-label",
                              html: `<div class="text-[10px] absolute bg-black bg-opacity-50 text-white py-0.5 px-1 rounded w-max">${layer.feature.properties.name}</div>`,
                              iconAnchor: [-12, 10],
                            }),
                            zIndexOffset: 20,
                          });

                          // Add the label to the SVMarkersGroup feature group
                          label.addTo(SVMarkersGroup);

                          // Assign the label to the marker
                          layer.label = label;

                          // Store the marker's unique identifier in the addedMarkers array
                          addedMarkers.push(layer.feature.properties.id);
                        }
                      });

                      // Remove duplicate markers and labels
                      SVMarkersGroup.eachLayer(function (layer) {
                        if (layer instanceof L.Marker) {
                          // Check if the marker's unique identifier has duplicates
                          const count = addedMarkers.filter(
                            (id) => id === layer.feature.properties.id,
                          ).length;

                          // If there are duplicates, remove the marker and its label
                          if (count > 1) {
                            SVMarkersGroup.removeLayer(layer);
                            if (layer.label) {
                              SVMarkersGroup.removeLayer(layer.label);
                            }
                          }
                        }
                      });
                    } else if (kmlObj.KmlName === "RoU") {
                      kmlLayer.setStyle({
                        fillColor: "transparent",
                      });
                      kmlLayer.type = "polygon";
                      kmlLayer.bringToBack();
                    }
                    if (kmlObj.KmlName === "Boundary") {
                      kmlLayer.setStyle({
                        fillColor: "transparent",
                        color: "#ff0000",
                      });
                      kmlLayer.bringToFront();
                      kmlLayer.type = "polyline";
                    }

                    if (bounds.isValid() && firstTimeAdd === 0) {
                      map.fitBounds(bounds);
                      setFirstTimeAdd(firstTimeAdd + 1);
                      // console.log("increamet");
                    }
                  })
                  .catch((error) => {
                    console.error("Error returning KML:", error);
                  });
              }
            } else if (!kmlObj.visible) {
              const kmlLayer = getKmlLayerById(kmlObj.id);
              if (kmlLayer !== null) {
                kmlLayerGroup.removeLayer(kmlLayer);
              }
            }
          });
        } else {
        }
      } catch (error) {
        console.error("Error", error);
      }
    };
    getDataFromKml();
    return () => {
      map.removeLayer(kmlLayerGroup);
    };
  }, [KmlsetArray]);
  useEffect(() => {
    const handleZoomChange = () => {
      if (!KmlsetArray) {
        return;
      }
      KmlsetArray.forEach((kmlObj) => {
        kmlLayerGroup.eachLayer((kmlLayer) => {
          if (kmlLayer._leaflet_id === kmlObj.id) {
            if (kmlObj.KmlName === "Center Line") {
              if (map.getZoom() < 14) {
                kmlLayer.setStyle({
                  weight: 5,
                  fillColor: "transparent",
                  color: "#ff0000",
                });
              } else {
                kmlLayer.setStyle({
                  weight: 4,
                  fillColor: "transparent",
                  color: "#ff0000",
                });
              }
            } else if (kmlObj.KmlName === "RoU") {
              if (map.getZoom() < 14) {
                kmlLayer.setStyle({
                  weight: 0,
                  fillColor: "transparent",
                });
              } else {
                kmlLayer.setStyle({
                  weight: 3,
                  fillColor: "transparent",
                });
              }
            } else if (kmlObj.KmlName === "SV Stations") {
              // console.log(kmlObj, "sv statiosn");
              kmlLayer.type = "marker";
              // kmlLayer.eachLayer(function (layer) {
              //   if (layer instanceof L.Marker) {
              //     layer.label.setOpacity(map.getZoom() >= 16 ? 1 : 0);
              //   }
              // });
            }
          }
        });
      });
    };

    map.on("zoomend", handleZoomChange);

    // Cleanup function to remove the event listener
    return () => {
      map.off("zoomend", handleZoomChange);
    };
  }, [map, KmlsetArray]);
  const markerFeatureGroup = L.featureGroup().addTo(map);
  useEffect(() => {
    const fetchDataFromLocalStorage = async () => {
      try {
        // Retrieve measure data from local storage
        const localData = await fetchData("measurements");

        if (!localData) {
          // //console.log("No measurements found");
          return;
        }

        const assetLocalData = localData.filter(
          (measurement) =>
            asset?.Name === measurement.assetId ||
            measurement.assetId === "Measure",
        );
        setMeasurementArray(assetLocalData);

        // Clear existing markers on the map
        markerFeatureGroup.clearLayers();

        if (assetLocalData.length > 0) {
          // Iterate over each measure in the filtered local data
          assetLocalData.forEach((measurement) => {
            // Check if the measure exists on the map
            const existingMarker = layerControlUtils.getLayerByIdUtil(
              measurement.id,
              map,
            );
            if (existingMarker === null) {
              // Add measure to the map based on filter_type
              if (measurement.filter_type === "polyline") {
                const polyline = L.polyline(measurement.measurePosition, {
                  stroke: true,
                  color: "#fff",
                  weight: 4,
                  opacity: 0.9,
                  lineCap: "round",
                  lineJoin: "round",
                  fill: false,
                  clickable: true,
                });
                // Add polyline to the map with ID
                polyline._leaflet_id = measurement.id;
                polyline.type = measurement.filter_type;
                // Call a function that calculates length and stores it in db,
                const labelpolyline = measure.handlePolyline(
                  polyline,
                  map,
                  measurement,
                  storedPreset,
                );

                // polyline.on("click", addToolTip(polyline));

                markerFeatureGroup.addLayer(polyline);
                markerFeatureGroup.addLayer(labelpolyline);
                // Add onclick function to polyline
                // Bind a popup to the polyline
                polyline.bindPopup(
                  `<span>
                      Length:  
                      ${measure
                        .convertDistance(
                          measurement.length,
                          storedPreset?.units.length,
                        )
                        ?.toFixed(4)}
                      ${storedPreset?.units.length}
                  </span>`,
                );

                // Add polyline to feature group
              } else if (measurement.filter_type === "polygon") {
                const polygon = L.polygon(measurement.measurePosition, {
                  stroke: true,
                  color: "#000",
                  weight: 4,
                  opacity: 0.9,
                  lineCap: "round",
                  lineJoin: "round",
                  fill: true,
                  clickable: true,
                  fillColor: "#fff",
                  fillOpacity: 0.2,
                });
                // Add polygon to the map with ID
                polygon._leaflet_id = measurement.id;
                polygon.type = measurement.filter_type;
                // Calculate area, perimeter, etc.
                const labelPolygon = measure.handlePolygon(
                  polygon,
                  map,
                  measurement,
                  storedPreset,
                );

                // Bind a popup to the polygon
                polygon.bindPopup(
                  `<span class="text-bold">
                    Area:
                    ${measure.convertArea(measurement.area, storedPreset?.units.area)} 
                    ${storedPreset?.units.area}
                    <br>
                    Perimeter:  
                    ${measure
                      .convertDistance(
                        measurement.perimeter,
                        storedPreset?.units.length,
                      )
                      ?.toFixed(4)}
                    ${storedPreset?.units.length}
                </span>`,
                );
                markerFeatureGroup.addLayer(polygon); // Add polygon to feature group
                markerFeatureGroup.addLayer(labelPolygon);
              }
            }
          });
        } else {
          //console.log(
          //   "No measurement data found for the asset in local storage"
          // );
        }
        // Update the specific measurements in local storage, if necessary
        await updateData("measurements", 1, localData);
      } catch (error) {
        //console.error("Error fetching data from local storage:", error);
      }
    };

    fetchDataFromLocalStorage();
    return () => {
      map.removeLayer(markerFeatureGroup);
    };
  }, [refCounterMeasure, asset, storedPreset]);
  useEffect(() => {
    const getPreset = async () => {
      try {
        const fetchedPreset = await fetchData("presets");
        setStoredPreset(fetchedPreset);
      } catch (error) {
        console.error("Failed to fetch preset:", error);
      }
    };
    getPreset();
  }, [refCounterUnits]);
  const [LgeoJsonGroup, setLgeoJsonGroup] = useState();
  const shapeGeoJsonGroup = L.featureGroup();
  const masterLayerGroup = L.featureGroup();
  const [pointLayerGroup, setPointLayerGroup] = useState([]);
  const [MastergeoJsonGroup, setMastergeoJsonGroup] = useState([]);
  const [shapeBounds, setShapeBounds] = useState({});
  const [minMaxElevation, setMinMaxElevation] = useState({});
  const [layersOnMapTracker, setLayersOnMapTracker] = useState({});
  useEffect(() => {
    if (map) {
      // Function to add or update WFS layer
      const addOrUpdateWFSLayer = () => {
        if (!shapeFeturesetArray) {
          return;
        }
        // Define the WFS URL and parameters
        // console.log(LgeoJsonGroup, "<-shapeGeoJsonGroup->", shapeGeoJsonGroup);
        // shapeGeoJsonGroup.clearLayers();
        // LgeoJsonGroup?.eachLayer((layer) => {
        //   console.log("layer",layer);
        //   map.removeLayer(layer);
        // });
        shapeFeturesetArray.forEach(async (shapeFileObj) => {
          // LgeoJsonGroup.clearLayers();
          MastergeoJsonGroup.forEach((marsterlayer) => {
            map.removeLayer(marsterlayer);
          });
          setMastergeoJsonGroup([]);
          masterLayerGroup.clearLayers();
          if (shapeFileObj.visible) {
            // console.log("visible obj", shapeFileObj);
            // console.time("Time to add WFS layer");
            const workspaceName = shapeFileObj.shapeFileName;
            const layerName = encodeURIComponent(shapeFileObj.layerName);
            const bbox = map.getBounds().toBBoxString();
            const wfsURL = `https://gis.indrones.com/geoserver/${workspaceName}/ows?`;
            const url = `${wfsURL}service=WFS&version=1.0.0&request=GetFeature&typeName=${layerName}&EPSG:4326&outputFormat=application/json`;
            // Check cache first
            const cachedData = await fetchWFSData(bbox, layerName);
            if (false) {
              preProcessWfsData(cachedData, shapeFileObj);
            } else {
              // Fetch WFS data
              try {
                const response = await fetch(url);
                if (!response.ok)
                  throw new Error("Network response was not ok.");
                const data = await response.json(); // Directly using the GeoJSON response
                await storeWFSData(bbox, layerName, data);
                preProcessWfsData(data, shapeFileObj);
              } catch (error) {
                console.error("Error fetching WFS data:", error);
              }
            }
            // console.timeEnd("Time to add WFS layer");
          } else if (!shapeFileObj.visible) {
            if (pointLayerGroup) {
              pointLayerGroup.forEach((layer) => {
                map.removeLayer(layer);
              });
            }
            // console.log("every Not visible obj", shapeFileObj);
            // if (LgeoJsonGroup) {
            //   LgeoJsonGroup.eachLayer((layer) => {
            //     if (layer.custID === shapeFileObj.id) {
            //       // console.log("removing layer " , layer);
            //       LgeoJsonGroup.removeLayer(layer);
            //       map.removeLayer(layer);
            //     }
            //   });
            //   // LgeoJsonGroup.clearLayers();
            // }
          }
        });
      };
      const preProcessWfsData = (data, shapeFileObj) => {
        // Compute min and max elevation
        let minElevation = Infinity;
        let maxElevation = -Infinity;
        data.features.forEach((feature) => {
          if (
            feature.geometry.type === "LineString" ||
            feature.geometry.type === "MultiLineString"
          ) {
            const elevation = feature.properties.ELEVATION || 0;
            if (elevation < minElevation) {
              minElevation = elevation;
            }
            if (elevation > maxElevation) {
              maxElevation = elevation;
            }
          }
        });
        setMinMaxElevation({ minElevation, maxElevation });
        const applyStyles = (layer, feature) => {
          if (
            feature.geometry.type === "LineString" ||
            feature.geometry.type === "MultiLineString"
          ) {
            const elevation = feature.properties.ELEVATION || 0;
            layer.setStyle({
              color: getColorByElevation(
                elevation,
                minElevation,
                maxElevation,
                shapeFileObj,
              ),
              weight: 2,
              opacity: 1, // Ensure this value is defined
              lineCap: "round", // Optional: smoothens the line ends
            });
            return null;
          } else if (
            feature.geometry.type === "Point" ||
            feature.geometry.type === "MultiPoint"
          ) {
            // Create a custom icon
            const customMarkerIcon = new L.Icon({
              className: "custom-marker-icon",
              iconUrl: highMarker,
              iconSize: [32, 32],
              iconAnchor: [16, 32],
              popupAnchor: [0, -32],
            });

            // Create a marker with the custom icon
            const latLng = L.latLng(
              feature.geometry.coordinates[1],
              feature.geometry.coordinates[0],
            );
            const marker = L.marker(latLng, {
              icon: customMarkerIcon,
              opacity: 1, // Ensure this value is defined
            });

            marker.addTo(map); // Add the marker to the map
            setPointLayerGroup((layer) => [...layer, marker]);
            return marker;
          } else {
            layer.setStyle({
              opacity: shapeGroupopacityValues,
            });
          }
        };
        let typeLayer = null;
        const newLayer = L.geoJSON(data, {
          onEachFeature: (feature, layer) => {
            typeLayer = applyStyles(layer, feature); // Apply styles directly
            if (
              feature.geometry.type === "Point" ||
              feature.geometry.type === "MultiPoint"
            ) {
              layer = typeLayer;
            }
            const properties = feature.properties;

            const tooltipContent = `
  <div>
    <strong>Data:</strong> ${
      shapeFileObj.layerName === "mrvc:CHAINAGE_POINT"
        ? properties?.NAME
        : feature.id
    }
  </div>`;

            layer.bindTooltip(tooltipContent, {
              permanent: false,
              direction: "top",
              sticky: true,
              opacity: 0.8,
            });
            layer.on("mouseover", () => {
              layer.openTooltip();
            });
            layer.on("mouseout", () => {
              layer.closeTooltip();
            });
            layer.FID_1 = properties.FID_1;
            layer.custID = shapeFileObj.id;
          },
        });
        // Update the state with the new layer group
        // setLgeoJsonGroup(shapeGeoJsonGroup);
        // shapeGeoJsonGroup.addTo(map);
        // Store bounds in the state
        newLayer._leaflet_id = shapeFileObj.id;

        //adding on map and in array
        if (typeLayer !== null) {
          //separte stuff for markers
          setMastergeoJsonGroup((prevLayerGroup) => [
            ...prevLayerGroup,
            newLayer,
          ]);
          // setPointLayerGroup(newLayer);
          // newLayer.addTo(map);
        } else {
          masterLayerGroup.addLayer(newLayer);
          masterLayerGroup.addTo(map);
        }

        setMastergeoJsonGroup((prevLayerGroup) => [
          ...prevLayerGroup,
          newLayer,
        ]);
        const bounds = newLayer.getBounds();
        if (bounds.isValid()) {
          setShapeBounds((prevBounds) => ({
            ...prevBounds,
            [shapeFileObj.id]: bounds,
          }));
        }
      };
      // Debounce function to limit the rate of requests
      // const debounce = (func, wait) => {
      //   let timeout;
      //   return (...args) => {
      //     clearTimeout(timeout);
      //     timeout = setTimeout(() => func(...args), wait);
      //   };
      // };

      // Debounced event listener for map move
      // const debouncedAddOrUpdateWFSLayer = debounce(addOrUpdateWFSLayer, 1100);
      // map.on("moveend", debouncedAddOrUpdateWFSLayer);
      addOrUpdateWFSLayer();
      // Cleanup function to remove the WFS layer when the component unmounts
      return () => {
        map.removeLayer(masterLayerGroup);
        // map.removeLayer(LgeoJsonGroup);
        // map.off("moveend", debouncedAddOrUpdateWFSLayer);
      };
    }
  }, [shapeFeturesetArray]);

  const deleteSingleMeasures = (
    id,
    measurementArray,
    setMeasurementArray,
    map,
  ) => {
    const promise = new Promise((resolve, reject) => {
      const confirmDelete = () => {
        toast(
          <div className="text-center">
            <p className="text-center">
              Are you sure you want to delete this measurement?
            </p>
            <div className="mt-2 flex justify-center gap-4">
              <button
                className="rounded border border-red-500 bg-transparent px-4 py-2 font-semibold text-red-700 hover:border-transparent hover:bg-red-500 hover:text-white"
                onClick={() => {
                  layerControlUtils.clearMeasure(
                    id,
                    measurementArray,
                    setMeasurementArray,
                    map,
                  );
                  resolve("Deleted!"); // Resolve with a success message when "Yes" is clicked
                  toast.dismiss();
                }}
              >
                Yes
              </button>
              <button
                className="rounded border border-blue-500 bg-transparent px-4 py-2 font-semibold text-blue-700 hover:border-transparent hover:bg-blue-500 hover:text-white"
                onClick={() => {
                  resolve("Canceled"); // Resolve with null when "No" is clicked
                  toast.dismiss();
                }}
              >
                No
              </button>
            </div>
          </div>,
          {
            duration: null, // Set duration to null to keep toast open until resolved
            position: "top-center",
            style: {
              minWidth: "300px",
            },
            onClose: () => {
              // onClose(); // Close the popup when the toast is closed
            },
          },
          {
            duration: 5000,
          },
        );
      };

      confirmDelete();
      // Add event listener to the map for click events
      map.on("click", () => {
        // resolve("Canceled"); // Resolve with 'Canceled' when user clicks on the map
        toast.dismiss();
      });
    });

    promise.then((value) => {
      if (value && value !== "Canceled") {
        toast.success(value, {
          duration: 1000,
          icon: "☑️",
          iconTheme: {
            primary: "#FF0000",
            secondary: "#FF0000",
          },
        }); // Display success toast with duration 2 seconds
      } else if (value === "Canceled") {
        toast.error("Canceled", { duration: 1000 }); // Display 'Canceled' toast with duration 2 seconds
      }
    });
  };
  const getColorByElevation = (
    elevation,
    minElevation,
    maxElevation,
    shapeFileObj,
  ) => {
    const layerColors = {
      Bridge: "#A52A2A", // Dark brown (stands out over natural tones)
      "Ch-Line": "#FF4500", // Bright orange-red (distinct from real-world tones)
      Chainage: "#FFD700", // Bright gold (easily visible on any background)
      "Chainage Point": "#FF8C00", // Dark orange (high visibility)
      Drain: "#4682B4", // Steel blue (stands out over water features)
      "Electric Pole": "#8B8B8B", // Keep this neutral for utility poles
      "Existing Land Boundary": "#32CD32", // Lime green for clear boundaries
      "Existing Track": "#8A2BE2", // Purple (to avoid blending with grayscale tones)
      Lamp: "#FFFF99", // Light yellow (clear visibility)
      "Lt Line": "#B22222", // Firebrick red (strong contrast)
      Nala: "#00CED1", // Dark turquoise (distinct from natural water)
      "Over Head Water Tank": "#5F9EA0", // Cadet blue (stands out from water)
      Paylon: "#FF1493", // Deep pink (to stand out over landscape features)
      Platform: "#FF69B4", // Hot pink (high contrast over RGB imagery)
      Pond: "#1E90FF", // Bright blue for water (distinct from natural tones)
      "Proposed Rail Track": "#FF8C00", // Dark orange (distinctive and visible)
      "Prop Row": "#FF6347", // Tomato red (vivid against natural tones)
      River: "#4169E1", // Royal blue (stands out over water)
      Road: "#FF4500", // Bright orange-red (replaces dim gray for roads)
      Structure: "#A52A2A", // Brown for buildings/structures
      Wall: "#D2691E", // Chocolate (clear against RGB imagery)
      Well: "#87CEEB", // Sky blue (for water features)
    };

    if (shapeFileObj.shapeFileName === "mrvc") {
      //predrfined colors for each obj layer
      return layerColors[
        shapeFileObj.layerName
          .replace(/^mrvc:/, "")
          .toLowerCase()
          .replace(/_+/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase())
      ];
    }
    // Check if any of the props are invalid (null, undefined, or NaN)
    if (
      elevation == null ||
      isNaN(elevation) ||
      minElevation == null ||
      isNaN(minElevation) ||
      maxElevation == null ||
      isNaN(maxElevation) ||
      minElevation === maxElevation // Prevent division by zero
    ) {
      return "rgb(255, 182, 193)"; //pink
    }

    // Normalize elevation within the range [0, 1]
    const normalizedElevation = Math.max(
      0,
      Math.min((elevation - minElevation) / (maxElevation - minElevation), 1),
    );

    // Calculate color gradient
    const r = Math.floor(255 * normalizedElevation);
    const g = Math.floor(255 * (1 - normalizedElevation));
    const b = 0; // No blue in the gradient

    return `rgb(${r},${g},${b})`;
  };
  //using jeojson metod
  const setLayerGroupOpacity = (opacityVal, shapeObj) => {
    if (shapeObj.layerName === "mrvc:CHAINAGE_POINT") {
      pointLayerGroup.forEach((layer) => {
        layer.setOpacity(opacityVal);
      });
    } else {
      MastergeoJsonGroup.forEach((layerGroup) => {
        if (layerGroup._leaflet_id === shapeObj.id) {
          layerGroup.setStyle({ opacity: opacityVal });
        }
      });
    }
  };

  return (
    <div
      className={`${
        openLayers ? "block" : "hidden"
      } relative mb-2 ml-2 mr-2 mt-2 h-screen min-w-[18rem] !grow overflow-y-auto rounded-md border-[1px] border-solid border-[#d7d8d8] bg-white`}
    >
      <div className="flex w-full items-center rounded-tl-md rounded-tr-md bg-[#ffca0094] pb-[10px] pt-[10px] font-[500]">
        <div className="flex grow items-center justify-center">Layers</div>
      </div>
      <div>
        <Tabs defaultValue={1}>
          <TabsList className="min-w-tabs-list mb-2 flex content-between items-center justify-center rounded-md bg-white font-[400]">
            <Tab
              slotProps={{
                root: ({ selected, disabled }) => ({
                  className: ` ${
                    selected
                      ? "text bg-[#ffca0094] "
                      : "bg-transparent focus:text-white hover:bg-[#faea8e] border-[#ffca0094]"
                  } ${
                    disabled
                      ? "cursor-not-allowed opacity-50"
                      : "cursor-pointer"
                  }w-full p-2 m-1.5 border rounded-lg flex justify-center focus:outline-0 focus:shadow-outline-purple-light`,
                }),
              }}
              value={1}
            >
              Measurements
            </Tab>
            <Tab
              slotProps={{
                root: ({ selected, disabled }) => ({
                  className: ` ${
                    selected
                      ? " bg-[#ffca0094]"
                      : "bg-transparent focus:text-white border-[#ffca0094] hover:bg-[#faea8e] "
                  } ${
                    disabled
                      ? "cursor-not-allowed opacity-50"
                      : "cursor-pointer"
                  } w-full p-2 m-1.5 border rounded-md flex justify-center focus:outline-0 focus:shadow-outline-purple-light`,
                }),
              }}
              value={2}
            >
              Assets
            </Tab>
          </TabsList>
          <TabPanel className="w-full" key="measuremetnsTab" value={1}>
            <div className="flex-1 overflow-y-auto overflow-x-hidden">
              {measurementArray?.length <= 0 || !measurementArray ? (
                <p className="p-2 text-center text-sm">
                  No measurements created
                </p>
              ) : (
                measurementArray?.map((measurement, index) => (
                  <Accordion
                    className={`!my-0 border-t border-white !p-0 text-sm ${
                      editMeasureMode ? "" : ""
                    }`}
                    key={index + 1}
                    expanded={expanded === index}
                    onChange={handleAccordionToggle(index)}
                    disabled={editMeasureMode}
                    title={
                      editMeasureMode
                        ? "Save or cancel editing current measure first"
                        : ""
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      onClick={(event) => event.stopPropagation()}
                      className="m-0 flex min-h-max !items-center !justify-center"
                    >
                      <button
                        className={`visibility-toggle-btn`}
                        onClick={(event) => {
                          event.stopPropagation();
                          layerControlUtils.toggleMeasurementVisibilityById(
                            measurement.id,
                            measurementArray,
                            setMeasurementArray,
                            map,
                          );
                        }}
                        disabled={showMeasureEditButtonsArray[index]}
                      >
                        {measurement?.visible ? (
                          <img
                            src={visibilityIco}
                            alt="Visible"
                            className="h-4"
                          />
                        ) : (
                          <img
                            src={visibilityOffIco}
                            alt="Hidden"
                            className="h-4"
                          />
                        )}
                      </button>

                      <div className="ml-2 flex items-center justify-between gap-4 rounded-md">
                        <p
                          className="btn-dangerrounded"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (measurement.measurePosition) {
                              const latlng = [measurement.measurePosition];
                              const bounds = L.latLngBounds(latlng);
                              map?.flyToBounds(bounds, {
                                duration: 2, // Animation duration in seconds
                                easeLinearity: 0.5, // Animation easing factor (0 = linear, 1 = same speed throughout)
                                maxZoom: 21,
                              });
                            } else {
                              //console.error("Layer coordinates not available");
                            }
                            //console.log("clicked on measurement");
                          }}
                        >
                          <p key={index}>
                            {measurement.filter_type === "polyline"
                              ? "Distance "
                              : "Area "}
                            {`measure ${index + 1}`}
                          </p>
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      disabled={!measurement.visible}
                      className="!mb-0 !mt-0 w-full grow"
                    >
                      <div className="flex flex-col text-sm">
                        <div className="flex items-center">
                          <div className="mr-2">Change color:</div>
                          <input
                            type="color"
                            className="rounded-md border"
                            // value={selectedColor}
                            onChange={(e) => {
                              layerControlUtils.handleMarkerColorChange(
                                measurement.id,
                                e.target.value,
                                map,
                              );
                            }}
                            disabled={!measurement.visible}
                          />
                        </div>
                        <div className="flex items-center justify-center">
                          <p className="w-[70px]">Opacity:</p>
                          <Slider
                            min={0}
                            max={1}
                            step={0.1}
                            value={opacityValues[measurement.id] || 0.9}
                            valueLabelDisplay="auto"
                            onChange={(e) => {
                              setOpacityValues((prevState) => ({
                                ...prevState,
                                [measurement.id]: e.target.value,
                              }));
                              layerControlUtils.handleAssetOpacityChange(
                                measurement.id,
                                e.target.value,
                                map,
                              );
                            }}
                            disabled={!measurement.visible || editMeasureMode} // Disable slider if marker is not visible
                            className="h-1 w-1/2"
                          />
                        </div>
                      </div>
                      {measurement.filter_type === "polyline" ? (
                        <span>
                          Length:{" "}
                          {measure
                            .convertDistance(
                              measurement.length,
                              storedPreset?.units.length,
                            )
                            ?.toFixed(4)}
                          {storedPreset?.units.length}
                        </span>
                      ) : (
                        <div className="flex flex-col">
                          <span>
                            Area:{" "}
                            {`${measure.convertArea(measurement.area, storedPreset?.units.area)} 
                               ${storedPreset?.units.area}`}
                          </span>
                          <span>
                            Perimeter:{" "}
                            {measure
                              .convertDistance(
                                measurement.perimeter,
                                storedPreset?.units.length,
                              )
                              ?.toFixed(4)}
                            {storedPreset?.units.length}
                          </span>
                        </div>
                      )}
                      <div className="mt-2 flex items-center justify-center gap-2">
                        {!showMeasureEditButtonsArray[index] ? (
                          <button
                            onClick={() => {
                              deleteSingleMeasures(
                                measurement.id,
                                measurementArray,
                                setMeasurementArray,
                                map,
                              );
                              // layerControlUtils.clearMeasure(
                              //   measurement.id,
                              //   setMeasurementArray,
                              //   map
                              // );
                            }}
                            className="group my-auto mr-[4px] flex h-[28px] w-auto cursor-pointer items-center justify-center rounded-md border border-red-700 p-1 px-2 text-xs font-medium text-red-700 hover:bg-red-800 hover:text-white focus:outline-none focus:ring-0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 -960 960 960"
                              width="24"
                              className="mr-1 h-4 w-4 fill-red-700 stroke-current group-hover:fill-white"
                              // fill="red"
                              // stroke="red"
                            >
                              <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
                            </svg>
                            Delete
                          </button>
                        ) : null}
                        {!showMeasureEditButtonsArray[index] ? (
                          <button
                            className={`my-auto flex w-auto cursor-pointer items-center justify-center rounded-md border p-1 px-2 text-xs ${
                              showMeasureEditButtonsArray[index]
                                ? "bg-purple-400 text-white"
                                : "border-purple-400 text-purple-400 hover:bg-purple-400 hover:text-white"
                            } ${
                              !measurement.visible
                                ? "cursor-not-allowed opacity-50"
                                : ""
                            } `}
                            onClick={() => {
                              layerControlUtils.handleMeasureEditClick(
                                measurement.id,
                                index,
                                setSelecteMeasuredLayer,
                                setEditMeasureMode,
                                editCtrlMeasure,
                                editMeasureMode,
                                measurement,
                                setMeasurementArray,
                                setShowMeasureEditButtonsArray,
                                markerFeatureGroup,
                                measurementArray,
                                map,
                                storedPreset,
                              );
                            }}
                            disabled={!measurement.visible}
                          >
                            <svg
                              className="h-[5.5] w-5 text-purple-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g
                                strokeLinecap="round"
                                strokeWidth="2"
                                stroke="currentColor"
                              >
                                <path d="m20 20H4"></path>
                                <path
                                  clipRule="evenodd"
                                  fillRule="evenodd"
                                  d="m14.5858 4.41422c.781-.78105 2.0474-.78105 2.8284 0 .7811.78105.7811 2.04738 0 2.82843l-8.28322 8.28325-3.03046.202.20203-3.0304z"
                                ></path>
                              </g>
                            </svg>
                            <p className="ml-1"> Edit Shape</p>
                          </button>
                        ) : null}
                        {showMeasureEditButtonsArray[index] && (
                          <div className="flex items-center">
                            <button
                              className="btn btn-success btn-sm mr-[2px] rounded-md border border-green-800 bg-green-400 p-1 px-2 text-xs text-white hover:bg-white hover:bg-opacity-50 hover:text-black"
                              onClick={() => {
                                layerControlUtils.handleMeasureSaveEdit(
                                  index,
                                  selectedMeasureLayer,
                                  editCtrlMeasure,
                                  setEditMeasureMode,
                                  setShowMeasureEditButtonsArray,
                                  measurementArray,
                                  setSelecteMeasuredLayer,
                                  markerFeatureGroup,
                                  setMeasurementArray,
                                  map,
                                  storedPreset,
                                );
                              }}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-danger btn-sm rounded-md border border-red-500 bg-white bg-opacity-50 p-1 px-2 text-xs hover:bg-red-400 hover:bg-opacity-100 hover:text-white"
                              onClick={() => {
                                layerControlUtils.handleMeasureCancelEdit(
                                  index,
                                  selectedMeasureLayer,
                                  editMeasureMode,
                                  editCtrlMeasure,
                                  setEditMeasureMode,
                                  setShowMeasureEditButtonsArray,
                                  measurementArray,
                                  setSelecteMeasuredLayer,
                                  markerFeatureGroup,
                                  setMeasurementArray,
                                  map,
                                  storedPreset,
                                );
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))
              )}
            </div>
          </TabPanel>

          <TabPanel className="w-full" key="assetsTab" value={2}>
            <div className="flex-1 overflow-y-auto overflow-x-hidden">
              {KmlsetArray?.length <= 0 && shapeFeturesetArray.length <= 0 ? (
                <p className="p-2 text-center text-sm">No assets to show</p>
              ) : (
                KmlsetArray?.map((kml, index) => (
                  <Accordion key={index + kml.id} className="accordianWrapper">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      onClick={(event) => event.stopPropagation()}
                      className="m-0 flex min-h-max !items-center !justify-center"
                    >
                      <button
                        className={`visibility-toggle-btn`}
                        onClick={(event) => {
                          event.stopPropagation();
                          dispatch(gisLeafletActions.toggleVisibility(kml.id));
                          // toggleKmlVisibilityById(kml.id);
                        }}
                        // disabled={showMeasureEditButtonsArray[index]}
                      >
                        {kml?.visible ? (
                          <img
                            src={visibilityIco}
                            alt="Visible"
                            className="h-4"
                          />
                        ) : (
                          <img
                            src={visibilityOffIco}
                            alt="Hidden"
                            className="h-4"
                          />
                        )}
                      </button>

                      <div className="ml-2 flex items-center justify-between gap-4 rounded-md">
                        <p
                          className="btn-dangerrounded text-black"
                          onClick={(event) => {
                            event.stopPropagation();
                            const kmlLayer = layerControlUtils.getLayerByIdUtil(
                              kml.id,
                              map,
                            );
                            if (kmlLayer) {
                              const bounds = kmlLayer.getBounds();
                              map?.flyToBounds(bounds, {
                                duration: 2, // Animation duration in seconds
                                easeLinearity: 0.5, // Animation easing factor (0 = linear, 1 = same speed throughout)
                                maxZoom: 19,
                              });
                            } else {
                              //console.error("Layer coordinates not available");
                            }
                          }}
                        >
                          {kml.KmlName}
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      disabled={!kml.visible}
                      className="!mb-0 !mt-0 w-full grow"
                    >
                      <div className="flex flex-col text-sm">
                        <div className="flex items-start">
                          <p className="w-[70px]">Opacity:</p>
                          <Slider
                            min={0}
                            max={1}
                            step={0.1}
                            value={opacityValues[kml.id] || 1}
                            valueLabelDisplay="auto"
                            onChange={(e) => {
                              setOpacityValues((prevState) => ({
                                ...prevState,
                                [kml.id]: e.target.value,
                              }));
                              layerControlUtils.handleAssetOpacityChange(
                                kml.id,
                                e.target.value,
                                map,
                              );
                            }}
                            disabled={!kml.visible} // Disable slider if marker is not visible
                            className="ml-1 w-3/5"
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))
              )}
              {geoJSONArray.length > 0 &&
                geoJSONArray?.map((geoJson, index) => (
                  <Accordion
                    key={index + geoJson.id}
                    className="accordianWrapper"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      onClick={(event) => event.stopPropagation()}
                      className="m-0 flex min-h-max !items-center !justify-center"
                    >
                      <button
                        className={`visibility-toggle-btn`}
                        onClick={(event) => {
                          event.stopPropagation();
                          dispatch(
                            gisLeafletActions.toggleGeoJSONVisibility(
                              geoJson.id,
                            ),
                          );
                          // toggleKmlVisibilityById(kml.id);
                        }}
                        // disabled={showMeasureEditButtonsArray[index]}
                      >
                        {geoJson?.visible ? (
                          <img
                            src={visibilityIco}
                            alt="Visible"
                            className="h-4"
                          />
                        ) : (
                          <img
                            src={visibilityOffIco}
                            alt="Hidden"
                            className="h-4"
                          />
                        )}
                      </button>

                      <div className="ml-2 flex items-center justify-between gap-4 rounded-md">
                        <p
                          className="btn-dangerrounded text-black"
                          onClick={(event) => {
                            event.stopPropagation();
                            const geoJsonLayer =
                              layerControlUtils.getLayerByIdUtil(
                                geoJson.id,
                                map,
                              );
                            if (geoJsonLayer) {
                              const bounds = geoJson.getBounds();
                              map?.flyToBounds(bounds, {
                                duration: 2, // Animation duration in seconds
                                easeLinearity: 0.5, // Animation easing factor (0 = linear, 1 = same speed throughout)
                                maxZoom: 19,
                              });
                            } else {
                              //console.error("Layer coordinates not available");
                            }
                          }}
                        >
                          {geoJson.geoJsonName}
                        </p>
                      </div>
                    </AccordionSummary>
                  </Accordion>
                ))}
              {/* shape file */}
              {shapeFeturesetArray.length > 0 &&
                shapeFeturesetArray?.map((shapeObj, index) => (
                  <Accordion
                    key={index + shapeObj.id}
                    className="accordianWrapper"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      onClick={(event) => event.stopPropagation()}
                      className="m-0 flex min-h-max !items-center !justify-center"
                    >
                      <button
                        className={`visibility-toggle-btn`}
                        onClick={(event) => {
                          event.stopPropagation();
                          dispatch(
                            gisLeafletActions.toggleShapeFeatureVisibility(
                              shapeObj.id,
                            ),
                          );
                        }}
                        // disabled={showMeasureEditButtonsArray[index]}
                      >
                        {shapeObj?.visible ? (
                          <img
                            src={visibilityIco}
                            alt="Visible"
                            className="h-4"
                          />
                        ) : (
                          <img
                            src={visibilityOffIco}
                            alt="Hidden"
                            className="h-4"
                          />
                        )}
                      </button>

                      <div className="ml-2 flex items-center justify-between gap-4 rounded-md">
                        <p
                          className="btn-dangerrounded text-black"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (shapeObj.visible) {
                              const bounds = shapeBounds[shapeObj.id];
                              if (bounds) {
                                map?.flyToBounds(bounds, {
                                  duration: 1.5, // Animation duration in seconds
                                  easeLinearity: 0.5, // Animation easing factor (0 = linear, 1 = same speed throughout)
                                  maxZoom: 19,
                                });
                              }
                            } else {
                              //console.error("Layer coordinates not available");
                            }
                          }}
                        >
                          {
                            shapeObj.layerName
                              .replace(/^mrvc:/, "") // Remove 'mrvc:' prefix
                              .toLowerCase() // Convert to lowercase first for consistent camel casing
                              .replace(/_+/g, " ") // Replace underscores with a space
                              .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
                          }
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      disabled={!shapeObj.visible}
                      className="!mb-0 !mt-0 w-full grow"
                    >
                      <div className="flex flex-col text-sm">
                        <div className="flex items-start">
                          <p className="w-[70px]">Opacity:</p>
                          <Slider
                            min={0}
                            max={1}
                            step={0.1}
                            value={
                              shapeGroupopacityValues[shapeObj.id] !== undefined
                                ? shapeGroupopacityValues[shapeObj.id]
                                : 1
                            }
                            valueLabelDisplay="auto"
                            onChange={(e) => {
                              const newOpacity = e.target.value;

                              // Update opacity value for the specific shapeObj.id
                              setShapeGroupopacityValues((prev) => ({
                                ...prev,
                                [shapeObj.id]: newOpacity,
                              }));
                              setLayerGroupOpacity(e.target.value, shapeObj); //function
                            }}
                            disabled={!shapeObj.visible} // Disable slider if marker is not visible
                            className="ml-1 w-3/5"
                          />
                        </div>
                        {/* <Legend
                          minElevation={minMaxElevation.minElevation}
                          maxElevation={minMaxElevation.maxElevation}
                        /> */}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <Toaster />
    </div>
  );
};

export default SidebarLayersLeaflet;

const Legend = ({ minElevation, maxElevation }) => {
  // Generate a gradient that reflects the elevation scale
  const gradient = `linear-gradient(to right, rgb(255,0,0) 0%, rgb(255,255,0) 50%, rgb(0,255,0) 100%)`;

  return (
    <div className="mt-4 flex w-full flex-col items-center">
      <div className="relative mb-2 h-7 w-full">
        {/* Gradient bar */}
        <div
          className="absolute left-0 top-0 h-full w-full bg-gradient-to-r from-red-500 via-yellow-500 to-green-500"
          style={{ backgroundImage: gradient }}
        ></div>
      </div>
      <div className="flex w-full justify-between text-xs text-gray-600">
        <span>{minElevation} m</span>
        <span className="font-bold">Elevation</span>
        <span>{maxElevation} m</span>
      </div>
    </div>
  );
};

/*wfs using API*/
// useEffect(() => {
//   // const markers = L.markerClusterGroup();
//   // const polygonClusterGroup = L.markerClusterGroup();
//   // const polylineClusterGroup = L.markerClusterGroup();
//   // const polygonCdeflateFeatures = L.deflate({
//   //   minSize: 15, // Adjust based on dataset
//   //   maxZoom: 15, // Limit deflation zoom level
//   //   markerOptions: { icon: defaultMarkerIcon },
//   //   markerLayer: polygonClusterGroup,
//   // }).addTo(map);

//   // const polylineCdeflateFeatures = L.deflate({
//   //   minSize: 15, // Adjust based on dataset
//   //   maxZoom: 15, // Limit deflation zoom level
//   //   markerOptions: { icon: defaultMarkerIcon },
//   //   markerLayer: polylineClusterGroup,
//   // }).addTo(map);

//   const addShapeFiles = () => {
//     try {
//       if (!shapeFeturesetArray) {
//         return;
//       }
//       if (shapeFeturesetArray.length > 0) {
//         shapeFeturesetArray.forEach(async (shapeFile) => {
//           if (shapeFile.visible) {
//             console.time("Load and Convert Shapefile"); // Start measuring total time for loading and converting shapefile
//             const response = await fetch(shapeFile.shapeFileePath);
//             const arrayBuffer = await response.arrayBuffer();
//             const geojson = await shp(arrayBuffer);
//             console.timeEnd("Load and Convert Shapefile"); // End measuring total time for loading and converting shapefile
//             console.log("shape to geojson", geojson);

//             console.time("Plot GeoJSON"); // Start measuring plotting time
//             // Compute min and max elevation
//             let minElevation = Infinity;
//             let maxElevation = -Infinity;

//             geojson.features.forEach((feature) => {
//               if (
//                 feature.geometry.type === "LineString" ||
//                 feature.geometry.type === "MultiLineString"
//               ) {
//                 const elevation = feature.properties.ELEVATION || 0;
//                 if (elevation < minElevation) minElevation = elevation;
//                 if (elevation > maxElevation) maxElevation = elevation;
//               }
//             });
//             console.log(
//               `Min Elevation: ${minElevation}, Max Elevation: ${maxElevation}`,
//             );

//             L.geoJSON(geojson, {
//               pointToLayer: (feature, latlng) => {
//                 if (feature.geometry.type === "Point") {
//                   return L.marker(latlng);
//                 }
//                 return null;
//               },
//               style: (feature) => {
//                 if (
//                   feature.geometry.type === "LineString" ||
//                   feature.geometry.type === "MultiLineString"
//                 ) {
//                   const elevation = feature.properties.ELEVATION || 0;
//                   return {
//                     color: getColorByElevation(
//                       elevation,
//                       minElevation,
//                       maxElevation,
//                     ),
//                     weight: 2,
//                   };
//                 }
//                 return {};
//               },
//               onEachFeature: (feature, layer) => {
//                 const properties = feature.properties;
//                 feature.id = properties.FID_1;
//                 const tooltipContent = `
//                 <div>
//                     <strong>FID_1:</strong> ${properties.FID_1}<br>
//                     <strong>ELEVATION:</strong> ${properties.ELEVATION}<br>
//                     <strong>LAYER:</strong> ${properties.LAYER}<br>
//                     <strong>Len:</strong> ${properties.Len.toFixed(2)} m<br>
//                     <strong>NAME:</strong> ${properties.NAME}
//                 </div>`;
//                 layer.bindTooltip(tooltipContent, {
//                   permanent: false, // Tooltip will not be permanently displayed
//                   direction: 'top', // Tooltip direction
//                   sticky: true, // Tooltip will stick to the mouse cursor
//                   opacity: 0.8, // Tooltip opacity
//               });

//               // Add event listeners for tooltip visibility on hover
//               layer.on('mouseover', () => {
//                   layer.openTooltip(); // Show tooltip on hover
//               });
//               layer.on('mouseout', () => {
//                   layer.closeTooltip(); // Hide tooltip when not hovering
//               });
//                 layer.FID_1 = properties.FID_1;
//                 // console.log(layer);

//                 if (
//                   feature.geometry.type === "Polygon" ||
//                   feature.geometry.type === "MultiPolygon"
//                 ) {
//                   shapeClusterGroup.addLayer(layer);
//                 } else if (
//                   feature.geometry.type === "LineString" ||
//                   feature.geometry.type === "MultiLineString"
//                 ) {
//                   shapeClusterGroup.addLayer(layer);
//                 } else {
//                   shapeClusterGroup.addLayer(layer);
//                 }
//               },
//             });
//             shapeClusterGroup.addTo(map);
//             // dispatch(gisLeafletActions.setShpaeGeoJson(geojson.features));
//             // Add the marker cluster group to the map
//             // masterClusterGroup.addLayer(markers);
//             // masterClusterGroup.addLayer(polygonClusterGroup);
//             // masterClusterGroup.addLayer(polylineClusterGroup);

//             console.timeEnd("Plot GeoJSON"); // End measuring plotting time
//           } else if (!shapeFile.visible) {
//             console.log("in toggle false removing feature group");
//             map.removeLayer(shapeClusterGroup);
//           }
//         });
//       }
//     } catch (error) {
//       console.error("Error loading shapefile:", error);
//     }
//   };
//   addShapeFiles();
//   // Cleanup function
//   return () => {
//     console.log("this clean Up ");
//     map.removeLayer(shapeClusterGroup);
//     // map.removeLayer(markers);
//     // map.removeLayer(masterClusterGroup);
//     // map.removeLayer(polygonClusterGroup);
//     // map.removeLayer(polylineClusterGroup);
//     // map.removeLayer(polygonCdeflateFeatures);
//     // map.removeLayer(polylineCdeflateFeatures);
//   };
// }, [shapeFeturesetArray]);
