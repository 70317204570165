import * as AWS from "aws-sdk";

const configuration = {
  region: "ap-south-1",
  secretAccessKey: "aNxVVkZLBNu3aneI85TrHI6pPyIzAFb72bDKlvXP",
  accessKeyId: "AKIAX546GMWHGDP77WX6",
};

AWS.config.update(configuration);
const docClient = new AWS.DynamoDB.DocumentClient();

let tableName = process.env.REACT_APP_OBSERVATIONS_TABLE; // for hmel annotations

// const tableName = "ori_annotations_leaflet"; // general

export class DynamoDBApisL {
  // constructor(tableName) {
  //   this.tableName = tableName;
  // }

  //get all entries from a given table
  static getAllData = () => {
    docClient.scan(
      {
        TableName: tableName,
      },
      (err, data) => {
        if (err) {
          console.error("Error scanning table:", err);
          return false;
        } else {
          // console.log("Items in the table:", data.Items);

          return data.Items;
        }
      },
    );
  };

  //get entries based on a given assetID in a given table
  static getDataByID = (assetId) => {
    // const filterExpression =
    //   "assetId IN (" + assetID.map((_, i) => `:assetId${i}`).join(", ") + ")";
    // const expressionAttributeValues = assetID.reduce((acc, id, i) => {
    //   acc[`:assetId${i}`] = id;
    //   return acc;
    // }, {});


    const filterExpression = "assetId = :assetId";
    const expressionAttributeValues = {
      ":assetId": assetId,
    };

    const params = {
      TableName: tableName,
      FilterExpression: filterExpression,
      ExpressionAttributeValues: expressionAttributeValues,
    };

    return new Promise((resolve, reject) => {
      let allItems = [];

      const scanAndAccumulate = async () => {
        try {
          const results = await docClient.scan(params).promise();
          allItems = allItems.concat(results.Items);

          if (results.LastEvaluatedKey) {
            params.ExclusiveStartKey = results.LastEvaluatedKey;
            await scanAndAccumulate(); // Recursive call for pagination
          } else {
            resolve(allItems); // No more pages, resolve with all data
          }
        } catch (err) {
          reject(err); // Error during scan, reject promise
        }
      };

      scanAndAccumulate(); // Initiate the data retrieval process
    });
  };

  static getDataByProject = (projectName) => {
    const filterExpression = "groupName = :project_name";
    const expressionAttributeValues = {
      ":project_name": projectName,
    };

    const params = {
      TableName: tableName,
      FilterExpression: filterExpression,
      ExpressionAttributeValues: expressionAttributeValues,
    };

    return new Promise((resolve, reject) => {
      docClient.scan(params, (err, data) => {
        if (err) {
          console.error("Error querying table:", err);
          reject(err);
        } else {
          // console.log("Items with assetID:", data.Items);
          resolve(data.Items);
        }
      });
    });
  };

  static getDataBygrpID = (grpID) => {
    const filterExpression = "grpID = :grpID";
    const expressionAttributeValues = {
      ":grpID": grpID,
    };

    const params = {
      TableName: tableName,
      FilterExpression: filterExpression,
      ExpressionAttributeValues: expressionAttributeValues,
    };

    return new Promise((resolve, reject) => {
      let allItems = [];

      const scanAndAccumulate = async () => {
        try {
          const results = await docClient.scan(params).promise();
          allItems = allItems.concat(results.Items);

          if (results.LastEvaluatedKey) {
            params.ExclusiveStartKey = results.LastEvaluatedKey;
            await scanAndAccumulate(); // Recursive call for pagination
          } else {
            resolve(allItems); // No more pages, resolve with all data
          }
        } catch (err) {
          reject(err); // Error during scan, reject promise
        }
      };

      scanAndAccumulate();
    });
  };

  static getDataByFilter = (
    category,
    severity,
    assetIDs,
    searchQuery,
    groupName,
  ) => {
    let filterExpression = "(groupName = :groupName)";
    let expressionAttributeValues = {
      ":groupName": groupName,
    };

    if (assetIDs && assetIDs.length > 0) {
      const assetIdPlaceholders = assetIDs
        .map((_, i) => `:assetId${i}`)
        .join(", ");
      filterExpression += ` AND assetId IN (${assetIdPlaceholders})`;

      assetIDs.forEach((id, i) => {
        expressionAttributeValues[`:assetId${i}`] = id;
      });
    }

    if (category) {
      filterExpression += " AND category = :category";
      expressionAttributeValues[":category"] = category;
    }

    if (severity) {
      filterExpression += " AND severity = :severity";
      expressionAttributeValues[":severity"] = severity;
    }

    const params = {
      TableName: tableName,
      FilterExpression: filterExpression,
      ExpressionAttributeValues: expressionAttributeValues,
    };

    return new Promise((resolve, reject) => {
      let allItems = [];

      const scanAndAccumulate = async () => {
        try {
          const results = await docClient.scan(params).promise();
          allItems = allItems.concat(results.Items);

          if (results.LastEvaluatedKey) {
            // Update params for next scan (if available)
            params.ExclusiveStartKey = results.LastEvaluatedKey;
            await scanAndAccumulate(); // Recursive call for pagination
          } else {
            // No more pages, resolve with all accumulated data
            if (!searchQuery) {
              resolve(allItems);
            } else {
              const filteredResults = allItems.filter((item) =>
                item.id.includes(searchQuery),
              );
              resolve(filteredResults);
            }
          }
        } catch (err) {
          reject(err); // Error during scan, reject promise
        }
      };

      scanAndAccumulate();
    });
  };

  static addReport = (dataToPush) => {
    docClient.put(
      {
        TableName: tableName,
        Item: dataToPush,
      },
      (err, dataToPush) => {
        if (err) {
          console.error("Error pushing data to the table:", err);
          return false;
        } else {
          // console.log("Data pushed successfully:", dataToPush);
          return "Annotated Sucessfully";
        }
      },
    );
  };

  static addStreamLinks = (dataToPush) => {
    // console.log(dataToPush);
    docClient.put(
      {
        TableName: tableName,
        Item: dataToPush,
      },
      (err, dataToPush) => {
        if (err) {
          console.error("Error pushing data to the table:", err);
          return false;
        } else {
          // console.log("Data pushed successfully:", dataToPush);
          // return "Annotated Sucessfully";
        }
      },
    );
  };
}

export class AnnotationManager {
  // constructor(tableName) {
  //   this.tableName = tableName;
  // }

  static addAnnotations = (dataToPush, file) => {
    docClient.put(
      {
        TableName: tableName,
        Item: dataToPush,
      },
      (err, dataToPush) => {
        if (err) {
          console.error("Error pushing data to the table:", err);
          return false;
        } else {
          // console.log("Data pushed successfully:", dataToPush);
          return "Annotated Sucessfully";
        }
      },
    );
  };

  static deleteAnnotations = (getId, getTimestamp) => {
    docClient.delete(
      {
        TableName: tableName,
        Key: { id: getId, timestamp: getTimestamp },
      },
      (err, data) => {
        if (err) {
          console.error("Error deleting item:", err);
          return false;
        } else {
          // console.log("Item deleted successfully:", data);
          return "Item deleted successfully";
        }
      },
    );
  };

  static editAnnotations = (key, fieldsToUpdate) => {
    // Construct the update expression and expression attribute values dynamically
    // console.log(fieldsToUpdate, "fieldsToUpdate", key, "key");
    let updateExpression = "SET ";
    const expressionAttributeValues = {};

    // Loop through the fields to update object and build the update expression and values
    Object.keys(fieldsToUpdate).forEach((field, index, fieldsArray) => {
      if (field === "images") {
        // Handle the 'images' attribute separately to push the new object
        updateExpression +=
          " #images = list_append(if_not_exists(#images, :empty_list), :new_image)";
        expressionAttributeValues[":new_image"] = fieldsToUpdate[field];
        expressionAttributeValues[":empty_list"] = [];

        // Add a comma if there are more fields to update
        if (index < fieldsArray.length - 1) {
          updateExpression += ", ";
        }
      } else {
        updateExpression += `${field} = :new_value_${index}`;
        expressionAttributeValues[`:new_value_${index}`] =
          fieldsToUpdate[field];

        // Add a comma if there are more fields to update
        if (index < fieldsArray.length - 1) {
          updateExpression += ", ";
        }
      }
    });
    // Define the parameters for the update operation
    const params = {
      TableName: tableName,
      Key: key,
      UpdateExpression: updateExpression,
      ExpressionAttributeValues: expressionAttributeValues,
      ExpressionAttributeNames: {
        "#images": "images", // Specify the attribute name for 'images'
      },
      ReturnValues: "ALL_NEW",
    };

    // Perform the update operation
    docClient.update(params, (err, data) => {
      if (err) {
        console.error("Error updating item:", err);
      } else {
        // console.log("Item updated successfully:", data.Attributes); // Access the updated item
      }
    });
  };

  static editShape = (key, fieldsToUpdate) => {
    // Construct the update expression and expression attribute values dynamically
    let updateExpression = "SET ";
    const expressionAttributeValues = {};

    // Loop through the fields to update object and build the update expression and values
    Object.keys(fieldsToUpdate).forEach((field, index, fieldsArray) => {
      if (field === "markerPosition") {
        // Handle the 'markerPositions' attribute separately to set the new positions
        updateExpression += " #markerPosition = :new_position";
        expressionAttributeValues[":new_position"] = fieldsToUpdate[field];

        // Add a comma if there are more fields to update
        if (index < fieldsArray.length - 1) {
          updateExpression += ", ";
        }
      } else {
        // For other fields, simply update their values
        updateExpression += `${field} = :new_value_${index}`;
        expressionAttributeValues[`:new_value_${index}`] =
          fieldsToUpdate[field];

        // Add a comma if there are more fields to update
        if (index < fieldsArray.length - 1) {
          updateExpression += ", ";
        }
      }
    });

    // Define the parameters for the update operation
    const params = {
      TableName: tableName, // Assuming tableName is defined somewhere in your code
      Key: key,
      UpdateExpression: updateExpression,
      ExpressionAttributeValues: expressionAttributeValues,
      ExpressionAttributeNames: {
        "#markerPosition": "markerPosition", // Specify the attribute name for 'markerPositions'
      },
      ReturnValues: "ALL_NEW", // Optionally, specify the desired return values
    };

    // Perform the update operation
    docClient.update(params, (err, data) => {
      if (err) {
        console.error("Error updating item:", err);
      } else {
        // console.log("Item updated successfully:", data.Attributes); // Access the updated item
      }
    });
  };
}

export class CommentManager {
  // constructor(tableName) {
  //   this.tableName = tableName;
  // }

  static addComments = (dataToPush) => {
    docClient.put(
      {
        TableName: tableName,
        Item: dataToPush,
      },
      (err, dataToPush) => {
        if (err) {
          console.error("Error pushing data to the table:", err);
          return false;
        } else {
          // console.log("Data pushed successfully:", dataToPush);
          return "Annotated Sucessfully";
        }
      },
    );
  };

  static getCommentsByMarkerID = (markerID) => {
    const filterExpression = "markerID = :markerID";
    const expressionAttributeValues = {
      ":markerID": markerID,
    };

    const params = {
      TableName: tableName,
      FilterExpression: filterExpression,
      ExpressionAttributeValues: expressionAttributeValues,
    };

    return new Promise((resolve, reject) => {
      docClient.scan(params, (err, data) => {
        if (err) {
          console.error("Error querying table:", err);
          reject(err);
        } else {
          const sortedItems = data.Items.sort((a, b) => {
            return b.timestamp - a.timestamp;
          });
          resolve(sortedItems);
        }
      });
    });
  };

  static deleteComment = (id, timestamp) => {
    docClient.delete(
      {
        TableName: tableName,
        Key: { id, timestamp },
      },
      (err, data) => {
        if (err) {
          console.error("Error deleting item:", err);
          return false;
        } else {
          // console.log("Item deleted successfully:", data);
          return "Item deleted successfully";
        }
      },
    );
  };

  static updateCommentById = (key, newComment) => {
    const params = {
      TableName: tableName,
      Key: key,
      UpdateExpression: "SET #comment = :newComment", // Update the 'comment' attribute
      ExpressionAttributeValues: {
        ":newComment": newComment,
      },
      ExpressionAttributeNames: {
        "#comment": "comment", // Define the alias for 'comment'
      },
      ReturnValues: "ALL_NEW", // Return the updated item
    };

    return new Promise((resolve, reject) => {
      docClient.update(params, (err, data) => {
        if (err) {
          console.error("Error updating item:", err);
          reject(err);
        } else {
          resolve(data.Attributes); // Return the updated item's attributes
        }
      });
    });
  };
}

export const getPolyAnnotation = (assetId, filter_type) => {
  const filterExpression = "filter_type = :filter_type AND assetId = :assetId";
  const expressionAttributeValues = {
    ":assetId": assetId,
    ":filter_type": filter_type,
  };

  const params = {
    TableName: tableName,
    FilterExpression: filterExpression,
    ExpressionAttributeValues: expressionAttributeValues,
  };

  return new Promise((resolve, reject) => {
    docClient.scan(params, (err, data) => {
      if (err) {
        console.error("Error querying table:", err);
        reject(err);
      } else {
        // console.log("Poly:", data.Items);
        resolve(data.Items);
      }
    });
  });
};
