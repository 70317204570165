import { useDispatch, useSelector } from "react-redux";
import { gisLeafletActions } from "../../../redux/slices/GIS/gis-leaflet-slice";
import { Tooltip } from "@mui/material";

const PreviousCurrentUpcommingLayerToggles = () => {
  const { asset, layersBetweenStation } = useSelector(
    (state) => state.gisLeaflet
  );
  const dispatch = useDispatch();
  const formatRangeInKmSegment = (str) => {
    const parts = str?.split("_");
    const startValue = parts[2];
    const endValue = parts[4];
    return `${startValue}km to ${endValue}km`;
  };

  const handlePreviousCurrentUpcomingLayers = (layersBetweenStation, asset) => {
    const currentLayer = layersBetweenStation.find(
      (layer) => layer === asset?.Name
    );

    const length = layersBetweenStation.length;
    const currentAssetIndex = layersBetweenStation.findIndex(
      (layer) => layer === currentLayer
    );

    let upcomingLayer;
    let previousLayer;

    if (currentAssetIndex < length - 1) {
      upcomingLayer = layersBetweenStation[currentAssetIndex + 1];
    } else {
      upcomingLayer = "No layer";
    }

    if (currentAssetIndex > 0) {
      previousLayer = layersBetweenStation[currentAssetIndex - 1];
    } else {
      previousLayer = "No layer";
    }

    return {
      previousLayer,
      currentLayer: currentLayer
        ? formatRangeInKmSegment(currentLayer)
        : "No layer",
      upcomingLayer: upcomingLayer,
    };
  };

  const { previousLayer, currentLayer, upcomingLayer } =
    handlePreviousCurrentUpcomingLayers(layersBetweenStation, asset);

  return (
    <div
      className={`flex items-center justify-center h-10 w-max text-white  text-sm bg-[rgba(20,26,34,.725)]  absolute right-12 bottom-0 py-2 px-1 rounded-full `}
    >
      <Tooltip
        title={`${
          previousLayer === "No layer"
            ? "No Previous Segment Available"
            : `Previous Segment`
        }`}
        placement="top"
      >
        <button
          className={` px-2 py-1 rounded-full ${
            currentLayer === previousLayer
              ? "bg-blue-500"
              : "bg-transparent hover:bg-blue-100 hover:text-[#141a22] "
          } ${previousLayer === "No layer" ? " cursor-not-allowed" : ""}`}
          onClick={() => {
            previousLayer !== "No layer" &&
              dispatch(
                gisLeafletActions.setSelectAssetFromPreviousCurrentNextNav(
                  previousLayer
                )
              );
          }}
          disabled={previousLayer === "No layer"}
        >
          {previousLayer !== "No layer"
            ? formatRangeInKmSegment(previousLayer)
            : previousLayer}
        </button>
      </Tooltip>
      <Tooltip
        title={`
         Current Segment
        `}
        placement="top"
      >
        <button
          className={`mx-1 px-2 py-1  rounded-full cursor-default text-[#141a22]  !bg-[#ffffffd9]  ${
            currentLayer === asset?.Name ? "" : "hover:bg-blue-100"
          }`}
          style={{ boxShadow: "0 0 8px rgba(20,26,34,.4)" }}
        >
          {currentLayer}
        </button>
      </Tooltip>
      <Tooltip
        title={`${
          upcomingLayer === "No layer"
            ? "No Next Segment Available"
            : `Next Segment`
        }`}
        placement="top"
      >
        <button
          className={` px-2 py-1 rounded-full ${
            currentLayer === upcomingLayer
              ? "bg-blue-500"
              : "bg-transparent hover:bg-blue-100 hover:text-[#141a22] "
          } ${upcomingLayer === "No layer" ? " cursor-not-allowed" : ""}`}
          onClick={() => {
            upcomingLayer !== "No layer" &&
              dispatch(
                gisLeafletActions.setSelectAssetFromPreviousCurrentNextNav(
                  upcomingLayer
                )
              );
          }}
          disabled={upcomingLayer === "No layer"}
        >
          {upcomingLayer !== "No layer"
            ? formatRangeInKmSegment(upcomingLayer)
            : upcomingLayer}
        </button>
      </Tooltip>
    </div>
  );
};

export default PreviousCurrentUpcommingLayerToggles;
