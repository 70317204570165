import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "project",
  initialState: {
    projectMeta: {},
  },
  reducers: {
    setProjectMeta(state, action) {
      state.projectMeta = action.payload;
    },
  },
});

export const projectActions = projectSlice.actions;

export default projectSlice.reducer;
