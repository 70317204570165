import { CircularProgress, Fade, Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import html2canvas from "html2canvas";
import Cookies from "js-cookie";
import L from "leaflet";
import "rc-pagination/assets/index.css";
import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  allIco,
  checkBlackIco,
  exportIco,
} from "../../../Assets/icons/gisIcons/icons";
import {
  observationCategories,
  svStationsJson,
} from "../../../Data/config/ProjectConfig";
import { DynamoDBApisL } from "../../../Services/ThirdPartyApi/AWS/DynamoDBL";
import { getDate } from "../../../Utils/OtherUtils";

const convertDDMMYYToDate = (ddmmyy) => {
  const [day, month, year] = ddmmyy.split("_");
  return new Date(`20${year}`, month - 1, day);
};

const lineChartOptions = {
  chart: {
    type: "line",
    toolbar: {
      show: false,
    },
  },

  dataLabels: {
    enabled: true,
  },

  xaxis: {
    type: "datetime",
    title: {
      text: "Timeline",
    },
  },
  yaxis: {
    title: {
      text: "Volume in m³",
    },
  },
};

const barChartOptions = {
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
  },

  dataLabels: {
    enabled: true,
  },

  xaxis: {
    type: "datetime",
    title: {
      text: "Timeline",
    },
  },
  yaxis: {
    title: {
      text: "Number of Sites",
    },
  },
};

const ReportTableL = ({ isSidebaropen, openShapes, openLayers, mapRef }) => {
  const map = mapRef.current;

  const { projectMeta } = useSelector((state) => state.projectMeta);
  const { clientName } = projectMeta;

  const { avaliableRasterAssets: groupData, asset } = useSelector(
    (state) => state.gisLeaflet,
  );

  const { userPermissions } = useSelector((state) => state.gis);

  const chartRef = useRef(null);
  const chartRefBar = useRef(null);
  const [series, setSeries] = useState([]);
  const [seriesBar, setSeriesBar] = useState([]);
  const [assetFilter, setAssetFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [severityFilter, setSeverityFilter] = useState("");
  const [showReport, setShowreport] = useState(false);
  const { bottomReportToggle } = useSelector((state) => state.gisHome);
  const { refCounter } = useSelector((state) => state.gis);
  const [selectedItems, setSelectedItems] = useState([]);
  const [assetQuery, setAssetQuery] = useState([]);

  const [isSelectableReportCheckbox, setIsSelectableReportCheckbox] =
    useState(false);

  const fetchTableData = async (
    categoryFilter,
    severityFilter,
    assetFilter,
    searchQuery,
    clientName,
  ) => {
    try {
      const retrievedData = await DynamoDBApisL.getDataByFilter(
        categoryFilter,
        severityFilter,
        assetQuery ? assetFilter : null,
        searchQuery !== "" ? searchQuery : "",
        clientName,
      );

      return retrievedData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const { status, data, error } = useQuery({
    queryKey: [
      "fetchTableData",
      categoryFilter,
      severityFilter,
      assetQuery,
      searchQuery,
      clientName,
      refCounter,
    ],
    queryFn: () =>
      fetchTableData(
        categoryFilter,
        severityFilter,
        assetQuery,
        searchQuery,
        clientName,
      ),
  });

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data, refCounter]);

  // showing shape metrics
  const shapeMetrics = (item) => {
    let result;

    if (item?.shapeData?.shape_type === "polygon") {
      result = (item?.shapeData?.area / 1000).toFixed(2) + "m²";
    } else {
      result = "lat: " + item?.latitude.toFixed(4);
    }

    return <p>{result}</p>;
  };

  const severities = ["Low", "Medium", "High"];

  const findAssetNameById = (assetId, data) => {
    const foundAsset = data.find((item) => item.id === assetId);

    return foundAsset ? getDate(foundAsset.date) : "-";
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // getting time string from the timestamp
  function formatTimestamp(timestamp, dateOnly) {
    const date = new Date(timestamp);

    const year = date.getFullYear();

    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const seconds = date.getSeconds().toString().padStart(2, "0");

    var formattedDate;
    if (!dateOnly) {
      formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    } else {
      formattedDate = `${day}_${month}_${year}`;
    }

    return formattedDate;
  }

  const generateChartImage = async (chartSeries, ref, type) => {
    for (let i = 0; i < chartSeries.length; i++) {
      chartSeries[i].data.sort((a, b) => new Date(a.x) - new Date(b.x));
    }

    if (type === "line") {
      setSeries(chartSeries);
    } else {
      setSeriesBar(chartSeries);
    }

    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (ref) {
          try {
            const canvas = await html2canvas(ref.chartRef.current, {
              useCORS: true,
            });
            const data = canvas.toDataURL("image/png");
            resolve(data);
          } catch (error) {
            console.error("Error capturing chart image:", error);
            reject(error);
          }
        }
      }, 1000);
    });
  };

  const generateChartData = async (data) => {
    setShowreport(true);
    const assetIdToDate = {};
    groupData.forEach((item) => {
      assetIdToDate[item.id] = convertDDMMYYToDate(item?.date);
    });

    const groupedByCategory = data.reduce((result, currentObject) => {
      const date = assetIdToDate[currentObject.assetId];
      const category = currentObject.category;

      if (!result[category]) {
        result[category] = { name: category, data: [] };
      }

      // Check if the category is "Stock Pile" or "Steel Scrap"
      if (category === "Stock Pile" || category === "Steel Scrap") {
        // Calculate total volume for "Stock Pile" and "Steel Scrap"
        const existingDataIndex = result[category].data.findIndex(
          (item) => item.x === date,
        );

        if (existingDataIndex !== -1) {
          // If date already exists, update the volume
          result[category].data[existingDataIndex].y += currentObject.volume;
        } else {
          // If date doesn't exist, add a new entry
          result[category].data.push({ x: date, y: currentObject.volume });
        }
      } else {
        // For other categories, count the number of elements
        const existingDataIndex = result[category].data.findIndex(
          (item) => item.x === date,
        );

        if (existingDataIndex !== -1) {
          // If date already exists, update the count
          result[category].data[existingDataIndex].y += 1;
        } else {
          // If date doesn't exist, add a new entry
          result[category].data.push({ x: date, y: 1 });
        }
      }

      return result;
    }, {});

    const resultArray = Object.values(groupedByCategory);

    const lineChartSeries = resultArray.filter(
      (item) => item.name === "Stock Pile" || item.name === "Steel Scrap",
    );

    const lineChart = await generateChartImage(
      lineChartSeries,
      chartRef.current,
      "line",
    );

    const barChartSeries = resultArray.filter(
      (item) => item.name !== "Stock Pile" && item.name !== "Steel Scrap",
    );

    const barChart = await generateChartImage(
      barChartSeries,
      chartRefBar.current,
      "bar",
    );

    setShowreport(false);
    return [lineChart, barChart];
  };

  // report modal
  const handleReportClick = async () => {
    toast.success("Report generation started successfully");

    const params = {};

    params.data = filteredData;

    try {
      const response = await axios.post(
        "https://delivery.inspect.indrones.com/api/v1/getPdf/generate_pil_report",
        params,
        {
          responseType: "blob", // Tell Axios to expect a binary response
        },
      );
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a download link and trigger a click to initiate download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `report_${formatTimestamp(Date.now(), 1)}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success("Report downloaded successfully");
    } catch (error) {
      // console.error("response mohit error", error);
    }
  };

  const myElementRef = useRef(null);

  const [elementWidth, setElementWidth] = useState(null);

  useEffect(() => {
    const element = myElementRef.current;

    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === element) {
          setElementWidth(entry.contentRect.width);
        }
      }
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, []);

  const gridRef = useRef();

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
    };
  }, []);
  const sideBar = useMemo(() => {
    return {
      toolPanels: ["columns"],
    };
  }, []);

  const columnsOfDiff = [
    {
      headerName: "Name",
      field: "markerName",
    },
    // {
    //   headerName: "Timeline",
    //   valueGetter: (params) =>
    //     params?.data?.assetId
    //       ? findAssetNameById(params?.data?.assetId, groupData)
    //       : "-",
    // },
    {
      headerName: "Type",
      field: "observationType",
      sortable: true,
    },
    {
      headerName: "Category",
      field: "category",
      sortable: true,
    },
    {
      headerName: "Created On",

      valueGetter: (params) =>
        params?.data?.timestamp
          ? formatTimestamp(params?.data?.timestamp)
          : "-",
      sortable: true,
    },
    {
      headerName: "Description",
      field: "description",
      valueGetter: (params) =>
        params?.data?.description ? params?.data?.description : "-",
    },
    {
      headerName: "ID",
      valueGetter: (params) =>
        params?.data?.id ? params?.data?.id.split("-")[0] : "-",
    },
    {
      headerName: "Risk",
      field: "severity",

      cellStyle: (params) => {
        if (params?.value === "High") {
          return {
            color: "red",
            fontWeight: 600,
            backgroundColor: "rgb(255 0 0 / 10%)",
          };
        } else if (params.value === "Medium") {
          return {
            color: "orange",
            fontWeight: 600,
            backgroundColor: "rgb(255 165 0 / 10%)",
          };
        } else {
          return {
            fontWeight: 600,
            backgroundColor: "rgb(255 255 0 / 23%)",
          };
        }
      },
    },
  ];

  useEffect(() => {
    const otherItem = document.getElementById("GISSidebarBodyLeaflet");

    if (otherItem) {
      const width = otherItem.offsetWidth;

      const component = document.getElementById("ReportTableL");

      if (component) {
        component.style.width = `calc(100vw - ${width}px)`;
      }
    }
  }, [isSidebaropen, openShapes, openLayers]);

  // HMEL_DSM_13-04-2024_364.8_TO_383.9_COG
  function formatAssetName(inputString) {
    // Extracting the relevant parts
    let parts = inputString.split("_");
    let startChainage = parts[3];
    let endChainage = parts[5];
    let type = parts[1];

    // Creating the formatted string
    let formattedString = `${type} | ${startChainage}km to ${endChainage}km`;
    return formattedString;
  }

  const handleAssetFilter = (e) => {
    const selectedValue = e.target.value;
    const selectedStation = svStationsJson.find(
      (station) => station.id === selectedValue,
    );

    if (
      !selectedStation ||
      (!selectedStation && !selectedStation.old_assets.length)
    ) {
      setAssetFilter(null);
      setAssetQuery(null);
    } else {
      let assetsToSearch = selectedStation.old_assets
        ? selectedStation.old_assets
        : [];

      assetsToSearch.push(selectedStation.name);
      setAssetQuery(assetsToSearch);
      setAssetFilter(selectedValue);
    }
  };

  // Example usage:// Output: "13-04-2024 to 383.9 | DSM"

  return (
    <>
      <div
        className={`absolute z-50 ${
          bottomReportToggle ? "bottom-0" : "bottom-6"
        } left-[100%]`}
        id="ReportTableL"
      >
        {showReport && (
          <div id="chart" className="absolute z-[1] h-[300px] w-[500px] px-2">
            <ReactApexChart
              options={lineChartOptions}
              series={series}
              ref={chartRef}
              type="line"
              height="100%"
              width="100%"
            />
            <ReactApexChart
              options={barChartOptions}
              series={seriesBar}
              ref={chartRefBar}
              type="bar"
              height="100%"
              width="100%"
            />
          </div>
        )}

        {bottomReportToggle && (
          <>
            <div className="relative z-20 flex h-auto flex-col justify-between border border-l-gray-300 bg-white">
              <div className="actionBarOfReportTableL flex h-full flex-col">
                <div className="flex h-[8vh] items-center justify-between px-2">
                  <div className="flex h-8 items-center">
                    <h1 className="border-b-4 border-b-yellow-400 font-bold">
                      Detailed Reports
                    </h1>
                  </div>
                  {/* filter options  */}
                  <div className="flex gap-2 overflow-hidden py-2 pl-2">
                    <select
                      className="min-h-[1.5rem] rounded-md border border-gray-400 text-sm"
                      value={assetFilter}
                      onChange={handleAssetFilter}
                    >
                      <option value={"All"}>All Timelines</option>
                      {svStationsJson?.map((svStation, index) => (
                        <option key={index} value={svStation.id}>
                          {/* {getDate(groupData.date)} */}
                          {svStation.name}
                        </option>
                      ))}
                    </select>

                    <select
                      className="min-h-[1.5rem] rounded-md border border-gray-400 text-sm"
                      value={categoryFilter}
                      onChange={(e) =>
                        e.target.value === "All"
                          ? setCategoryFilter(null)
                          : setCategoryFilter(e.target.value)
                      }
                    >
                      <option key="all" value="All">
                        All Categories
                      </option>
                      {observationCategories.map((category, index) =>
                        category === "Categories" ? (
                          <option key={index} value="All">
                            All Categories
                          </option>
                        ) : (
                          <option key={index} value={category}>
                            {category}
                          </option>
                        ),
                      )}
                    </select>

                    <select
                      className="m-h-[1.5rem] rounded-md border-[0.5px] border-gray-400 text-sm"
                      value={severityFilter}
                      onChange={(e) => {
                        e.target.value === "All"
                          ? setSeverityFilter(null)
                          : setSeverityFilter(e.target.value);
                      }}
                    >
                      <option value="All">All Severity</option>
                      {severities.map((severity, index) => (
                        <option key={index} value={severity}>
                          {severity}
                        </option>
                      ))}
                    </select>
                    <div className="searchBarBottomReports rounded-md">
                      <input
                        type="text"
                        name="searchBar"
                        placeholder="Search Id"
                        value={searchQuery}
                        onChange={handleInputChange}
                        className="rounded-md focus:border focus:border-b-2"
                      />

                      {/* <button onClick={handleSearch}>Search</button> */}
                    </div>
                  </div>
                </div>
                <div className="reportTableContainer grow-1 flex flex-col justify-between">
                  <div className="max-h-[44vh] min-h-[24vh] border-b px-2">
                    {status === "success" && filteredData.length > 0 ? (
                      <>
                        <Suspense
                          fallback={
                            <p className="mt-12 text-center">
                              <CircularProgress />
                            </p>
                          }
                        >
                          <div className={"ag-theme-quartz h-[35vh]"}>
                            <AgGridReact
                              ref={gridRef}
                              rowData={filteredData ?? []}
                              columnDefs={columnsOfDiff}
                              defaultColDef={{ flex: 1 }}
                              autoGroupColumnDef={autoGroupColumnDef}
                              sideBar={sideBar}
                              rowGroupPanelShow={"always"}
                              onRowDoubleClicked={(row) => {
                                const idToCopy = row.data.id.split("-")[0];
                                navigator.clipboard
                                  .writeText(idToCopy)
                                  .then(() => {
                                    toast.success(
                                      `Id ${idToCopy} copied successfully  `,
                                    );
                                  })
                                  .catch((err) => {
                                    console.error(
                                      "Error copying ID to clipboard:",
                                      err,
                                    );
                                  });
                              }}
                              onRowClicked={(row) => {
                                const bounds = L.latLngBounds([
                                  row.data.markerPosition,
                                ]);

                                map?.flyToBounds(bounds, {
                                  duration: 2,
                                  easeLinearity: 0.5,
                                  minZoom: 15,

                                  maxZoom: 19,
                                });
                              }}
                            />
                          </div>
                        </Suspense>
                      </>
                    ) : (
                      filteredData.length === 0 &&
                      asset && (
                        <p className="mt-12 text-center">
                          No Annotations with Selected Filters
                        </p>
                      )
                    )}
                  </div>
                  <div className="reportTableBottomBox bottom-0 my-2 flex w-full items-start justify-between px-2">
                    <div className="flex">
                      <p className="mr-4 flex items-center gap-2 px-1 text-center text-sm">
                        <span className="flex items-center">
                          <img
                            src={allIco}
                            className="h-5"
                            alt="All items icon of report table"
                          />
                          <p className="font-semibold">Total Count</p>
                        </span>
                        <span className="min-h-4 min-w-4 rounded-sm bg-green-300 px-1 py-[2px] font-semibold">
                          {filteredData?.length}
                        </span>
                      </p>
                      {isSelectableReportCheckbox ? (
                        <p className="mr-4 flex items-center gap-2 px-1 text-center text-sm">
                          <span className="flex items-center">
                            <img
                              src={checkBlackIco}
                              className="h-5"
                              alt="All items icon of report table"
                            />

                            <p className="font-semibold">Selected</p>
                          </span>
                          <span className="rounded-sm bg-blue-300 px-1 py-[2px] font-extrabold">
                            {selectedItems?.length}
                          </span>
                        </p>
                      ) : null}
                    </div>

                    <div
                      className="bottomButtons flex items-center"
                      onClick={() => handleReportClick()}
                    >
                      {/* <Tooltip
                          title="Select"
                          placement="top"
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          arrow
                        >
                          <button
                            className="mr-2 p-1 bg-blue-400 hover:bg-blue-500 active:bg-blue-700 text-white rounded-full text-sm "
                            onClick={() => {
                              setIsSelectableReportCheckbox(
                                !isSelectableReportCheckbox
                              );
                            }}
                          >
                            <img
                              src={checkIco}
                              alt="check icon"
                              className="h-4"
                              srcSet=""
                            />
                          </button>
                        </Tooltip> */}

                      {userPermissions.includes("export_reports") && (
                        <Tooltip
                          title="Export Report"
                          placement="top"
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          arrow
                        >
                          <button className="flex items-center justify-end gap-1 rounded-full bg-green-400 px-2 py-1 text-sm text-white hover:bg-green-500 active:bg-green-700">
                            <img
                              src={exportIco}
                              alt="export icon"
                              className="h-4"
                              srcSet=""
                            />{" "}
                            <span className="text-black hover:tracking-wide">
                              Export
                            </span>
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ReportTableL;
