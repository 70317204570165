import { Popper, TextField } from "@mui/material";
import axios from "axios";
import React from "react";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const Client = () => {
  const [clients, setClients] = React.useState([]);
  const [reloader, setReloader] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [clientName, setClientName] = React.useState(null);
  const [selectedClient, setSelectedClient] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    const getClients = async () => {
      const response = await axios.get(
        "https://delivery.inspect.indrones.com/api/v1/client/get_all_clients",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("response", response.data);
      setClients(response.data);
    };
    getClients();
  }, [reloader]);

  const updateReloader = () => {
    setReloader(reloader + 1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClient = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleEdit = (index, name) => {
    setSelectedClient((prevIndex) => (prevIndex === index ? null : index));
    setClientName(name);
    setEditMode(true);
  };

  const addClient = async (event) => {
    event.preventDefault();
    const name = event.target.client_name.value;

    try {
      await axios.post(
        "https://delivery.inspect.indrones.com/api/v1/client/create",
        {
          name,
        }
      );
      // console.log("add cli res", response);
      handleClose();
      updateReloader();
    } catch (error) {
      console.error("err", error);
    }
  };

  const handleSave = async (id) => {
    try {
      await axios.post(
        `https://delivery.inspect.indrones.com/api/v1/client/edit/${id}`,
        {
          name: clientName,
        }
      );
      // console.log("edit cli res", response);
      updateReloader();
      setEditMode(false);
    } catch (error) {
      console.error("err", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.post(
        `https://delivery.inspect.indrones.com/api/v1/client/delete/${id}`,
        {}
      );
      // console.log("edit cli res", response);
      updateReloader();
    } catch (error) {
      console.error("err", error);
    }
  };

  return (
    <>
      <div
        onClick={handleClient}
        className="cursor-pointer w-max ml-auto mr-5 !rounded-[5px] bg-[#4a4844] text-white  px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
      >
        Add Client
      </div>
      <div
        className={`h-[calc(100%-340px)] cursor-default m-4 overflow-y-auto overflow-x-auto custom-scrollbar`}
      >
        <table className="table w-full border-[1px] border-solid border-[#e5e7eb]">
          <thead className="bg-[#4a4844] text-[#fff] sticky top-0">
            <tr>
              <th className="px-2 py-3 font-[500]">Sr No</th>
              <th className="px-2 py-3 font-[500]">Name</th>
              <th className="px-2 py-3 font-[500]">Actions</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {clients.map((client, index) => {
              const isSelected = selectedClient === index;
              return (
                <tr key={index}>
                  <td className="px-2 py-3">{index + 1}</td>
                  <td className="px-2 py-3">
                    {editMode && isSelected ? (
                      <>
                        <TextField
                          name="textNote"
                          size="small"
                          placeholder="in cubic meter"
                          value={clientName}
                          onChange={(e) => setClientName(e.target.value)}
                          className="grow"
                        />
                      </>
                    ) : (
                      client.name
                    )}
                  </td>
                  <td className="px-2 py-3">
                    <div className="flex items-center justify-center gap-3">
                      <EditIcon
                        onClick={() => handleEdit(index, client.name)}
                      />
                      <DeleteIcon onClick={() => handleDelete(client.id)} />
                      {editMode && (
                        <SaveIcon onClick={() => handleSave(client.id)} />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        placement="right"
        className="z-[9999] !ml-2"
      >
        <div className="bg-white rounded-[12px] border flex flex-col h-min">
          <form
            className="grow mt-5 flex flex-col justify-between m-2"
            onSubmit={addClient}
          >
            <div className="flex flex-col items-center w-[100%] h-full">
              <div className="flex items-center mt-2">
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="Client Name"
                  name="client_name"
                  variant="outlined"
                  required
                />
              </div>
            </div>
            <div className="m-auto my-2">
              <button
                aria-label="add"
                className="!rounded-[5px] bg-[#4a4844] text-white  px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
                type="submit"
              >
                Add
              </button>
            </div>
          </form>
        </div>
      </Popper>
    </>
  );
};

export default Client;
