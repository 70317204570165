export const hmelIndexing = [
  // {
  //   id: "0FC6B54F973078D15AEC",
  //   name: "DT",
  //   chainage: {
  //     startChainage: 0,
  //     endChainage: 15.095,
  //   },
  //   distanceFromPrevious: 0,
  //   village: "Mundra",
  //   taluka: "Mundra",
  //   district: "Kutch",
  //   state: "GUJARAT",
  //   coordinates: [69.74915479019967, 22.817227531460126, 0],
  //   old_assets: [],
  // },
  // {
  //   id: "0732FC4BB03078D15AED",
  //   name: "SV-1",
  //   chainage: {
  //     startChainage: 15.095,
  //     endChainage: 35.289,
  //   },
  //   distanceFromPrevious: 15.095,
  //   village: "Mundra",
  //   taluka: "Mundra",
  //   district: "Kutch",
  //   state: "GUJARAT",
  //   coordinates: [69.77733333839498, 22.89586913717253, 0],
  //   old_assets: [],
  // },
  {
    id: "0961D2FC033078D15C23",
    name: "SV-2",
    chainage: {
      startChainage: 35.289,
      endChainage: 65.797,
    },
    distanceFromPrevious: 20.194,
    village: "Gundala",
    taluka: "Anjar",
    district: "Kutch",
    state: "GUJARAT",
    coordinates: [69.90099204683857, 23.03481672105442, 0],
    old_assets: [],
  },
  // {
  //   id: "1EB87E7E253078D15C23",
  //   name: "SV-3",
  //   chainage: {
  //     startChainage: 65.797,
  //     endChainage: 93.737,
  //   },
  //   distanceFromPrevious: 30.508,
  //   village: "Bhimasar",
  //   taluka: "Anjar",
  //   district: "Kutch",
  //   state: "GUJARAT",
  //   coordinates: [70.12980616114973, 23.19090395006687, 0],
  //   old_assets: [
  //     "HMEL_ORTHO_13-04-2024_71.1_TO_78.8_COG",
  //     "HMEL_ORTHO_14-04-2024_78.8_TO_97.2_COG",
  //   ],
  // },
];

export const agpIndexing = [
  {
    section: {
      title: "13-03-2022",
    },
    assets: [
      { layerName: "AGP_Group_13_03_2022_COG", type: "ORI" },
      { layerName: "AGP_Group_13_03_2022_COG", type: "DSM" },
    ],
  },
  {
    section: {
      title: "25-06-2022",
    },
    assets: [
      { layerName: "AGP_Group_25_06_2022_COG", type: "ORI" },
      { layerName: "AGP_Group_25_06_2022_COG", type: "DSM" },
    ],
  },
];

export const aksharIndexing = [
  {
    section: {
      title: "13-03-2022",
    },
    assets: [{ layerName: "AKSHAR_BUSINESS_PARK_ORTHO_COG", type: "ORI" }],
  },
  {
    section: {
      title: "25-06-2022",
    },
    assets: [{ layerName: "AKSHAR_BUSINESS_PARK_DSM_COG", type: "ORI" }],
  },
];

export const agpIndexinasd = [
  { layerName: "AGP_Group_23_03_2022_COG", layerType: "ORI" },
  { layerName: "AGP_Group_23_03_2022_COG", layerType: "DSM" },
  { layerName: "AGP_Group_19_04_2022_COG", layerType: "ORI" },
  { layerName: "AGP_Group_19_04_2022_COG", layerType: "DSM" },
  { layerName: "AGP_Group_13_04_2022_COG", layerType: "ORI" },
];

export const esselMining = [
  {
    section: {
      title: "13-03-2022",
    },
    assets: [{ layerName: "AKSHAR_BUSINESS_PARK_ORTHO_COG", type: "ORI" }],
  },
  {
    section: {
      title: "25-06-2022",
    },
    assets: [{ layerName: "AKSHAR_BUSINESS_PARK_DSM_COG", type: "ORI" }],
  },
];
