import React, { useState } from "react";
// import "../../styles/video-canvas-respo.css";
import Loader from "@mui/material/CircularProgress";
import Hls from "hls.js";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useParams } from "react-router-dom";

const VideoPlayer = (props) => {
  const canvasRef = React.useRef(null);
  const hlsRef = React.useRef(null);
  const videoRef = props.videoRef;
  const videoSrc = useSelector((state) => state.project.videoSrc);
  const { currentLevel, getQualityLevels } = props;
  const level = currentLevel;
  const [isOpen, setIsOpen] = React.useState(false);

  const [zoomLevel, setZoomLevel] = React.useState(1);
  const [xOffset, setXOffset] = React.useState(0);
  const [yOffset, setYOffset] = React.useState(0);

  const loopMode = useSelector((state) => state.mapToggle.loopMode);
  const loopChainge = useSelector((state) => state.loop);

  const [date, setDate] = React.useState("");
  const [showDate, setShowDate] = React.useState(false);
  const { project } = useParams();

  React.useEffect(() => {
    if (project === "24") {
      setDate("24/05/2024 06:03:53 PM");
      setShowDate(true);
    } else if (project === "25") {
      setDate("25/06/2024 05:15:13 PM");
      setShowDate(true);
    } else {
      setDate("");
      setShowDate(false);
    }
  }, [project]);

  const increaseZoomLevel = () => {
    setZoomLevel((prev) => prev + 0.5);
  };

  const decreaseZoomLevel = () => {
    setZoomLevel((prev) => Math.max(prev - 0.5, 1));
  };

  const resetZoomSettings = () => {
    setZoomLevel(1);
    setXOffset(0);
    setYOffset(0);
  };

  const increaseOffsetX = () => {
    if (zoomLevel !== 1) {
      setXOffset((prev) => prev + 20);
    }
  };

  const decreaseOffsetX = () => {
    if (zoomLevel !== 1) {
      setXOffset((prev) => prev - 20);
    }
  };

  const increaseOffsetY = () => {
    if (zoomLevel !== 1) {
      setYOffset((prev) => prev + 20);
    }
  };

  const decreaseOffsetY = () => {
    if (zoomLevel !== 1) {
      setYOffset((prev) => prev - 20);
    }
  };

  React.useEffect(() => {
    let hls;

    if (videoRef.current) {
      const video = videoRef.current;

      if (Hls.isSupported() && !hlsRef.current) {
        hls = new Hls();
        hls.loadSource(videoSrc);
        hls.attachMedia(video);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          const levels = hls.levels;
          getQualityLevels(levels);
        });

        hlsRef.current = hls;
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = videoSrc;
      }
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }
    };
  }, [videoSrc, videoRef]);

  React.useEffect(() => {
    if (hlsRef.current) {
      hlsRef.current.currentLevel = level;
    }
  }, [level]);

  let animationFrameId;
  const renderFrame = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      const context = canvas.getContext("2d");
      const aspectRatio = video.videoWidth / video.videoHeight;

      const canvasWidth = canvas.width;
      const canvasHeight = canvasWidth / aspectRatio;

      const offsetX = (canvas.width - canvasWidth * zoomLevel) / 2;
      const offsetY = (canvas.height - canvasHeight * zoomLevel) / 2;

      context.drawImage(
        video,
        offsetX + xOffset,
        offsetY + yOffset,
        canvasWidth * zoomLevel,
        canvasHeight * zoomLevel,
      );

      animationFrameId = requestAnimationFrame(renderFrame);
    }
  };

  React.useEffect(() => {
    const canvas = canvasRef.current;

    const videoCanPlayHandler = () => {
      if (videoRef) {
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        renderFrame();
      }
    };

    videoRef.current.addEventListener("canplay", videoCanPlayHandler);

    return () => {
      cancelAnimationFrame(animationFrameId);
      if (videoRef.current) {
        videoRef.current.removeEventListener("canplay", videoCanPlayHandler);
      }
    };
  }, [videoRef]);

  React.useEffect(() => {
    renderFrame();
  }, [zoomLevel, xOffset, yOffset]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="parent-canvas-video-respo relative ml-2 mr-2 flex w-[67%] items-center border-l bg-gray-200 max-[700px]:w-[100%] min-[700px]:h-full">
      {props.showLoader && (
        <div className="absolute top-0 z-[1] flex h-full w-full items-center justify-center bg-black">
          <Loader className="!h-[60px] !w-[60px]" />
        </div>
      )}
      <div className="absolute bottom-6 right-6 z-[999] flex flex-col text-white">
        <div className="speed-dial-container">
          <button className="speed-dial-button" onClick={toggleOpen}>
            {isOpen ? (
              <CloseRoundedIcon className="!text-[#fff]" />
            ) : (
              <SettingsIcon className="!text-[#fff]" />
            )}
          </button>
          {isOpen && (
            <>
              <div className="speed-dial-options_2">
                <div className="flex items-center justify-center">
                  <Tooltip title="Reset" placement="top" arrow>
                    <div
                      className="speed-dial-option"
                      aria-label="Reset Zoom Settings"
                      onClick={resetZoomSettings}
                    >
                      <RefreshIcon />
                    </div>
                  </Tooltip>
                </div>
                <div className="flex flex-row items-center justify-center gap-[8px]">
                  <Tooltip title="Increase Zoom" placement="top" arrow>
                    <div
                      className="speed-dial-option"
                      aria-label="Increase Zoom"
                      onClick={increaseZoomLevel}
                    >
                      <AddRoundedIcon />
                    </div>
                  </Tooltip>
                  <Tooltip title="Decrease Zoom" placement="top" arrow>
                    <div
                      className="speed-dial-option"
                      aria-label="Decrease Zoom"
                      onClick={decreaseZoomLevel}
                    >
                      <RemoveRoundedIcon />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="speed-dial-options">
                <div className="flex flex-row items-center justify-center">
                  <Tooltip title="Move Left" placement="left" arrow>
                    <div
                      className="speed-dial-option"
                      aria-label="Move Left"
                      onClick={increaseOffsetX}
                    >
                      <ArrowCircleLeftIcon />
                    </div>
                  </Tooltip>
                  <div className="flex flex-col items-center justify-center gap-[8px]">
                    <Tooltip title="Move Up" placement="top" arrow>
                      <div
                        className="speed-dial-option"
                        aria-label="Move Up"
                        onClick={increaseOffsetY}
                      >
                        <ArrowCircleUpIcon />
                      </div>
                    </Tooltip>
                    <Tooltip title="Move Down" placement="bottom" arrow>
                      <div
                        className="speed-dial-option"
                        aria-label="Move Down"
                        onClick={decreaseOffsetY}
                      >
                        <ArrowCircleDownIcon />
                      </div>
                    </Tooltip>
                  </div>
                  <Tooltip title="Move Right" placement="right" arrow>
                    <div
                      className="speed-dial-option"
                      aria-label="Move Right"
                      onClick={decreaseOffsetX}
                    >
                      <ArrowCircleRightIcon />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {loopMode && (
        <div className="absolute bottom-0 left-0 z-[999] bg-black/50 p-2 text-white">
          {`Looping From ${loopChainge.chainage.start_chainage} Km to ${loopChainge.chainage.end_chainage} Km`}
        </div>
      )}

      {showDate && (
        <>
          <div className="absolute left-0 top-0 z-[999] bg-black/50 p-2 text-white">
            {date}
          </div>
        </>
      )}

      <div className="h-full w-full">
        <video
          onLoadedMetadata={props.handleDuration}
          onTimeUpdate={props.handleTimeUpdate}
          ref={videoRef}
          muted
          autoPlay
          onClick={props.handlePlayPause}
          className="hidden h-full w-full"
          style={{
            objectFit: "fill",
          }}
        />
        <canvas
          ref={canvasRef}
          className="canvas-video-respo h-[100%] w-[100%]"
          onClick={props.handlePlayPause}
          style={{ transform: "translateZ(0)", objectFit: "fill" }}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
