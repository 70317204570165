import L from "leaflet";
/**
 *This function handles activation Leaflet draw event triggered from toolbar
 * @param {function} disableAllTools
 * @param {function} drawMarker
 * @param {function} drawPolygon
 * @param {function} drawPolyline
 * @param {string} selectedTool
 */
 export const handleToolClick = (
   disableAllTools,
   drawMarker,
   drawPolygon,
   drawPolyline,
   drawLineMeasure,
   selectedTool
) => {
  disableAllTools(drawMarker, drawPolygon, drawPolyline);
  if (selectedTool === "marker") {
    drawMarker.enable();
  } else if (selectedTool === "polygon" || selectedTool === "polygonMeasure") {
    drawPolygon.enable();
  } else if (selectedTool === "polyline" || selectedTool === "polylineMeasure") {
    L.Draw.Polyline.prototype._onMouseUp = function (e) {
      var originalEvent = e.originalEvent;
      if (originalEvent.button === 2) {
        // Check if the event was triggered by a right-click
        this.deleteLastVertex(); // Call the function to delete the last vertex
        // Prevent the default right-click behavior
        L.DomEvent.preventDefault(originalEvent);
      } else {
        var clientX = originalEvent.clientX;
        var clientY = originalEvent.clientY;
        this._endPoint.call(this, clientX, clientY, e);
      }
      this._clickHandled = null;
    };
    drawPolyline.enable();
  } else if (selectedTool === "lineMeasure") {
    drawLineMeasure.enable();
  } else {
    console.error("Invalid shape type.");
  }
};
