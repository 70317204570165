// src/theme.js
import { createTheme } from '@mui/material/styles';

const muiTheme = createTheme({
  typography: {
    fontFamily: '"Nunito", sans-serif !important',
    h1: {
      fontFamily: '"Nunito", sans-serif !important',
    },
    h2: {
      fontFamily: '"Nunito", sans-serif !important',
    },
    body1: {
      fontFamily: '"Nunito", sans-serif !important',
    },
    // Add other typography variants here if needed
  },
  // Optionally, apply the font family to all Mui components
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: '"Nunito", sans-serif !important',
        },
      },
    },
  },
});

export default muiTheme;
