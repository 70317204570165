import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  settingsIcon,
  closeIco,
  logoutIco,
} from "../../Assets/icons/gisIcons/icons";
import { removeAllCookies } from "../../Utils/Cookie";
import { useNavigate } from "react-router";
const ProfileModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [initial, setInitial] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const firstName = Cookies.get("userFirstName");
    const lastName = Cookies.get("userLastName");
    const email = Cookies.get("userEmail");

    if (firstName) {
      setInitial(firstName.charAt(0).toUpperCase());
      setFullName(`${firstName} ${lastName}`);
    }

    if (email) {
      setEmail(email);
    }
  }, []);

  const handleLogout = () => {
    removeAllCookies();
    navigate("/");
    // cookieValue === "infra" ? navigate("/AGPLogin") : navigate("/");
  };
  const handleAdminClick = () => {
    navigate("/setting");
  };

  return (
    <div
      className={` ${isOpen ? "flex" : "hidden"} fixed inset-0 z-[101] flex items-center justify-center`}
      onClick={onClose}
    >
      <div
        className="outline-solid-transparent absolute right-4 top-16 !z-[110] flex h-[322px] w-[451px] cursor-default flex-col items-center rounded-[28px] bg-[#e9eef6] px-2 py-4 text-xs shadow-md"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-row">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 flex h-8 w-8 items-center justify-center overflow-hidden rounded-full transition duration-300 ease-in-out hover:bg-gray-300"
          >
            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white">
              <img
                src={closeIco}
                alt="Close"
                className="h-4 w-4 invert-[100%] filter transition duration-300 ease-in-out"
              />
            </div>
          </button>

          <div className="mt-1 text-sm font-semibold">{email}</div>
        </div>
        <div className="mt-6 flex h-[80px] w-[80px] items-center justify-center rounded-full bg-[#ffca00] text-[#4a4844]">
          <div className="text-5xl font-semibold">{initial}</div>
        </div>
        <div className="mt-4 text-xl font-medium">Hi, {fullName}!</div>
        <div className="relative mt-8 flex w-[370px] flex-row gap-1">
          <button
            className="group relative flex h-[60px] w-1/2 items-center justify-center rounded-l-[40px] bg-white hover:bg-gray-100"
            onClick={() => handleAdminClick()}
          >
            <div className="relative flex items-center justify-center">
              <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white transition-colors duration-300 group-hover:bg-[#ffe06b]">
                <img
                  src={settingsIcon}
                  alt="Settings"
                  className="h-[20px] w-[20px] invert-[100%] filter"
                />
              </div>
            </div>
            <div className="ml-2 text-base font-semibold">Settings</div>
          </button>
          <button
            className="group relative flex h-[60px] w-1/2 items-center justify-center rounded-r-[40px] bg-white hover:bg-gray-100"
            onClick={() => handleLogout()}
          >
            <div className="relative flex items-center justify-center">
              <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white transition-colors duration-300 group-hover:bg-[#ffe06b]">
                <img
                  src={logoutIco}
                  alt="Logout"
                  className="h-[20px] w-[20px]"
                />
              </div>
            </div>
            <div className="ml-2 text-base font-semibold">Logout</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;
