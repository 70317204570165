/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchData, storeData } from "../../Utils/indexedDB";
import Cookies from "js-cookie";
import L from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "../../Assets/styles/GISStyle.css";
import GISLeaflet from "../../Components/GIS/Leaflet/GISLeaflet";
import GISSidebarLeaflet from "../../Components/GIS/Leaflet/GISSidebarLeaflet";
import { DynamoDBApisL } from "../../Services/ThirdPartyApi/AWS/DynamoDBL";
import { tokenChecker } from "../../Utils/Cookie";
import { gisHomeActions } from "../../redux/slices/GIS/gis-home-slice";
import { gisLeafletActions } from "../../redux/slices/GIS/gis-leaflet-slice";
import { gisActions } from "../../redux/slices/GIS/gis-slice";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import {
  satelliteIcon,
  BaseLayerIcon,
  GoogleSateliteMapIcon,
  GoogleStreetMapIcon,
  OpenTopoMapIcon,
} from "../../Assets/icons/gisIcons/icons";
import { Toaster } from "react-hot-toast";
import { projectActions } from "../../redux/slices/project-slice";
const GISHomeLeaflet = () => {
  const user_permissions = Cookies.get("user_permissions")?.split(",");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { asset } = useSelector((state) => state.gisLeaflet);
  const { refCounter } = useSelector((state) => state.gis);
  const queryClient = useQueryClient();
  const [mapInitialized, setMapInitialized] = useState(false);
  const [baseLayer, setBaseLayer] = useState("GoogleStreetMap");
  const [maxZoomLimit, seMaxZoomLimit] = useState(false);
  const [projectData, setProjectData] = useState(null);
  // const { grp: clientName } = useParams();

  useEffect(() => {
    const map = L.map("map").setView([23.1667, 80.2167], 5);

    mapRef.current = map;
    googleMapLayer.addTo(mapRef.current);
    setMapInitialized(true);
    setBaseLayer("GoogleStreetMap");
    return () => {
      map.remove();
    };
  }, []);

  useEffect(() => {
    dispatch(gisHomeActions.setShapesIds([]));
    dispatch(gisActions.setUserPermissions(user_permissions));
    document.title = "Inspect";
  }, []);

  useEffect(() => {
    tokenChecker(navigate);
  }, [navigate]);

  const mapRef = useRef(null);

  const googleMapLayer = L.tileLayer(
    "https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
    {
      maxZoom: 20,
      subdomains: ["mt0", "mt1", "mt2", "mt3"],
      name: "baselayer",
    },
  );

  const googleSatelliteLayer = L.tileLayer(
    "https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}",
    {
      attribution: "Google Maps Satellite",
      maxNativeZoom: 20,
      maxZoom: 20,
      name: "baselayer",
    },
  );

  const openTopoMapLayer = L.tileLayer(
    "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
    {
      maxZoom: 20,
      name: "baselayer",
    },
  );

  const openstreetmap = L.tileLayer(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    {
      maxZoom: 20,
      name: "baselayer",
    },
  );

  const handleBaseLayerChange = (layerName) => {
    setBaseLayer(layerName);
    let selectedLayer;
    switch (layerName) {
      case "GoogleStreetMap":
        selectedLayer = googleMapLayer;
        break;
      case "GoogleSatellite":
        selectedLayer = googleSatelliteLayer;
        break;
      case "OpenTopoMap":
        selectedLayer = openTopoMapLayer;
        break;
      case "OpenStreetMap":
        selectedLayer = openstreetmap;
        break;
      default:
        selectedLayer = googleMapLayer;
    }

    // Remove existing Baselayer
    mapRef.current.eachLayer((layer) => {
      if (layer.options.name === "baselayer") {
        mapRef.current.removeLayer(layer);
      }
    });
    // Add the selected layer to the map
    selectedLayer.addTo(mapRef.current);
    // Close the expanded options after selecting a layer
  };

  const fetchAssetRelatedObservations = async (id) => {
    // console.log(id, "id");
    const retrievedData = await DynamoDBApisL.getDataByID(id);
    if (retrievedData === undefined) {
      throw new Error("Data retrieval failed or returned undefined");
    }
    return retrievedData;
  };

  const { data, status } = useQuery({
    queryKey: asset?.Name ? ["assetRelatedObservations", asset.Name] : null,
    queryFn: async () => {
      if (asset?.Name) {
        return await fetchAssetRelatedObservations(asset.Name);
      }
      return [];
    },
    enabled: !!asset?.Name,
  });

  useEffect(() => {
    if (asset) {
      // Invalidate queries to ensure data freshness
      queryClient.invalidateQueries({
        queryKey: ["assetRelatedObservations", asset?.Name],
      });

      // Update observations bucket if data is available
      if (data) {
        dispatch(gisLeafletActions.setObservationsBucket(data));
        const modifiedObservations = data.map((observation) => {
          return {
            id: observation.id,
            visible: observation.visible,
          };
        });

        dispatch(
          gisLeafletActions.setObservationsVisibilityStatus(
            modifiedObservations,
          ),
        );
      }
    }
  }, [asset, data, refCounter]);

  useEffect(() => {
    const handleZoomChange = () => {
      if (mapRef.current.getZoom() >= 24) {
        seMaxZoomLimit(true);
      } else {
        seMaxZoomLimit(false);
      }
    };
    mapRef.current.on("zoomend", handleZoomChange);
    // Cleanup function to remove the event listener
    return () => {
      mapRef.current.off("zoomend", handleZoomChange);
    };
  }, [mapRef.current]);

  useEffect(() => {
    const initializePreset = async () => {
      try {
        // Initialize preset if not already stored
        const preset = await fetchData("presets");
        if (preset === null) {
          await storeData("presets", {
            username: "",
            units: {
              length: "m",
              area: "m²",
            },
          });
        }
        dispatch(gisLeafletActions.refCounterUnitsUpdate());
      } catch (error) {
        console.error("Failed to initialize preset:", error);
      }
    };

    initializePreset();
  }, []);

  useEffect(() => {
    const initializeProjectData = async () => {
      try {
        const projectDataFromDB = await fetchData("project");
        setProjectData(projectDataFromDB);
        dispatch(projectActions.setProjectMeta(projectDataFromDB));
      } catch (error) {
        console.error("Failed to fetch project data:", error);
      }
    };

    initializeProjectData();
  }, []);

  return (
    <>
      <div className="baselayerPicker absolute right-2 top-1 z-[500]">
        <div className="relative z-[500] flex">
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            direction="left"
            sx={{
              "& .MuiSpeedDial-fab": {
                height: 40,
                width: 40,
                boxShadow: "none",
              },
            }}
            icon={
              <img
                className={`absolute left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2 transform-gpu rounded-[50%] border-2 border-[#303336] transition-transform duration-300 ease-in-out hover:scale-110 hover:border-[#48b]`}
                src={
                  baseLayer === "GoogleStreetMap"
                    ? GoogleStreetMapIcon
                    : baseLayer === "GoogleSatellite"
                      ? GoogleSateliteMapIcon
                      : baseLayer === "OpenTopoMap"
                        ? OpenTopoMapIcon
                        : BaseLayerIcon
                }
                alt=""
              />
            }
          >
            <SpeedDialAction
              key="Google Street Map"
              arrow
              tooltipTitle="Google Street Map"
              onClick={() => handleBaseLayerChange("GoogleStreetMap")}
              icon={
                <img
                  className={`absolute left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2 transform-gpu rounded-[50%] border-2 border-[#303336] transition-transform duration-300 ease-in-out hover:scale-110 hover:border-[#48b]`}
                  src={GoogleStreetMapIcon}
                  alt="Google Street Map"
                />
              }
              sx={{
                boxShadow: "none",
              }}
            ></SpeedDialAction>
            <SpeedDialAction
              key="Google Satellite"
              arrow
              tooltipTitle="Google Satellite"
              onClick={() => handleBaseLayerChange("GoogleSatellite")}
              icon={
                <img
                  className={`h-10 w-10 transform-gpu rounded-[50%] border-2 border-[#303336] transition-transform duration-300 ease-in-out hover:scale-110 hover:border-[#48b]`}
                  src={GoogleSateliteMapIcon}
                  alt="Google Satellite"
                />
              }
              sx={{
                boxShadow: "none",
              }}
            ></SpeedDialAction>
            <SpeedDialAction
              key="OpenTopoMap"
              arrow
              tooltipTitle="OpenTopoMap"
              onClick={() => handleBaseLayerChange("OpenTopoMap")}
              icon={
                <img
                  className={`h-10 w-10 transform-gpu rounded-[50%] border-2 border-[#303336] transition-transform duration-300 ease-in-out hover:scale-110 hover:border-[#48b]`}
                  src={OpenTopoMapIcon}
                  alt="OpenTopoMap"
                />
              }
              sx={{
                boxShadow: "none",
              }}
            ></SpeedDialAction>
          </SpeedDial>
        </div>
      </div>
      <div id="map" className="h-screen w-full"></div>
      {mapInitialized && (
        <div
          className={`duration-400 fixed inset-x-0 bottom-36 z-[500] flex items-center justify-center transition-opacity ${
            maxZoomLimit ? "opacity-100" : "pointer-events-none opacity-0"
          }`}
        >
          <div className="flex items-center rounded-[25px] bg-[rgba(20,26,34,.725)] px-3 py-2 text-center text-sm text-white shadow-sm shadow-[rgba(255,255,255,.7)]">
            <img
              className="mr-2 h-5"
              src={satelliteIcon}
              alt="satellite icon"
            />
            <span className="flex flex-1 items-center">
              Max Resolution Reached
            </span>
          </div>
        </div>
      )}
      {mapInitialized && projectData && (
        <GISLeaflet
          mapRef={mapRef}
          projectData={projectData}
          baseLayer={baseLayer}
        />
      )}
      {mapInitialized && <GISSidebarLeaflet mapRef={mapRef} />}
      <Toaster />
    </>
  );
};

export default GISHomeLeaflet;
