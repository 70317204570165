import { useDispatch, useSelector } from "react-redux";
import { addIco } from "../../../Assets/icons/gisIcons/icons";
import { gisLeafletActions } from "../../../redux/slices/GIS/gis-leaflet-slice";
import { svStationsJson } from "../../../Data/config/ProjectConfig";
import { useEffect, useState } from "react";
import axios from "axios";
import X2JS from "x2js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import toast, { Toaster } from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { LinearProgress } from "@mui/material";

const SVStationIndexing = ({ openAssetIndexing, clientName, mapRef }) => {
  const map = mapRef.current;
  const dispatch = useDispatch();
  const { currentStation, asset, selectAssetFromPreviousCurrentNextNav } =
    useSelector((state) => state.gisLeaflet);
  const { projectMeta } = useSelector((state) => state.projectMeta);

  const indexingData = projectMeta?.assetIndexing?.indexingFormat;
  const [assetData, setAssetData] = useState([]);
  const [layersByChainage, setLayersByChainage] = useState([]);
  const [expandedStationId, setExpandedStationId] = useState(null);
  const [flyToChainage, setFlyToChainage] = useState("");
  const [flagClickFromFlyToButton, setFlagClickFromFlyToButton] =
    useState(false);
  const [chainageForQue, setChainageForQue] = useState(null);

  const segregateAssets = (objectsArray) => {
    const Assets = [];
    objectsArray.forEach((obj) => {
      const dateMatch = obj.Name.match(/\d{2}_\d{2}_\d{4}/);
      if (dateMatch) {
        const date = dateMatch[0];
        const object = { date, ...obj };
        Assets.push(object);
      } else {
        Assets.push(obj);
      }
    });
    return Assets;
  };

  useEffect(() => {
    const geoServerClientReferenace = clientName.toLowerCase();
    const getCapabilities = async () => {
      const response = await axios.get(
        `https://gis.indrones.com/geoserver/${geoServerClientReferenace}/wms?request=GetCapabilities`,
      );
      const x2js = new X2JS();
      const jsonObj = x2js.xml2js(response.data);
      const layers = jsonObj.WMS_Capabilities.Capability.Layer.Layer;
      const groupedData = segregateAssets(layers);
      setAssetData([]);
      setAssetData(groupedData);
    };
    getCapabilities();
  }, []);

  /**
   * Sorts an array of strings representing chainage data by their starting chainages.
   * @param {string[]} arr - The array of chainage data strings to be sorted.
   * @returns {string[]} - The sorted array of chainage data strings.
   */
  function sortByStartChainage(arr) {
    return arr.sort((a, b) => {
      const startChainageA = parseFloat(a.split("_")[2]);
      const startChainageB = parseFloat(b.split("_")[2]);
      return startChainageA - startChainageB;
    });
  }

  const fetchLayersbetweenStartandEndChainage = async (currentStation) => {
    const reqBody = {
      start_chainage: currentStation?.chainage?.startChainage.toFixed(1) || 0,
      end_chainage: currentStation?.chainage?.endChainage.toFixed(1) || 0,
    };

    try {
      const res = await axios.post(
        "https://inspecthb.indrones.com/get_ori_between_chainage",
        reqBody,
      );

      const sortedLayersByChainage = sortByStartChainage(res.data.layers);

      return sortedLayersByChainage;
    } catch (error) {
      return error;
    }
  };

  const {
    data: layersbetweenStartandEndChainageData,
    error: layersbetweenStartandEndChainageError,
    status: layersbetweenStartandEndChainageStatus,
    refetch: layersbetweenStartandEndChainageRefetch,
  } = useQuery({
    queryKey: ["layersByChainage", currentStation],
    queryFn: () => fetchLayersbetweenStartandEndChainage(currentStation),
  });

  useEffect(() => {
    if (layersbetweenStartandEndChainageData?.length > 0) {
      setLayersByChainage(layersbetweenStartandEndChainageData);
      dispatch(
        gisLeafletActions.setLayersBetweenStation(
          layersbetweenStartandEndChainageData,
        ),
      );
    }

    if (layersbetweenStartandEndChainageError) {
      console.error(
        "Error fetching layers:",
        layersbetweenStartandEndChainageError,
      );
      dispatch(gisLeafletActions.setLayersBetweenStation([]));
      dispatch(gisLeafletActions.setObservationsBucket([]));
    }
  }, [layersbetweenStartandEndChainageData]);

  const setORILayers = (layerToSet, flyToLayerStartFlag) => {
    const assetToSet = assetData.find((layer) => layer.Name === layerToSet);
    dispatch(gisLeafletActions.setAsset(assetToSet));

    if (flyToLayerStartFlag === true) {
      const chainage = layerToSet.split("_")[2];
      if (chainage) {
        const chainageTypeNumber = parseFloat(chainage).toFixed(1);
        setChainageForQue(chainageTypeNumber);
      }
    }
  };

  useEffect(() => {
    setORILayers(selectAssetFromPreviousCurrentNextNav);
  }, [selectAssetFromPreviousCurrentNextNav]);

  const handleStationClick = (station, clickFrom, layer) => {
    if (currentStation?.id !== station?.id) {
      setLayersByChainage([]);
      dispatch(gisLeafletActions.setAsset(""));
      dispatch(gisLeafletActions.setCurrentStation(station));
      map.setView([station.coordinates[1], station.coordinates[0]], 16);
      setExpandedStationId(station.id);
    } else if (currentStation?.id === station?.id) {
      setExpandedStationId(station.id);
    } else {
      setExpandedStationId("");
    }

    if (clickFrom === "flyto") {
      setORILayers(layer);
      layersbetweenStartandEndChainageRefetch();
      setExpandedStationId(station.id);
      setFlagClickFromFlyToButton(false);
    }
  };

  const formatRangeInKmSegment = (str) => {
    const parts = str.split("_");
    const startValue = parts[2];
    const endValue = parts[4];
    return `${startValue}km to ${endValue}km`;
  };

  // getoribychainage
  const getOriByChainage = async (chainage) => {
    const reqBody = {
      chainage: parseFloat(chainage),
    };
    try {
      const response = await axios.post(
        "https://inspecthb.indrones.com/get_ori_by_chainage",
        reqBody,
      );

      return response.data;
    } catch (error) {
      throw error; // Re-throw for error handling in useQuery
    }
  };

  const { status: flyByChainageQueryStatus, data: flyByChainageQueryData } =
    useQuery({
      queryKey: ["getOriByChainage", chainageForQue],
      queryFn: () => getOriByChainage(chainageForQue),
      enabled: !!chainageForQue,
    });

  // useEffect(() => {
  //   if (asset?.Name && flyToLayerStartFlag) {
  //     const chainage = asset.Name.split("_")[2];
  //     if (chainage) {
  //       const chainageTypeNumber = parseFloat(chainage).toFixed(1);
  //       setChainageForQue(chainageTypeNumber);
  //       setFlyToLayerStartFlag(false);
  //     }
  //   }
  // }, [asset, flyToLayerStartFlag]);

  /**
   * this function finds the exact layer by checking where does the chainage lies
   *
   * @param {float} chainage
   * @param {arr[]} layerData
   * @returns  string
   */

  function findChainageLayer(chainage, layerData) {
    for (const layer of layerData) {
      try {
        const parts = layer.split("_");
        const start = parseFloat(parts[2]);
        const end = parseFloat(parts[4]);

        if (start <= chainage && chainage <= end) {
          return layer;
        }
      } catch (error) {
        console.error(`Invalid layer format: ${layer}`);
        continue;
      }
    }

    return null;
  }

  useEffect(() => {
    if (flyByChainageQueryData) {
      const station = flyByChainageQueryData.station;
      const filteredLayers = sortByStartChainage(flyByChainageQueryData.layers);
      const layerToAdd = findChainageLayer(chainageForQue, filteredLayers);
      // console.log(layerToAdd);
      if (flagClickFromFlyToButton) {
        handleStationClick(station, "flyto", layerToAdd);
      }
    }
    if (flyByChainageQueryData?.point?.coordinates.length > 0) {
      map.setView(
        [
          flyByChainageQueryData?.point?.coordinates[1],
          flyByChainageQueryData?.point?.coordinates[0],
        ],
        18,
      );
      setFlyToChainage("");
    }
  }, [flyByChainageQueryData]);

  const validateFlyToInputAndSet = (chainage) => {
    if (chainage < 0 || flyToChainage > 1018.9) {
      toast.error("Invalid Chainage");
      setFlyToChainage("");
    } else if (flyToChainage === chainageForQue) {
      // toast.custom("")
      setFlyToChainage("");
    } else {
      let cahinageToSet = parseFloat(flyToChainage).toFixed(1);
      setChainageForQue(cahinageToSet);
      setFlagClickFromFlyToButton(true);
    }
  };

  return (
    <div
      className={`${
        openAssetIndexing ? "block" : "hidden"
      } relative mb-2 ml-2 mr-2 mt-2 min-w-[18rem] !grow overflow-x-hidden rounded-tl-md rounded-tr-md border-[1px] border-solid border-[#d7d8d8] bg-[#fff]`}
    >
      <div className="flex w-full items-center rounded-tl-[1px] rounded-tr-[1px] bg-[#ffca0094] pb-[10px] pt-[10px] font-[500]">
        <div className="flex grow items-center justify-center">Sections</div>
      </div>
      <div class="flex items-center rounded-md p-1 shadow-inner">
        <input
          type="number"
          placeholder="Flyto chainage"
          class="flex-grow rounded-md border-none px-2 focus:outline-none"
          onChange={(e) => setFlyToChainage(e.target.value)}
          value={flyToChainage}
          step="0.1"
          min="0"
          max="1018.9"
        />
        <button
          class="ml-2 rounded-md bg-yellow-200 px-2 py-1 text-sm font-medium text-gray-700 hover:bg-yellow-300 focus:outline-none"
          onClick={() => {
            if (flyToChainage === "") {
              toast.error("Invalid Chainage");
            } else {
              validateFlyToInputAndSet(flyToChainage);
            }
          }}
        >
          Fly
        </button>
      </div>

      {flyByChainageQueryStatus === "pending" && !!chainageForQue && (
        <LinearProgress />
      )}

      <div className="stations-accordion">
        {indexingData?.map((station, index) => {
          const isExpanded = expandedStationId === station.id;

          return (
            <div
              key={station.id}
              className="station-item border-t-[1px] border-solid border-[#d7d8d8]"
            >
              <div
                className={`station-header flex cursor-pointer items-center justify-between px-3 py-2 ${
                  isExpanded ? "active" : ""
                } ${currentStation?.id === station?.id ? "bg-[#ecfc61]" : ""}`}
                onClick={() => {
                  handleStationClick(station, "indexing");
                }}
              >
                <p className="text-sm">
                  {station?.name}
                  <span className="ml-2 text-sm text-gray-400">{`(${station?.chainage?.startChainage.toFixed(
                    2,
                  )}km - ${station?.chainage?.endChainage.toFixed(
                    2,
                  )}km)`}</span>
                </p>
                <ExpandMoreIcon
                  className={`transition-transform duration-200 ${
                    isExpanded ? "rotate-180 transform" : ""
                  }`}
                />
              </div>
              {isExpanded && (
                <div className="station-content flex flex-col gap-2 overflow-x-hidden p-2">
                  {layersByChainage.length > 0 &&
                  layersbetweenStartandEndChainageStatus === "success" ? (
                    layersByChainage.map((layer) => (
                      <div
                        key={layer + "ORI_ITEM"}
                        className={`asset-item flex cursor-pointer items-center justify-between rounded-md px-4 py-2 hover:bg-[#ffea00b4] ${
                          layer === asset?.Name ? "bg-[#ecfc61]" : ""
                        }`}
                        onClick={() => {
                          setORILayers(layer, true);
                        }}
                      >
                        <p className="text-sm">
                          {formatRangeInKmSegment(layer)}
                        </p>
                        <img
                          src={addIco}
                          className="my-auto h-5 rounded-full border-2 border-[#808080]"
                          alt=""
                        />
                      </div>
                    ))
                  ) : layersbetweenStartandEndChainageStatus === "pending" ? (
                    <LinearProgress />
                  ) : (
                    <p className="text-center">No layers found.</p>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <Toaster />
    </div>
  );
};

export default SVStationIndexing;
