// import starts here
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Slider from "@mui/material/Slider";
import L from "leaflet";
import React, { useEffect, useId, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  moreIco,
  visibilityIco,
  visibilityOffIco,
} from "../../../Assets/icons/gisIcons/icons";
import { observationCategories } from "../../../Data/config/ProjectConfig";
import { AnnotationManager } from "../../../Services/ThirdPartyApi/AWS/DynamoDBL";
import {
  LayerControlUtils,
  Measure,
} from "../../../Utils/Leaflet/LeafletUtils";
import { gisLeafletActions } from "../../../redux/slices/GIS/gis-leaflet-slice";
import { gisActions } from "../../../redux/slices/GIS/gis-slice";
import { AnnotationReport } from "../AnnotationReport";
import { UIUtils } from "../../../Utils/Leaflet/UIUtils";
import { VariableSizeList as List } from "react-window";
import { Box, Fade, Popper } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { fetchData } from '../../../Utils/indexedDB';
// import ends here

const AnnotationsLeaflet = ({ openObservation, mapRef }) => {
  const layerControlUtils = new LayerControlUtils();
  const measure = new Measure();
  const uiutil = new UIUtils();
  const dispatch = useDispatch();
  const { refCounterUnits } = useSelector((state) => state.gisLeaflet);
  const { allObservations } = useSelector((state) => state.gis);
  const { observationsBucket, observationsVisibilityStatus } = useSelector(
    (state) => state.gisLeaflet,
  );
  const [categoryFilter, setCategoryFilter] = useState("");
  const [severityFilter, setSeverityFilter] = useState("");
  const [markerData, setMarkerData] = useState([]);
  const [finalFilteredAnnotations, setFinalFilteredAnnotations] =
    useState(observationsBucket);
  const severities = ["All", "Low", "Medium", "High"];
  const assetId = useSelector((state) => state.gisLeaflet.asset);
  const [assetFilter, setAssetFilter] = useState(null);
  const map = useMemo(() => {
    return mapRef.current;
  }, [mapRef.current]);
  const [expanded, setExpanded] = useState(false);
  const handleAccordionToggle = (panelIndex) => (event, isExpanded) => {
    setExpanded(isExpanded ? panelIndex : false);
  };
  const [storedPreset, setStoredPreset] = useState();
  const [editMode, setEditMode] = useState(false);
  const [showMeasureEditButtonsArray, setShowMeasureEditButtonsArray] =
    useState(markerData ? markerData?.map(() => false) : [false]);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [selectedLayerIndex, setSelectedLayerIndex] = useState(null);
  const editCtrl = useRef(null); // Ref to hold the editCtrl object
  const [segregatedAnnotations, setSegregatedAnnotations] = useState({});

  useEffect(() => {
    const getPreset = async () => {
      try {
        const fetchedPreset = await fetchData('presets');
        setStoredPreset(fetchedPreset);
      } catch (error) {
        console.error('Failed to fetch preset:', error);
      }
    };
    getPreset();
  }, [refCounterUnits]);

  useEffect(() => {
    if (assetId) {
      setAssetFilter(assetId);
    }
  }, [assetId]);

  useEffect(() => {
    setMarkerData(observationsBucket);
    setFinalFilteredAnnotations([]);
    setFinalFilteredAnnotations(observationsBucket);
  }, [observationsBucket]);

  function segregateAnnotationsByFilterType(annotations) {
    const segregatedAnnotations = {};

    annotations.forEach((annotation) => {
      const observationCategory = annotation.category;
      if (!segregatedAnnotations[observationCategory]) {
        segregatedAnnotations[observationCategory] = [];
      }
      segregatedAnnotations[observationCategory].push(annotation);
    });

    return segregatedAnnotations;
  }

  const filteredAnnotations = useMemo(() => {
    return categoryFilter === "" && severityFilter === ""
      ? markerData
      : markerData.filter(
        (observation) =>
          (categoryFilter === "" ||
            observation.category === categoryFilter) &&
          (severityFilter === "" || observation.severity === severityFilter),
      );
  }, [markerData, categoryFilter, severityFilter]);

  useEffect(() => {
    setFinalFilteredAnnotations(filteredAnnotations);
  }, [filteredAnnotations]);

  useEffect(() => {
    const segregated = segregateAnnotationsByFilterType(
      finalFilteredAnnotations,
    );
    setSegregatedAnnotations(segregated);
  }, [finalFilteredAnnotations]);

  const handleChange = (severity) => {
    switch (severity) {
      case "Low":
        return "bg-[#ffff33]";
      case "Medium":
        return "bg-[#ff9933]";
      case "High":
        return "bg-[#ff3333]";

      default:
        return "bg-[#cccccc]";
    }
  };

  const updateMarkerData = (id, layer) => {
    // Get the marker from markerData using the provided index
    // const markerToUpdate = markerData[index];
    const markerToUpdate = markerData.find((marker) => marker.id === id);
    // Update the marker position based on its type
    if (
      markerToUpdate?.filter_type === "polygon" ||
      markerToUpdate?.filter_type === "polyline"
    ) {
      // markerToUpdate.markerPosition = layer?.getLatLngs();

      const key = {
        id: markerToUpdate.id,
        timestamp: markerToUpdate.timestamp,
      };

      const fieldsToUpdate = {
        markerPosition: layer?.getLatLngs(),
      };

      // AnnotationManager.editAnnotations(key, fieldsToUpdate);
      AnnotationManager.editShape(key, fieldsToUpdate);
    } else {
      const key = {
        id: markerToUpdate.id,
        timestamp: markerToUpdate.timestamp,
      };
      const fieldsToUpdate = {
        markerPosition: layer?.getLatLng(),
      };

      // AnnotationManager.editAnnotations(key, fieldsToUpdate);
      AnnotationManager.editShape(key, fieldsToUpdate);
    }
    map.removeLayer(layer);

    dispatch(gisActions.refCounterUpdate());
  };
  const handleEditClick = (marker, index) => () => {
    const id = marker.id;

    const layer = layerControlUtils.getLayerByIdUtil(id, map);
    if (marker.markerPosition) {
      const bounds = L.latLngBounds([marker.markerPosition]);

      map?.flyToBounds(bounds, {
        duration: 2,
        easeLinearity: 0.5,
        minZoom: 15,
      });
    }

    var mulitiEdit = false;

    if (selectedLayer !== layer && selectedLayer && editMode) {
      handleSaveEdit(selectedLayerIndex);
      mulitiEdit = true;
    }
    setSelectedLayer(layer); // Set the selected layer for editing
    setSelectedLayerIndex(index);
    // if (layer.type !== "marker") {
    //   map.removeLayer(layer.label); //for label
    // }

    if (layer) {
      if (layer?.label) {
        map.removeLayer(layer.label);
      }
      // Toggle the edit mode
      setEditMode((prevEditMode) => !prevEditMode);
      // Show the edit buttons
      setShowMeasureEditButtonsArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = !newArray[index];
        return newArray;
      });
      // Filter out the selected layer and create a new feature group
      const selectedFeatureGroup = L.featureGroup([layer]);
      // Initialize the edit control with the new feature group if it's not initialized
      if (!editCtrl.current) {
        const editCtrlOptions = {
          featureGroup: selectedFeatureGroup,
        };
        editCtrl.current = new L.EditToolbar.Edit(map, editCtrlOptions);
      }
      // Enable or disable the edit functionality based on the edit mode
      if (!editMode || mulitiEdit) {
        editCtrl.current.enable();
      } else {
        setShowMeasureEditButtonsArray((prevArray) => {
          const newArray = [...prevArray];
          newArray[index] = false;
          return newArray;
        });
        editCtrl.current.disable();

        // updateMarkerData(index, selectedLayer);
        // Hide the edit buttons
        // setSelectedLayer(null);
        // editCtrl.current = null;
      }
    }
  };
  const handleSaveEdit = (index, id) => {
    if (selectedLayer) {
      if (editCtrl.current) {
        // Check if editCtrl is initialized
        editCtrl.current.save();
        editCtrl.current.disable(); // Disable the edit control
      }

      updateMarkerData(id, selectedLayer);
      setEditMode((prevEditMode) => !prevEditMode); // Disable edit mode
      // Hide the edit buttons
      setShowMeasureEditButtonsArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = !newArray[index];
        return newArray;
      });
      setSelectedLayer(null);
      editCtrl.current = null;
    }
  };
  const handleCancelEdit = (index, id) => {
    if (editMode && selectedLayer) {
      if (editCtrl.current) {
        // Check if editCtrl is initialized
        editCtrl.current.revertLayers();
        editCtrl.current.disable(); // Disable the edit control
      }
      updateMarkerData(id, selectedLayer);
      setEditMode(false); // Disable edit mode
      // Hide the edit buttons
      setShowMeasureEditButtonsArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = false;
        return newArray;
      });
      setSelectedLayer(null);
      editCtrl.current = null;
    }
  };

  const [opacityValues, setOpacityValues] = useState({});

  const toggleMarkerVisibility = (marker) => {
    // Toggle the visibility of the marker
    const updatedObservations = observationsVisibilityStatus.map(
      (observation) => {
        if (observation.id === marker.id) {
          // If the observation ID matches the marker ID, toggle its visibility
          return {
            id: observation.id,
            visible: !observation.visible,
          };
        } else {
          // Otherwise, keep the observation unchanged
          return observation;
        }
      },
    );

    // Dispatch an action to update the observationsVisibilityStatus
    dispatch(
      gisLeafletActions.setObservationsVisibilityStatus(updatedObservations),
    );
  };
  const calLength = (marker) => {
    const latLngs = marker.markerPosition; // Get LatLng coordinates of the layer
    const latLngArray = latLngs.map((latlng) =>
      L.latLng(latlng.lat, latlng.lng),
    ); // Convert coordinates to L.LatLng objects
    const length = L.GeometryUtil.length(latLngArray);
    return length;
  };
  const calArea = (marker) => {
    const areaInSquareMeters = L.GeometryUtil.geodesicArea(
      marker.markerPosition[0],
    );
    return areaInSquareMeters;
  };
  const calPerimeter = (marker) => {
    const latLngs = marker.markerPosition; // Get LatLng coordinates of the layer
    const latLngArray = latLngs[0].map((latlng) =>
      L.latLng(latlng.lat, latlng.lng),
    ); // Convert coordinates to L.LatLng objects
    const length = L.GeometryUtil.length(latLngArray);
    // console.log("latlng og",latLngs,"latlng mapped",latLngArray);
    return length;
  };

  const idForUi = useId();

  const listRef = useRef();

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [clickedIndex, setClickedIndex] = useState(null);

  useEffect(() => {
    if (listRef.current && clickedIndex !== null) {
      listRef.current.scrollToItem(clickedIndex, "smart");
    }
  }, [clickedIndex]);

  const [expandedIndex, setExpandedIndex] = useState(null);

  function SegregatedAnnotationsTable({ segregatedAnnotations }) {
    const scrollOffset = useRef(0);

    const handleClick = (rowIndex) => {
      setClickedIndex(rowIndex);
      // Save the current scroll position
      if (listRef.current) {
        scrollOffset.current = listRef.current.state.scrollOffset;
      }
    };

    return (
      <>
        {Object.entries(segregatedAnnotations).map(
          ([category, annotations], index) => (
            <Accordion
              key={index}
              expanded={expanded === index}
              onChange={handleAccordionChange(index)}
              className="!m-0 !shadow-none"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${index}-content`}
                id={`panel-${index}-header`}
                className="!p-1"
              >
                <p className=""> {category}</p>{" "}
                <p className="my-auto ml-3 text-sm text-gray-300">{`(${annotations.length} observations)`}</p>
              </AccordionSummary>
              <AccordionDetails className="!bg-white !p-0">
                {annotations.map((annotation, rowIndex) => {
                  const observationVisibility =
                    observationsVisibilityStatus.find(
                      (observation) => observation.id === annotation.id,
                    );

                  const isClicked = rowIndex === clickedIndex;
                  const isExpanded = rowIndex === expandedIndex;

                  return (
                    <div key={annotation.id} className="relative">
                      <div
                        className={`absolute left-0 top-0 h-full w-2 border border-white ${handleChange(
                          annotation.severity,
                        )}`}
                      ></div>
                      <div
                        // style={style}
                        className={`flex border !p-2 ${isClicked ? "bg-blue-100" : "bg-transparent"
                          }`}
                      >
                        <button
                          className="visibility-toggle-btn ml-2"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleClick(rowIndex);
                            toggleMarkerVisibility(annotation);
                          }}
                          disabled={showMeasureEditButtonsArray[rowIndex]}
                        >
                          {observationVisibility?.visible ? (
                            <img
                              src={visibilityIco}
                              alt="Visible"
                              className="h-4"
                            />
                          ) : (
                            <img
                              src={visibilityOffIco}
                              alt="Hidden"
                              className="h-4"
                            />
                          )}
                        </button>

                        <div className="ml-2 flex grow items-center justify-between gap-4 rounded-md">
                          <p
                            className="inline-block w-24 overflow-hidden text-ellipsis whitespace-nowrap text-sm"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleClick(rowIndex);

                              if (annotation.markerPosition) {
                                const bounds = L.latLngBounds([
                                  annotation.markerPosition,
                                ]);
                                map?.flyToBounds(bounds, {
                                  duration: 2,
                                  easeLinearity: 0.5,
                                  minZoom: 15,
                                });
                              } else {
                                console.error(
                                  "Layer coordinates not available",
                                );
                              }
                            }}
                          >
                            {annotation?.observationType}
                          </p>

                          <button
                            type="button"
                            // disabled={editMode}
                            onClick={(event) => {
                              if (editMode) {
                                toast.error(
                                  "Please complete ongoing edit action.",
                                );
                              } else {
                                setExpandedIndex(isExpanded ? null : rowIndex);

                                event.stopPropagation();
                                handleClick(rowIndex);

                                if (annotation.markerPosition) {
                                  const bounds = L.latLngBounds([
                                    annotation.markerPosition,
                                  ]);
                                  map?.flyToBounds(bounds, {
                                    duration: 2,
                                    easeLinearity: 0.5,
                                    minZoom: 15,
                                  });
                                } else {
                                  console.error(
                                    "Layer coordinates not available",
                                  );
                                }
                              }
                            }}
                          >
                            <img src={moreIco} alt="more ico" className="h-4" />
                          </button>
                        </div>
                      </div>

                      {isExpanded && observationVisibility?.visible ? (
                        <div
                          disabled={!observationVisibility?.visible}
                          className="!mb-0 w-full grow p-2"
                        >
                          <div className="flex flex-col px-2 text-sm">
                            {annotation.filter_type !== "marker" && (
                              <div className="flex items-center justify-center">
                                <p className="w-[70px]">Opacity:</p>
                                <Slider
                                  min={0}
                                  max={1}
                                  step={0.1}
                                  value={opacityValues[annotation.id] || 1}
                                  valueLabelDisplay="auto"
                                  onChange={(e, newValue) => {
                                    setOpacityValues((prevState) => ({
                                      ...prevState,
                                      [annotation.id]: newValue,
                                    }));
                                    layerControlUtils.handleAssetOpacityChange(
                                      annotation.id,
                                      newValue,
                                      map,
                                    );
                                  }}
                                  disabled={!annotation.visible}
                                  className="h-1 w-1/2"
                                />
                              </div>
                            )}
                            <div className="flex">
                              <p className="w-[82px]">Type</p>
                              <div>
                                :{" "}
                                {uiutil.firstLetterCapital(
                                  annotation.filter_type,
                                )}
                              </div>
                            </div>
                            <div className="flex">
                              <p className="w-[82px]">Severity</p>
                              <div>
                                :{" "}
                                {annotation.severity
                                  ? annotation.severity
                                  : "-"}
                              </div>
                            </div>
                            {annotation.filter_type === "polyline" && (
                              <div className="flex">
                                <p className="w-[82px]">Length</p>
                                <div>
                                  :{" "}
                                  {
                                    measure.convertDistance(
                                        calLength(annotation),
                                        storedPreset?.units.length,
                                      )?.toFixed(4)}{" "}
                                  {storedPreset?.units.length}
                                </div>
                              </div>
                            )}
                            {annotation.filter_type === "polygon" && (
                              <>
                                <div className="flex">
                                  <p className="w-[82px]">Area</p>
                                  <div>
                                    :{" "}
                                    {`${measure.convertArea(
                                      calArea(annotation),
                                      storedPreset?.units.area,
                                    )} 
                                 ${storedPreset?.units.area}`}
                                  </div>
                                </div>
                                <div className="flex">
                                  <p className="w-[82px]">Perimeter</p>
                                  <div>
                                    :{" "}
                                    {measure.convertDistance(calPerimeter(annotation),storedPreset?.units.length,)?.toFixed(4)}
                                    {" "}
                                    { storedPreset?.units.length}
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="flex">
                              <p className="w-[82px]">Created on</p>
                              <div>: {annotation.createdOn}</div>
                            </div>
                          </div>
                          <div className="mt-2 flex items-center justify-center gap-2">
                            {!showMeasureEditButtonsArray[rowIndex] && (
                              <button
                                className={`my-auto flex w-auto cursor-pointer items-center justify-center rounded-md border p-1 px-2 text-xs ${showMeasureEditButtonsArray[index]
                                    ? "bg-purple-400 text-white"
                                    : "border-purple-400 text-purple-400 hover:bg-purple-400 hover:text-white"
                                  } ${!annotation.visible
                                    ? "cursor-not-allowed opacity-50"
                                    : ""
                                  }`}
                                onClick={handleEditClick(annotation, rowIndex)}
                                disabled={!annotation.visible}
                              >
                                <svg
                                  className="h-[5.5] w-5 text-purple-600"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                  >
                                    <path d="m20 20H4"></path>
                                    <path
                                      clipRule="evenodd"
                                      fillRule="evenodd"
                                      d="m14.5858 4.41422c.781-.78105 2.0474-.78105 2.8284 0 .7811.78105.7811 2.04738 0 2.82843l-8.28322 8.28325-3.03046.202.20203-3.0304z"
                                    ></path>
                                  </g>
                                </svg>
                                <p className="ml-1">Edit Shape</p>
                              </button>
                            )}

                            {showMeasureEditButtonsArray[rowIndex] && (
                              <div className="flex items-center justify-center gap-2">
                                <button
                                  className="btn btn-success btn-sm mr-[2px] rounded-md border border-green-800 bg-green-400 p-1 px-2 text-xs text-white hover:bg-white hover:bg-opacity-50 hover:text-black"
                                  onClick={() =>
                                    handleSaveEdit(rowIndex, annotation.id)
                                  }
                                >
                                  Save
                                </button>
                                <button
                                  className="btn btn-danger btn-sm rounded-md border border-red-500 bg-white bg-opacity-50 p-1 px-2 text-xs hover:bg-red-400 hover:bg-opacity-100 hover:text-white"
                                  onClick={() =>
                                    handleCancelEdit(rowIndex, annotation.id)
                                  }
                                >
                                  Cancel
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          ),
        )}
      </>
    );
  }

  return (
    <>
      <div
        className={`${openObservation ? "block" : "hidden"
          } relative mb-2 ml-2 mr-2 mt-2 h-screen min-w-[18rem] !grow overflow-y-auto overflow-x-hidden rounded-md border-[1px] border-solid border-[#d7d8d8] bg-white`}
      >
        <div className="sticky flex w-full items-center rounded-tl-md rounded-tr-md bg-[#ffca0094] pb-[10px] pt-[10px] font-[500]">
          <div className="flex grow items-center justify-center">
            Observations
          </div>
        </div>
        <div className="sticky flex flex-col items-center gap-4 overflow-hidden border-b border-gray-200 p-2">
          <div className="relative flex w-full flex-col">
            <label className="absolute -top-2 left-2 mb-1 rounded-sm bg-white pl-1 pr-2 text-xs">
              Category
            </label>
            <select
              className="rounded border border-gray-400 text-xs"
              value={categoryFilter}
              onChange={(e) =>
                e.target.value === "All"
                  ? setCategoryFilter("")
                  : setCategoryFilter(e.target.value)
              }
            >
              <option key="all" value="All">
                All
              </option>
              {observationCategories?.map((category, index) => (
                <option key={idForUi + index + category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          <div className="relative flex w-full flex-col">
            <label className="absolute -top-2 left-2 rounded-sm bg-white pl-1 pr-2 text-xs">
              Severity
            </label>
            <select
              className="rounded border border-gray-400 text-xs"
              value={severityFilter}
              onChange={(e) => {
                e.target.value === "All"
                  ? setSeverityFilter("")
                  : setSeverityFilter(e.target.value);
              }}
            >
              {severities?.map((severity, index) => (
                <option key={idForUi + index + severity} value={severity}>
                  {severity}
                </option>
              ))}
            </select>
          </div>
        </div>

        {finalFilteredAnnotations?.length > 0 ? (
          // <div className="flex-1 h-[73vh] overflow-x-hidden ">
          //   <AutoSizer>
          //     {({ height, width }) => (
          //       <List
          //         className="List"
          //         height={height}
          //         itemCount={finalFilteredAnnotations?.length}
          //         itemSize={itemSize}
          //         width={width}
          //         ref={listRef}
          //       >
          //         {rowRenderer}
          //       </List>
          //     )}
          //   </AutoSizer>
          // </div>
          <SegregatedAnnotationsTable
            segregatedAnnotations={segregatedAnnotations}
          />
        ) : (
          <div className="mb-0 pb-3 text-center text-[12px]">
            {markerData.length > 0
              ? "No observations to show"
              : finalFilteredAnnotations?.length > 0
                ? "No observations with Selected Filters"
                : "No observations to show"}
          </div>
        )}
        {/* <button
          className="border  mx-auto"
          onClick={() => onExportAnnotationToKML(exportEntityCollection)}
        >
          Export to KML
        </button> */}
        {/* {finalFilteredAnnotations?.length > 15 && (
          <div className="relative bottom-0 text-center  text-[12px] bg-gray-300 py-1">
            <p className="">
              Number of observations: {finalFilteredAnnotations?.length}
            </p>
          </div>
        )} */}
      </div>
      <Toaster />
    </>
  );
};

export default AnnotationsLeaflet;
