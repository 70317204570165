import React from "react";
import { locationIcon, fileIcon } from "../../Assets/icons/gisIcons/icons";
import DocViwerModal from "./DocViwerModal";
import { Tooltip } from "@mui/material";
import { industryImages } from "../../Data/config/ProjectCatalogConfig";
const CatalogCard = ({ industry, handleViewClick }) => {
  const [open, setOpen] = React.useState(false);

  // Calculate the count of "ORI" layers in photogrammetryLayers
  // const oriCount = industry?.photogrammetryLayers?.filter(
  //   (layer) => layer.layerType === "ORI",
  // ).length;

  return (
    <>
      <div className="relative flex h-[300px] w-auto flex-col items-center">
        <div className="custom-grid absolute top-[40px] h-auto w-full rounded-2xl border border-gray-300 bg-[#091E420F] p-4 shadow-md">
          <div className="mt-10 flex h-20 flex-col items-center">
            <div className="text-2xl font-semibold">{industry.name}</div>
            {industry?.subheading && (
              <p className="mb-2 text-sm">{industry?.subheading}</p>
            )}

            <div className="mt-auto flex items-center text-xs text-gray-600">
              <img
                src={locationIcon}
                alt="markerIcon"
                className="mr-1 h-4 w-4"
              />
              {industry.location}India
            </div>
          </div>
          <div className="my-4 flex flex-wrap justify-center gap-2">
            {industry.tags.map((tag, tagIndex) => (
              <div
                key={tagIndex}
                className="flex h-5 items-center justify-center rounded-3xl border border-black bg-white px-2 text-xs font-semibold"
              >
                {tag}
              </div>
            ))}
          </div>
          <div className="my-4 w-full border-t border-gray-400"></div>
          <div className="flex items-center justify-between">
            {industry?.documents && (
              <Tooltip title={"Documents"} arrow placement="bottom">
                <button onClick={() => setOpen(true)}>
                  <img src={fileIcon} alt="fileicon" className="h-6 w-6" />
                </button>
              </Tooltip>
            )}
            <div className="ml-auto flex gap-2">
              {industry.projectType === "Videography" && (
                <button
                  className="flex h-8 w-24 items-center justify-center rounded-2xl border-0 border-[#0065FF] bg-white text-black transition duration-300 ease-in-out hover:bg-[rgba(9,30,66,0.08)]"
                  onClick={() => handleViewClick(industry, true, industry.id)}
                >
                  Compare
                </button>
              )}
              <button
                className="flex h-8 w-20 items-center justify-center rounded-2xl bg-[#0055CC] text-white transition duration-300 ease-in-out hover:bg-[#0065FF]"
                onClick={() => handleViewClick(industry, false, industry.id)}
              >
                View
              </button>
            </div>
          </div>
        </div>
        <div className="absolute top-0 flex h-20 w-20 items-center justify-center rounded-full border border-yellow-400 !bg-yellow-200">
          <img
            src={industryImages[industry.name]}
            alt="Industry Icon"
            className="h-12 w-12"
          />
        </div>
      </div>

      {open && (
        <DocViwerModal
          isOpen={open}
          onClose={() => {
            setOpen(false);
          }}
          industry={industry}
        />
      )}
    </>
  );
};

export default CatalogCard;
