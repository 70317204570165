/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, Modal, TextField } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { observationTaskStatus } from "../../../Data/config/ProjectConfig";
import { MarkerClass } from "../../../Utils/Leaflet/LeafletUtils";
import { gisActions } from "../../../redux/slices/GIS/gis-slice";
import { gisToolActions } from "../../../redux/slices/GIS/gis-tools-slice";
import { useQuery } from "@tanstack/react-query";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "30%",
  boxShadow: 24,
  p: 4,
};

const dropzoneStyle = {
  borderRadius: "4px",
  cursor: "pointer",
  textAlign: "center",
  border: "2px dashed #cccccc",
};

const MetaInputLeaflet = ({ isOpen, onClose, onSave, markerLayer, map }) => {
  const [markerName, setMarkerName] = useState("Observation"); //name
  const [observationType, setObservationType] = useState(""); // observation type
  const [selectedCategory, setSelectedCategory] = useState(""); //category
  const [selectedSeverity, setSelectedSeverity] = useState(""); // risk
  const [description, setDescription] = useState(""); // remark
  const [AnnotationType, setAnnotaionType] = useState(null); // type of marker marker , polyline , polygon
  const [keyValuePairs, setKeyValuePairs] = useState([]); // keyvaluePairs
  const [date, setDate] = useState("");
  // images
  const [uploadedFile, setUploadedFile] = useState(null);
  // keyvalue pair field
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  // metrics
  const [length, setLength] = useState(null);
  const [area, setArea] = useState(null);
  const [perimeter, setPerimeter] = useState(null);
  // task details
  // const { first_name } = useSelector((state) => state.userData);
  const first_name = Cookies.get("userFirstName");
  const last_name = Cookies.get("userLastName");
  const [observerName, setObserverName] = useState(
    `${first_name + " " + last_name}`,
  );
  const [verifierName, setVerifierName] = useState("");
  const [reviewerName, setReviewerName] = useState("");
  const [taskStatus, setTaskStatus] = useState(observationTaskStatus[0]);

  useEffect(() => {
    setAnnotaionType(markerLayer.type);
    setLength(markerLayer.length);
    setArea(markerLayer.area);
    setPerimeter(markerLayer.perimeter);
  }, []);

  const fetchClientCategoriesAndTypes = async () => {
    try {
      const response = await fetch(
        "https://delivery.inspect.indrones.com/api/v1/settings/category/get_all/hmel",
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Fetch error:", error);
      throw error;
    }
  };

  const severities = ["Low", "Medium", "High"];

  const dispatch = useDispatch();
  const { currentStation, asset } = useSelector((state) => state.gisLeaflet);
  const assetId = asset.Name;

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleKeyChange = (event) => {
    setKey(event.target.value);
  };

  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  const handleAddKeyValuePair = () => {
    if (key && value) {
      const uppercaseKey = key.toUpperCase();
      setKeyValuePairs([...keyValuePairs, { key: uppercaseKey, value }]);
      setKey("");
      setValue("");
    }
  };

  const handleOnClose = () => {
    map.removeLayer(markerLayer);
    setMarkerName("");
    setObservationType("");
    setSelectedCategory("");
    setSelectedSeverity("");
    setDescription("");
    setAnnotaionType("");
    setKeyValuePairs([]);
    setTaskStatus("");
    dispatch(gisToolActions.turnOffTools());
    dispatch(gisActions.setMarkerCategory(""));
    dispatch(gisToolActions.setselectedTool(null));
  };

  // form error ui handlers
  const [nameError, setNameError] = useState("");
  const [categoryError, setCategoryError] = useState("");

  const handleSave = () => {
    if (!observationType) {
      setCategoryError("Please select manditory fields");
    } else if (!selectedCategory) {
      setCategoryError("Please select manditory fields");
    } else {
      setNameError("");
      setCategoryError("");
      onSave(
        description,
        keyValuePairs,
        observationType,
        selectedCategory,
        selectedSeverity,
        markerName,
        assetId,
        uploadedFile,
        date,
        AnnotationType,
        taskStatus,
        observerName,
        verifierName,
        reviewerName,
        length,
        area,
        perimeter,
      );
      // Save description, key-value pairs, and category
      setAnnotaionType("");
      setDescription("");
      setKeyValuePairs([]);
      setSelectedCategory("");
      setSelectedSeverity("");
      setMarkerName("");
      dispatch(gisToolActions.turnOffTools());
      dispatch(gisToolActions.setselectedTool(null));

      dispatch(gisActions.setMarkerCategory(""));
      dispatch(gisActions.refCounterUpdate());
      onClose();
    }
  };

  const onDrop = (acceptedFiles) => {
    setUploadedFile(acceptedFiles[0]);
  };

  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  const [expandedTask, setExpandedTask] = useState(false);

  const handleChangeTask = () => {
    expandedTask ? setExpandedTask(false) : setExpandedTask(true);
  };

  const {
    status,
    data: categoriesAndTypes,
    error,
  } = useQuery({
    queryKey: ["fetchClientCategoriesAndTypes"],
    queryFn: () => fetchClientCategoriesAndTypes(),
  });

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleOnClose();
        onClose();
      }}
      onContextMenu={(e) => e.preventDefault()}
      className="border-none"
    >
      <div
        style={style}
        className="!focus-visible:border-none flex h-max w-auto flex-col items-center justify-between rounded-[12px] bg-white"
      >
        <div className="flex w-full items-center justify-between rounded-tl-[12px] rounded-tr-[12px] border-b bg-[#ffca0094] p-5 pb-[10px] pt-[10px] font-[500]">
          <h3 className="flex grow items-center justify-center text-[1.20rem] text-[#211f1c]">
            {selectedCategory === "Custom" ? (
              <h1 className="text-lg font-bold">Enter marker details</h1>
            ) : (
              <h1 className="text-md font-medium">Observation</h1>
            )}
          </h3>
          <div className="mr-[10px]">
            <IconButton
              onClick={() => {
                handleOnClose();
                onClose();
              }}
              aria-label="Close"
              size="small"
              className="text-[#211f1c]"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="flex w-full grow flex-col gap-3 p-3">
          {/* <div className="flex items-center justify-center w-full ">
            <label className="w-[30%]">
              Name: <span className="text-red-500">*</span>{" "}
            </label>
            <div className="flex flex-grow flex-col">
              <TextField
                name="textNote"
                size="small"
                value={markerName}
                onChange={handleMarkerNameChange}
                className="grow"
                maxLength={25}
              />
              <p className="text-xs text-gray-400">
                Remaining characters: {remainingCharacters}
              </p>
            </div>
          </div>
          {nameError && (
            <p className="ml-[30%] text-red-500 text-sm">{nameError}</p>
          )} */}

          {/* observations category  */}
          <div className="flex w-full items-center justify-center">
            <label className="w-[25%]">
              Category: <span className="text-red-500">*</span>
            </label>
            <select
              className="grow rounded border border-gray-600 px-2 py-1"
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
              }}
            >
              <option value="">Select Category</option>
              {categoriesAndTypes?.map((category, index) => (
                <option key={category.id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          {categoryError && (
            <p className="ml-[25%] text-sm text-red-500">{categoryError}</p>
          )}

          {/* observation type  */}
          {selectedCategory !== "" && (
            <div className="flex w-full items-center justify-center">
              <label className="w-[25%]">
                Type: <span className="text-red-500">*</span>
              </label>
              <select
                className="grow rounded border border-gray-600 px-2 py-1"
                value={observationType}
                onChange={(event) => {
                  // setTypeOfMarker(event.target.value);
                  setObservationType(event.target.value);
                  // dispatch(gisActions.setMarkerCategory(event.target.value));
                }}
              >
                <option value="">Select Type</option>
                {categoriesAndTypes
                  ?.filter((category) => category.name === selectedCategory)
                  ?.map((type) =>
                    type.type.map((type) => (
                      <option key={type.id} value={type.name}>
                        {type.name}
                      </option>
                    )),
                  )}
              </select>
            </div>
          )}

          {/* risk  */}
          <div className="flex w-full items-center justify-center">
            <label className="w-[25%]">Risk:</label>
            <select
              className="grow rounded border border-gray-600 px-2 py-1"
              value={selectedSeverity}
              onChange={(event) => setSelectedSeverity(event.target.value)}
            >
              <option value="">Select a severity</option>
              {severities.map((severity, index) => (
                <option key={index} value={severity}>
                  {severity}
                </option>
              ))}
            </select>
          </div>
          {/* Remark  */}
          <div className="flex w-full items-center justify-center">
            <label className="w-[25%]">Remark: </label>
            <textarea
              name="textNote"
              size="small"
              value={description}
              onChange={handleDescriptionChange}
              className="grow rounded border border-gray-600 px-2 py-1"
            />
          </div>
          <div className="flex w-full items-center justify-center">
            <div className="w-[25%]">Add Image:</div>
            {!uploadedFile ? (
              <>
                <div
                  className="flex h-[30px] grow flex-col"
                  {...getRootProps()}
                  style={dropzoneStyle}
                >
                  {/* <input {...getInputProps()} /> */}
                  <p className="m-auto text-xs">
                    Drag & Drop Image here or Click to upload
                  </p>
                </div>
              </>
            ) : (
              <>
                <TextField
                  name="textNote"
                  size="small"
                  value={uploadedFile.name}
                  className="grow"
                  inputProps={{ readOnly: true }}
                />
              </>
            )}
          </div>

          {/* <div className="flex items-center justify-center w-full">
            <label className="w-[30%]">Date:</label>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              sx={{ flexGrow: 1 }}
            >
              <DemoContainer components={["DatePicker"]} sx={{ flexGrow: 1 }}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Timeline"
                    slotProps={{ textField: { size: "small" } }}
                    onChange={(selectedDate) => {
                      setDate(dayjs(selectedDate).format("DD_MM_YY"));
                    }}
                  />
                </FormControl>
              </DemoContainer>
            </LocalizationProvider>
          </div> */}

          {/* {AnnotationType === "marker" ? (
            <></>
          ) : AnnotationType === "polygon" ? (
            <>
              <div className="flex items-center justify-center w-full">
                <label className="w-[30%]">Area:</label>
                <TextField
                  name="textNote"
                  size="small"
                  value={areaValue}
                  inputProps={{ readOnly: true }}
                  className="grow"
                />
              </div>
              <div className="flex items-center justify-center w-full">
                <label className="w-[30%]">Perimeter:</label>
                <TextField
                  name="textNote"
                  size="small"
                  value={perimeterValue}
                  inputProps={{ readOnly: true }}
                  className="grow"
                />
              </div>
              <div className="flex items-center justify-center w-full">
                <label className="w-[30%]">Volume:</label>
                <TextField
                  name="textNote"
                  size="small"
                  placeholder="in cubic meter"
                  value={volume}
                  onChange={(e) => setVolume(e.target.value)}
                  className="grow"
                />
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center justify-center w-full">
                <label className="w-[30%]">Length:</label>
                <TextField
                  name="textNote"
                  size="small"
                  value={lengthValue}
                  inputProps={{ readOnly: true }}
                  className="grow"
                />
              </div>
            </>
          )} */}
        </div>

        <hr className="border-gray-400" />
        <div className="w-full p-5 pb-0">
          <div
            className="flex cursor-pointer items-center justify-between py-2"
            onClick={handleChangeTask}
          >
            Task Details
            <span>
              <ExpandMoreIcon className={expandedTask ? "rotated" : ""} />
            </span>
          </div>
          <div
            className={`flex flex-col gap-2 transition-all duration-300 ${
              expandedTask ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
            } overflow-hidden`}
          >
            <div className="flex w-full items-center justify-center">
              <label className="w-[25%]">Status</label>
              <select
                className="grow rounded border border-gray-600 px-2 py-1"
                value={taskStatus}
                onChange={(event) => setTaskStatus(event.target.value)}
              >
                {/* <option value="">Select status</option> */}
                {observationTaskStatus?.map((status, index) => (
                  <option key={index} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex w-full items-center justify-center">
              <label className="w-[25%]">Added By</label>
              <div className="flex flex-grow flex-col">
                <TextField
                  name="textNote"
                  size="small"
                  value={observerName}
                  onChange={(e) => setObserverName(e.target.value)}
                  className="grow"
                  disabled
                />
              </div>
            </div>
            <div className="flex w-full items-center justify-center">
              <label className="w-[25%]">Verified By</label>
              <div className="flex flex-grow flex-col">
                <TextField
                  name="textNote"
                  size="small"
                  value={verifierName}
                  onChange={(e) => setVerifierName(e.target.value)}
                  className="grow"
                  maxLength={25}
                />
              </div>
            </div>
            <div className="flex w-full items-center justify-center">
              <label className="w-[25%]">Reviewed By</label>
              <div className="flex flex-grow flex-col">
                <TextField
                  name="textNote"
                  size="small"
                  value={reviewerName}
                  onChange={(e) => setReviewerName(e.target.value)}
                  className="grow"
                  maxLength={25}
                />
              </div>
            </div>
          </div>
        </div>

        {selectedCategory === "Custom" ? (
          <div className="flex w-full flex-col p-3">
            <p className="mb-2 text-left font-semibold">
              Add Info <span className="text-xs">(In Key-Value Format)</span>
            </p>
            <div className="flex w-max flex-row space-x-2">
              <input
                className="w-1/2 rounded border border-gray-600 px-2 py-1"
                type="text"
                placeholder="Enter key"
                value={key}
                onChange={handleKeyChange}
              />
              <input
                className="w-1/2 rounded border border-gray-600 px-2 py-1"
                type="text"
                placeholder="Enter value"
                value={value}
                onChange={handleValueChange}
              />
              <button
                className="w-24 rounded bg-blue-500 px-4 py-1 font-bold text-white hover:bg-blue-700"
                onClick={handleAddKeyValuePair}
              >
                + Add
              </button>
            </div>
          </div>
        ) : null}
        {keyValuePairs.length > 0 && (
          <div className="my-4">
            <table className="w-full">
              <thead>
                <tr className="border bg-gray-100">
                  <th className="p-2">Key</th>
                  <th className="p-2">Value</th>
                  <th className="p-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {keyValuePairs.map((pair, index) => (
                  <tr key={index} className="border text-center">
                    <td className="p-2">{pair.key}</td>
                    <td className="p-2">{pair.value}</td>
                    <td className="p-2">
                      <button
                        onClick={() => {
                          const updatedPairs = keyValuePairs.filter(
                            (_, i) => i !== index,
                          );
                          setKeyValuePairs(updatedPairs);
                        }}
                        className="rounded border bg-gray-400 px-2 py-1 text-xs"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className="my-2 flex items-center justify-center gap-2">
          <button
            className="my-2 cursor-pointer rounded border bg-green-200 px-[18px] py-[5px] text-slate-700 hover:border-green-300 hover:bg-green-300"
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </button>

          <button
            className="my-2 cursor-pointer rounded border border-red-200 px-[18px] py-[5px] text-slate-700 hover:border-red-300 hover:bg-red-300"
            onClick={() => {
              handleOnClose();
              onClose();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MetaInputLeaflet;
