/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import L from "leaflet";
import "leaflet.markercluster";
import "Leaflet.Deflate";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
// import hmelChainageMarkers from "../../../Data/geoData/hmel_chainage_markers.json";
// import hmelChainageMarkersUpdated from "../../../Data/geoData/hmel_chainage_markers_updated.json";
// import hmelAssetMarkers from "../../../Data/geoData/hmel_assets_data.json";
import GISToolbarLeaflet from "./GISToolbarLeaflet";
import { useCompareAssetsL } from "../../../Features/GIS/hooks/leafletHooks/useCompareAssetsL";
import ComparerUIL from "./ComparerUIL";
import {
  assetMarkerIco,
  defaultMarker,
  highMarker,
  lowMarker,
  mediumMarker,
  svStationMarker,
} from "../../../Assets/icons/gisIcons/icons";
import MetaDataModalLeaflet from "./MetaDataModalLeaflet";
import { v4 as uuidv4 } from "uuid";
import { gisLeafletActions } from "../../../redux/slices/GIS/gis-leaflet-slice";
import { MarkerClass, Measure } from "../../../Utils/Leaflet/LeafletUtils";
import axios from "axios";
import { fetchData } from "../../../Utils/indexedDB";
import { Toaster } from "react-hot-toast";
import {
  amMarkerIco,
  ifpMarkerIco,
  ofcMarkerIco,
  otherMarkerIco,
  tlpMarkerIco,
  tpoMarkerIco,
  wbMarkerIco,
  wpMarkerIco,
} from "../../../Assets/icons/markers/markers";
import { projectActions } from "../../../redux/slices/project-slice";
import Cookies from "js-cookie";
import * as leafletWms from "leaflet.wms";

const GISLeaflet = ({ mapRef, projectData, baseLayer }) => {
  const dispatch = useDispatch();
  const clientName = Cookies.get("clientName");
  const measure = new Measure();
  const markerClass = new MarkerClass();
  const map = mapRef.current;
  const {
    asset,
    observationsBucket,
    observationsVisibilityStatus,
    layersBetweenStation,
    IsWMSAssetLoading,
    geoJSONArray,
  } = useSelector((state) => state.gisLeaflet);
  const [tileLayer, setTileLayer] = useState(null);
  const [bounds, setBounds] = useState("");
  const { showComparer } = useSelector((state) => state.gisTools);
  const { userPermissions, refCounter } = useSelector((state) => state.gis);
  const [isMetaModalOpen, setIsMetaModalOpen] = useState(false);
  const [clickedMarker, setClickedMarker] = useState(null);
  const [markersInCurrentBbox, setMarkersInCurrentBbox] = useState([]);
  const [storedPreset, setStoredPreset] = useState();
  const { refCounterUnits } = useSelector((state) => state.gisLeaflet);
  useEffect(() => {
    const getPreset = async () => {
      try {
        const fetchedPreset = await fetchData("presets");
        setStoredPreset(fetchedPreset);
      } catch (error) {
        console.error("Failed to fetch preset:", error);
      }
    };
    getPreset();
  }, [refCounterUnits]);

  // console.log(clientName?.toLowerCase(), "clientNameclientName");

  // var imageryProvider = new WebMapServiceImageryProvider({
  //   url: "https://gis.indrones.com/geoserver/skyhigh/wms",
  //   layers: asset,

  //   parameters: {
  //     transparent: "true",
  //     format: "image/png",
  //   },
  // });

  useEffect(() => {
    // const jsonFiles = [
    //   // {
    //   //   file: "https://d2boh9caghjgas.cloudfront.net/hmel/KML/hmelAssets.json",
    //   //   name: "Assets",
    //   //   initialVisible: true,
    //   //   type: "markers",
    //   // },
    //   {
    //     file: "https://d2boh9caghjgas.cloudfront.net/hmel/KML/hmel_chainage_markers_old.json",
    //     name: "Chainage",
    //     initialVisible: true,
    //     type: "label",
    //   },
    //   {
    //     file: "https://d2boh9caghjgas.cloudfront.net/hmel/KML/hmel_assets_markers_AM.json",
    //     name: "AM asset markers",
    //     initialVisible: true,
    //     type: "markers",
    //   },
    //   {
    //     file: "https://d2boh9caghjgas.cloudfront.net/hmel/KML/hmel_assets_markers_KM.json",
    //     name: "KM asset markers",
    //     initialVisible: true,
    //     type: "markers",
    //   },
    //   {
    //     file: "https://d2boh9caghjgas.cloudfront.net/hmel/KML/hmel_assets_markers_LFP.json",
    //     name: "LFP asset markers",
    //     initialVisible: true,
    //     type: "markers",
    //   },
    //   {
    //     file: "https://d2boh9caghjgas.cloudfront.net/hmel/KML/hmel_assets_markers_OFC.json",
    //     name: "OFC asset markers",
    //     initialVisible: true,
    //     type: "markers",
    //   },
    //   {
    //     file: "https://d2boh9caghjgas.cloudfront.net/hmel/KML/hmel_assets_markers_TLP.json",
    //     name: "TLP asset markers",
    //     initialVisible: true,
    //     type: "markers",
    //   },
    //   {
    //     file: "https://d2boh9caghjgas.cloudfront.net/hmel/KML/hmel_assets_markers_TP.json",
    //     name: "TP asset markers",
    //     initialVisible: true,
    //     type: "markers",
    //   },
    //   {
    //     file: "https://d2boh9caghjgas.cloudfront.net/hmel/KML/hmel_assets_markers_WB.json",
    //     name: "WB asset markers",
    //     initialVisible: true,
    //     type: "markers",
    //   },
    //   {
    //     file: "https://d2boh9caghjgas.cloudfront.net/hmel/KML/hmel_assets_markers_Other.json",
    //     name: "Other asset markers",
    //     initialVisible: true,
    //     type: "markers",
    //   },
    //   // {
    //   //   file: "https://d2boh9caghjgas.cloudfront.net/hmel/KML/hmel_chainage_markers_latest.json",
    //   //   name: "Chainage Updated",
    //   //   initialVisible: false,
    //   //   type: "label",
    //   // },
    // ];
    const jsonFiles = projectData.assetLayers.filter(
      (layer) => layer.fileType === "json",
    );

    const loadKML = (geoJson) => {
      const geoJsonLayer = {
        id: uuidv4(),
        geoJsonName: geoJson.name,
        geoJsonFilePath: geoJson.path,
        visible: true,
        type: geoJson.type,
      };
      dispatch(gisLeafletActions.setGeoJSON(geoJsonLayer));
    };
    jsonFiles.forEach(loadKML);
    return () => {
      dispatch(gisLeafletActions.clearGeoJSONArray());
    };
  }, []);

  function createGeoJSON(data) {
    const features = [];

    // Add Point features
    data.points?.forEach((point) => {
      features.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: point,
        },
      });
    });

    // Add LineString features
    data.linestring_list?.forEach((line) => {
      features.push({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: line,
        },
      });
    });

    // Add Polygon features
    data.polygon_outer_boundary_list?.forEach((outerBoundary, index) => {
      const innerBoundaries = data.polygon_inner_boundary_list[index] || [];
      features.push({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [outerBoundary].concat(innerBoundaries),
        },
      });
    });

    // Add MultiPoint features
    if (data.point_list?.length > 0) {
      features.push({
        type: "Feature",
        geometry: {
          type: "MultiPoint",
          coordinates: data.point_list,
        },
      });
    }

    // Add MultiLineString features
    data.multigeometry_linestring_list?.forEach((multiLine) => {
      features.push({
        type: "Feature",
        geometry: {
          type: "MultiLineString",
          coordinates: multiLine,
        },
      });
    });

    // Add MultiPolygon features
    data.multigeometry_polygon_out_list?.forEach((outerBoundary, index) => {
      const innerBoundaries = data.multigeometry_polygon_in_list[index] || [];
      features.push({
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [[outerBoundary].concat(innerBoundaries)],
        },
      });
    });

    const geojson = {
      type: "FeatureCollection",
      features: features,
    };

    return geojson;
  }

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  useEffect(() => {
    if (!map) return;

    const geoJsonCache = {};

    const updateMarkersInBounds = () => {
      const bounds = map.getBounds();
      const southWest = bounds.getSouthWest();
      const northEast = bounds.getNorthEast();

      const filterMarkers = (markers) => {
        const points = markers?.point_list || markers?.points;
        return points.filter((point) => {
          const [lat, lng] = point;
          if (lat < southWest.lat || lat > northEast.lat) {
            return false;
          }
          return lng >= southWest.lng && lng <= northEast.lng;
        });
      };

      const fetchAndFilterMarkers = async (geoJson) => {
        if (!geoJson.visible) return [];

        if (geoJsonCache[geoJson.geoJsonFilePath]) {
          return filterMarkers(geoJsonCache[geoJson.geoJsonFilePath]);
        }

        try {
          const response = await axios.get(geoJson.geoJsonFilePath);
          if (response && response.data) {
            geoJsonCache[geoJson.geoJsonFilePath] = response.data; // Cache the data
            return filterMarkers(response.data);
          } else {
            console.error("Invalid response format", response);
            return [];
          }
        } catch (error) {
          console.error("Error fetching GeoJSON data:", error);
          return [];
        }
      };

      const processMarkers = async () => {
        const markersPromises = geoJSONArray.map(fetchAndFilterMarkers);
        const markersArray = await Promise.all(markersPromises);
        const markersInBounds = markersArray.flat();

        setMarkersInCurrentBbox(markersInBounds);
      };

      processMarkers();
    };

    const debounceUpdateMarkersInBounds = debounce(updateMarkersInBounds, 500);

    const filterData = () => {
      debounceUpdateMarkersInBounds();
    };

    map.on("moveend", filterData);
    return () => {
      map.off("moveend", filterData);
    };
  }, [geoJSONArray]);

  function createIcon(iconUrl) {
    return L.icon({
      iconUrl: iconUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    });
  }

  const iconMap = {
    svStationMarker: createIcon(svStationMarker),
    amMarkerIco: createIcon(amMarkerIco),
    ifpMarkerIco: createIcon(ifpMarkerIco),
    ofcMarkerIco: createIcon(ofcMarkerIco),
    otherMarkerIco: createIcon(otherMarkerIco),
    tlpMarkerIco: createIcon(tlpMarkerIco),
    tpoMarkerIco: createIcon(tpoMarkerIco),
    wbMarkerIco: createIcon(wbMarkerIco),
    wpMarkerIco: createIcon(wpMarkerIco),
  };

  useEffect(() => {
    const chaingeMarkers = L.featureGroup().addTo(map);
    if (!map || markersInCurrentBbox.length === 0) {
      chaingeMarkers.clearLayers();
    }

    const zoomLevel = map.getZoom();

    if (markersInCurrentBbox.length < 550) {
      map.createPane("labels");
      map.getPane("labels").style.zIndex = 650;
      // here label[3]) is a string containg chainage value like 96.1 , 96.2 ....
      // zoomLevel <15 only km labels like 96 , 97 , 98
      // zoomLevel 16 show only divisible by 5 like 96 , 96.5 , 97
      // zoomLevel >16 show all
      // return label

      const zoomWiseFiltered = markersInCurrentBbox?.map((label) => {
        const chainageValue = parseFloat(label[3]);
        const length_of_point_label = label[3]?.length;

        // const isNumeric = isNaN(parseFloat(label[3]));
        // const length_of_point_label = label[3]?.length;

        // console.log(isNumeric, label[3]);

        let shouldKeepLabel = true;
        if (length_of_point_label > 7) {
          return shouldKeepLabel ? label : null;
        } else if (length_of_point_label < 7) {
          if (zoomLevel <= 15) {
            shouldKeepLabel = chainageValue % 1 === 0;
          } else if (zoomLevel === 16 || zoomLevel === 17) {
            const integerPart = Math.floor(chainageValue);
            const fractionalPart = chainageValue - integerPart;
            shouldKeepLabel = fractionalPart === 0 || fractionalPart === 0.5;
          } else {
            shouldKeepLabel = true;
          }

          return shouldKeepLabel ? label : null;
        }
      });

      if (zoomWiseFiltered !== undefined && zoomWiseFiltered.length > 0) {
        zoomWiseFiltered?.forEach((point) => {
          if (point !== null && point !== undefined) {
            // console.log(
            //   point[3]?.split(""),
            //   point[3]?.length,
            //   "point",
            //   zoomWiseFiltered.length
            // );
            const point_label = point[3];
            const length_of_point_label = point[3]?.length;
            const isNumeric = !isNaN(point[3]);
            if (!isNumeric && zoomWiseFiltered.length < 200) {
              if (point_label.includes("KM")) {
                const kmBoardIcon = L.divIcon({
                  className: "km-board-icon",
                  html: `<span class="km-text font-semibold">${point_label?.split(" ")[0]}</span> <span class="km-text">${point_label?.split(" ")[1]}</span>`,
                });

                const KMBoard = L.marker([point[0], point[1]], {
                  icon: kmBoardIcon,
                  zIndexOffset: 200,
                }).addTo(chaingeMarkers);
              } else if (point_label.includes("AM")) {
                const assetMarker = L.marker([point[0], point[1]], {
                  icon: iconMap.amMarkerIco,
                  zIndexOffset: 200,
                })
                  .bindPopup(
                    `<span>
                      ${point[3]}
                  </span>`,
                  )
                  .addTo(chaingeMarkers);
              } else if (point_label.includes("IFP")) {
                const assetMarker = L.marker([point[0], point[1]], {
                  icon: iconMap.ifpMarkerIco,
                  zIndexOffset: 200,
                })
                  .bindPopup(
                    `<span>
                      ${point[3]}
                  </span>`,
                  )
                  .addTo(chaingeMarkers);
              } else if (point_label.includes("OFC")) {
                const assetMarker = L.marker([point[0], point[1]], {
                  icon: iconMap.ofcMarkerIco,
                  zIndexOffset: 200,
                })
                  .bindPopup(
                    `<span>
                      ${point[3]}
                  </span>`,
                  )
                  .addTo(chaingeMarkers);
              } else if (point_label.includes("TLP")) {
                const assetMarker = L.marker([point[0], point[1]], {
                  icon: iconMap.tlpMarkerIco,
                  zIndexOffset: 200,
                })
                  .bindPopup(
                    `<span>
                      ${point[3]}
                  </span>`,
                  )
                  .addTo(chaingeMarkers);
              } else if (point_label.includes("TPO")) {
                const assetMarker = L.marker([point[0], point[1]], {
                  icon: iconMap.tpoMarkerIco,
                  zIndexOffset: 200,
                })
                  .bindPopup(
                    `<span>
                      ${point[3]}
                  </span>`,
                  )
                  .addTo(chaingeMarkers);
              } else if (point_label.includes("WB")) {
                const assetMarker = L.marker([point[0], point[1]], {
                  icon: iconMap.wbMarkerIco,
                  zIndexOffset: 200,
                })
                  .bindPopup(
                    `<span>
                      ${point[3]}
                  </span>`,
                  )
                  .addTo(chaingeMarkers);
              } else if (point_label.includes("WP")) {
                const assetMarker = L.marker([point[0], point[1]], {
                  icon: iconMap.wpMarkerIco,
                  zIndexOffset: 200,
                })
                  .bindPopup(
                    `<span>
                      ${point[3]}
                  </span>`,
                  )
                  .addTo(chaingeMarkers);
              } else {
                const assetMarker = L.marker([point[0], point[1]], {
                  icon: iconMap.otherMarkerIco,
                  zIndexOffset: 200,
                })
                  .bindPopup(
                    `<span>
                      ${point[3]}
                  </span>`,
                  )
                  .addTo(chaingeMarkers);
              }
            } else if (length_of_point_label < 7 && isNumeric) {
              const circleMarker = L.circleMarker([point[0], point[1]], {
                radius: 4,
                stroke: true,
                color: "red",
                weight: 2,
                fillColor: "#ffffff",
                fillOpacity: 0.9,
              }).addTo(chaingeMarkers);

              const label = L.marker([point[0], point[1]], {
                icon: L.divIcon({
                  className: "leaflet-div-label",
                  html: `<div class="text-[10px] absolute bg-black bg-opacity-50 text-white py-0.5 px-1 rounded w-max">${point[3]} km</div>`,
                  iconAnchor: [0, -10],
                }),
                zIndexOffset: 20, // Ensure the label appears above markers
                pane: "labels", // Assign the label to the custom pane
              }).addTo(chaingeMarkers);

              // Adjust label position
              label.setLatLng([point[0], point[1]]);
            }
          }
        });
      }

      return () => {
        map.removeLayer(chaingeMarkers);
      };
    }
  }, [markersInCurrentBbox]);

  // useEffect(() => {
  //   if (ViewTo.length > 0) {
  //     map.setView([ViewTo[1], ViewTo[0]], 18);
  //   }
  // }, [ViewTo]);

  useEffect(() => {
    if (map && clientName && asset) {
      if (!showComparer) {
        const geoServerClientReferenace = clientName.toLowerCase();
        let wmsUrl;
        wmsUrl = `https://gis.indrones.com/geoserver/${geoServerClientReferenace}/wms`;

        const wmsParams = {
          layers: asset?.Name,
          format: "image/vnd.jpeg-png8",
          transparent: true,
          version: "1.1.1",
          maxZoom: 24,
          keepBuffer: 6,
          tileSize: 256,
        };

        tileLayer && map.removeLayer(tileLayer);

        // const newTileLayer = L.tileLayer.wms(wmsUrl, wmsParams);
        L.WMS = leafletWms;
        let newTileLayer;

        if (leafletWms && !asset?.Name.includes("ORTHO")) {
          newTileLayer = L.WMS.overlay(wmsUrl, wmsParams);

          dispatch(gisLeafletActions.setIsWMSAssetLoading(false));
        } else {
          newTileLayer = L.tileLayer.wms(wmsUrl, wmsParams);
          newTileLayer.on("loading", function () {
            dispatch(gisLeafletActions.setIsWMSAssetLoading(true));
          });

          newTileLayer.on("load", function () {
            dispatch(gisLeafletActions.setIsWMSAssetLoading(false));
          });
        }
        // console.log("newTileLayer", newTileLayer);
        newTileLayer.addTo(map);

        setTileLayer(newTileLayer);

        const {
          westBoundLongitude,
          eastBoundLongitude,
          southBoundLatitude,
          northBoundLatitude,
        } = asset?.EX_GeographicBoundingBox;

        let bounds = L.latLngBounds(
          L.latLng(
            parseFloat(southBoundLatitude),
            parseFloat(westBoundLongitude),
          ),
          L.latLng(
            parseFloat(northBoundLatitude),
            parseFloat(eastBoundLongitude),
          ),
        );
        setBounds(bounds);
        map.fitBounds(bounds);
        // map.setView([23.2087916761, 70.169892497], 18);
        // map.flyTo([15.63299599493898, 73.87548810555688]);

        return () => {
          map.removeLayer(newTileLayer);
        };
      } else {
        IsWMSAssetLoading &&
          dispatch(gisLeafletActions.setIsWMSAssetLoading(false));
        tileLayer && map.removeLayer(tileLayer);
      }
    }
  }, [asset, baseLayer]);

  useCompareAssetsL(mapRef);

  /* UseEffect to remove leaflets defualt ZoomControls */
  useEffect(() => {
    if (map) {
      map.removeControl(map?._controlCorners?.topleft);

      let zoomControl = null;

      return () => {
        if (zoomControl !== null) {
          map.removeControl(zoomControl);
          zoomControl = null;
        }
      };
    }
  }, [map]);

  useEffect(() => {
    const kmlFiles = projectData?.assetLayers.filter(
      (layer) => layer.fileType === "kml",
    );

    const loadKML = (kmlFile) => {
      const kmlLayer = {
        id: uuidv4(),
        KmlName: kmlFile?.name,
        kmlFilePath: process.env.REACT_APP_CDN_BASE_URL + kmlFile?.path,
        visible: kmlFile.initialVisible,
      };
      dispatch(gisLeafletActions.setKmlsetArray(kmlLayer));
    };
    kmlFiles.forEach(loadKML);
    return () => {
      // Cleanup function to remove the loaded KML layers and associated actions
      dispatch(gisLeafletActions.clearKmlsetArray());
    };
  }, []);

  /* useeffect to add shpae file objetcs in array*/
  useEffect(() => {
    const shapeFiles = projectData?.assetLayers.filter(
      (layer) => layer.fileType === "shapeFile",
    );
    const loadShapeFiles = (shapeFile) => {
      const shapeLayer = {
        id: uuidv4(),
        layerName: shapeFile?.layerName, //actual layer name in geoserver
        shapeFileName: shapeFile.name,
        shapeFileePath: process.env.REACT_APP_CDN_BASE_URL + shapeFile?.path,
        visible: shapeFile.initialVisible,
      };
      dispatch(gisLeafletActions.setshapeFeturesetArray(shapeLayer));
    };
    shapeFiles.forEach(loadShapeFiles);
    // addShapeFiles();
    return () => {
      // Cleanup function to remove the loaded shapeFiles layers and associated actions
      dispatch(gisLeafletActions.clearshapeFeturesetArray());
    };
  }, []);

  const getPolylineColor = (severity) => {
    severity = severity.toLowerCase();

    switch (severity) {
      case "low":
        return "yellow";
      case "medium":
        return "orange";
      case "high":
        return "red";
      default:
        return "blue"; // Default color
    }
  };

  // Function to determine polygon color based on severity
  const getPolygonColor = (severity) => {
    severity = severity.toLowerCase();
    switch (severity) {
      case "low":
        return "yellow";
      case "medium":
        return "orange";
      case "high":
        return "red";
      default:
        return "blue"; // Default color
    }
  };

  const highMarkerIcon = new L.Icon({
    className: "custom-marker-icon",
    iconUrl: highMarker,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const mediumMarkerIcon = new L.Icon({
    className: "custom-marker-icon",
    iconUrl: mediumMarker,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const lowMarkerIcon = new L.Icon({
    className: "custom-marker-icon",
    iconUrl: lowMarker,
    iconSize: [32, 32],
  });

  const defaultMarkerIcon = new L.Icon({
    className: "custom-marker-icon",
    iconUrl: defaultMarker,
    iconSize: [32, 32],
  });
  const assetMarkerIcon = new L.Icon({
    className: "custom-marker-icon",
    iconUrl: assetMarkerIco,
    iconSize: [32, 32],
  });

  // Function to determine which icon to use based on severity
  function getMarkerIcon(severity) {
    severity = severity.toLowerCase();
    switch (severity) {
      case "high":
        return highMarkerIcon;
      case "medium":
        return mediumMarkerIcon;
      case "low":
        return lowMarkerIcon;
      default:
        return defaultMarkerIcon;
    }
  }

  const canViewPolygons = useMemo(
    () => userPermissions.includes("annotation_polygon_view"),
    [userPermissions],
  );
  const canViewPolylines = useMemo(
    () => userPermissions.includes("annotation_polyline_view"),
    [userPermissions],
  );

  const visibilityMap = useMemo(
    () =>
      new Map(
        observationsVisibilityStatus.map((visItem) => [
          visItem.id,
          visItem.visible,
        ]),
      ),
    [observationsVisibilityStatus],
  );

  const addMarkersToMap = useCallback(
    (markerObjects, markerClusterGroup) => {
      markerClusterGroup.clearLayers();
      markerObjects.forEach((marker) => {
        const markerToAdd = L.marker(marker?.markerPosition, {
          icon: getMarkerIcon(marker.severity),
          zIndexOffset: 200,
        });
        markerToAdd._leaflet_id = marker.id;
        markerToAdd.type = marker.filter_type;
        markerToAdd.on("click", () => {
          setClickedMarker(marker);
          setIsMetaModalOpen(true);
        });

        markerClusterGroup.addLayer(markerToAdd);
      });
    },
    [visibilityMap, observationsBucket],
  );

  const addPolygonsToMap = useCallback(
    (polygonObjects, polygonCdeflateFeatures, shapeLabelLayerGroup) => {
      polygonCdeflateFeatures.clearLayers();
      shapeLabelLayerGroup.clearLayers();

      // Create a new layer group for shape labels

      const handleZoom = () => {
        if (map.getZoom() >= 18) {
          if (!map?.hasLayer(shapeLabelLayerGroup)) {
            map.addLayer(shapeLabelLayerGroup);
          }
        } else {
          if (map.hasLayer(shapeLabelLayerGroup)) {
            map.removeLayer(shapeLabelLayerGroup);
          }
        }
      };

      polygonObjects.forEach((polygon) => {
        const polygonToAdd = L.polygon(polygon.markerPosition, {
          color: getPolygonColor(polygon.severity),
          smoothFactor: 0,
        });
        polygonToAdd._leaflet_id = polygon.id;
        polygonToAdd.type = polygon.filter_type;

        const shapeLabel = markerClass.handleLabelCreation(
          polygonToAdd,
          map,
          polygon.observationType,
        );

        polygonToAdd.on("click", () => {
          setClickedMarker(polygon);
          setIsMetaModalOpen(true);
        });

        measure.handlePolygon(polygonToAdd, map, null, storedPreset);
        polygonCdeflateFeatures.addLayer(polygonToAdd);

        // Add shapeLabel to the shapeLabelLayerGroup
        shapeLabelLayerGroup.addLayer(shapeLabel);
      });

      // Handle initial zoom level
      handleZoom();

      // Set up zoom end event listener
      map.on("zoomend", handleZoom);
    },
    [visibilityMap, observationsBucket],
  );

  const addPolylinesToMap = useCallback(
    (polylineObjects, polylineCdeflateFeatures) => {
      polylineCdeflateFeatures.clearLayers();
      polylineObjects.forEach((polyline) => {
        const polylineToAdd = L.polyline(polyline.markerPosition, {
          color: getPolylineColor(polyline.severity),
        });
        polylineToAdd._leaflet_id = polyline.id;
        polylineToAdd.type = polyline.filter_type;
        polylineToAdd.on("click", () => {
          setClickedMarker(polyline);
          setIsMetaModalOpen(true);
        });
        measure.handlePolyline(polylineToAdd, map, null, storedPreset);
        polylineCdeflateFeatures.addLayer(polylineToAdd);
      });
    },
    [visibilityMap, observationsBucket],
  );
  useEffect(() => {
    const masterClusterGroup = L.markerClusterGroup().addTo(map);
    const markerClusterGroup = L.markerClusterGroup().addTo(map);
    const polygonClusterGroup =
      L.markerClusterGroup().addTo(masterClusterGroup);
    const polylineClusterGroup =
      L.markerClusterGroup().addTo(masterClusterGroup);
    const shapeLabelLayerGroup = L.layerGroup();

    const polygonCdeflateFeatures = L.deflate({
      minSize: 40,
      markerOptions: { icon: defaultMarkerIcon },
      markerLayer: polygonClusterGroup,
    }).addTo(map);

    const polylineCdeflateFeatures = L.deflate({
      minSize: 10,
      markerOptions: { icon: defaultMarkerIcon },
      markerLayer: polylineClusterGroup,
    }).addTo(map);

    const { markerObjects, polygonObjects, polylineObjects } =
      observationsBucket.reduce(
        (acc, item) => {
          const isVisible = visibilityMap.get(item.id);
          if (isVisible) {
            if (item.filter_type === "polygon" && canViewPolygons) {
              acc.polygonObjects.push(item);
            } else if (item.filter_type === "polyline" && canViewPolylines) {
              acc.polylineObjects.push(item);
            } else if (
              item.filter_type !== "polygon" &&
              item.filter_type !== "polyline"
            ) {
              acc.markerObjects.push(item);
            }
          }
          return acc;
        },
        { markerObjects: [], polygonObjects: [], polylineObjects: [] },
      );

    addMarkersToMap(markerObjects, markerClusterGroup);
    addPolygonsToMap(
      polygonObjects,
      polygonCdeflateFeatures,
      shapeLabelLayerGroup,
    );
    addPolylinesToMap(polylineObjects, polylineCdeflateFeatures);

    return () => {
      map.removeLayer(markerClusterGroup);
      map.removeLayer(polygonClusterGroup);
      map.removeLayer(polylineClusterGroup);
      map.removeLayer(masterClusterGroup);
      map.removeLayer(shapeLabelLayerGroup);
    };
  }, [observationsBucket, observationsVisibilityStatus, refCounter]);

  /**
   * The function `handleZoom` takes a direction ('in' or 'out') as input and zooms the map in or out
   * accordingly. only for 2d i.e. leaflet
   *
   * @param {*} direction
   */
  const handleZoom = (direction) => {
    if (direction === "in") {
      map.zoomIn();
    } else if (direction === "out") {
      map.zoomOut();
    }
  };

  const handleHomeButtonClick = () => {
    // replace with your logic to get bounds

    if (bounds && bounds.length) {
      map.flyToBounds(bounds, { duration: 15, easeLinearity: 0.25 });
    } else {
      // Coordinates for India
      const indiaBounds = [
        [8.4, 68.7],
        [37.6, 97.25],
      ]; // Example coordinates for India's bounding box
      map.flyToBounds(indiaBounds, { duration: 15, easeLinearity: 0.25 });
    }
  };

  return (
    <>
      <div className="z-[1000] h-[auto]">
        {/* <input
          type="file"
          onChange={handleFileChange}
          className="z-[1000] absolute top-10 left-10 w-[10rem]"
        /> */}
        <GISToolbarLeaflet
          handleHomeButtonClick={handleHomeButtonClick}
          handleZoom={handleZoom}
          mapRef={mapRef}
        />
        {showComparer && <ComparerUIL />}
        {isMetaModalOpen && (
          <MetaDataModalLeaflet
            isOpen={isMetaModalOpen}
            onClose={() => setIsMetaModalOpen(false)}
            marker={clickedMarker}
            onDeleteMarker={() => {
              // handleDeleteMarker(selectedMarker?.id, selectedMarker?.timestamp)
            }}
            map={map}
          />
        )}
      </div>
      <Toaster />
    </>
  );
};

export default GISLeaflet;
