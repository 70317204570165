import { useParams } from "react-router-dom";
import GISHomeLeaflet from "../../Pages/GIS/GISHomeLeaflet";
import GISHome3d from "../GIS/GISHome3d";
import GISHome3dCompare from "../GIS/GISHome3dCompare";

const DemoFlowLayout = () => {
  const { mode } = useParams();

  if (mode === "ortho") {
    return <GISHomeLeaflet />;
  } else if (mode === "3d") {
    return <GISHome3d />;
  } else if (mode === "video") {
    //video page will go here
  }
};

export default DemoFlowLayout;
