import {
  oilIcon,
  municipalIcon,
  railwayIcon,
  infraIcon,
  landRecordsIcon,
  miningTruckIcon,
  smartCityIcon,
  forestIcon,
} from "../../Assets/icons/gisIcons/icons";
import {
  aksharIndexing,
  esselMining,
  hmelIndexing,
} from "./demoIndexingConfigs";
export const industryImages = {
  Forestry: forestIcon,
  "Municipality - I": municipalIcon,
  "Municipality - II": municipalIcon,
  "Municipality - III": municipalIcon,
  Railway: railwayIcon,
  "Railway - II": railwayIcon,
  "Oil and Gas": oilIcon,
  "Oil and Gas - I": oilIcon,
  "Oil and Gas - II": oilIcon,
  "Real Estate - I": infraIcon,
  "Real Estate - II": infraIcon,
  "Real Estate - III": infraIcon,
  "Real Estate - IV": infraIcon,
  "Real Estate - V": infraIcon,
  "Real Estate - VI": infraIcon,
  Mining: miningTruckIcon,
  "Smart city": smartCityIcon,
  "Land Records - I": landRecordsIcon,
  "Land Records - II": landRecordsIcon,
};
const projectCatalog = {
  industries: [
    {
      id: 1,
      name: "Railway",
      clientName: "MRVC",
      availableData: [],
      onGeoserver: false,
      onCesiumION: false,
      tags: ["VDO"],
      subheading: "Linear asset monitoring",
      assetLayers: [],
      photogrammetryLayers: [],
      projectType: "Videography",
      mode: "video",
      routeId: "rv",
      natureOfProject: "linear",
      location: "",
    },
    {
      id: 4, // don't change the id
      name: "Oil and Gas",
      clientName: "MRVC",
      availableData: [],
      onGeoserver: false,
      onCesiumION: false,
      tags: ["VDO", "AI"],
      subheading: "Linear asset monitoring",
      assetLayers: [],
      photogrammetryLayers: [],
      projectType: "Videography",
      mode: "video",
      routeId: "ogv",
      natureOfProject: "linear",
    },
    {
      id: 3,
      name: "Oil and Gas - I",
      clientName: "hmel",
      availableData: ["ORI", "DSM"],
      onGeoserver: true,
      onCesiumION: false,
      tags: ["ORI", "DSM"],
      subheading: "Linear asset monitoring",
      assetLayers: [
        {
          file: "https://d2boh9caghjgas.cloudfront.net/hmel/KML/SV_Markers.kml",
          name: "SV stations",
          initialVisible: true,
        },
        {
          path: "/hmel/KML/HMEL_50m_Buffer_GREEN_ZONE.kml",
          name: "RoU",
          fileType: "kml",
          initialVisible: true,
          type: "line",
        },
        {
          path: "/hmel/KML/hmel_centerline.kml",
          name: "Center line",
          fileType: "kml",
          initialVisible: true,
          type: "line",
        },
        {
          path: "/hmel/KML/hmel_chainage_markers_old.json",
          name: "Chainage",
          fileType: "json",
          initialVisible: true,
          type: "label",
        },
        {
          file: "https://d2boh9caghjgas.cloudfront.net/hmel/KML/hmel_chainage_markers_old.json",
          name: "Chainage",
          initialVisible: true,
          type: "label",
        },
      ], // path is the cdn link and type is the file type ( kml / json)
      photogrammetryLayers: [{ layerName: "layer_name", layerType: "ORI" }],
      assetIndexing: {
        indexingType: "sectional",
        indexingFormat: hmelIndexing,
      },
      mode: "ortho",
      projectType: "Photogrammetry",
      routeId: "ogip",
      natureOfProject: "linear",
      location: "Mundra-Bhatinda, ",
    },
    {
      id: 2,
      name: "Real Estate - I",
      clientName: "Agp",
      availableData: ["ORI"],
      onGeoserver: true,
      onCesiumION: false,
      compareMode: true,
      tags: ["ORI"],
      assetLayers: [
        {
          path: "/agp/KML/mukund_ind.kml",
          name: "Boundary",
          fileType: "kml",
          initialVisible: false,
          type: "line",
        },
      ],
      photogrammetryLayers: [
        { layerName: "AGP_Group_23_03_2022_COG", layerType: "ORI" },
        { layerName: "AGP_Group_25_06_2022_COG", layerType: "ORI" },
      ],
      assetIndexing: {
        indexingType: "timeline",
        indexingFormat: null,
      },
      mode: "ortho",
      projectType: "Photogrammetry",
      routeId: "ip",
      natureOfProject: "nonlinear",
      location: "Maharashtra, ",
      documents: [
        {
          title: "Case Study",
          url: "https://d2boh9caghjgas.cloudfront.net/agp/documents/AGP_Group_Case_Study.pdf",
        },
        {
          title: "CAD Overlay Report",
          url: "https://d2boh9caghjgas.cloudfront.net/agp/documents/CAD_Overlay_Report.pdf",
        },
        {
          title: "Annotations Report",
          url: "https://d2boh9caghjgas.cloudfront.net/documents/annotations_Report-to-display.pdf",
        },
      ],
    },
    {
      id: 5,
      name: "Forestry",
      clientName: "deheradun",
      availableData: ["ORI", "DSM", "DTM"],
      onGeoserver: false,
      onCesiumION: false,
      tags: ["ORI", "DSM", "DTM"],
      subheading: "",
      assetLayers: [],
      photogrammetryLayers: [],
      assetIndexingType: "",
      mode: "ortho",
      projectType: "Photogrammetry",
      routeId: "fp",
      natureOfProject: "nonlinear",
      location: "",
    },
    {
      id: 6, // don't change the id
      name: "Real Estate - VI",
      clientName: "akshar",
      availableData: [],
      onGeoserver: false,
      onCesiumION: false,
      tags: ["VDO"],
      subheading: "Project monitoring",
      assetLayers: [],
      photogrammetryLayers: [],
      projectType: "Videography",
      mode: "video",
      routeId: "reviv",
      natureOfProject: "linear",
      documents: [
        {
          title: "Feature digitization",
          url: "https://d2boh9caghjgas.cloudfront.net/documents/AKSHAR-SNAP-pdf.pdf",
        },
      ],
    },
    {
      id: 15,
      name: "Municipality - II",
      clientName: "gangasagar",
      availableData: ["ORI"],
      onGeoserver: false,
      onCesiumION: false,
      tags: ["ORI"],
      subheading: "Crowd counting",
      assetLayers: [],
      photogrammetryLayers: [
        {
          layerName: "LOT_8_ORTHO_COG",
          layerType: "ORI",
          nameToShow: "Sagar - Lot 8",
        },
        {
          layerName: "KACHUBERIA_ORTHO_COG",
          layerType: "ORI",
          nameToShow: "Sagar - Kachuberia",
        },
      ],
      mode: "ortho",
      projectType: "Photogrammetry",
      routeId: "mip",
      natureOfProject: "nonlinear",
      location: "Kolkata, ",
      assetIndexing: {
        indexingType: "general",
        indexingFormat: null,
      },
    },
    // {
    //   id: 7,
    //   name: "Municipality - II",
    //   clientName: "Barve",
    //   availableData: [],
    //   onGeoserver: false,
    //   onCesiumION: false,
    //   tags: ["ORI"],
    //   subheading: "",
    //   assetLayers: [],
    //   photogrammetryLayers: [],
    //   assetIndexingType: "",
    //   mode: "ortho",
    //   projectType: "Photogrammetry",
    //   natureOfProject: "nonlinear",
    //   location: "Maharashtra, ",
    // },
    {
      id: 8,
      name: "Municipality - I",
      clientName: "Gangasagar temple area",
      availableData: ["3D"],
      onGeoserver: false,
      onCesiumION: true,
      tags: ["3D"],
      subheading: "",
      assetLayers: [],
      photogrammetryLayers: [{ layerName: 2596015, layerType: "3DTILES" }],
      assetIndexingType: "",
      mode: "3d",
      projectType: "Photogrammetry",
      routeId: "miiip",
      natureOfProject: "nonlinear",
      location: "Kolkata, ",
    },
    {
      id: 9,
      name: "Smart city",
      clientName: "share UAV",
      availableData: ["3d tiles"],
      onGeoserver: false,
      onCesiumION: true,
      tags: ["3D"],
      subheading: "",
      assetLayers: [],
      photogrammetryLayers: [{ layerName: 2525683, layerType: "3DTILES" }],
      assetIndexingType: "",
      mode: "3d",
      projectType: "Photogrammetry",
      routeId: "scp",
      natureOfProject: "nonlinear",
      location: "Delhi, ",
    },
    {
      id: 10,
      name: "Land Records - I",
      clientName: "Delhi",
      availableData: ["3D"],
      onGeoserver: false,
      onCesiumION: true,
      tags: ["3D"],
      subheading: "",
      assetLayers: [],
      photogrammetryLayers: [{ layerName: 2589234, layerType: "3DTILES" }],
      assetIndexingType: "",
      mode: "3d",
      projectType: "Photogrammetry",
      routeId: "lrp",
      natureOfProject: "nonlinear",
      location: "",
    },
    {
      id: 11,
      name: "Mining",
      clientName: "essel",
      availableData: ["ORI", "DSM", "DTM"],
      onGeoserver: false,
      onCesiumION: false,
      tags: ["ORI", "DSM", "DTM"],
      subheading: "",
      assetLayers: [
        {
          path: "/demo/mining/kml/Pit+Area.kml",
          name: "Pit Area",
          fileType: "kml",
          initialVisible: false,
          type: "polygon",
        },
        {
          path: "/demo/mining/kml/Coalyard.kml",
          name: "Coalyard",
          fileType: "kml",
          initialVisible: false,
          type: "polygon",
        },
        {
          path: "/demo/mining/shapeFiles/ESSEL_MINING_Without_prj.zip", //not required
          layerName: "ESSEL_MINING", //actual layer name in geoserver
          name: "Feature",
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
      ],
      mode: "ortho",
      photogrammetryLayers: {
        nameToShow: "Mining",
        layers: [
          { layerName: "ORTHO_ESSEL_MINING", layerType: "ORI" },
          {
            layerName: "DSM_ESSEL_MINING",
            layerType: "DSM",
          },
          {
            layerName: "DTM_ESSEL_MINING",
            layerType: "DTM",
          },
        ],
      },

      assetIndexing: {
        indexingType: "general",
        indexingFormat: esselMining,
      },
      projectType: "Photogrammetry",
      routeId: "mp",
      natureOfProject: "nonlinear",
      location: "Madhya Pradesh, ",
      documents: [
        {
          title: "Mining Volume",
          url: "https://d2boh9caghjgas.cloudfront.net/demo/mining/documents/ESSEL_MINING_VOLUME.pdf",
        },
      ],
    },
    {
      id: 11,
      name: "Real Estate - II",
      clientName: "agp",
      availableData: ["3D"],
      onGeoserver: false,
      onCesiumION: true,
      tags: ["3D"],
      subheading: "Project monitoring",
      assetLayers: [],
      photogrammetryLayers: [{ layerName: 2472939, layerType: "3DTILES" }],
      assetIndexingType: "",
      mode: "3d",
      projectType: "Photogrammetry",
      routeId: "iiip",
      natureOfProject: "nonlinear",
      location: "Maharashtra, ",
    },
    {
      id: 12,
      name: "Real Estate - III",
      clientName: "wearhouse",
      availableData: ["3D"],
      onGeoserver: false,
      onCesiumION: true,
      tags: ["3D", "BIM"],
      subheading: "Bim Solutions",
      assetLayers: [],
      photogrammetryLayers: [{ layerName: 2620148, layerType: "3DTILES" }],
      assetIndexingType: "",
      mode: "3d",
      projectType: "Photogrammetry",
      routeId: "iiiip",
      natureOfProject: "nonlinear",
      location: "Maharashtra, ",
    },
    {
      id: 13,
      name: "Real Estate - IV",
      clientName: "wearhouse",
      availableData: ["3D"],
      onGeoserver: false,
      onCesiumION: true,
      tags: ["3D"],
      subheading: "Commercial Building",
      assetLayers: [],
      photogrammetryLayers: [{ layerName: 2634788, layerType: "3DTILES" }],
      assetIndexingType: "",
      mode: "3d",
      projectType: "Photogrammetry",
      routeId: "iiiip",
      natureOfProject: "nonlinear",
      location: "Maharashtra, ",
    },
    {
      id: 14,
      name: "Real Estate - V",
      clientName: "akshar",
      availableData: ["ORI"],
      onGeoserver: true,
      onCesiumION: false,
      compareMode: true,
      tags: ["ORI"],
      assetLayers: [],
      photogrammetryLayers: [
        {
          layerName: "AKSHAR_BUSINESS_PARK_ORTHO_COG",
          layerType: "ORI",
          nameToShow: "Vashi (ORI)",
        },
        {
          layerName: "AKSHAR_BUSINESS_PARK_DSM_COG",
          layerType: "DSM",
          nameToShow: "Vashi (DSM)",
        },
      ],
      assetIndexing: {
        indexingType: "general",
        indexingFormat: aksharIndexing,
      },
      mode: "ortho",
      projectType: "Photogrammetry",
      routeId: "rivp",
      natureOfProject: "nonlinear",
      location: "Maharashtra, ",
      subheading: "Project monitoring",
      documents: [
        {
          title: "500 buffer",
          url: "https://d2boh9caghjgas.cloudfront.net/documents/500M_BUFFER.pdf",
        },
        {
          title: "Aerial map with boundary",
          url: "https://d2boh9caghjgas.cloudfront.net/documents/AERIAL_MAP_WITH_BOUNDARY.pdf",
        },
        {
          title: "Contour map",
          url: "https://d2boh9caghjgas.cloudfront.net/documents/CONTOUR_MAP.pdf",
        },
        {
          title: "Coordinate map",
          url: "https://d2boh9caghjgas.cloudfront.net/documents/COORDINATE_MAP.pdf",
        },
        {
          title: "Drainage direction level map",
          url: "https://d2boh9caghjgas.cloudfront.net/documents/DRAINAGE_DIRECTION_LEVEL_MAP.pdf",
        },
        {
          title: "Drainage map",
          url: "https://d2boh9caghjgas.cloudfront.net/documents/DRAINAGE_MAP.pdf",
        },
        {
          title: "Grid 10x10m map",
          url: "https://d2boh9caghjgas.cloudfront.net/documents/GRID_10X10M_MAP.pdf",
        },
        {
          title: "HT line 30mtr buffer map",
          url: "https://d2boh9caghjgas.cloudfront.net/documents/HT_LINE_30MTR_BUFFER_MAP.pdf",
        },
        {
          title: "Kdmc",
          url: "https://d2boh9caghjgas.cloudfront.net/documents/kdmc.pdf",
        },
        {
          title: "Road level map",
          url: "https://d2boh9caghjgas.cloudfront.net/documents/ROAD_LEVEL_MAP.pdf",
        },
        {
          title: "Road width and length map",
          url: "https://d2boh9caghjgas.cloudfront.net/documents/ROAD_WIDTH_AND_LENGTH_MAP.pdf",
        },
        {
          title: "Tech boundary map",
          url: "https://d2boh9caghjgas.cloudfront.net/documents/TECH_BOUNDARY_MAP.pdf",
        },
        {
          title: "Topographical map",
          url: "https://d2boh9caghjgas.cloudfront.net/documents/TOPOGRAPHYCAL_MAP.pdf",
        },
        {
          title: "Volume map",
          url: "https://d2boh9caghjgas.cloudfront.net/documents/VOLUME_MAP.pdf",
        },
      ],
    },
    {
      id: 15,
      name: "Land Records - II",
      clientName: "karnataka3d",
      availableData: ["3D"],
      onGeoserver: false,
      onCesiumION: true,
      tags: ["3D"],
      subheading: "",
      assetLayers: [],
      photogrammetryLayers: [{ layerName: 2641792, layerType: "3DTILES" }],
      assetIndexingType: "",
      mode: "3d",
      projectType: "Photogrammetry",
      routeId: "lriip",
      natureOfProject: "nonlinear",
      location: "Karnataka, ",
    },
    {
      id: 16,
      name: "Railway - II",
      clientName: "MRVC",
      availableData: [],
      onGeoserver: true,
      onCesiumION: false,
      tags: ["ORI", "SHP"],
      subheading: "Linear asset monitoring",
      assetLayers: [
        {
          path: "", // not required
          layerName: "mrvc:BRIDGE", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:CH-LINE", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:CHAINAGE", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:CHAINAGE_POINT", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:DRAIN", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:ELECTRIC_POLE", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:EXISTING LAND BOUNDARY", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:EXISTING_TRACK", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:LAMP", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:LT_LINE", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:NALA", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:OVER_HEAD_WATER_TANK", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:PAYLON", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:PLATFORM", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:POND", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:PROPOSED_RAIL_TRACK", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:PROP_ROW", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:RIVER", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:ROAD", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:STRUCTURE", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:WALL", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
        {
          path: "", // not required
          layerName: "mrvc:WELL", // actual layer name in GeoServer
          name: "mrvc", // updated to "mrvc"
          fileType: "shapeFile",
          initialVisible: false,
          type: "LineString",
        },
      ],
      mode: "ortho",
      photogrammetryLayers: [
        {
          layerName: "ORTHO_MRVC",
          layerType: "ORI",
          nameToShow: "ORI",
        },
      ],
      assetIndexing: {
        indexingType: "general",
        indexingFormat: aksharIndexing,
      },
      projectType: "Photogrammetry",
      routeId: "rv",
      natureOfProject: "linear",
      location: "",
    },
  ],
};

export default projectCatalog;
