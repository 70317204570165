import "leaflet-geometryutil";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import polylabel from "@mapbox/polylabel";
import "leaflet-draw";
import omnivore from "leaflet-omnivore";
import { fetchData, updateData, storeData } from '../indexedDB';
import { CompressOutlined } from "@mui/icons-material";
class MarkerClass {
  // Marker-specific functions

  addLabelAtCenter(latLng, name, map, layer) {
    // Remove previous label
    if (layer.label) {
      map.removeLayer(layer.label);
    }
    if (name !== null) {
      const label = L.marker(latLng.reverse(), {
        icon: L.divIcon({
          className: "leaflet-div-label",
          html: `
             <div class="measure-label-box">  
                <div class="measure-label" style="">
                
                <span class="measure-label-text">${name}</span>
       
        </div>
             </div>
              `,
        }),
      });

      label.setOpacity(map.getZoom() >= 19 ? 1 : 0);

      layer.label = label;
      map.on("zoomend", () => {
        label.setOpacity(map.getZoom() >= 19 ? 1 : 0);
      });
      this.previousLabel = label;
      return label;
    }
  }

  handleLabelCreation(layer, map, name) {
    const latLngsArray = layer.getLatLngs();
    const array = [latLngsArray[0].map((latLng) => [latLng.lng, latLng.lat])];
    const centerLatLng = polylabel(array, 1.0);
    return this.addLabelAtCenter(centerLatLng, name, map, layer);
  }
}
class Measure {
  // constructor() {
  //   storedPreset = fetchPreset();
  //   console.log("preset", this.preset);
  // }
  /**
   * The function `handlePolyline` calculates the length of a polyline on a map and adds a label with the
   * length at the end of the polyline.
   * @param {Leaflet_layer} layer
   * @param {*} map
   * @param {object} measure
   * @return {label}
   * @memberof Measure
   */
  handlePolyline(layer, map, measure,storedPreset) {
    const lengthInMeters = L.GeometryUtil.length(layer);
    const lastLatLng = layer.getLatLngs()[layer.getLatLngs().length - 1];
    return this.addPolylineLabel(
      lastLatLng,
      lengthInMeters,
      map,
      layer,
      measure,
      storedPreset
    );
  }

  /**
   * The function `handlePolygon` calculates the area and perimeter of a polygon layer on a map and adds
   * a label with this information at the center of the polygon.
   * @param {leaflet layer} layer
   * @param {*} map
   * @param {object} measure
   * @return {leaflet label}
   * @memberof Measure
   */
  handlePolygon(layer, map, measure,storedPreset) {
    const areaInSquareMeters = L.GeometryUtil.geodesicArea(
      layer.getLatLngs()[0],
    );
    const latLngs = layer.getLatLngs()[0];
    const perimeterInMeters = this.calculatePerimeter(latLngs);
    // const centerLatLng = layer.getBounds().getCenter();
    const latLngsArray = layer.getLatLngs();
    const array = [latLngsArray[0].map((latLng) => [latLng.lng, latLng.lat])];
    // const coordinates=[array];
    const centerLatLng = polylabel(array, 1.0);
    return this.addPolygonLabel(
      centerLatLng,
      areaInSquareMeters,
      perimeterInMeters,
      map,
      layer,
      measure,
      storedPreset
    );
  }

  calculatePolylineLength(layer) {
    const lengthInMeters = L.GeometryUtil.length(layer);
    return lengthInMeters;
  }
  calculatePolygonArea(layer) {
    const areaInSquareMeters = L.GeometryUtil.geodesicArea(
      layer.getLatLngs()[0],
    );
    // const latLngs = layer.getLatLngs()[0];
    // const perimeterInMeters = this.calculatePerimeter(latLngs);
    return areaInSquareMeters;
  }

  addPolylineLabel(latLng, lengthInMeters, map, layer, measure,storedPreset) {
    if (layer.label && map) {
      map.removeLayer(layer.label);
    }
    if (measure !== null) {
      const label = L.marker(latLng, {
        icon: L.divIcon({
          className: "leaflet-div-label",
          html: `<div class="measure-label-box">  
                  <div class="measure-label" style="">
                    <span class="measure-label-text">
                    ${this.convertDistance(
                      lengthInMeters,
                      storedPreset?.units.length,
                    )?.toFixed(4)} 
                    ${storedPreset?.units.length}
                    </span>
                  </div>
                </div>`,
        }),
      });
      measure.length = lengthInMeters;
      label.setOpacity(map.getZoom() >= 19 ? 1 : 0);
      layer.label = label;
      map.on("zoomend", () => {
        label.setOpacity(map.getZoom() >= 19 ? 1 : 0);
      });
      this.previousLabel = label;
      return label;
    } else {
      return (layer.length = lengthInMeters);
    }
  }

  addPolygonLabel(
    latLng,
    areaInSquareMeters,
    perimeterInMeters,
    map,
    layer,
    measure,
    storedPreset
  ) {
    // Remove previous label
    if (layer.label) {
      map.removeLayer(layer.label);
    }
    if (measure !== null) {
      const label = L.marker(latLng.reverse(), {
        icon: L.divIcon({
          className: "leaflet-div-label",
          html: `
               <div class="measure-label-box">  
                  <div class="measure-label" style="">                  
                  <span class="measure-label-text">  
                    ${this.convertArea(areaInSquareMeters, storedPreset?.units.area)} 
                    ${storedPreset?.units.area}
                    </span>
                  </div>
               </div>
                `,
        }),
      });

      label.setOpacity(map.getZoom() >= 19 ? 1 : 0);

      measure.area = areaInSquareMeters;
      measure.perimeter = perimeterInMeters;

      layer.label = label;
      map.on("zoomend", () => {
        label.setOpacity(map.getZoom() >= 19 ? 1 : 0);
      });
      this.previousLabel = label;
      return label;
    } else {
      layer.area = areaInSquareMeters;
      layer.perimeter = perimeterInMeters;
    }
  }

  calculatePerimeter(latLngs) {
    let perimeterInMeters = 0;
    for (let i = 0; i < latLngs.length - 1; i++) {
      const latLng1 = latLngs[i];
      const latLng2 = latLngs[i + 1];
      const distance = latLng1.distanceTo(latLng2);
      perimeterInMeters += distance;
    }
    const lastLatLng = latLngs[latLngs.length - 1];
    const firstLatLng = latLngs[0];
    const closingDistance = lastLatLng.distanceTo(firstLatLng);
    perimeterInMeters += closingDistance;
    return perimeterInMeters;
  }

  convertDistance = (value, toUnit) => {
    const conversionFactors = {
      m: 1, // Meters as the base unit
      km: 0.001, // 1 meter = 0.001 kilometers
      Miles: 0.000621371, // 1 meter = 0.000621371 miles
      "Nautical Miles": 0.000539957, // 1 meter = 0.000539957 nautical miles
    };
    // Then convert from the base unit to the target unit
    return value * conversionFactors[toUnit];
  };
  convertArea = (value, toUnit) => {
    const conversionFactors = {
      "m²": 1, // Square meters as the base unit
      "km²": 1e-6,
      Acres: 0.000247105,
      Hectares: 0.0001,
    };
    const convertedValue = value * conversionFactors[toUnit];
    if (toUnit === "m²") {
      return convertedValue.toFixed(2);
    } else if (convertedValue < 0.01) {
      return convertedValue.toExponential(2); // Use scientific notation for small values
    } else {
      return convertedValue.toFixed(4);
    }
  };
}
class LayerControlUtils {
  /**
   * This function is used to retrieve a specific layer from a Leaflet map based on its id.
   * @param {alphanumeric} id
   * @param {*} map
   * @memberof LayerControlUtils
   */
  getLayerByIdUtil = (id, map) => {
    let targetLayer = null;
    map.eachLayer((layer) => {
      if (layer._leaflet_id === id) {
        targetLayer = layer;
      }
    });
    return targetLayer;
  };

  /**
   * This function is used to change the opacity of a marker layer on a map  based on the provided `id`.
   * @param {alphanumeric} id
   * @param {opacity_value} OvalueChange - value between 0 and 1
   * @param {*} map
   * @memberof LayerControlUtils
   */
  handleAssetOpacityChange = (id, OvalueChange, map) => {
    const markerLayer = this.getLayerByIdUtil(id, map);
    // If the marker layer exists
    if (markerLayer) {
      // Determine the type of the layer
      if (markerLayer.type === "polyline") {
        markerLayer.setStyle({ opacity: OvalueChange });
      } else if (markerLayer.type === "polygon") {
        let fillINOpacity;
        if (OvalueChange > 0.2) {
          fillINOpacity = 0.2;
        } else {
          fillINOpacity = OvalueChange;
        }
        markerLayer.setStyle({
          fillOpacity: fillINOpacity, // Set fill opacity
          opacity: OvalueChange, // Set border opacity
        });
      } else {
        markerLayer.eachLayer(function (layer) {
          if (layer instanceof L.Marker) {
            layer.setOpacity(OvalueChange);
          }
        });
      }
    } else {
      //console.log("Layer not found");
    }
  };

  /**
   * The function is used to change the color of a marker on a map based on the provided `id` and `colour` values.
   * @param {alphanumeric} id
   * @param {HexColor} colour
   * @param {*} map
   * @memberof LayerControlUtils
   */
  handleMarkerColorChange = (id, colour, map) => {
    // Find the layer with the corresponding index
    const markerLayer = this.getLayerByIdUtil(id, map);
    // If the marker layer exists
    if (markerLayer && markerLayer.type !== "marker") {
      // Check if it's a polygon layer
      if (markerLayer instanceof L.Polygon) {
        // Update the style of the polygon
        markerLayer.setStyle({ color: colour, fillColor: colour });
      } else if (markerLayer instanceof L.Polyline) {
        // Update the style of the polyline
        markerLayer.setStyle({ color: colour });
      }
    }
  };

  /**
   * function toggles the visibility of a measurement identified by its `id.
   * @param {alphanumeric} id
   * @param {ArrayOfMeasuremtObjects} measurementArray
   * @param {methodThatSets_measurementArray} setMeasurementArray
   * @param {*} map
   * @memberof LayerControlUtils
   */
  toggleMeasurementVisibilityById = async (
    id,
    measurementArray,
    setMeasurementArray,
    map,
  ) => {
    const measurementToUpdate = measurementArray.find(
      (measurement) => measurement.id === id,
    );

    if (measurementToUpdate) {
      measurementToUpdate.visible = !measurementToUpdate.visible;

      setMeasurementArray((prevMeasurements) => {
        const updatedMeasurements = prevMeasurements.map((measurement) => {
          if (measurement.id === id) {
            return measurementToUpdate;
          }
          return measurement;
        });
        return updatedMeasurements;
      });

      const layer = this.getLayerByIdUtil(id, map);

      // Check if the layer exists
      if (layer) {
        // Toggle the opacity of the layer based on visibility
        const opacity = measurementToUpdate.visible ? 0.5 : 0;
        const opacitylabel = measurementToUpdate.visible ? 1 : 0;

        // Update the opacity of the layer and label
        if (measurementToUpdate.filter_type === "polyline") {
          layer.setStyle({ opacity: opacity });
          // layer.label.setStyle({ opacity: opacity });
          layer.label.setOpacity(opacitylabel);
        } else if (measurementToUpdate.filter_type === "polygon") {
          layer.setStyle({
            fillOpacity: opacity, // Set fill opacity
            opacity: opacity, // Set border opacity
          });
          layer.label.setOpacity(opacitylabel);
        }
      } else {
        //console.error(`Layer with ID ${id} not found.`);
      }
    } else {
      //console.error(`Measurement with ID ${id} not found.`);
    }
    await updateData('measurements',1,measurementArray);
  };

  /**
   * Methos removes a particular measure from map and local storage.
   * @param {alphanumeric} id
   * @param {methodThatSets_measurementArray} setMeasurementArray
   * @param {*} map
   * @memberof LayerControlUtils
   */
  clearMeasure = async (id, measurementArray, setMeasurementArray, map) => {
    let layerToRemove = this.getLayerByIdUtil(id, map);
    map.removeLayer(layerToRemove.label); // remove label
    map.removeLayer(layerToRemove); //remove layer
    const updatedLayers = measurementArray.filter(
      (layer, index) => layer.id !== id,
    );
    setMeasurementArray(updatedLayers);
    await updateData('measurements',1,updatedLayers);
    // dispatch(gisLeafletActions.refCounterMeasureUpdate());
    // avoids calling useEffect and re-add all markers again thus colous and other layercontroll properties of exiting makers are maintained
  };

  /**
   * Method removes all checked measure from map.
   * @param {*} measurementArray
   * @param {*} subCheckboxesChecked
   * @param {*} markerFeatureGroup
   * @param {*} hideAll
   * @param {*} setMeasurementArray
   * @param {*} setHideAll
   * @param {*} map
   * @memberof LayerControlUtils
   */
  clearAllMeasure = async (
    measurementArray,
    subCheckboxesChecked,
    markerFeatureGroup,
    hideAll,
    setMeasurementArray,
    setHideAll,
    map,
  ) => {
    // Check if all measurements are selected
    const allSelected = measurementArray.every(
      (measurement) => subCheckboxesChecked[`checkbox${measurement.id}`],
    );

    if (allSelected) {
      // Clear all measurements if all are selected
      map.eachLayer((layer) => {
        if (layer.label) {
          map.removeLayer(layer.label);
        }
      });
      markerFeatureGroup.clearLayers();

      await updateData('measurements',1,[]);
      setHideAll(!hideAll);
      // dispatch(gisLeafletActions.refCounterMeasureUpdate());
    } else {
      // Clear only selected measurements
      const selectedMeasurements = measurementArray.filter(
        (measurement) => subCheckboxesChecked[`checkbox${measurement.id}`],
      );

      selectedMeasurements.forEach((measurement) => {
        this.clearMeasure(measurement.id, setMeasurementArray, map);
      });
    }
  };

  loadKML = (kmlFile, id) => {
    return fetch(kmlFile)
      .then((response) => response.text())
      .then((kmlData) => {
        const kmlLayer = omnivore.kml.parse(kmlData);
        return kmlLayer;
      })
      .catch((error) => {
        console.error("Error loading KML:", error);
        throw error;
      });
  };

  handleMeasureEditClick = (
    id,
    index,
    setSelecteMeasuredLayer,
    setEditMeasureMode,
    editCtrlMeasure,
    editMeasureMode,
    measurement,
    setMeasurementArray,
    setShowMeasureEditButtonsArray,
    markerFeatureGroup,
    measurementArray,
    map,
    storedPreset
  ) => {
    const layer = this.getLayerByIdUtil(id, map);
    setSelecteMeasuredLayer(layer); // Set the selected layer for editing
    setSelecteMeasuredLayer(layer); // Set the selected layer for editing
    map.removeLayer(layer.label);
    if (layer) {
      // Toggle the edit mode
      setEditMeasureMode((prevEditMeasureMode) => !prevEditMeasureMode);
      setShowMeasureEditButtonsArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = !newArray[index];
        return newArray;
      });
      const selectedMeasureFeatureGroup = L.featureGroup([layer]);
      // Initialize the edit control with the new feature group if it's not initialized
      if (!editCtrlMeasure.current) {
        const editCtrlMeasureOptions = {
          featureGroup: selectedMeasureFeatureGroup,
        };
        editCtrlMeasure.current = new L.EditToolbar.Edit(
          map,
          editCtrlMeasureOptions,
        );
      }
      // Enable or disable the edit functionality based on the edit mode
      if (!editMeasureMode) {
        editCtrlMeasure.current.enable();
      } else {
        editCtrlMeasure.current.disable();
        // setMeasureShowEditButtons(false); // Hide the edit buttons
        // Hide the edit buttons for the specific measure
        setShowMeasureEditButtonsArray((prevArray) => {
          const newArray = [...prevArray];
          newArray[index] = false;
          return newArray;
        });
        this.updateMarkerGeometry(
          layer,
          setMeasurementArray,
          measurement,
          measurementArray,
          markerFeatureGroup,
          map,
          storedPreset
        );
        editCtrlMeasure.current = null;
        setSelecteMeasuredLayer(null);
      }
    }
  };

  updateMarkerGeometry = async (
    layer,
    setMeasurementArray,
    measurement,
    measurementArray,
    markerFeatureGroup,
    map,
    storedPreset
  ) => {
    const measure = new Measure();
    if (layer.type === "polygon") {
      // Calculate area, perimeter, etc.

      const labelPolygon = measure.handlePolygon(layer, map, measurement,storedPreset);
      // labelPolygon.addTo(map);
      markerFeatureGroup.addLayer(labelPolygon);
    } else {
      const labelPolyline = measure.handlePolyline(layer, map, measurement,storedPreset);
      // labelPolyline.addTo(map);
      markerFeatureGroup.addLayer(labelPolyline);
    }
    // measurement.measurePosition = layer.getLatLngs();
    measurement.measurePosition = this.convertLatLngToPlainObject(layer.getLatLngs())
    // Create a new array with updated measurements
    const updatedMarkerData = measurementArray.map((marker) => {
      if (marker.id === measurement.id) {
        return marker;
      }
      return marker;
    });
    // Update the markerData state
    setMeasurementArray(updatedMarkerData);
    // // Update the markerData in local storage
    await updateData('measurements',1,updatedMarkerData);
    // dispatch(gisLeafletActions.refCounterMeasureUpdate());
  };
  handleMeasureSaveEdit = (
    index,
    selectedMeasureLayer,
    editCtrlMeasure,
    setEditMeasureMode,
    setShowMeasureEditButtonsArray,
    measurementArray,
    setSelecteMeasuredLayer,
    markerFeatureGroup,
    setMeasurementArray,
    map,
    storedPreset
  ) => {
    if (selectedMeasureLayer && editCtrlMeasure.current) {
      editCtrlMeasure.current.save();
      editCtrlMeasure.current.disable();
      // Toggle the edit mode
      setEditMeasureMode((prevEditMeasureMode) => !prevEditMeasureMode);
      // Show the edit buttons
      setShowMeasureEditButtonsArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = !newArray[index];
        return newArray;
      });
      map.addLayer(selectedMeasureLayer.label);
      this.updateMarkerGeometry(
        selectedMeasureLayer,
        setMeasurementArray,
        measurementArray[index],
        measurementArray,
        markerFeatureGroup,
        map,
        storedPreset
      );
      editCtrlMeasure.current = null;
      setSelecteMeasuredLayer(null);
    }
  };
  
  handleMeasureCancelEdit = (
    index,
    selectedMeasureLayer,
    editMeasureMode,
    editCtrlMeasure,
    setEditMeasureMode,
    setShowMeasureEditButtonsArray,
    measurementArray,
    setSelecteMeasuredLayer,
    markerFeatureGroup,
    setMeasurementArray,
    map,
    storedPreset
  ) => {
    if (editMeasureMode && selectedMeasureLayer) {
      if (editCtrlMeasure.current) {
        // Check if  editCtrlMeasure.current is initialized
        editCtrlMeasure.current.revertLayers();
        editCtrlMeasure.current.disable(); // Disable the edit control
      }
      setEditMeasureMode(false); // Disable edit mode
      // setMeasureShowEditButtons(false); // Hide the edit buttons
      // Hide the edit buttons for the specific measure
      setShowMeasureEditButtonsArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = false;
        return newArray;
      });
      map.addLayer(selectedMeasureLayer.label);
      this.updateMarkerGeometry(
        selectedMeasureLayer,
        setMeasurementArray,
        measurementArray[index],
        measurementArray,
        markerFeatureGroup,
        map,
        storedPreset
      );

      editCtrlMeasure.current = null;
      setSelecteMeasuredLayer(null);
    }
  };

  // Helper function to convert LatLng to plain object
  convertLatLngToPlainObject = (latLngArray) => {
    // Check if the first element is an array (polygon case)
    if (Array.isArray(latLngArray[0])) {
      return latLngArray.map(innerArray => 
        innerArray.map(latLng => ({
          lat: latLng.lat,
          lng: latLng.lng
        }))
      );
    }
  
    // Polyline case
    return latLngArray.map(latLng => ({
      lat: latLng.lat,
      lng: latLng.lng
    }));
  };
  
}

// Export the classes
export { MarkerClass, Measure, LayerControlUtils };

//     <p class="ml-[1px] text-[10px]">
//       ${
//         perimeterInMeters < 1000
//           ? `${perimeterInMeters.toFixed(2)}`
//           : `${(perimeterInMeters / 1000).toFixed(2)} `
//       }
//     </p>
//     <p class="ml-[1px] text-[10px]">
//       ${perimeterInMeters < 1000 ? "m" : "Km"}
//     </p>
//   </div>
// </div>

//  <div class="flex items-center mt-[0.5px]">
//                       <div class="flex items-center">
//                         <p class="m-0 text-[10px]">
//                           Perimeter:
//                         </p>

//                 </div>
// <button class="remove" aria-label="Remove"><span><svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11"><g fill="none" fill-rule="evenodd" stroke-linecap="round"><line x1="9.5" x2="1.5" y1="1.5" y2="9.5" stroke="#FFF" stroke-width="1.75"/><line x1="9.5" x2="1.5" y1="1.5" y2="9.5" stroke="#FFF" stroke-width="1.75" transform="rotate(-90 5.5 5.5)"/></g></svg></span></button>
